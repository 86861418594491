import { useEffect, useState } from "react";
import classNames from "classnames";
import { IoSearchSharp as SearchIcon } from "react-icons/io5";
import { Dialog, DialogContent } from "@mui/material";
import Button from "@mui/material/Button";

import GlobalSearch from "./GlobalSearch";

const isMac = navigator.userAgent.includes("Mac");

const GlobalSearchAction = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleKeyPress = (event: KeyboardEvent) => {
    if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === "k") {
      event.preventDefault();
      setDialogOpen(true);
    }
  };

  useEffect(() => {
    // Add event listener
    document.addEventListener("keydown", handleKeyPress);

    // Cleanup: Remove event listener when the component is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []); // Empty dependency array ensures it only runs once

  return (
    <>
      <Button
        id="global-search"
        data-testid="global-search"
        onClick={() => setDialogOpen(true)}
        variant="outlined"
        className="w-full !justify-start !border-gray-300 !text-gray-600 hover:!bg-gray-100 flex flex-row"
        startIcon={<SearchIcon />}
      >
        <span>Search</span>
        <span className="border rounded pl-1 pr-1 text-sm ml-auto flex flex-row items-center">
          <kbd
            className={classNames({
              "text-xs": !isMac,
              "text-sm": isMac,
            })}
          >
            {isMac ? "⌘" : "CTRL"}+
          </kbd>

          <kbd className="text-xs">K</kbd>
        </span>
      </Button>
      <Dialog
        disableRestoreFocus
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
            marginTop: "4rem",
          },
        }}
      >
        <DialogContent>
          <GlobalSearch onClick={() => setDialogOpen(false)}></GlobalSearch>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GlobalSearchAction;
