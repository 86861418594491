import { useEffect, useState } from "react";
import { isEqual } from "date-fns";
import { toDate } from "date-fns-tz";

import { formatAPIDate } from "shared/api/utils";

import DatePicker from "features/ui/DatePicker";
import DatePickerRange from "features/ui/DatePickerRange";
import DropdownWithSearch from "features/ui/DropdownWithSearch/DropdownWithSearch";
import InputFilter from "features/ui/Filters/FilterTypes/InputFilter";
import {
  FilterOperator,
  FilterType,
  InitialDateValues,
} from "features/ui/Filters/types";
import {
  DATE_UNIT_OPTIONS,
  getDefaultDateUnitOption,
  getDefaultLastXValue,
  isDateTimeField,
  isValueInLastXFormat,
} from "features/ui/Filters/utils";
import { DataType } from "features/ui/Table/TableBodyCell/types";

interface Props {
  fieldName: string;
  selectedOperatorId: FilterOperator;
  initialValues?: InitialDateValues;
  onDateFromChange: (value: string) => void;
  onDateToChange: (value: string) => void;
  defaultIsOpen?: boolean;
  disabled?: boolean;
  filterType: FilterType;
  dataType?: DataType;
}

const DateFilters = ({
  fieldName,
  selectedOperatorId,
  initialValues,
  onDateFromChange,
  onDateToChange,
  defaultIsOpen,
  disabled,
  dataType,
}: Props) => {
  const isDateTime = isDateTimeField(dataType);

  const [lastXValue, setLastXValue] = useState(
    getDefaultLastXValue(initialValues)
  );
  const [selectedUnitOption, setSelectedUnitOption] = useState(
    getDefaultDateUnitOption(initialValues)
  );

  const initialDateStart =
    (initialValues &&
      initialValues[0] &&
      initialValues[0] !== "null" &&
      !isValueInLastXFormat(initialValues[0]) &&
      toDate(initialValues[0])) ||
    null;

  const initialDateEnd =
    (initialValues &&
      initialValues[1] &&
      !isValueInLastXFormat(initialValues[1]) &&
      toDate(initialValues[1])) ||
    null;

  useEffect(() => {
    if (
      !lastXValue ||
      !selectedUnitOption ||
      selectedOperatorId !== FilterOperator.IN_LAST
    ) {
      return;
    }

    onDateFromChange(`${lastXValue}${selectedUnitOption.id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastXValue, selectedUnitOption, selectedOperatorId]);

  if (selectedOperatorId === FilterOperator.BETWEEN) {
    return (
      <DatePickerRange
        key={fieldName}
        initialDateStart={initialDateStart}
        initialDateEnd={initialDateEnd}
        onChange={(dateFrom: Date | null, dateTo: Date | null) => {
          if (
            dateFrom &&
            (!initialDateStart || !isEqual(dateFrom, initialDateStart))
          ) {
            onDateFromChange(formatAPIDate(dateFrom.toString(), dataType));
          }

          if (dateTo && (!initialDateEnd || !isEqual(dateTo, initialDateEnd))) {
            onDateToChange(formatAPIDate(dateTo.toString(), dataType));
          }
        }}
        defaultIsOpen={defaultIsOpen}
        disabled={disabled}
        showTimeSelect={isDateTime}
      />
    );
  }

  if (selectedOperatorId === FilterOperator.IN_LAST) {
    return (
      <div className="flex flex-row space-x-3 items-center">
        <InputFilter
          fieldName={fieldName}
          type="number"
          value={lastXValue}
          onChange={(e) => {
            setLastXValue(e.target.value);
          }}
          min={1}
          disabled={disabled}
        />
        <DropdownWithSearch
          options={DATE_UNIT_OPTIONS}
          selectedOption={selectedUnitOption}
          onSelectedOptionChange={setSelectedUnitOption}
          disabled={disabled}
          disableClearable={true}
        />
      </div>
    );
  }

  if (
    [
      FilterOperator.GREATER_OR_EQUAL,
      FilterOperator.GREATER_THAN,
      FilterOperator.LESS_OR_EQUAL,
      FilterOperator.LESS_THAN,
    ].includes(selectedOperatorId)
  ) {
    return (
      <DatePicker
        initialDate={initialDateStart}
        disabled={disabled}
        showTimeSelect={isDateTime}
        onChange={(date: Date | null) => {
          if (date) {
            onDateFromChange(formatAPIDate(date.toString(), dataType));
          }
        }}
      />
    );
  }

  return null;
};

export default DateFilters;
