import { DuckMessageFormat } from "duck/context/types";
import { SetAgentResponseOptions, UIHandlers } from "duck/graph/types";

interface SendAgentResponseParams {
  setAgentResponse: UIHandlers["setAgentResponse"];
  selectedTabKey?: string;
  newTabKey?: string;
  newTabTitle?: string;
  chartName?: string;
  chartOptionsKey?: string;
  selectedChartOptions?: Record<string, string>;
  newChartOptions?: Record<string, string | undefined>;
  selectedGroupByAttribute?: string;
  newGroupByAttribute?: string;
  filterString?: string;
  selectedFilterString?: string;
  sortString?: string;
  selectedSortString?: string;
  source?: string;
}

export const sendAgentResponse = ({
  setAgentResponse,
  selectedTabKey,
  newTabKey,
  newTabTitle,
  filterString,
  selectedFilterString,
  sortString,
  selectedSortString,
  chartName,
  chartOptionsKey,
  selectedChartOptions,
  newChartOptions,
  selectedGroupByAttribute,
  newGroupByAttribute,
  source,
}: SendAgentResponseParams): void => {
  const respond = (message: string, options: SetAgentResponseOptions = {}) => {
    setAgentResponse(message, {
      ...options,
      triggerAgentPreludeMessage: true,
    });
  };

  if (newTabKey && selectedTabKey !== newTabKey) {
    respond(`- Navigate to the ${newTabTitle} tab`);
  }

  if (
    filterString !== undefined &&
    (selectedFilterString === undefined ||
      // The selectedFilterString sometimes has more double quotes than the filterQueryString.
      // If the double quotes are the only difference, then consider them to be the same.
      filterString !== selectedFilterString.replaceAll('"', ""))
  ) {
    respond(filterString, {
      format: DuckMessageFormat.FILTER,
      filterOptions: { source: String(source) },
    });
  }

  if (
    sortString !== undefined &&
    (selectedSortString === undefined || sortString !== selectedSortString)
  ) {
    respond(sortString, {
      format: DuckMessageFormat.SORT,
      sortOptions: { source: String(source) },
    });
  }

  if (newChartOptions) {
    let alreadySetChartOptionsHeader = false;
    Object.entries(newChartOptions).forEach(([actionId, optionId]) => {
      if (
        !selectedChartOptions ||
        selectedChartOptions[actionId] !== optionId
      ) {
        if (!alreadySetChartOptionsHeader) {
          respond(`- Assign the ${chartName} chart options:`);
          alreadySetChartOptionsHeader = true;
        }

        respond("", {
          format: DuckMessageFormat.CHART_OPTIONS,
          chartOptionsOptions: {
            chartOptionsKey: String(chartOptionsKey),
            actionId,
            optionId,
            selectedChartOptions,
          },
        });
      }
    });
  }

  if (newGroupByAttribute && selectedGroupByAttribute !== newGroupByAttribute) {
    respond(
      `- Set the ${chartName} group by attribute to ${newGroupByAttribute}`
    );
  }
};
