import {
  APIListValuesRequest,
  APIPaginatedRequest,
  CountRequest,
  DeleteRequest,
  ListAttributesRequest,
  patchFetcher,
  postFetcher,
} from "shared/api/api";
import client from "shared/api/axios";
import { createURL } from "shared/api/utils";
import {
  EventTypeEnum,
  EventTypeEnumCapitalizedType,
  MileageUnit,
} from "shared/types";

import {
  CustomSignalEventStatus,
  TimeUnit,
} from "pages/CustomSignalEvents/types";

const CUSTOM_SIGNAL_EVENTS_ROUTE = "customSignalEvents";

interface CustomSignalEventCustomAttribute {
  ID: string;
  label: string;
  type: string;
  parameters: CustomSignalEventCustomAttributeParameters;
}

interface CustomSignalEventCustomAttributeParameters {
  eventType?: EventTypeEnumCapitalizedType;
  eventFilter?: string;
  unit?: string;
  before?: number;
  beforeUnit?: TimeUnit;
  after?: number;
  afterUnit?: TimeUnit;
  field?: string;
  fieldA?: string;
  fieldB?: string;
  value?: number;
}

interface CustomSignalEventDownsampling {
  type: "occursAtLeast" | "restPeriod";
  parameters: {
    before: number;
    beforeUnit?: TimeUnit;
    after: number;
    afterUnit?: TimeUnit;
    occurrences?: number;
  };
}

export interface CustomSignalEventsRequest {
  limit?: number;
}

export interface CustomSignalEventsRequestBody {
  inputEventType: EventTypeEnum | undefined;
  inputEventFilter?: string;
  customAttributes: CustomSignalEventCustomAttribute[];
  customAttributeFilter?: string;
  vehiclesFilter: string;
  downsampling?: CustomSignalEventDownsampling;
  mileageUnit: MileageUnit;
}

export const listCustomSignalEventsURI = (
  args: CustomSignalEventsRequest
): string =>
  client.getUri({
    method: "post",
    url: createURL([CUSTOM_SIGNAL_EVENTS_ROUTE, "query"]),
    params: args,
  });

export interface CustomSignalEvent {
  VIN: string;
  date: string;
  customAttributes: Record<string, string | number>;
  inputEventData: Record<string, string | number>;
  inputEventType: string;
}

export const listCustomSignalEventsTimelineURI = ({
  skipRequest,
  ...args
}: CustomSignalEventsTimelineRequest): string =>
  skipRequest
    ? ""
    : client.getUri({
        method: "post",
        url: createURL([CUSTOM_SIGNAL_EVENTS_ROUTE, "timeline"]),
        params: args,
      });

export interface CustomSignalEventsTimelineBucket {
  VIN: string;
  date: string;
  occurrences: number;
  signalEventID: string;
  description?: string;
}

export type CustomSignalEventTimelineGrouping =
  | "day"
  | "hour"
  | "minute"
  | "none"
  | "all";

export interface CustomSignalEventsTimelineRequest extends APIPaginatedRequest {
  skipRequest?: boolean;
  grouping?: CustomSignalEventTimelineGrouping;
}

export const listCustomSignalEventsCountURI = (): string =>
  client.getUri({
    method: "post",
    url: createURL([CUSTOM_SIGNAL_EVENTS_ROUTE, "count"]),
  });

export interface CreateCustomSignalEventDefinition {
  ID: string;
  backfillError: string | null;
  createdAt: string;
  description: string | null;
  eventDefinition: EventDefinition;
  invalidationReason: string | null;
  name: string;
  query: string | null;
  status: CustomSignalEventStatus;
  type: string;
  updatedAt: string;
}

export interface CustomSignalEventEventDefinitionRequestBody {
  ID: string;
  description: string | null;
  eventDefinition: EventDefinition;
  name: string;
  type: string;
  status?: CustomSignalEventStatus;
}

export interface EventDefinition {
  inputEventType: EventTypeEnum;
  inputEventFilter?: string;
  customAttributes: CustomSignalEventCustomAttribute[];
  customAttributeFilter?: string;
  vehiclesFilter: string;
  downsampling?: CustomSignalEventDownsampling;
  mileageUnit: MileageUnit;
}

export const newCustomSignalEventDefinition = (
  data: CustomSignalEventEventDefinitionRequestBody
) =>
  postFetcher<CreateCustomSignalEventDefinition>(
    createCustomSignalEventDefinitionURI(),
    data
  );

export const createCustomSignalEventDefinitionURI = (): string =>
  client.getUri({
    method: "post",
    url: createURL(["customSignalEvents"]),
  });

export const deleteCustomSignalEventDefinition = (args: DeleteRequest) =>
  client.delete(deleteCustomSignalEventDefinitionRequestURI(args));

export const deleteCustomSignalEventDefinitionRequestURI = ({
  id,
}: DeleteRequest): string =>
  client.getUri({
    method: "DELETE",
    url: createURL([CUSTOM_SIGNAL_EVENTS_ROUTE, id]),
  });

export const publishCustomSignalEventDefinition = (id: string) =>
  patchFetcher(updateSignalEventDefinitionRequestURI(id), {
    status: "ready_for_validation",
  });

export const updateSignalEventDefinitionRequestURI = (id: string): string =>
  client.getUri({
    method: "PATCH",
    url: createURL([CUSTOM_SIGNAL_EVENTS_ROUTE, id]),
  });

export interface CustomSignalEventDefinition {
  ID: string;
  name: string;
  description: string;
  backfillError: string;
  eventDefinition: EventDefinition;
  preFlight: Record<string, any>;
  invalidationReason: string;
  query: string;
  status: CustomSignalEventStatus;
  type: string;
  createdAt: string;
  updatedAt: string;
}

export interface UpdateCustomSignalEventDefinitionRequest {
  id: string;
  status?: string;
}

export const listCustomSignalEventDefinitionsURI = (
  args: APIPaginatedRequest
): string =>
  client.getUri({
    method: "POST",
    url: createURL([CUSTOM_SIGNAL_EVENTS_ROUTE]),
    params: args,
  });

export const listCustomSignalEventDefinitionsAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  client.getUri({
    method: "GET",
    url: createURL([CUSTOM_SIGNAL_EVENTS_ROUTE, "attributes"]),
    params,
  });

export const listCustomSignalEventDefinitionsValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  client.getUri({
    method: "GET",
    url: createURL([CUSTOM_SIGNAL_EVENTS_ROUTE, "values", fieldName]),
    params,
  });

export const listCustomSignalEventDefinitionsCountURI = (
  args: CountRequest
): string =>
  client.getUri({
    method: "GET",
    url: createURL([CUSTOM_SIGNAL_EVENTS_ROUTE, "count"]),
    params: args,
  });

const updateCustomSignalEventDefinitionRequestURI = ({
  id,
  ...params
}: UpdateCustomSignalEventDefinitionRequest): string =>
  client.getUri({
    method: "PATCH",
    url: createURL([CUSTOM_SIGNAL_EVENTS_ROUTE, id]),
    params,
  });

export const updateCustomSignalEventDefinition = (
  args: CustomSignalEventEventDefinitionRequestBody
) =>
  patchFetcher<CustomSignalEventDefinition>(
    updateCustomSignalEventDefinitionRequestURI({ id: args.ID }),
    args
  );

export interface ActionsState {
  canCancel: boolean;
  canEditID: boolean;
  canEditDefinition: boolean;
  canPublish: boolean;
  canDelete: boolean;
  canNavigateToDetailsPage: boolean;
}

export interface CustomSignalEventState {
  actionsState: ActionsState;
}

export interface GetCustomSignalEventDefinitionRequest {
  id: string;
}

export const getCustomSignalEventDefinitionRequestURI = ({
  id,
}: GetCustomSignalEventDefinitionRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([CUSTOM_SIGNAL_EVENTS_ROUTE, id]),
  });
