import { useState } from "react";
import { AiOutlineCloudDownload as DownloadIcon } from "react-icons/ai";
import { IconButton } from "@mui/material";

import {
  APIPaginatedRequest,
  APISuccessResponse,
  ExportFileType,
  ExportRequest,
} from "shared/api/api";

import ConfirmationModal from "features/ui/ConfirmationModal";
import TableCount from "features/ui/Table/TableCount";

import { FilterGroupState } from "./Filters/FilterBuilder/types";

const TEXT_DOWNLOADING = "Download will start soon...";
const TEXT_ERROR =
  "Could not the download the file right now. Please try later or contact us directly.";
const DEFAULT_ICON_SIZE = 25;
const DEFAULT_FILE_NAME = "viaduct-download";
const DEFAULT_FILE_TYPE = "xlsx";
const LARGE_DOWNLOAD_THRESHOLD = 10000;

export interface RequestParams extends APIPaginatedRequest {
  // make limit required, otherwise download will only download default number of items on the API (25 at this point)
  limit?: number;
  // we need this for export URLs that need an ID in between, like issues/:ID/signalEvents/export/xlsx
  IDs?: string[];
  // we need this to support exporting comparison populations in issues
  includeComparisonPopulation?: boolean;
  // we need this to support separate vehicles filter on requests
  vehiclesFilter?: string;
}

interface Props {
  requestParams: RequestParams;
  iconSize?: number;
  disabled?: boolean;
  fileName?: string;
  fileType?: ExportFileType;
  entityName: string;
  count?: number;
  downloadFunc: (request: ExportRequest) => Promise<APISuccessResponse<Blob>>;
  filters: FilterGroupState;
}

const createAndDownloadFile = (
  response: APISuccessResponse<Blob>,
  fileName: string,
  fileType: ExportFileType
): void => {
  const blob = new Blob([response.data], {
    type: response.headers["content-type"],
  });

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `${fileName}.${fileType}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const DownloadAction = ({
  requestParams,
  iconSize = DEFAULT_ICON_SIZE,
  disabled,
  fileName = DEFAULT_FILE_NAME,
  fileType = DEFAULT_FILE_TYPE,
  entityName,
  count,
  downloadFunc,
  filters,
}: Props) => {
  const [isPreparingDownload, setIsPreparingDownload] = useState(false);
  const [modalText, setModalText] = useState<string | undefined>();
  const [modalOpen, setModalOpen] = useState(false);

  const handleDownloadAction = () => {
    setModalText(TEXT_DOWNLOADING);
    setIsPreparingDownload(true);

    downloadFunc({
      type: fileType,
      IDs: [],
      ...requestParams,
    })
      .then((response) => {
        createAndDownloadFile(response, fileName, fileType);
        setModalOpen(false);
      })
      .catch((e) => {
        console.warn(e);
        setModalText(TEXT_ERROR);
      })
      .finally(() => {
        setModalText(undefined);
        setIsPreparingDownload(false);
      });
  };

  const handleClose = (confirmed: boolean) => {
    // we prevent closing the popup when download is in progress
    if (isPreparingDownload) return;

    if (confirmed) {
      handleDownloadAction();

      return;
    }

    setModalOpen(false);
  };

  return (
    <>
      <IconButton
        title="Download"
        onClick={() => setModalOpen(true)}
        disabled={disabled}
        size="small"
        data-testid="button-download-action"
      >
        <DownloadIcon size={iconSize} />
      </IconButton>
      <ConfirmationModal
        text={
          modalText ?? (
            <DownloadInfo
              filters={filters}
              entityName={entityName}
              count={count}
            />
          )
        }
        title="Confirm Download"
        isOpen={modalOpen}
        loading={isPreparingDownload}
        onClose={handleClose}
        closeDisabled={isPreparingDownload}
        confirmText="Download"
        closeText="Cancel"
      />
    </>
  );
};

interface DownloadInfoProps {
  filters: FilterGroupState;
  entityName: string;
  count?: number;
}

const DownloadInfo = ({ filters, count, entityName }: DownloadInfoProps) => (
  <div>
    {count ? (
      <div className="mb-4">
        <TableCount
          isLoading={false}
          count={count}
          error={false}
          entityName={entityName}
          extraClasses="font-bold text-gray-500 text-sm"
        />
      </div>
    ) : null}
    {Number(count) > LARGE_DOWNLOAD_THRESHOLD && (
      <p>Downloading large files may take up to several minutes.</p>
    )}
  </div>
);

export default DownloadAction;
