import { useState } from "react";

import useSignalEventOccurrencesSchema from "shared/schemas/signalEventOccurrencesSchema";

import DropdownSelect from "features/ui/DropdownSelect";
import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterBuilderQueryToFilterBuilderState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { getFilterLabel } from "features/ui/Filters/utils";

interface Props {
  initialFilterQuery: string | undefined;
  onChange: (filters: string) => void;
}

const PAGE_KEY = "vehicles_admin_default_signal_event_filters";

const DefaultSignalEventFilterSelector = ({
  initialFilterQuery,
  onChange,
}: Props) => {
  const { schema } = useSignalEventOccurrencesSchema();

  const defaultFilterValues = initialFilterQuery
    ? filterBuilderQueryToFilterBuilderState(initialFilterQuery)
    : undefined;

  const filterState = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultFilterValues: defaultFilterValues ?? DEFAULT_FILTER_BUILDER_STATE,
    disableUsingLocalStorage: true,
    disableUsingQuery: true,
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleOnChange = (filterState: FilterGroupState) => {
    onChange(getFiltersQuery(filterState));
  };

  return (
    <DropdownSelect
      testId="default-signal-event-filters-dropdown"
      label={getFilterLabel(
        "Default Signal Event Filters",
        filterState.filters
      )}
      open={dropdownOpen}
      onOpen={(open) => {
        setDropdownOpen(open);
      }}
      hasAdvancedFilters={false}
      content={
        <FilterSelector
          schema={schema}
          filterSortState={filterState}
          disableAdvancedFilter
          pendingFiltersKey={getPendingFiltersKey(PAGE_KEY)}
          onApplyFilters={handleOnChange}
          testId="signal-event-filters-selector"
        />
      }
    />
  );
};

export default DefaultSignalEventFilterSelector;
