import { AlertEventType } from "shared/api/alertDefinitions/api";
import { useConfigContext } from "shared/contexts/ConfigContext";

import ConfirmationModalIssueUpdate from "pages/ClaimAnalytics/ConfirmationModalIssueUpdate";
import BackToIssueBreadcrumb from "pages/Issues/BackToIssueBreadcrumb";
import {
  ISSUE_ID_QUERY_KEY,
  ISSUE_IS_SUGGESTED_QUERY_KEY,
  ISSUE_NAME_QUERY_KEY,
  ISSUE_RUN_DATE_QUERY_KEY,
} from "pages/Issues/constants";
import WatchlistCreateAlertAction from "pages/LandingPage/Watchlist/WatchlistCreateAlertAction";

import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { filterBuilderQueryToFilterBuilderState } from "features/ui/Filters/FilterBuilder/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { getChartSettingsKey, getFiltersKey } from "features/ui/Filters/utils";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Title from "features/ui/Title";

import {
  PAGE_TITLE,
  PENDING_FILTERS_SE_LS_KEY,
  PENDING_FILTERS_VEHICLES_LS_KEY,
  SIGNAL_EVENTS_PAGE_KEY,
  SIGNAL_EVENTS_TAB_KEY,
  VEHICLES_PAGE_KEY,
} from "./constants";
import SignalEventsAnalyticsFilters from "./SignalEventsAnalyticsFilters";
import SignalEventsAnalyticsTabs from "./SignalEventsAnalyticsTabs";

const QUERY_PARAMS_TO_PERSIST_THROUGH_TABS = [
  SIGNAL_EVENTS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
].reduce(
  (soFar, key) => [...soFar, getFiltersKey(key), getChartSettingsKey(key)],
  [
    ISSUE_ID_QUERY_KEY,
    ISSUE_NAME_QUERY_KEY,
    ISSUE_IS_SUGGESTED_QUERY_KEY,
    ISSUE_RUN_DATE_QUERY_KEY,
  ]
);

const SignalEventsAnalytics = () => {
  const {
    pages: { signalEventsAnalytics },
  } = useConfigContext();

  const defaultSignalEventFilters =
    filterBuilderQueryToFilterBuilderState(
      signalEventsAnalytics?.defaultFilters
    ) || DEFAULT_FILTER_BUILDER_STATE;

  const signalEventsFiltersFilterSortState = useFilterSortState({
    pageKey: SIGNAL_EVENTS_PAGE_KEY,
    defaultFilterValues: defaultSignalEventFilters,
    pendingFiltersLocalStorageKey: PENDING_FILTERS_SE_LS_KEY,
    defaultTab: SIGNAL_EVENTS_TAB_KEY,
  });

  const defaultVehicleFilters =
    filterBuilderQueryToFilterBuilderState(
      signalEventsAnalytics?.defaultVehicleFilters
    ) || DEFAULT_FILTER_BUILDER_STATE;

  const vehiclePopulationFiltersFilterSortState = useFilterSortState({
    pageKey: VEHICLES_PAGE_KEY,
    defaultFilterValues: defaultVehicleFilters,
    pendingFiltersLocalStorageKey: PENDING_FILTERS_VEHICLES_LS_KEY,
    defaultTab: SIGNAL_EVENTS_TAB_KEY,
  });

  const resetState = () => {
    vehiclePopulationFiltersFilterSortState?.resetFilterSortState();
  };

  return (
    <>
      <ConfirmationModalIssueUpdate
        page="signalEventAnalytics"
        vehicleFilters={vehiclePopulationFiltersFilterSortState.filters}
        signalEventFilters={signalEventsFiltersFilterSortState.filters}
      />
      <PageHeaderWrapper>
        <div className="flex flex-col">
          <BackToIssueBreadcrumb />
          <Title text={PAGE_TITLE} />
        </div>
        <PageHeaderActionsWrapper>
          <WatchlistCreateAlertAction
            eventType={AlertEventType.SIGNAL_EVENT_OCCURRENCE}
            eventFilter={signalEventsFiltersFilterSortState.filters}
          />
        </PageHeaderActionsWrapper>
      </PageHeaderWrapper>
      <SignalEventsAnalyticsFilters
        signalEventsFilterSortState={signalEventsFiltersFilterSortState}
        vehiclesFilterSortState={vehiclePopulationFiltersFilterSortState}
        defaultSignalEventFilters={defaultSignalEventFilters}
        defaultVehicleFilters={defaultVehicleFilters}
      />
      <SignalEventsAnalyticsTabs
        signalEventsFiltersFilterSortState={signalEventsFiltersFilterSortState}
        vehiclesFiltersFilterSortState={vehiclePopulationFiltersFilterSortState}
        queryParamsToPersist={QUERY_PARAMS_TO_PERSIST_THROUGH_TABS}
        onBadRequest={resetState}
      />
    </>
  );
};

export default SignalEventsAnalytics;
