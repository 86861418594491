import { useAttributesContext } from "shared/contexts/GenericAttributesContextWrapper";
import { ContextProviderName } from "shared/contexts/types";
import { ALERT_DEFINITIONS_GENERIC_FILTER } from "shared/filterDefinitions";

import { UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
  getSchemaFromAttributes,
} from "./utils";

const useAlertDefinitionSchema = (
  accessorsToHide: string[] = []
): UseSchema => {
  const { attributes } = useAttributesContext(
    ContextProviderName.AlertDefinition
  );
  const attributeMap = getAttributeMap(attributes);

  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const getFilter = getFilterFunction(
    ALERT_DEFINITIONS_GENERIC_FILTER,
    attributeMap
  );

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const schema = getSchemaFromAttributes(
    attributes,
    getSchemaEntry,
    undefined,
    undefined,
    accessorsToHide
  );

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  return {
    schema,
    getDisplayLabel,
    attributeMap,
    attributes,
    getSchemaEntry,
    whitelist: Object.keys(attributeMap || {}),
    getSchemaForAccessor,
  };
};
export default useAlertDefinitionSchema;
