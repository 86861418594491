import { useState } from "react";
import { format } from "date-fns";

import { MONTH_YEAR_FULL, MONTH_YEAR_SHORT } from "shared/constants";

import Card from "features/ui/Card";
import ChartActions from "features/ui/charts/Actions/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/Actions/ChartActionsWrap";
import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import AreaChart from "features/ui/charts/AreaChart";
import { getDefaultActions } from "features/ui/charts/utils";

export interface GraphDataBucket {
  ts: number;
  countRepairs: number;
  countFailures: number;
  countTotal: number;
}

interface FailureModeCampaignEventsGraphProps {
  data: GraphDataBucket[];
}

const formatTooltipLabel = (label: keyof GraphDataBucket): string => {
  switch (label) {
    case "countFailures":
      return "Failures";
    case "countRepairs":
      return "Proactive Repairs";
    case "countTotal":
      return "Total Repairs";
    default:
      return label;
  }
};

const HEIGHT_PX = 300;
const COLOR_REPAIRS = "#16A34A";
const COLOR_FAILURES = "#DC2626";
const COLOR_TOTAL = "#4B5563";

export const FailureModeEventsGraph = ({
  data,
}: FailureModeCampaignEventsGraphProps) => {
  const actions: ChartAction[] = [
    {
      id: "y",
      title: "Y Axis",
      type: "dropdownSelect",
      options: [
        {
          id: "count",
          value: "Total Count",
        },
        {
          id: "IPTV",
          value: "IPTV",
        },
      ],
    },
  ];

  const [selectedOptions, setSelectedOptions] = useState<
    SelectedChartOptions[]
  >(getDefaultActions(actions));

  const yAxisKey = selectedOptions.find((x) => x.id === "y")?.optionId;

  const linesCount = [
    {
      key: "countFailures",
      label: "Failures",
      color: COLOR_FAILURES,
      stackId: "1",
      opacity: 0.5,
    },
    {
      key: "countRepairs",
      label: "Proactive Repairs",
      color: COLOR_REPAIRS,
      stackId: "1",
      opacity: 0.5,
    },
    {
      key: "countTotal",
      label: "Total Repairs",
      color: COLOR_TOTAL,
      stackId: "2",
      opacity: 0,
    },
  ];

  const linesPTV = [
    {
      key: "countFailuresPTV",
      label: "Failures PTV",
      color: COLOR_FAILURES,
      stackId: "1",
      opacity: 0.5,
    },
    {
      key: "countRepairsPTV",
      label: "Proactive Repairs PTV",
      color: COLOR_REPAIRS,
      stackId: "1",
      opacity: 0.5,
    },
    {
      key: "countTotalPTV",
      label: "Total Repairs PTV",
      color: COLOR_TOTAL,
      stackId: "2",
      opacity: 0,
    },
  ];

  const lines = yAxisKey === "count" ? linesCount : linesPTV;

  if (data.length === 0) {
    return <h3 className="text-gray-400 text-sm">Not enough data.</h3>;
  }

  return (
    <Card>
      <ChartActionsWrap>
        <ChartActions
          actions={actions}
          selectedOptions={selectedOptions}
          onOptionChange={setSelectedOptions}
        />
      </ChartActionsWrap>
      <AreaChart
        data={data}
        width="100%"
        areas={lines}
        height={HEIGHT_PX}
        xAxisKey="ts"
        showLegend={true}
        xAxisProps={{
          tickFormatter: (unixTime: any) => format(unixTime, MONTH_YEAR_SHORT),
        }}
        tooltipProps={{
          formatter: (value: any, name: any) => [
            value,
            formatTooltipLabel(name),
          ],
          labelFormatter: (unixTime: number) =>
            format(unixTime, MONTH_YEAR_FULL),
        }}
      />
    </Card>
  );
};
