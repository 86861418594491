import React, { useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import {
  deleteRecentItems,
  RECENT_ITEM_PATHS,
  RecentItem,
} from "shared/api/recentItems/api";
import { useListRecentItems } from "shared/api/recentItems/hooks";
import { SortBy } from "shared/types";
import { pluralize } from "shared/utils";

import { RECENT_ITEMS_TITLE } from "pages/LandingPage/constants";

import APIError from "features/ui/APIError";
import Card from "features/ui/Card";
import { getCheckboxCheckedProps } from "features/ui/Checkbox/utils";
import ConfirmationModal from "features/ui/ConfirmationModal";
import { useFilterSortState } from "features/ui/Filters/hooks";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import { DataType } from "features/ui/Table/TableBodyCell/types";
import TableCellWithCheckbox from "features/ui/Table/TableCellWithCheckbox";
import { OnSortParams, SchemaEntry, Selectable } from "features/ui/Table/types";

const DEFAULT_SORT: SortBy = { createdAt: "desc" };
const RECENT_ITEMS_PAGE_KEY: string = "landing-page-recent-items";
const REMOVE_ITEMS_TEXT = "Remove";
const SELECTED_ROW_ACTIONS_TEXT = "Selected row actions:";
const CONFIRM_TEXT = "Yes, delete forever";
const CANCEL_TEXT = "No, cancel";
const SUCCESS_DELETE_TEXT = "Recent items deleted successfully";
const NO_ITEMS_TEXT = "No recent items available";

const getRecentItemsSchema = (selectableOptions: Selectable): SchemaEntry[] => [
  {
    label: "Recent Item",
    accessor: "objectID",
    dataType: DataType.STRING,
    selectable: selectableOptions,
    limitedWidthClass: "w-80",
  },
  {
    label: "Type",
    accessor: "objectType",
    dataType: DataType.STRING,
    sortable: true,
    limitedWidthClass: "w-40",
  },
  {
    label: "Visited at",
    accessor: "createdAt",
    sortable: true,
    dataType: DataType.DATE_WITH_TIME,
    limitedWidthClass: "w-40",
  },
];

const formatRow = (
  row: RecentItem,
  selectedIds: Set<string>,
  setSelectedIds: (events: Set<string>) => void
) => {
  const encodedID = encodeURIComponent(row.objectID);
  const itemPath = `${RECENT_ITEM_PATHS[row.objectType]}/${encodedID}`;

  return {
    ...row,
    objectID: (
      // displaying objectID as a link text, while keeping selection state in ID
      <div className="flex items-center">
        <TableCellWithCheckbox
          value={row.ID}
          selectedValues={selectedIds}
          setSelectedValues={setSelectedIds}
          testId="checkbox-recent-item"
          hideText
        />
        <Link
          to={itemPath}
          className="text-metabase-blue hover:underline overflow-hidden text-ellipsis ml-2"
          title={row.name}
        >
          {row.name}
        </Link>
      </div>
    ),
  };
};

const RecentItems = () => {
  const [sortString, setSortString] = useState<string>("");
  const { sort, manageOnSortChange } = useFilterSortState({
    pageKey: RECENT_ITEMS_PAGE_KEY,
    defaultSort: DEFAULT_SORT,
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [refreshKey, setRefreshKey] = useState<boolean>(false);

  const { data, error, isLoading, ...paginationData } = useListRecentItems({
    limit: 5,
    sort: sortString,
    refreshKey,
  });

  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set());
  const allSelectableValues = data?.map((x) => x.ID) || [];
  const { allChecked, indeterminateChecked } = getCheckboxCheckedProps(
    selectedIds,
    allSelectableValues
  );

  const toggleSelectedItems = () => {
    if (allChecked) {
      setSelectedIds(new Set<string>());

      return;
    }

    setSelectedIds(new Set<string>(allSelectableValues));
  };

  const schema = getRecentItemsSchema({
    onClick: toggleSelectedItems,
    checked: allChecked,
    indeterminate: indeterminateChecked,
  });

  const formattedData = useMemo(
    () => data?.map((row) => formatRow(row, selectedIds, setSelectedIds)),
    [data, selectedIds]
  );

  const onSort = ({ accessor, sort }: OnSortParams) => {
    setSortString(`${accessor}:${sort}`);
    manageOnSortChange({ [accessor]: sort });
  };

  const onDeleteClick = () => {
    setModalVisible(true);
  };

  const handleDelete = (confirmed: boolean) => {
    if (!confirmed) {
      setModalVisible(false);

      return;
    }

    const payload = Array.from(selectedIds).map((x) => ({ ID: x }));
    deleteRecentItems(payload).then(() => {
      setSelectedIds(new Set<string>());
      setRefreshKey(!refreshKey);
      setModalVisible(false);
      toast.success(SUCCESS_DELETE_TEXT);
    });
  };

  const deleteText = () => (
    <div>
      Are you sure you want to <strong>permanently</strong> delete{" "}
      <i>
        {selectedIds.size} {pluralize("recent item", selectedIds.size)}
      </i>
      ?
    </div>
  );

  if (isLoading) return <Skeleton height={200} />;

  if (error) return <APIError error={error} />;

  return (
    data && (
      <Card>
        <div className="flex flex-col justify-between w-full max-h-30">
          <h3 className="flex space-x-3 items-center font-semibold mb-3 capitalize">
            {RECENT_ITEMS_TITLE}
          </h3>

          {data.length === 0 && (
            <span className="text-sm">{NO_ITEMS_TEXT}</span>
          )}
          {data.length > 0 && (
            <>
              <div className="bg-gray-50 p-1.5 mb-1 ml-0 text-xs w-fit rounded-md">
                <label className="mr-4 text-gray-400">
                  {SELECTED_ROW_ACTIONS_TEXT}
                </label>
                <button
                  className="text-blue-400 disabled:text-gray-300"
                  disabled={selectedIds.size === 0}
                  onClick={onDeleteClick}
                >
                  {REMOVE_ITEMS_TEXT}
                </button>
              </div>
              <PaginatedTable
                {...paginationData}
                data={formattedData}
                schema={schema}
                dense={true}
                onSort={onSort}
                sortBy={sort}
              />
            </>
          )}
        </div>
        <ConfirmationModal
          isOpen={modalVisible}
          onClose={handleDelete}
          loading={false}
          confirmText={CONFIRM_TEXT}
          closeText={CANCEL_TEXT}
          title=""
          text={deleteText()}
          confirmColor="error"
        />
      </Card>
    )
  );
};

export default RecentItems;
