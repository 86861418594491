import { getFetcher } from "shared/api/api";
import client from "shared/api/axios";
import { createURL } from "shared/api/utils";

const VECTOR_STORE_BASE_ROUTE = "vectorStore";

export interface VectorStoreSearchParameters {
  query: string;
  k?: number;
  distanceThreshold?: number;
  source?: string;
}

export interface VectorStoreSearchResult {
  documentID: string;
  document: string;
  distance: number;
  url: string;
  title: string;
  source: string;
  metadata: string;
}

export const vectorStoreSearchRequestURI = ({
  query,
  ...params
}: VectorStoreSearchParameters): string =>
  client.getUri({
    method: "GET",
    url: createURL(["internal", VECTOR_STORE_BASE_ROUTE, "search", query]),
    params,
  });

export const vectorStoreSearch = (args: VectorStoreSearchParameters) =>
  getFetcher<VectorStoreSearchResult[]>(vectorStoreSearchRequestURI(args));
