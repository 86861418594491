import React from "react";
import Skeleton from "react-loading-skeleton";
import { generatePath, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { mutate } from "swr";

import { Issue } from "shared/api/issues/api";
import { useOrderedValues } from "shared/api/orderedValues/hooks";
import { transformOrderedValuesToSelectOptions } from "shared/api/orderedValues/utils";
import {
  SuggestedIssue,
  updateSuggestedIssue,
} from "shared/api/suggestedIssues/api";
import { useSuggestedIssue } from "shared/api/suggestedIssues/hooks";
import { SHORT_DATE_FORMAT } from "shared/constants";
import useSuggestedIssuesSchema from "shared/schemas/useSuggestedIssuesSchema";
import { formatDate, mutateMultipleSWRRequestKeys } from "shared/utils";

import CardHeader from "pages/Issues/Details/CardHeader";
import { IssueRequestKeys } from "pages/Issues/types";

import APIError from "features/ui/APIError";
import Card from "features/ui/Card";
import Label from "features/ui/Label";
import Select from "features/ui/Select";
import TextAreaInlineEdit from "features/ui/TextAreaInlineEdit/TextAreaInlineEdit";

import { routes } from "services/routes";

import GroupDropdown from "./GroupDropdown";

interface Props extends IssueRequestKeys {
  issue: SuggestedIssue;
}

const SKELETON_HEIGHT = 30;

const SuggestedIssueCardOverview = ({ issue, issueRequestKeys }: Props) => {
  const { getDisplayLabel } = useSuggestedIssuesSchema();
  const {
    confidence,
    updated,
    discovered,
    ID,
    description,
    statusObj,
    notes,
    promotedTo,
  } = issue;

  const { data: suggestedIssue, requestKey: issueRequestKeySI } =
    useSuggestedIssue({
      id: ID,
    });

  const {
    data: suggestedIssueStatuses,
    isLoading: suggestedIssueStatusesLoading,
    error: suggestedIssueStatusesError,
  } = useOrderedValues({ valueType: "suggestedIssueStatus" });

  if (suggestedIssueStatusesError) {
    return <APIError error={suggestedIssueStatusesError} />;
  }

  const suggestedIssueStatusOptions = transformOrderedValuesToSelectOptions(
    suggestedIssueStatuses
  );

  const onUpdateSuggestedIssue = (
    field: string,
    value: string | null,
    fieldNiceName?: string
  ) => {
    updateSuggestedIssue({ ID, [field]: value })
      .then(() => {
        toast.success(
          `Suggested issue ${fieldNiceName || field} updated successfully`
        );
        if (issueRequestKeys) {
          mutateMultipleSWRRequestKeys(issueRequestKeys);
        }

        mutate(issueRequestKeySI);
      })
      .catch((err: Error) => {
        toast.error(
          `Updating suggested issue ${fieldNiceName || field} failed`
        );
        console.log(err);
      });
  };

  const promotedIssuesLinks = (promotedTo: Issue[]) =>
    promotedTo.map(({ ID: issueID, name, createdAt, createdBy }) => (
      <li>
        <Link
          to={generatePath(routes.issue, {
            id: encodeURIComponent(issueID),
          })}
          className="text-metabase-blue hover:underline"
        >
          {name}
        </Link>{" "}
        (promoted by <u>{createdBy}</u> on{" "}
        <u>{formatDate(createdAt, SHORT_DATE_FORMAT)}</u>)
      </li>
    ));

  return (
    <Card classNames="!p-5">
      <CardHeader title="Overview" />
      <div className="flex md:space-x-3 space-y-3 md:space-y-0 flex-col md:flex-row justify-between mb-3">
        {typeof confidence === "number" && (
          <div>
            <Label text={getDisplayLabel("confidence", "Confidence")} />
            <Label text={String(confidence)} className="text-viaduct-black" />
          </div>
        )}
        <div>
          <Label text={getDisplayLabel("discovered", "Discovered")} />
          <Label text={discovered} className="text-viaduct-black" />
        </div>
        <div className="relative">
          <Label text={getDisplayLabel("updated", "Updated")} />
          <Label text={updated} className="text-viaduct-black" />
        </div>
      </div>
      <div className="md:flex md:space-x-3 mb-3">
        {suggestedIssueStatusesLoading && <Skeleton height={SKELETON_HEIGHT} />}
        <Select
          label={getDisplayLabel("statusObj", "Status")}
          options={suggestedIssueStatusOptions}
          fullWidth
          selected={
            suggestedIssueStatusOptions.find((x) => x.id === statusObj?.ID) ||
            suggestedIssueStatusOptions[0]
          }
          onSelect={(option) => {
            if (option.id === statusObj?.ID) return;

            onUpdateSuggestedIssue("statusID", option.id as string, "status");
          }}
          testId="suggested-issue-status"
        />
        <GroupDropdown
          assignedGroupID={suggestedIssue?.assignedGroupID || null}
          onUpdate={onUpdateSuggestedIssue}
          label={getDisplayLabel("assignedGroupID", "Responsible Group")}
          testId="suggested-issue-assignedGroupID"
        />
      </div>
      <div className="md:flex mb-3">
        <div className="relative">
          <Label text={getDisplayLabel("promotedTo", "Promoted To")} />
          {!promotedTo?.length && (
            <Label text="No promoted issues" className="text-viaduct-black" />
          )}
          {promotedTo?.length > 0 && (
            <Label text={promotedIssuesLinks(promotedTo)} />
          )}
        </div>
      </div>

      <div className="flex mb-3">
        <div className="relative">
          <Label text={getDisplayLabel("description", "Description")} />
          <pre
            data-testid="issue_notes"
            className="pl-1 text-viaduct-black whitespace-pre-wrap max-h-[200px] overflow-y-auto break-all"
            style={{ fontFamily: "inherit" }}
          >
            {description}
          </pre>
        </div>
      </div>

      <TextAreaInlineEdit
        fieldName={getDisplayLabel("notes", "Notes")}
        canEdit={true}
        fieldValue={notes || ""}
        onSubmit={(value) => onUpdateSuggestedIssue("notes", value)}
      />
    </Card>
  );
};

export default SuggestedIssueCardOverview;
