import { addWeeks, format } from "date-fns";

import { API_DATE_FORMAT } from "shared/constants";

import { DEFAULT_ISSUE_DATA } from "./constants";
import { IssueFormData } from "./types";

export const getVINTimelineParamsFromAssociatedVehicles = (
  vehicleManufacturedAt: string | null,
  firstOccurrenceDate: string,
  lastOccurrenceDate: string
) => ({
  dateFrom: vehicleManufacturedAt || firstOccurrenceDate,
  dateTo: format(addWeeks(lastOccurrenceDate, 3), API_DATE_FORMAT),
});

export const getDefaultIssueCreateData = (assignee: string): IssueFormData => ({
  ...DEFAULT_ISSUE_DATA,
  assignee,
});
