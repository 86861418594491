import { GeneralConfigModel } from "shared/api/admin/api";

export const DEFAULT_GENERAL_CONFIG: GeneralConfigModel = {
  mileageUnit: "km",
  maxDate: "",
  helpContactEmail: "",
  rootPage: "",
  bookmarks: [],
  metabaseDashboards: [],
};
