import { useContext, useEffect, useRef } from "react";
import { DuckContext } from "duck/context/DuckContextWrapper";
import { DuckMessageAuthor, DuckMessageFormat } from "duck/context/types";
import callAgent from "duck/graph";
import { PAGE_AGENT_RESPONSE_PRELUDE } from "duck/graph/nodes/constants";
import { AvailableData, UIHandlers } from "duck/graph/types";
import { DUCK_GENERIC_ERROR_MESSAGE } from "duck/ui/constants";
import { useDuckAccess, usePageState } from "duck/ui/hooks";
import { LocationInfo, Reload } from "duck/ui/types";
import { jwtDecode } from "jwt-decode";
import { useOktaAuth } from "@okta/okta-react";

import { JWT } from "shared/types";

interface DuckSubmitProcessorProps {
  availableData: AvailableData;
  utterance: string;
  threadId: string;
  acquireLocationInformation: (reset: boolean) => LocationInfo;
  clearLocationInfo: () => void;
  captureImages: boolean;
  handleComplete: () => void;
  // Strict React dev mode calls useEffect twice.
  // This prop allows us to only run the submit() function once in that circumstance.
  startTime: number;
}

const DuckSubmitProcessor = ({
  availableData,
  utterance,
  threadId,
  acquireLocationInformation,
  clearLocationInfo,
  captureImages,
  handleComplete,
  startTime,
}: DuckSubmitProcessorProps) => {
  const mostRecentStartTime = useRef(0);

  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken() || "";
  const { tenant, sub: user }: JWT = jwtDecode(accessToken);

  const agentResponseCountRef = useRef(0);

  const agentResponsePreludeSent = useRef(false);

  const {
    messages,
    addMessage,
    setEphemeralMessage,
    handleStreamEvent,
    streamedMessage,
    setPendingAction,
    setLoading,
  } = useContext(DuckContext);

  const duckAccess = useDuckAccess();

  // Make sure that the page state is up to date
  const currentState = usePageState();

  /**
   * This function is passed to the agent so that it is able to respond to the user.
   *
   * @param message The message from the agent.
   * @param triggerAgentPreludeMessage This optional parameter causes the
   * "agent prelude message" to be sent before the indicated message is sent,
   * if it has not already been sent. This help us send the agent
   * prelude message to the user exactly one time.
   */
  const handleAgentResponse: UIHandlers["setAgentResponse"] = (
    message,
    options = {
      triggerAgentPreludeMessage: false,
      format: DuckMessageFormat.TEXT,
    }
  ) => {
    const { format, triggerAgentPreludeMessage, ...otherOptions } = options;

    if (triggerAgentPreludeMessage && !agentResponsePreludeSent.current) {
      addMessage({
        author: DuckMessageAuthor.AGENT,
        message: PAGE_AGENT_RESPONSE_PRELUDE,
      });

      agentResponsePreludeSent.current = true;
    }

    addMessage({
      author: DuckMessageAuthor.AGENT,
      format,
      message,
      options: otherOptions,
    });

    agentResponseCountRef.current++;
  };

  const uiHandlers: UIHandlers = {
    setAgentResponse: handleAgentResponse,
    setEphemeralMessage,
    duckAccess,
    handleStreamEvent,
  };

  useEffect(() => {
    const submit = async (): Promise<void> => {
      if (!availableData.vinView) {
        console.error("The available data needed by the agent has not loaded");

        return;
      }

      agentResponseCountRef.current = 0;
      agentResponsePreludeSent.current = false;

      const priorMessages = [...messages];

      console.log("submit", utterance);
      addMessage({
        author: DuckMessageAuthor.HUMAN,
        message: utterance,
      });

      setLoading(true);
      setPendingAction(false);
      clearLocationInfo();

      try {
        await callAgent({
          text: utterance,
          messageHistory: priorMessages,
          uiHandlers,
          threadId,
          tenant: String(tenant),
          user: String(user),
          currentState,
          availableData,
          captureImages,
        });

        setEphemeralMessage("");

        const { reloadRequired } = acquireLocationInformation(false);
        if (reloadRequired === Reload.HARD || reloadRequired === Reload.SOFT) {
          if (agentResponseCountRef.current >= 1) {
            setPendingAction(true);
          } else {
            // All of the queued actions match the current state.
            // Because of that:
            // - No messages have been sent to the user
            // - There is no pending action
            addMessage({
              author: DuckMessageAuthor.AGENT,
              message:
                "The current page should already provide you the information you are looking for. Can I help you with anything else?",
            });
          }
        } else {
          if (!streamedMessage.current && agentResponseCountRef.current < 1) {
            // We didn't queue any actions and we didn't send any meaningful messages to the user
            addMessage({
              author: DuckMessageAuthor.AGENT,
              message:
                "I wasn't able to act on your request. Please try asking something more specific.",
            });
          }
        }
      } catch (error) {
        console.error(`${new Date().getTime()} error`, error);
        addMessage({
          author: DuckMessageAuthor.AGENT,
          message: DUCK_GENERIC_ERROR_MESSAGE,
        });
      } finally {
        setEphemeralMessage("");
        setLoading(false);
        handleComplete();
      }
    };

    if (mostRecentStartTime.current !== startTime) {
      mostRecentStartTime.current = startTime;
      void submit();
    }

    // We want this to run exactly once when the component initializes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default DuckSubmitProcessor;
