import { useMemo, useState } from "react";
import qs from "qs";
import { useNavigate } from "react-router-dom";

import { SignalEventsAssociatedClaimsRequest } from "shared/api/signalEvents/api";
import { useListSignalEventsAssociatedClaims } from "shared/api/signalEvents/hooks";
import { getSortFilter } from "shared/api/utils";
import { useClaimsFiltersSchema } from "shared/hooks";
import { useClaimsSchema } from "shared/schemas/claimsSchema";
import { VEHICLE_ACCESSOR } from "shared/schemas/constants";
import { useSchemaEntryForAttribute } from "shared/schemas/hooks";
import { EventTypeEnum, SortBy } from "shared/types";
import { cloneObject, pluralize } from "shared/utils";

import {
  CLAIMS_FILTER_LABEL,
  CLAIMS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
} from "pages/ClaimAnalytics/constants";
import { canShowDescription } from "pages/ClaimAnalytics/tabPages/TopContributors/utils";
import { GROUP_BY_ATTRIBUTE_KEY } from "pages/constants";
import { ASSOCIATED_CLAIMS_TAB_KEY } from "pages/SignalEventsAnalytics/constants";
import { SignalEventsAnalyticsTabsProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";
import { getInitialSelectedGroupByAttribute } from "pages/SignalEventsAnalytics/utils";

import APIError from "features/ui/APIError";
import { getCheckboxCheckedProps } from "features/ui/Checkbox/utils";
import DescriptionColumn from "features/ui/DescriptionColumn";
import DropdownSelect from "features/ui/DropdownSelect";
import {
  getFiltersQuery,
  updateOrAddRowFilterGroupState,
} from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import OccursFilterActions from "features/ui/Filters/FilterTypes/OccursFilter/OccursFilterActions";
import OccurTimeWindowForm, {
  WINDOW_DIRECTION_OPTION_AFTER,
} from "features/ui/Filters/FilterTypes/OccursFilter/OccursTimeWindowForm";
import SelectedRowsActions from "features/ui/Filters/FilterTypes/OccursFilter/SelectedRowsActions";
import {
  encodeOccursFilterAndOptions,
  encodeOccursFilterState,
} from "features/ui/Filters/FilterTypes/OccursFilter/utils";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import {
  FilterOperator,
  OccursFilterState,
  OccursWindowOptions,
} from "features/ui/Filters/types";
import { getFilterLabel, getFiltersKey } from "features/ui/Filters/utils";
import NestedAutocomplete from "features/ui/NestedAutocomplete";
import { SelectOption } from "features/ui/Select";
import Table, { OnSortParams, SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell/types";
import TableCellWithCheckbox from "features/ui/Table/TableCellWithCheckbox";

import { routes } from "services/routes";

import {
  PAGE_KEY,
  SE_ASSOCIATED_CLAIMS_KEY,
  SIGNAL_EVENTS_ASSOCIATED_CLAIMS_GROUP_BY_OPTIONS_KEY,
  SIGNAL_EVENTS_ASSOCIATED_CLAIMS_WINDOW_SIZE_OPTIONS_KEY,
  WINDOW_SIZE_KEY,
} from "./constants";
import { useSignalEventsAssociatedClaimsDefaultGroupBy } from "./hooks";
import {
  createOccursFilter,
  getDefaultSchema,
  useDefaultClaimFilters,
} from "./utils";

const DEFAULT_SORT: SortBy = { associationStrength: "desc" };
const LIMIT = 500;
const TABLE_HEIGHT_PX = 500;

const DEFAULT_WINDOW_SIZE = 30;

const SE_ASSOCIATED_CLAIMS_KEY_PENDING = getPendingFiltersKey(PAGE_KEY);

const AssociatedClaims = ({
  signalEventsFiltersFilterSortState,
  vehiclesFilters,
}: SignalEventsAnalyticsTabsProps) => {
  const navigate = useNavigate();

  const claimAnalyticsFilterKey = getFiltersKey(CLAIMS_PAGE_KEY);
  const vehicleFilterKey = getFiltersKey(VEHICLES_PAGE_KEY);

  const signalEventsFilters = signalEventsFiltersFilterSortState?.filters;

  const defaultClaimFilters = useDefaultClaimFilters();

  const { groupBySelectOptions } = useClaimsSchema([VEHICLE_ACCESSOR]);

  const defaultGroupBy = useSignalEventsAssociatedClaimsDefaultGroupBy({
    groupBySelectOptions,
  });

  const PENDING_FILTERS_ASSOCIATED_CLAIMS_LS_KEY = getPendingFiltersKey(
    SE_ASSOCIATED_CLAIMS_KEY
  );

  const {
    manageFilterChange,
    resetFilters,
    filters,
    sort,
    manageOnSortChange,
    initialized: filtersInitialized,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
  });

  const claimsFilterSortState = useFilterSortState({
    pageKey: SE_ASSOCIATED_CLAIMS_KEY,
    defaultFilterValues: defaultClaimFilters,
    pendingFiltersLocalStorageKey: PENDING_FILTERS_ASSOCIATED_CLAIMS_LS_KEY,
  });

  const initialSelectedGroupByAttribute: SelectOption =
    getInitialSelectedGroupByAttribute(
      claimsFilterSortState?.chartSettings,
      defaultGroupBy,
      groupBySelectOptions || [],
      ASSOCIATED_CLAIMS_TAB_KEY,
      SIGNAL_EVENTS_ASSOCIATED_CLAIMS_GROUP_BY_OPTIONS_KEY,
      GROUP_BY_ATTRIBUTE_KEY
    );

  const [selectedGroupByAttribute, setSelectedGroupByAttribute] = useState(
    initialSelectedGroupByAttribute
  );

  const handleGroupByAttributeChange = (groupByAttribute: SelectOption) => {
    setSelectedGroupByAttribute(groupByAttribute);
    if (claimsFilterSortState?.manageChartSettingsChange) {
      claimsFilterSortState.manageChartSettingsChange(
        [
          {
            id: GROUP_BY_ATTRIBUTE_KEY,
            optionId: groupByAttribute.id,
          },
        ],
        SIGNAL_EVENTS_ASSOCIATED_CLAIMS_GROUP_BY_OPTIONS_KEY
      );
    }
  };

  const [selectedValues, setSelectedValues] = useState(new Set<string>());

  const firstColumn = useSchemaEntryForAttribute(
    selectedGroupByAttribute.id as string,
    EventTypeEnum.CLAIM
  );

  const descriptionColumns: SchemaEntry[] = canShowDescription(
    selectedGroupByAttribute.id.toString()
  )
    ? [
        {
          label: "Description",
          accessor: "description",
          dataType: DataType.JSX,
        },
      ]
    : [];

  const claimsFiltersSchema = useClaimsFiltersSchema();

  const claimFilters = claimsFilterSortState.filters;

  const claimsFilterLabel = getFilterLabel(
    "Claim Filters",
    claimsFilterSortState.filters
  );

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const [appliedOccursFilter, setAppliedOccursFilter] =
    useState<OccursFilterState>(createOccursFilter(claimsFilterSortState));

  const handleAppliedOccursFilterChange = (
    newOccursFilter: OccursFilterState
  ) => {
    setAppliedOccursFilter(newOccursFilter);
    if (claimsFilterSortState?.manageChartSettingsChange) {
      claimsFilterSortState.manageChartSettingsChange(
        [
          {
            id: WINDOW_SIZE_KEY,
            optionId: newOccursFilter.windowSize,
          },
        ],
        SIGNAL_EVENTS_ASSOCIATED_CLAIMS_WINDOW_SIZE_OPTIONS_KEY
      );
    }
  };

  const [occursFilter, setOccursFilter] = useState(appliedOccursFilter);

  const selectedPeriod = `${occursFilter.windowSize} ${pluralize(
    "day",
    occursFilter.windowSize
  )}`;

  const defaultSchema = getDefaultSchema(selectedPeriod);

  const onGroupByChange = (value: SelectOption) => {
    handleGroupByAttributeChange(value);
    setSelectedValues(new Set<string>());
  };

  const onUpdateOccursFilter = (row: OccursWindowOptions) => {
    setOccursFilter(
      createOccursFilter(
        claimsFilterSortState,
        parseInt(row.windowSize?.toString())
      )
    );
  };

  const onCancel = () => {
    const canceledOccursFilter = createOccursFilter(
      claimsFilterSortState,
      DEFAULT_WINDOW_SIZE
    );
    setOccursFilter(canceledOccursFilter);
    handleAppliedOccursFilterChange(canceledOccursFilter);
  };

  const onApply = () => {
    handleAppliedOccursFilterChange(occursFilter);
  };

  const onCloseFilters = () => {
    const windowSize = appliedOccursFilter.windowSize;
    setOccursFilter(createOccursFilter(claimsFilterSortState, windowSize));

    const appliedWindowSize = appliedOccursFilter.windowSize;
    handleAppliedOccursFilterChange(
      createOccursFilter(claimsFilterSortState, appliedWindowSize)
    );
  };

  const requestParams: SignalEventsAssociatedClaimsRequest = {
    sort: getSortFilter(sort),
    filter: getFiltersQuery(filters),
    signalEventOccurrencesFilter: getFiltersQuery(signalEventsFilters),
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
    claimFilter: getFiltersQuery(claimFilters),
    limit: LIMIT,
    groupBy: selectedGroupByAttribute.id as string,
    signalEventsTimeWindow: appliedOccursFilter.windowSize,
  };

  const { data, isLoading, headers, error, ...paginationData } =
    useListSignalEventsAssociatedClaims(requestParams);

  const allSelectableValues =
    data?.map(({ groupByAttributeValue }) => groupByAttributeValue as string) ||
    [];
  const { allChecked, indeterminateChecked } = getCheckboxCheckedProps(
    selectedValues,
    allSelectableValues
  );

  const toggleSelectedValues = () => {
    if (allChecked) {
      setSelectedValues(new Set<string>());

      return;
    }

    setSelectedValues(new Set<string>(allSelectableValues));
  };

  const schema: SchemaEntry[] = firstColumn
    ? [
        {
          ...firstColumn,
          dataType: DataType.JSX,
          selectable: {
            onClick: toggleSelectedValues,
            checked: allChecked,
            indeterminate: indeterminateChecked,
          },
        },
        ...descriptionColumns,
        ...defaultSchema,
      ]
    : defaultSchema;

  const formattedData = useMemo(
    () =>
      data &&
      data?.map((row) => {
        const { groupByAttributeValue } = row;

        return {
          ...row,
          groupByAttributeValue: (
            <TableCellWithCheckbox
              value={groupByAttributeValue || ""}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
              testId="checkbox-associated-claim"
            />
          ),
          description: groupByAttributeValue && (
            <DescriptionColumn
              fieldName={selectedGroupByAttribute.id.toString()}
              fieldValue={groupByAttributeValue}
            />
          ),
        };
      }),
    [data, selectedGroupByAttribute.id, selectedValues]
  );

  const navigateToClaimAnalytics = () => {
    let newClaimFilters = cloneObject(claimFilters);
    let seFilters = signalEventsFiltersFilterSortState?.filters;
    let firstChild = seFilters?.children[0];
    // check if signal event filters are empty, and if so skip adding occurs filter
    if (
      (firstChild?.type === "row" && firstChild.values?.length !== 0) ||
      firstChild?.type === "group"
    ) {
      const encodedOccursFilter = encodeOccursFilterAndOptions(
        signalEventsFiltersFilterSortState?.filters,
        {
          windowSize: occursFilter.windowSize,
          windowType: occursFilter.windowType,
          windowDirection: occursFilter.windowDirection,
        }
      );

      newClaimFilters = updateOrAddRowFilterGroupState(claimFilters, {
        id: "relatedSignalEventOccurrences",
        type: "row",
        attribute: "relatedSignalEventOccurrences",
        operator: FilterOperator.OCCURS,
        values: [encodedOccursFilter],
      });
    }

    // Add selected values as filter
    newClaimFilters = updateOrAddRowFilterGroupState(newClaimFilters, {
      id: "groupBy",
      type: "row",
      attribute: selectedGroupByAttribute.id as string,
      operator: FilterOperator.IN,
      values: Array.from(selectedValues),
    });

    navigate({
      pathname: routes.claimAnalytics,
      search: qs.stringify({
        [vehicleFilterKey]: getFiltersQuery(vehiclesFilters),
        [claimAnalyticsFilterKey]: getFiltersQuery(newClaimFilters),
      }),
    });
  };

  return (
    <>
      <div className="mt-4 space-y-2">
        <div className="flex flex-wrap leading-10 space-x-2 items-end">
          <span>Claim Defined By</span>
          <DropdownSelect
            testId="claims-filters-dropdown"
            label={claimsFilterLabel}
            buttonClass="mt-6 h-[38px] mr-4"
            content={
              <FilterSelector
                schema={claimsFiltersSchema}
                filterSortState={claimsFilterSortState}
                defaultFilters={defaultClaimFilters}
                title={CLAIMS_FILTER_LABEL}
                testId="claims-filters"
                pendingFiltersKey={SE_ASSOCIATED_CLAIMS_KEY_PENDING}
                onCloseFilters={onCloseFilters}
              />
            }
          />
          <OccurTimeWindowForm
            occursOptionsState={occursFilter}
            onUpdate={onUpdateOccursFilter}
            baseEntityText="Base Signal Event"
            inFilterSelector={false}
            windowDirectionOptions={[WINDOW_DIRECTION_OPTION_AFTER]}
          />
          <OccursFilterActions
            occursState={encodeOccursFilterState(occursFilter)}
            onApply={onApply}
            onCancel={onCancel}
            appliedOccursFilter={encodeOccursFilterState(appliedOccursFilter)}
          />
        </div>
        {groupBySelectOptions && groupBySelectOptions.length > 0 && (
          <NestedAutocomplete
            wrapperClasses="max-w-[300px] !mt-5"
            options={groupBySelectOptions}
            onSelectionChange={onGroupByChange}
            selected={selectedGroupByAttribute}
            testId="signal-events-associated-claims-dimension"
            label="Select a dimension"
          />
        )}
      </div>
      <div className="flex items-center my-3">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={resetFilters}
        />
      </div>
      <SelectedRowsActions
        occursFilter={appliedOccursFilter.filters}
        selectedSignalEvents={selectedValues}
        onExploreInClaimAnalyticsActionClick={navigateToClaimAnalytics}
      />
      {!error && (
        <Table
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={LIMIT}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageFilterChange}
          filters={filters}
          stickyFirstColumn={true}
          dense
          scrollHeight={TABLE_HEIGHT_PX}
          testId="associated-claims-table"
        />
      )}
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
      {!error && !isLoading && !data?.length && (
        <div className="py-4 text-gray-400 text-sm">No results.</div>
      )}
    </>
  );
};

export default AssociatedClaims;
