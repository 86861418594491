import { Vehicle } from "shared/api/vehicles/api";

import {
  useVehicleImage,
  useVehicleTransportCategoryDisplayName,
} from "pages/VINView/hooks";

import InfoCardsData from "./InfoCardsData";

interface Props {
  vin: string;
  vehicle?: Vehicle;
}

const VehicleInfo = ({ vin, vehicle }: Props) => {
  const inferredTransportCategoryName =
    useVehicleTransportCategoryDisplayName(vin);
  const TruckImage = useVehicleImage(vehicle);

  // we add async data from our hook. Data label/key defined in infoCardsDataMap
  const dataModified = vehicle
    ? { ...vehicle, inferredTransportCategoryName }
    : undefined;

  return (
    <div className="md:flex md:space-x-16">
      <div className="flex flex-col items-start min-w-[24rem] max-w-md">
        <div
          className="pb-4 text-3xl font-extrabold max-w-full break-words"
          data-testid="vin"
        >
          {vin}
        </div>
        <img src={TruckImage} alt="Vehicle" />
      </div>

      <div className="flex-1 min-w-0">
        <div className="flex flex-col space-y-4">
          <InfoCardsData data={dataModified} />
        </div>
      </div>
    </div>
  );
};

export default VehicleInfo;
