import { generatePath, Link } from "react-router-dom";

import { AlertEventType } from "shared/api/alertDefinitions/api";
import {
  ListServiceRecommendationsRequest,
  ServiceRecommendation,
} from "shared/api/serviceRecommendations/api";
import {
  useListServiceRecommendations,
  useListServiceRecommendationsCount,
} from "shared/api/serviceRecommendations/hooks";
import { APIFilter, getSortFilter } from "shared/api/utils";
import useServiceRecommendationsSchema from "shared/schemas/serviceRecommendationsSchema";

import WatchlistCreateAlertAction from "pages/LandingPage/Watchlist/WatchlistCreateAlertAction";
import { VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_KEY } from "pages/VINView/constants";

import APIError from "features/ui/APIError";
import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import {
  getFiltersQuery,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import { OnSortParams } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import TableCount from "features/ui/Table/TableCount";
import Title from "features/ui/Title";

import { routes } from "services/routes";

import {
  DEFAULT_SORT,
  ITEMS_PER_PAGE,
  PAGE_KEY,
  PAGE_TITLE,
  SP_RECOMMENDATIONS_FILTERS_LS_KEY,
  SP_RECOMMENDATIONS_VEHICLE_FILTERS_LS_KEY,
} from "./constants";
import ServiceRecommendationsFilters from "./ServiceRecommendationsFilters";

interface Props {
  staticFilters?: APIFilter[];
  isInsideTab?: boolean;
  pageKey?: string;
}

const ServiceRecommendations = ({
  staticFilters,
  isInsideTab = false,
  pageKey = PAGE_KEY,
}: Props) => {
  const { schema } = useServiceRecommendationsSchema(
    ["servicePlanID"],
    [
      "description",
      "vehicleFilter",
      "updatedBy",
      "updatedAt",
      "createdAt",
      "createdBy",
    ]
  );

  const vehicleFiltersFilterSortState = useFilterSortState({
    pageKey: SP_RECOMMENDATIONS_VEHICLE_FILTERS_LS_KEY,
    pendingFiltersLocalStorageKey: getPendingFiltersKey(
      SP_RECOMMENDATIONS_VEHICLE_FILTERS_LS_KEY
    ),
  });

  const serviceRecommendationFilterSortState = useFilterSortState({
    pageKey: SP_RECOMMENDATIONS_FILTERS_LS_KEY,
    pendingFiltersLocalStorageKey: getPendingFiltersKey(
      SP_RECOMMENDATIONS_FILTERS_LS_KEY
    ),
  });

  const resetState = () => {
    vehicleFiltersFilterSortState?.resetFilterSortState();
    serviceRecommendationFilterSortState?.resetFilterSortState();
    resetFilterSortState();
  };

  const {
    filters,
    sort,
    manageFilterChange,
    manageOnSortChange,
    resetFilterSortState,
    resetFilters,
    initialized: filtersInitialized,
  } = useFilterSortState({
    pageKey,
    defaultSort: DEFAULT_SORT,
  });

  const serviceRecommendationsFilters =
    serviceRecommendationFilterSortState?.filters;

  const requestParams: ListServiceRecommendationsRequest = {
    filter: getFiltersQuery(
      mergeFilterGroupStates(filters, serviceRecommendationsFilters),
      staticFilters
    ),
    vehiclesFilter: getFiltersQuery(vehicleFiltersFilterSortState?.filters),
    sort: getSortFilter(sort),
  };

  const { data, error, isLoading, ...paginationData } =
    useListServiceRecommendations(requestParams);

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
  } = useListServiceRecommendationsCount(requestParams);

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const formatRow = (serviceRecommendation: ServiceRecommendation) => {
    const { VIN, servicePlan } = serviceRecommendation;
    const pathname = generatePath(routes.vinView, {
      vin: VIN,
    });

    return {
      ...serviceRecommendation,
      VIN: (
        <Link
          to={{
            pathname,
            search: `tab=${VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_KEY}`,
          }}
          className="text-metabase-blue hover:underline"
        >
          {VIN}
        </Link>
      ),
      servicePlan: {
        ...servicePlan,
        name: (
          <Link
            to={{
              pathname: generatePath(routes.servicePlan, {
                id: servicePlan.ID,
              }),
            }}
            className="text-metabase-blue hover:underline"
          >
            {servicePlan.name}
          </Link>
        ),
      },
    };
  };

  const formattedData = data?.map(formatRow);

  return (
    <>
      {!isInsideTab && (
        <PageHeaderWrapper>
          <Title text={PAGE_TITLE} />
          <PageHeaderActionsWrapper>
            <WatchlistCreateAlertAction
              eventType={AlertEventType.SERVICE_RECOMMENDATION}
              eventFilter={serviceRecommendationsFilters}
            />
          </PageHeaderActionsWrapper>
        </PageHeaderWrapper>
      )}
      <ServiceRecommendationsFilters
        vehiclesFilterSortState={vehicleFiltersFilterSortState}
        serviceRecommendationFilterSortState={
          serviceRecommendationFilterSortState
        }
        defaultVehicleFilters={DEFAULT_FILTER_BUILDER_STATE}
        defaultServiceRecommendationFilters={DEFAULT_FILTER_BUILDER_STATE}
        isInsideTab={isInsideTab}
      />
      <div className="flex my-2 items-center">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={resetFilters}
        />
        <TableCount
          extraClasses="ml-auto self-end"
          count={countData?.count as number}
          entityName="service recommendation"
          isLoading={countIsLoading}
          error={!!countError}
        />
      </div>
      {error && <APIError error={error} onBadRequest={resetState} />}
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={ITEMS_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageFilterChange}
          filters={filters}
          staticFilters={staticFilters}
          hideStaticFiltersColumns
          pageKey={PAGE_KEY}
          dense
        />
      )}
    </>
  );
};
export default ServiceRecommendations;
