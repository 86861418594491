import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  getFetcher,
} from "shared/api/api";
import client from "shared/api/axios";
import { createURL } from "shared/api/utils";
import { ORDERED_VALUES_ROUTE } from "shared/constants";

import { OrderedValueType } from "./types";

export interface OrderedValue {
  ID: string;
  value: string;
  valueOrder: number;
  valueType: string;
}

export interface GetOrderedValueRequest {
  id: string;
}

export interface ListOrderedValuesRequest extends APIPaginatedRequest {
  valueType: OrderedValueType;
}

interface ListOrderedValuesValuesRequest extends APIListValuesRequest {
  valueType: OrderedValueType;
}

export const getOrderedValueRequestURL = (
  params: GetOrderedValueRequest
): string =>
  client.getUri({
    method: "GET",
    url: createURL([ORDERED_VALUES_ROUTE, params.id]),
  });

export const listOrderedValuesRequestURI = (
  params: ListOrderedValuesRequest
): string =>
  client.getUri({
    method: "GET",
    url: createURL([ORDERED_VALUES_ROUTE]),
    params,
  });

const listOrderedValuesValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([ORDERED_VALUES_ROUTE, "values", fieldName]),
    params,
  });

export const listOrderedValuesValues = (args: ListOrderedValuesValuesRequest) =>
  getFetcher<APIListValuesResponse>(listOrderedValuesValuesRequestURI(args));
