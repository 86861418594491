import { useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useNavigate } from "react-router-dom";

import { useIssue } from "shared/api/issues/hooks";
import { useConfigContext } from "shared/contexts/ConfigContext";

import { PAGE_TITLE as CLAIMS_PAGE_TITLE } from "pages/ClaimAnalytics/constants";
import {
  ISSUE_ID_QUERY_KEY,
  ISSUE_IS_SUGGESTED_QUERY_KEY,
} from "pages/Issues/constants";
import CreateIssue from "pages/Issues/CreateIssue/CreateIssue";
import { clearPendingIssueFilters } from "pages/Issues/CreateIssue/utils";
import { IssueFormData } from "pages/shared/types";
import { PAGE_TITLE as SE_PAGE_TITLE } from "pages/SignalEventsAnalytics/constants";

import DropdownSelect from "features/ui/DropdownSelect";
import { DropdownSelectOption } from "features/ui/DropdownSelect/DropdownSelect";
import { ActionType } from "features/ui/StepperModal";

import { useQuery } from "services/hooks";
import { routes } from "services/routes";

import { combineIssueData, getSearchParamsFor } from "./utils";

const DROPDOWN_LABEL = "Use Filters";

interface Props {
  issueData: IssueFormData;
  currentPage: "claimAnalytics" | "signalEventsAnalytics";
}

const UseFiltersMenu = ({ issueData, currentPage }: Props) => {
  const {
    rbac,
    issues: issuesFF,
    claimAnalytics: claimAnalyticsFF,
    signalEventsAnalytics: signalEventsAnalyticsFF,
  } = useFlags();
  const query = useQuery();
  const navigate = useNavigate();
  const issueId = query[ISSUE_ID_QUERY_KEY];
  const {
    data: issue,
    isLoading,
    error,
  } = useIssue({
    id: issueId as string,
    skipRequest: !issueId || query[ISSUE_IS_SUGGESTED_QUERY_KEY] === "true",
  });
  const [issueModalOpen, setIssueModalOpen] = useState(false);
  const [issueModalAction, setIssueModalAction] =
    useState<ActionType>("create");

  const isCurrentClaimAnalytics = currentPage === "claimAnalytics";

  const {
    pages: { issues, claimAnalytics, signalEventsAnalytics },
  } = useConfigContext();

  const showIssueOption = issues && issuesFF;

  const showAnalytics = isCurrentClaimAnalytics
    ? signalEventsAnalytics && signalEventsAnalyticsFF
    : claimAnalytics && claimAnalyticsFF;

  if (!showIssueOption && !showAnalytics) {
    return null;
  }

  const pageToUseFilterOnOption = isCurrentClaimAnalytics
    ? routes.signalEventAnalytics
    : routes.claimAnalytics;

  const pageToUseFilterOnOptionLabel = isCurrentClaimAnalytics
    ? SE_PAGE_TITLE
    : CLAIMS_PAGE_TITLE;

  const handleUpdateIssueModal = () => {
    if (!issue) {
      console.warn("No issue data..");

      return;
    }

    clearPendingIssueFilters();
    setIssueModalAction("update");
    setIssueModalOpen(true);
  };

  const canEditIssue = !rbac || (rbac && issue?.canEdit);
  const showUpdateIssueOption = canEditIssue && !isLoading && !error;

  const formattedIssueData = combineIssueData(
    issueData,
    issueModalAction,
    isCurrentClaimAnalytics,
    issue
  );

  const options: (DropdownSelectOption | Boolean | undefined)[] = [
    showIssueOption &&
      showUpdateIssueOption && {
        id: "update-issue",
        testId: "update-issue",
        value: "To update current Issue",
        onClick: handleUpdateIssueModal,
      },
    showIssueOption && {
      id: "create-issue",
      testId: "create-issue",
      value: "To create new issue",
      onClick: () => {
        clearPendingIssueFilters();
        setIssueModalAction("create");
        setIssueModalOpen(true);
      },
    },
    showAnalytics && {
      id: "on-claim-or-se-analytics",
      testId: "on-claim-or-se-analytics",
      value: `On ${pageToUseFilterOnOptionLabel}`,
      onClick: () => {
        const searchParams = getSearchParamsFor(pageToUseFilterOnOption, query);
        navigate({
          pathname: pageToUseFilterOnOption,
          search: searchParams,
        });
      },
    },
  ].filter(Boolean) as DropdownSelectOption[];

  const optionsToRender = options.filter(Boolean) as DropdownSelectOption[];

  const handleOnSelect = ({ id, onClick }: DropdownSelectOption) => {
    onClick && onClick();
  };

  return (
    <>
      <DropdownSelect
        label={DROPDOWN_LABEL}
        options={optionsToRender}
        color="primary"
        variant="text"
        onSelect={handleOnSelect}
        testId="use-filters-dropdown"
      />
      {issueModalOpen && (
        <CreateIssue
          action={issueModalAction}
          defaultOpen={true}
          issueData={formattedIssueData}
          showButton={false}
          onClose={() => setIssueModalOpen(false)}
          onlyAllowCurrentStep={false}
        />
      )}
    </>
  );
};

export default UseFiltersMenu;
