import { useEffect, useState } from "react";
import classnames from "classnames";
import DuckTranslate from "duck/ui/translate/DuckTranslate";
import { Stack } from "@mui/material";

import { TableCellValue } from "features/ui/Table/TableBodyCell/types";

interface LabelValuePairProps {
  hasNarratives?: boolean;
  alignment?: "left" | "center";
  translate?: boolean;
  schemaEntryLabel: string;
  initialValue: TableCellValue;
}

const DEFAULT_ALIGNMENT = "center";

const LabelValuePair = ({
  hasNarratives = false,
  alignment = DEFAULT_ALIGNMENT,
  translate = false,
  schemaEntryLabel,
  initialValue,
}: LabelValuePairProps) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div
      className={classnames("text-sm", {
        "flex space-x-3 border-b py-1": !hasNarratives && alignment !== "left",
        "grid grid-cols-[180px_auto] gap-x-3 py-2 border-b":
          alignment === "left",
      })}
    >
      <div
        className={classnames(
          {
            "w-1/2 md:text-right": !hasNarratives && alignment !== "left",
          },
          "text-gray-400 text-sm"
        )}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={0}
        >
          <p>{schemaEntryLabel}:</p>
          {translate && typeof initialValue === "string" && (
            <DuckTranslate originalText={initialValue} setValue={setValue} />
          )}
        </Stack>
      </div>
      <div
        className={classnames("break-words", {
          "w-1/2 text-left": !hasNarratives && alignment !== "left",
          "max-h-36 overflow-y-auto mt-1 mb-3 border border-gray-300":
            hasNarratives,
          "px-3 py-2": hasNarratives && typeof value === "string",
        })}
      >
        {value}
      </div>
    </div>
  );
};

export default LabelValuePair;
