import React, { useContext } from "react";
import classNames from "classnames";

import { useConfigContext } from "shared/contexts/ConfigContext";

import { LANDING_PAGE_PAGE_CONFIG_KEY } from "pages/Admin/PagesConfig/types";

import { RouteContext } from "services/routes/RouteMiddleware";
import { breadcrumbsConfig } from "services/routesConfig";

import Breadcrumbs from "./Breadcrumbs";
import Title from "./Title";

interface Props {
  children?: React.ReactNode;
  skipBreadcrumbsAndActions?: boolean;
}

const splitTitleAndOtherChildren = (
  children: React.ReactNode
): [React.ReactNode | undefined, React.ReactNode | undefined] => {
  if (!children) return [undefined, undefined];

  if (Array.isArray(children)) {
    const childrenArray = children as React.ReactElement[];
    const title = childrenArray.find((child) => child.type === Title);
    const otherChildren = childrenArray.filter((child) => child !== title);

    return [title, otherChildren];
  }

  // If children is not an array, it's likely a single element
  if (React.isValidElement(children) && children.type === Title) {
    return [children, undefined];
  }

  // If it's not a title element, return it as other children
  return [undefined, children];
};

const PageHeaderWrapper = ({ children, skipBreadcrumbsAndActions }: Props) => {
  const { pages } = useConfigContext();
  const routeContext = useContext(RouteContext);

  if (skipBreadcrumbsAndActions) {
    return (
      <div className="flex justify-between items-center mb-5">{children}</div>
    );
  }

  const [title, otherChildren] = splitTitleAndOtherChildren(children);

  return (
    <>
      {routeContext && routeContext.breadcrumb && (
        <div className={classNames({ "mb-5": title })}>
          <Breadcrumbs
            routeConfig={breadcrumbsConfig}
            customActions={otherChildren as JSX.Element}
            defaultActions={routeContext.routeActions || []}
          ></Breadcrumbs>
          {title}
        </div>
      )}
      {children && !routeContext?.breadcrumb && (
        <div className="flex justify-between items-center mb-5">
          {children}
          <div className="flex justify-between items-center ml-1">
            {/*If routeActions were already rendered in breadcrumbs above skip
            adding routeActions again*/}
            {pages[LANDING_PAGE_PAGE_CONFIG_KEY] &&
              routeContext &&
              !routeContext.breadcrumb &&
              routeContext.routeActions &&
              routeContext.routeActions.map((action, i) => (
                <div key={i}>{action}</div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default PageHeaderWrapper;
