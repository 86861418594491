import { toast } from "react-toastify";

import { APIError } from "shared/api/api";

export const showErrorToast = (error: APIError): void => {
  toast.error(
    <div>
      {error.message}
      {error.response?.data.details && (
        <div className="text-gray-400 text-xs">
          {JSON.stringify(error.response.data.details)}
        </div>
      )}
    </div>
  );
};
