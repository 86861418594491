import { getTenantFromHostname } from "shared/utils";

/**
 * This is intentionally in its own file due to Jest import problems that occur when
 * defined in shared/api/api.ts, shared/api/axios.ts, or shared/api/utils.ts
 */
export const getAPIUrl = (): string => {
  if (process.env.NODE_ENV === "test") {
    return "http://localhost:3001";
  }

  const tenant = getTenantFromHostname();

  if (tenant === "localhost") {
    return process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";
  }

  return `https://api.${tenant}.cloud.viaduct.ai`;
};
