import { LDFlagSet } from "launchdarkly-js-client-sdk";
import { Navigate } from "react-router-dom";
import {
  BreadcrumbComponentType,
  BreadcrumbsRoute,
} from "use-react-router-breadcrumbs";
import { LoginCallback } from "@okta/okta-react";

import { GeneralConfigModel } from "shared/api/admin/api";
import { RecentItemType } from "shared/api/recentItems/api";
import BreadcrumbWithIDParam from "shared/components/BreadcrumbWithIDParam";
import { EventTypeEnum } from "shared/types";

import AttributesConfig from "pages/Admin/AttributesConfig";
import GeneralConfig from "pages/Admin/GeneralConfig";
import OrderedValuesConfiguration from "pages/Admin/OrderedValuesConfig";
import PagesConfigPage from "pages/Admin/PagesConfig";
import {
  ALERT_DEFINITIONS_PAGE_CONFIG_KEY,
  CALCULATED_ATTRIBUTES_PAGE_CONFIG_KEY,
  CLAIM_ANALYTICS_PAGE_CONFIG_KEY,
  COLLECTIONS_PAGE_CONFIG_KEY,
  CUSTOM_SIGNAL_EVENTS_PAGE_CONFIG_KEY,
  FAILURE_MODES_PAGE_CONFIG_KEY,
  FLEETS_PAGE_CONFIG_KEY,
  INSPECTION_ANALYTICS_PAGE_CONFIG_KEY,
  ISSUES_PAGE_CONFIG_KEY,
  PagesConfigModel,
  SERVICE_PLANS_PAGE_CONFIG_KEY,
  SERVICE_RECORDS_PAGE_CONFIG_KEY,
  SIGNAL_EVENTS_ANALYTICS_PAGE_CONFIG_KEY,
  VEHICLES_PAGE_CONFIG_KEY,
} from "pages/Admin/PagesConfig/types";
import AlertDefinitions from "pages/AlertDefinitions";
import AlertDefinitionBreadcrumbs from "pages/AlertDefinitions/AlertDefinitionBreadcrumbs";
import AlertDefinition from "pages/AlertDefinitions/Form/AlertDefinition";
import CalculatedAttributes from "pages/CalculatedAttributes";
import CalculatedAttribute from "pages/CalculatedAttributes/CalculatedAttribute";
import CalculatedAttributeBreadcrumb from "pages/CalculatedAttributes/CalculatedAttributeBreadcrumb";
import ClaimAnalytics from "pages/ClaimAnalytics";
import SingleClaim from "pages/ClaimAnalytics/SingleClaim";
import Collections from "pages/Collections";
import CustomSignalEvents from "pages/CustomSignalEvents";
import CustomSignalEvent from "pages/CustomSignalEvents/CustomSignalEvent";
import CustomSignalEventBreadcrumb from "pages/CustomSignalEvents/CustomSignalEventBreadcrumb";
import CustomSignalEventForm from "pages/CustomSignalEvents/CustomSignalEventForm";
import Docs from "pages/Docs";
import FailureMode from "pages/FailureModes/FailureMode/FailureMode";
import FailureModeBreadcrumb from "pages/FailureModes/FailureMode/FailureModeBreadcrumb";
import FailureModes from "pages/FailureModes/FailureModes";
import Fleets from "pages/Fleets";
import FleetView from "pages/FleetView";
import Groups from "pages/Groups";
import Group from "pages/Groups/Group";
import GroupBreadcrumb from "pages/Groups/GroupBreadcrumb";
import Info from "pages/Info";
import InspectionAnalytics from "pages/InspectionAnalytics";
import SingleInspection from "pages/InspectionAnalytics/SingleInspection";
import Issues from "pages/Issues";
import Issue from "pages/Issues/Issue";
import IssueBreadcrumb from "pages/Issues/IssueBreadcrumb";
import SuggestedIssue from "pages/Issues/SuggestedIssues/SuggestedIssue";
import SuggestedIssueBreadcrumb from "pages/Issues/SuggestedIssues/SuggestedIssueBreadcrumb";
import SuggestedIssueLatestRun from "pages/Issues/SuggestedIssues/SuggestedIssueLatestRun";
import LandingPage from "pages/LandingPage";
import CreateBookmarkAction from "pages/LandingPage/Bookmarks/CreateBookmarkAction";
import WatchlistAction from "pages/LandingPage/Watchlist/WatchlistAction";
import Dashboard from "pages/MetabaseDashboardPage/MetabaseDashboardPage";
import NotFound from "pages/NotFound";
import RepairAnalytics from "pages/RepairAnalytics";
import SingleRepair from "pages/RepairAnalytics/SingleRepair/SingleRepair";
import ServicePlans from "pages/ServicePlans";
import ServicePlan from "pages/ServicePlans/Form/ServicePlan";
import ServicePlanBreadcrumb from "pages/ServicePlans/ServicePlanBreadcrumb";
import ServiceRecommendations from "pages/ServiceRecommendations";
import ServiceRecords from "pages/ServiceRecords";
import SingleCustomRecord from "pages/ServiceRecords/SingleCustomRecord";
import CustomRecordBreadcrumb from "pages/ServiceRecords/SingleCustomRecord/CustomRecordBreadcrumb";
import SignalEventsAnalytics from "pages/SignalEventsAnalytics";
import SignalEventOccurrenceBreadcrumb from "pages/SignalEventsAnalytics/SignalEventOccurrenceBreadcrumb";
import SingleSignalEventOccurrence from "pages/SignalEventsAnalytics/SingleSignalEventOccurrence";
import Vehicles from "pages/Vehicles";
import Version from "pages/Version";
import VINView from "pages/VINView";

import LoginLoading from "features/auth/Login/LoginLoading";
import CopyURLToClipboardAction from "features/ui/CopyURLToClipboardAction";

import { RouteKey, routes } from "services/routes";

import {
  Home,
  NEW_RESOURCE_DEFAULT_BREADCRUMB_TEXT,
  UrlDecodedFleet,
  UrlDecodedVIN,
} from "./breadcrumbs";

export interface RouteConfigNoComponent {
  path: string;
  title?: string;
  insecure?: boolean;
  component?: JSX.Element;
  breadcrumb?: BreadcrumbComponentType<any> | string | null;
  breadcrumbProps?: { [p: string]: unknown };
  shouldDisplay?: (props: RouteDataProps) => boolean;
  componentFunction?: (props: RouteDataProps) => JSX.Element;
  // Default actions will be displayed in the top right corner of the page
  // as part of the PageHeader component
  routeActions?: JSX.Element[];
  // Properties bellow are used for route middleware
  // no constraints between them yet, since the id UrlParameter
  // could be used in other middleware functionality.
  // The default value is ALWAYS "id".
  recentItemType?: RecentItemType;
  idUrlParameter?: "vin" | "id" | "fleetName";
}

// ensures that exactly one of component or componentFunction is defined
export type RouteConfig = RouteConfigNoComponent &
  (
    | { component: JSX.Element; componentFunction?: never }
    | {
        component?: never;
        componentFunction: (props: RouteDataProps) => JSX.Element;
      }
  );

export interface RouteDataProps {
  general: GeneralConfigModel;
  pages: PagesConfigModel;
  flags: LDFlagSet;
}

export type RoutesConfig = Record<RouteKey, RouteConfig>;

export const commonRouteActions = [
  <CreateBookmarkAction />,
  <WatchlistAction />,
];

export const routesConfig: RoutesConfig = {
  [RouteKey.LoginCallback]: {
    path: routes.loginCallback,
    insecure: true,
    component: <LoginCallback loadingElement={<LoginLoading />} />,
  },
  [RouteKey.Root]: {
    path: routes.root,
    breadcrumb: Home,
    componentFunction: ({ general, pages }: RouteDataProps) =>
      pages.landingPage ? (
        <LandingPage />
      ) : (
        <Navigate to={general?.rootPage || routes.vehicles} />
      ),
  },
  [RouteKey.Docs]: {
    path: routes.docs,
    component: <Docs />,
  },
  [RouteKey.FailureModes]: {
    path: routes.failureModes,
    componentFunction: () => <FailureModes />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    breadcrumb: "Failure Modes",
    shouldDisplay: ({ pages, flags }) => !!pages[FAILURE_MODES_PAGE_CONFIG_KEY],
  },
  [RouteKey.FailureMode]: {
    path: routes.failureMode,
    recentItemType: "failure_mode",
    componentFunction: () => <FailureMode />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    breadcrumb: FailureModeBreadcrumb,
    shouldDisplay: ({ pages, flags }) => !!pages[FAILURE_MODES_PAGE_CONFIG_KEY],
  },
  [RouteKey.ClaimAnalytics]: {
    path: routes.claimAnalytics,
    componentFunction: () => <ClaimAnalytics />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages, flags }) =>
      pages[CLAIM_ANALYTICS_PAGE_CONFIG_KEY] && flags.claimAnalytics,
  },
  [RouteKey.ClaimAnalyticsDetails]: {
    path: routes.claimAnalyticsDetails,
    recentItemType: "claim",
    componentFunction: () => <SingleClaim />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    breadcrumb: BreadcrumbWithIDParam,
    breadcrumbProps: { entity: EventTypeEnum.CLAIM },
    shouldDisplay: ({ pages, flags }) =>
      pages[CLAIM_ANALYTICS_PAGE_CONFIG_KEY] && flags.claimAnalytics,
  },
  [RouteKey.RepairAnalytics]: {
    path: routes.repairAnalytics,
    componentFunction: () => <RepairAnalytics />,
    routeActions: commonRouteActions,
  },
  [RouteKey.RepairAnalyticsDetails]: {
    path: routes.repairAnalyticsDetails,
    breadcrumb: BreadcrumbWithIDParam,
    recentItemType: "repair",
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    breadcrumbProps: { entity: EventTypeEnum.REPAIR },
    componentFunction: () => <SingleRepair />,
  },
  [RouteKey.InspectionAnalytics]: {
    path: routes.inspectionAnalytics,
    componentFunction: () => <InspectionAnalytics />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages, flags }) =>
      pages[INSPECTION_ANALYTICS_PAGE_CONFIG_KEY] && flags.inspectionAnalytics,
  },
  [RouteKey.InspectionAnalyticsDetails]: {
    path: routes.inspectionAnalyticsDetails,
    componentFunction: () => <SingleInspection />,
    breadcrumb: BreadcrumbWithIDParam,
    recentItemType: "inspection",
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    breadcrumbProps: { entity: EventTypeEnum.INSPECTION },
    shouldDisplay: ({ pages, flags }) =>
      pages[INSPECTION_ANALYTICS_PAGE_CONFIG_KEY] && flags.inspectionAnalytics,
  },
  [RouteKey.CustomRecordDetails]: {
    path: routes.customRecordDetails,
    componentFunction: () => <SingleCustomRecord />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    breadcrumb: CustomRecordBreadcrumb,
  },
  [RouteKey.SignalEventAnalytics]: {
    path: routes.signalEventAnalytics,
    componentFunction: () => <SignalEventsAnalytics />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages, flags }) =>
      pages[SIGNAL_EVENTS_ANALYTICS_PAGE_CONFIG_KEY] &&
      flags.signalEventsAnalytics,
  },
  [RouteKey.SignalEventsAnalyticsDetails]: {
    path: routes.signalEventsAnalyticsDetails,
    componentFunction: () => <SingleSignalEventOccurrence />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    breadcrumb: SignalEventOccurrenceBreadcrumb,
    shouldDisplay: ({ pages, flags }) =>
      pages[SIGNAL_EVENTS_ANALYTICS_PAGE_CONFIG_KEY] &&
      flags.signalEventsAnalytics,
  },
  [RouteKey.Dashboard]: {
    path: routes.dashboard,
    componentFunction: () => <Dashboard />,
    routeActions: commonRouteActions,
    breadcrumb: null,
    shouldDisplay: ({ general }) =>
      !!(general?.metabaseDashboards && general.metabaseDashboards?.length > 0),
  },
  [RouteKey.Vehicles]: {
    path: routes.vehicles,
    routeActions: commonRouteActions,
    componentFunction: () => <Vehicles pageKey="vehicles" />,
    shouldDisplay: ({ pages }) => !!pages[VEHICLES_PAGE_CONFIG_KEY],
  },
  [RouteKey.VinView]: {
    path: routes.vinView,
    recentItemType: "vehicle",
    idUrlParameter: "vin",
    componentFunction: () => <VINView />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    breadcrumb: UrlDecodedVIN,
    shouldDisplay: ({ pages }) => !!pages[VEHICLES_PAGE_CONFIG_KEY],
  },
  [RouteKey.Fleets]: {
    path: routes.fleets,
    componentFunction: () => <Fleets />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages }) => !!pages[FLEETS_PAGE_CONFIG_KEY],
  },
  [RouteKey.FleetView]: {
    path: routes.fleetView,
    recentItemType: "fleet",
    idUrlParameter: "fleetName",
    componentFunction: () => <FleetView />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    breadcrumb: UrlDecodedFleet,
    shouldDisplay: ({ pages }) => !!pages[FLEETS_PAGE_CONFIG_KEY],
  },
  [RouteKey.ServicePlans]: {
    path: routes.servicePlans,
    componentFunction: () => <ServicePlans />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages }) => !!pages[SERVICE_PLANS_PAGE_CONFIG_KEY],
  },
  [RouteKey.NewServicePlan]: {
    path: routes.newServicePlan,
    componentFunction: () => <ServicePlan />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    breadcrumb: NEW_RESOURCE_DEFAULT_BREADCRUMB_TEXT,
    shouldDisplay: ({ pages }) => !!pages[SERVICE_PLANS_PAGE_CONFIG_KEY],
  },
  [RouteKey.ServiceRecords]: {
    path: routes.serviceRecords,
    componentFunction: () => <ServiceRecords />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages }) => !!pages[SERVICE_RECORDS_PAGE_CONFIG_KEY],
  },
  [RouteKey.ServicePlan]: {
    path: routes.servicePlan,
    recentItemType: "service_plan",
    componentFunction: () => <ServicePlan />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    breadcrumb: ServicePlanBreadcrumb,
    shouldDisplay: ({ pages }) => !!pages[SERVICE_PLANS_PAGE_CONFIG_KEY],
  },
  [RouteKey.ServiceRecommendations]: {
    path: routes.serviceRecommendations,
    componentFunction: () => <ServiceRecommendations />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages }) => !!pages[SERVICE_PLANS_PAGE_CONFIG_KEY],
  },
  [RouteKey.CalculatedAttributes]: {
    path: routes.calculatedAttributes,
    componentFunction: () => <CalculatedAttributes />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages, flags }) =>
      pages[CALCULATED_ATTRIBUTES_PAGE_CONFIG_KEY] &&
      flags.calculatedAttributes,
  },
  [RouteKey.NewCalculatedAttribute]: {
    path: routes.newCalculatedAttribute,
    componentFunction: () => <CalculatedAttribute />,
    routeActions: commonRouteActions,
    breadcrumb: NEW_RESOURCE_DEFAULT_BREADCRUMB_TEXT,
    shouldDisplay: ({ pages, flags }) =>
      pages[CALCULATED_ATTRIBUTES_PAGE_CONFIG_KEY] &&
      flags.calculatedAttributes,
  },
  [RouteKey.CalculatedAttribute]: {
    path: routes.calculatedAttribute,
    recentItemType: "calculated_attribute",
    componentFunction: () => <CalculatedAttribute />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    breadcrumb: CalculatedAttributeBreadcrumb,
    shouldDisplay: ({ pages, flags }) =>
      pages[CALCULATED_ATTRIBUTES_PAGE_CONFIG_KEY] &&
      flags.calculatedAttributes,
  },
  [RouteKey.AlertDefinitions]: {
    path: routes.alertDefinitions,
    componentFunction: () => <AlertDefinitions />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages }) => !!pages[ALERT_DEFINITIONS_PAGE_CONFIG_KEY],
  },
  [RouteKey.AlertDefinition]: {
    path: routes.alertDefinition,
    recentItemType: "alert_definition",
    componentFunction: () => <AlertDefinition />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    breadcrumb: AlertDefinitionBreadcrumbs,
    shouldDisplay: ({ pages }) => !!pages[ALERT_DEFINITIONS_PAGE_CONFIG_KEY],
  },
  [RouteKey.NewAlertDefinition]: {
    path: routes.newAlertDefinition,
    componentFunction: () => <AlertDefinition />,
    breadcrumb: NEW_RESOURCE_DEFAULT_BREADCRUMB_TEXT,
    shouldDisplay: ({ pages }) => !!pages[ALERT_DEFINITIONS_PAGE_CONFIG_KEY],
  },
  [RouteKey.Collections]: {
    path: routes.collections,
    componentFunction: () => <Collections />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages }) => !!pages[COLLECTIONS_PAGE_CONFIG_KEY],
  },
  [RouteKey.Issues]: {
    path: routes.issues,
    componentFunction: () => <Issues />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ flags, pages }) =>
      !!pages[ISSUES_PAGE_CONFIG_KEY] && flags.issues,
  },
  [RouteKey.Issue]: {
    path: routes.issue,
    recentItemType: "issue",
    componentFunction: () => <Issue />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    breadcrumb: IssueBreadcrumb,
    shouldDisplay: ({ flags, pages }) =>
      !!pages[ISSUES_PAGE_CONFIG_KEY] && flags.issues,
  },
  [RouteKey.SuggestedIssues]: {
    path: routes.suggestedIssues,
    componentFunction: () => <Issues />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ flags, pages }) =>
      pages[ISSUES_PAGE_CONFIG_KEY]?.suggestedIssues && flags.suggestedIssues,
  },
  [RouteKey.SuggestedIssueLatestRun]: {
    path: routes.suggestedIssueLatestRun,
    recentItemType: "suggested_issue",
    componentFunction: () => <SuggestedIssueLatestRun />,
    routeActions: commonRouteActions,
    breadcrumb: SuggestedIssueBreadcrumb,
    shouldDisplay: ({ flags, pages }) =>
      pages[ISSUES_PAGE_CONFIG_KEY]?.suggestedIssues && flags.suggestedIssues,
  },
  [RouteKey.SuggestedIssue]: {
    path: routes.suggestedIssue,
    recentItemType: "suggested_issue",
    componentFunction: () => <SuggestedIssue />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    breadcrumb: "Suggested Issue",
    shouldDisplay: ({ flags }) => flags.suggestedIssues,
  },
  [RouteKey.CustomSignalEvents]: {
    path: routes.customSignalEvents,
    componentFunction: () => <CustomSignalEvents />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages, flags }) =>
      flags.customSignalEvents && pages[CUSTOM_SIGNAL_EVENTS_PAGE_CONFIG_KEY],
  },
  [RouteKey.NewCustomSignalEvent]: {
    path: routes.newCustomSignalEvent,
    componentFunction: () => (
      <CustomSignalEventForm pageTitle="New Custom Signal Event" />
    ),
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages, flags }) =>
      flags.customSignalEvents && pages[CUSTOM_SIGNAL_EVENTS_PAGE_CONFIG_KEY],
  },
  [RouteKey.CustomSignalEvent]: {
    path: routes.customSignalEvent,
    recentItemType: "custom_signal_event",
    componentFunction: () => <CustomSignalEvent />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    breadcrumb: CustomSignalEventBreadcrumb,
    shouldDisplay: ({ pages, flags }) =>
      flags.customSignalEvents && pages[CUSTOM_SIGNAL_EVENTS_PAGE_CONFIG_KEY],
  },
  [RouteKey.Version]: { path: routes.version, component: <Version /> },
  [RouteKey.Groups]: {
    path: routes.groups,
    componentFunction: () => <Groups />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ flags }) => flags.rbac && flags.rbacGroups,
  },
  [RouteKey.Group]: {
    path: routes.group,
    recentItemType: "group",
    componentFunction: () => <Group />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    breadcrumb: GroupBreadcrumb,
    shouldDisplay: ({ flags }) => flags.rbac && flags.rbacGroups,
  },
  [RouteKey.AttributesConfig]: {
    path: routes.attributesConfig,
    componentFunction: () => <AttributesConfig />,
    shouldDisplay: ({ flags }) => flags.adminPages,
  },
  [RouteKey.PagesConfig]: {
    path: routes.pagesConfig,
    componentFunction: () => <PagesConfigPage />,
    shouldDisplay: ({ flags }) => flags.adminPages,
  },
  [RouteKey.GeneralConfig]: {
    path: routes.generalConfig,
    componentFunction: () => <GeneralConfig />,
    shouldDisplay: ({ flags }) => flags.adminPages,
  },
  [RouteKey.OrderedValues]: {
    path: routes.orderedValues,
    componentFunction: () => <OrderedValuesConfiguration />,
    shouldDisplay: ({ flags }) => flags.adminPages,
  },
  [RouteKey.AdminInfo]: {
    path: routes.adminInfo,
    component: <Info />,
    shouldDisplay: ({ flags }) => flags.adminPages && flags.settingsPage,
  },
  [RouteKey.NotFound]: { path: routes.notFound, component: <NotFound /> },
};

export const breadcrumbsConfig: BreadcrumbsRoute[] = Object.entries(
  routesConfig
)
  .filter(([_, config]) => config.breadcrumb)
  .map(([_, config]) => ({
    path: config.path,
    breadcrumb: config.breadcrumb,
    props: config.breadcrumbProps,
  }));
