import { useFlags } from "launchdarkly-react-client-sdk";
import Skeleton from "react-loading-skeleton";

import {
  ClaimFiltersByVehicleAgeRequest,
  getClaimsByVehicleAgeTimelineExport,
} from "shared/api/claims/api";
import { useListClaimsByVehicleAgeTimeline } from "shared/api/claims/hooks";
import { NO_BY_PRODUCTION_DATE_ATTRIBUTES_CONFIGURED } from "shared/constants";
import { useClaimsSchema } from "shared/schemas/claimsSchema";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";
import { EventTypeEnum, EventTypeLabel } from "shared/types";

import { ClaimsFiltersProps } from "pages/ClaimAnalytics/ClaimAnalyticsTabs";
import { BY_VEHICLES_AGE_TAB_KEY } from "pages/constants";
import { useByVehicleAgeOptions, useChartSettings } from "pages/hooks";
import ByVehicleAgeChart from "pages/shared/analytics/ByVehicleAgeChart";
import ByVehicleAgeTable from "pages/shared/analytics/ByVehicleAgeTable";
import {
  areThereAnyByAgeAttributes,
  isSelectedByAgeAttributeInvalid,
} from "pages/utils";

import { getDefaultActions } from "features/ui/charts/utils";
import {
  getFiltersQuery,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";

import { useByVehicleAgeChartActions } from "./hooks";

export const BY_VEHICLE_AGE_CHART_OPTIONS_KEY =
  "claimAnalyticsByVehicleAgeChartOptions";

const ByVehicleAge = ({
  claimsFilters,
  vehiclesFilters,
  onBadRequest,
  chartSettings,
  manageChartSettingsChange,
  onAddDateRangeToVehicleFilters,
}: ClaimsFiltersProps) => {
  const { warrantyClaimsCost: warrantyClaimsCostFF } = useFlags();
  const actions = useByVehicleAgeChartActions(EventTypeLabel.CLAIM);

  const { selectedOptions, setSelectedOptions } = useChartSettings(
    chartSettings,
    manageChartSettingsChange,
    BY_VEHICLES_AGE_TAB_KEY,
    BY_VEHICLE_AGE_CHART_OPTIONS_KEY,
    getDefaultActions(actions)
  );

  const byVehicleAgeData = useByVehicleAgeOptions(selectedOptions, actions);

  const { byVehicleAgeBirthday, byVehicleAgeExposure, granularity } =
    byVehicleAgeData;

  const requestParams: ClaimFiltersByVehicleAgeRequest = {
    claimsFilter: claimsFilters && getFiltersQuery(claimsFilters),
    vehiclesFilter: vehiclesFilters && getFiltersQuery(vehiclesFilters),
    byVehicleAgeBirthday,
    byVehicleAgeExposure,
    granularity,
    hideCosts: warrantyClaimsCostFF,
  };

  const { data, isLoading, error } =
    useListClaimsByVehicleAgeTimeline(requestParams);

  const { attributes: vehicleAttributes } = useVehiclesSchema();
  const { attributes: claimAttributes } = useClaimsSchema();

  if (!areThereAnyByAgeAttributes(vehicleAttributes, claimAttributes)) {
    return NO_BY_PRODUCTION_DATE_ATTRIBUTES_CONFIGURED;
  } else if (
    isSelectedByAgeAttributeInvalid(
      byVehicleAgeData,
      vehicleAttributes,
      claimAttributes
    )
  ) {
    console.error("Invalid By Vehicle Age attribute selected");
    setSelectedOptions(getDefaultActions(actions));
  }

  if (isLoading) {
    return <Skeleton height={500} count={2} className="mt-5" />;
  }

  return (
    <div className="mt-5">
      <ByVehicleAgeChart
        byVehicleAgeData={byVehicleAgeData}
        data={data}
        error={error}
        actions={actions}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        onBadRequest={onBadRequest}
        onAddDateRangeToVehicleFilters={onAddDateRangeToVehicleFilters}
      />
      <ByVehicleAgeTable
        eventType={EventTypeEnum.CLAIM}
        byVehicleAgeData={byVehicleAgeData}
        filters={mergeFilterGroupStates(claimsFilters, vehiclesFilters)}
        data={data}
        error={error}
        downloadRequestParams={requestParams}
        downloadFunc={getClaimsByVehicleAgeTimelineExport}
        onBadRequest={onBadRequest}
      />
    </div>
  );
};

export default ByVehicleAge;
