import { FormControlLabel, Switch } from "@mui/material";

import {
  FailureModesConfig,
  PageConfigProps,
} from "pages/Admin/PagesConfig/types";

const SIMILAR_VEHICLES_KEY = "similarVehicles";
const SURVIVAL_CURVES_KEY = "survivalCurves";
const NOMINAL_RISK_MODEL_PERFORMANCE_KEY = "nominalRiskModelPerformance";
const SHOW_HIDE_COLUMNS_KEY = "showHideColumns";
const RISK_DISTRIBUTION_KEY = "riskDistribution";

const FailureModesConfigPage = ({
  pageId,
  config,
  onConfigChange,
}: PageConfigProps<FailureModesConfig>) => (
  <>
    <div className="mb-4">
      <FormControlLabel
        control={
          <Switch
            checked={config[SIMILAR_VEHICLES_KEY] ?? false}
            onChange={(_, checked) =>
              onConfigChange(pageId, SIMILAR_VEHICLES_KEY, checked)
            }
            size="small"
          />
        }
        label={<div className="text-xs">Show Similar Vehicles</div>}
      />
      <FormControlLabel
        control={
          <Switch
            checked={config[SURVIVAL_CURVES_KEY] ?? false}
            onChange={(_, checked) =>
              onConfigChange(pageId, SURVIVAL_CURVES_KEY, checked)
            }
            size="small"
          />
        }
        label={<div className="text-xs">Show Survival Curves</div>}
      />
      <FormControlLabel
        control={
          <Switch
            checked={config[NOMINAL_RISK_MODEL_PERFORMANCE_KEY] ?? false}
            onChange={(_, checked) =>
              onConfigChange(
                pageId,
                NOMINAL_RISK_MODEL_PERFORMANCE_KEY,
                checked
              )
            }
            size="small"
          />
        }
        label={
          <div className="text-xs">Show Nominal Risk Model Performance</div>
        }
      />
    </div>
    <div>
      <FormControlLabel
        control={
          <Switch
            checked={config[SHOW_HIDE_COLUMNS_KEY] ?? false}
            onChange={(_, checked) =>
              onConfigChange(pageId, SHOW_HIDE_COLUMNS_KEY, checked)
            }
            size="small"
          />
        }
        label={
          <div className="text-xs">Show/Hide predictions on Vehicles pages</div>
        }
      />
      <FormControlLabel
        control={
          <Switch
            checked={config[RISK_DISTRIBUTION_KEY] ?? false}
            onChange={(_, checked) =>
              onConfigChange(pageId, RISK_DISTRIBUTION_KEY, checked)
            }
            size="small"
          />
        }
        label={
          <div className="text-xs">
            Show Risk Distribution on Failure mode page - Definition tab
          </div>
        }
      />
    </div>
  </>
);

export default FailureModesConfigPage;
