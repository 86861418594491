import {
  ASSOCIATED_SIGNAL_EVENTS_TAB_KEY,
  ASSOCIATED_VEHICLES_TAB_KEY,
  BY_VEHICLES_AGE_TAB_KEY,
  TOP_CONTRIBUTORS_TAB_KEY,
} from "pages/constants";
import { FieldDateRange } from "pages/shared/analytics/types";
import { addDateRangeToFilterState } from "pages/shared/analytics/utils";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  ChartSettingsChangeHandler,
  PageChartSettingsState,
  UseFilterSortState,
} from "features/ui/Filters/types";
import { clearPendingFiltersForKey } from "features/ui/Filters/utils";
import StatefulTabs, { Tab } from "features/ui/StatefulTabs";

import {
  ASSOCIATED_CLAIMS_TAB_KEY,
  SIGNAL_EVENT_ANALYTICS_TABS,
  SIGNAL_EVENTS_TAB_KEY,
  VEHICLES_PAGE_KEY,
} from "./constants";
import AssociatedClaims from "./tabPages/AssociatedClaims/AssociatedClaims";
import AssociatedSignalEvents from "./tabPages/AssociatedSignalEvents/AssociatedSignalEvents";
import AssociatedVehicles from "./tabPages/AssociatedVehicles";
import ByVehicleAge from "./tabPages/ByVehicleAge/ByVehicleAge";
import SignalEvents from "./tabPages/SignalEvents/SignalEvents";
import TopContributors from "./tabPages/TopContributors/TopContributors";

export interface SignalEventsFiltersProps {
  signalEventsFilters?: FilterGroupState;
  vehiclesFilters?: FilterGroupState;
  chartSettings?: PageChartSettingsState;
  manageChartSettingsChange?: ChartSettingsChangeHandler;
  onBadRequest: () => void;
  pageKey?: string;
  onAddDateRangeToVehicleFilters?: (fieldDateRange: FieldDateRange) => void;
}

export interface SignalEventsAnalyticsProps {
  signalEventsFiltersFilterSortState: UseFilterSortState;
  vehiclesFiltersFilterSortState?: UseFilterSortState;
}

export interface SignalEventsAnalyticsTabsProps
  extends SignalEventsFiltersProps,
    SignalEventsAnalyticsProps {
  queryParamsToPersist?: string[];
}

const SignalEventsAnalyticsTabs = ({
  signalEventsFiltersFilterSortState,
  vehiclesFiltersFilterSortState,
  queryParamsToPersist,
  onBadRequest,
}: SignalEventsAnalyticsTabsProps) => {
  const signalEventsFilters = signalEventsFiltersFilterSortState?.filters;
  const vehiclesFilters = vehiclesFiltersFilterSortState?.filters;

  const onAddDateRangeToVehicleFilters = (fieldDateRange: FieldDateRange) => {
    if (!vehiclesFilters) return;

    vehiclesFiltersFilterSortState.updateFilters(
      addDateRangeToFilterState(vehiclesFilters, fieldDateRange)
    );
    clearPendingFiltersForKey(VEHICLES_PAGE_KEY);
  };

  const tabsItems: (Tab | boolean | undefined)[] = [
    {
      key: SIGNAL_EVENTS_TAB_KEY,
      title: SIGNAL_EVENT_ANALYTICS_TABS[SIGNAL_EVENTS_TAB_KEY],
      content: (
        <SignalEvents
          signalEventsFilters={signalEventsFilters}
          vehiclesFilters={vehiclesFilters}
          chartSettings={signalEventsFiltersFilterSortState?.chartSettings}
          manageChartSettingsChange={
            signalEventsFiltersFilterSortState?.manageChartSettingsChange
          }
          onBadRequest={onBadRequest}
        />
      ),
    },
    {
      key: BY_VEHICLES_AGE_TAB_KEY,
      title: SIGNAL_EVENT_ANALYTICS_TABS[BY_VEHICLES_AGE_TAB_KEY],
      content: (
        <ByVehicleAge
          signalEventsFilters={signalEventsFilters}
          vehiclesFilters={vehiclesFilters}
          chartSettings={signalEventsFiltersFilterSortState?.chartSettings}
          manageChartSettingsChange={
            signalEventsFiltersFilterSortState?.manageChartSettingsChange
          }
          onBadRequest={onBadRequest}
          onAddDateRangeToVehicleFilters={onAddDateRangeToVehicleFilters}
        />
      ),
    },
    {
      key: TOP_CONTRIBUTORS_TAB_KEY,
      title: SIGNAL_EVENT_ANALYTICS_TABS[TOP_CONTRIBUTORS_TAB_KEY],
      content: (
        <TopContributors
          signalEventsFiltersFilterSortState={
            signalEventsFiltersFilterSortState
          }
          vehiclesFiltersFilterSortState={vehiclesFiltersFilterSortState}
        />
      ),
    },
    {
      key: ASSOCIATED_VEHICLES_TAB_KEY,
      title: SIGNAL_EVENT_ANALYTICS_TABS[ASSOCIATED_VEHICLES_TAB_KEY],
      content: (
        <AssociatedVehicles
          signalEventsFilters={signalEventsFilters}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
    {
      key: ASSOCIATED_CLAIMS_TAB_KEY,
      title: SIGNAL_EVENT_ANALYTICS_TABS[ASSOCIATED_CLAIMS_TAB_KEY],
      content: (
        <AssociatedClaims
          signalEventsFiltersFilterSortState={
            signalEventsFiltersFilterSortState
          }
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
    {
      key: ASSOCIATED_SIGNAL_EVENTS_TAB_KEY,
      title: SIGNAL_EVENT_ANALYTICS_TABS[ASSOCIATED_SIGNAL_EVENTS_TAB_KEY],
      content: (
        <AssociatedSignalEvents
          signalEventsFiltersFilterSortState={
            signalEventsFiltersFilterSortState
          }
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
  ];

  const tabs = tabsItems.filter(Boolean) as Tab[];

  return (
    <StatefulTabs tabs={tabs} queryParamsToPersist={queryParamsToPersist} />
  );
};

export default SignalEventsAnalyticsTabs;
