import { useAttributesContext } from "shared/contexts/GenericAttributesContextWrapper";
import { ContextProviderName } from "shared/contexts/types";
import {
  SIGNAL_EVENT_OCCURRENCES_GENERIC_FILTER,
  SIGNAL_EVENTS_GENERIC_FILTER,
  VEHICLES_GENERIC_FILTER,
} from "shared/filterDefinitions";

import { SchemaEntry } from "features/ui/Table";

import { VEHICLE_ACCESSOR } from "./constants";
import { useGroupBySelectOptions } from "./hooks_groupBy";
import { SchemaOverride, SchemaRelations, UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
  getSchemaFromAttributes,
} from "./utils";
import useVehiclesSchema from "./vehiclesSchema";

const useSignalEventOccurrencesSchema = (
  accessorsToHide: string[] = []
): UseSchema => {
  const { attributes } = useAttributesContext(
    ContextProviderName.SignalEventOccurrence
  );

  const attributeMap = getAttributeMap(attributes);

  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const getFilter = getFilterFunction(
    SIGNAL_EVENT_OCCURRENCES_GENERIC_FILTER,
    attributeMap
  );

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const overrides: Record<string, SchemaOverride> = {
    // Do not allow filtering on "Vehicle" via UI. We use a separate query param for that.
    [VEHICLE_ACCESSOR]: {
      schemaOverride: {
        filter: undefined,
      },
    },
    signalEventID: {
      schemaOverride: {
        filter: SIGNAL_EVENTS_GENERIC_FILTER({
          fieldName: "signalEventID",
          fieldNameForAPI: "ID",
          label: getDisplayLabel("signalEventID", "Signal Event ID"),
          search: true,
          disableFiltering: true,
          disableContainsFilters: true,
        }),
      },
    },
    description: {
      schemaOverride: { limitedWidthClass: "max-w-96" },
    },
    VIN: {
      schemaOverride: {
        filter: VEHICLES_GENERIC_FILTER({
          fieldName: "VIN",
          label: getDisplayLabel("VIN", "VIN"),
          search: true,
          disableFiltering: true,
        }),
      },
    },
    relatedSignalEventOccurrences: {
      schemaOverride: {
        filter: SIGNAL_EVENTS_GENERIC_FILTER({
          fieldName: "relatedSignalEventOccurrences",
          fieldNameForAPI: "ID",
          label: getDisplayLabel(
            "relatedSignalEventOccurrences",
            "Related Signal Events"
          ),
          search: true,
          disableFiltering: true,
          disableContainsFilters: true,
          filterType: "occurs",
          baseEntityText: "Base Signal Events",
        }),
      },
    },
  };

  const schema: SchemaEntry<string>[] = getSchemaFromAttributes(
    attributes,
    getSchemaEntry,
    overrides,
    undefined,
    accessorsToHide
  );

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  const groupByRelations: SchemaRelations = {
    "/v1/vehicles": useVehiclesSchema(),
  };

  const attributesForGroupBy = attributes?.filter(
    ({ ID }) => !accessorsToHide.includes(ID)
  );

  const groupBySelectOptions = useGroupBySelectOptions(
    attributesForGroupBy,
    groupByRelations
  );

  return {
    schema,
    getDisplayLabel,
    attributeMap,
    attributes,
    groupBySelectOptions,
    whitelist: Object.keys(attributeMap || {}),
    getSchemaForAccessor,
    getSchemaEntry,
  };
};
export default useSignalEventOccurrencesSchema;
