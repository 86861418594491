import { useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";

import useServiceRecordsSchema from "shared/schemas/serviceRecordsSchema";

import {
  PageConfigProps,
  ServiceRecordsConfig,
} from "pages/Admin/PagesConfig/types";

import DropdownSelect from "features/ui/DropdownSelect";
import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterBuilderQueryToFilterBuilderState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { getFilterLabel } from "features/ui/Filters/utils";
import Input from "features/ui/Input";

const HIDE_MENU_ITEM_KEY = "hideMenuItem";
const SERVICE_RECORD_TEXT_KEY = "serviceRecordText";
const STATIC_FILTERS_KEY = "staticFilters";
const PAGE_KEY = "admin_serviceRecords_static_filters";

const ServiceRecordsConfigPage = ({
  pageId,
  config,
  onConfigChange,
}: PageConfigProps<ServiceRecordsConfig>) => {
  const { schema } = useServiceRecordsSchema();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const defaultFilterValues = config[STATIC_FILTERS_KEY]
    ? filterBuilderQueryToFilterBuilderState(config[STATIC_FILTERS_KEY])
    : undefined;

  const filterState = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultFilterValues: defaultFilterValues ?? DEFAULT_FILTER_BUILDER_STATE,
    disableUsingLocalStorage: true,
    disableUsingQuery: true,
  });

  const handleOnChange = (filterState: FilterGroupState) => {
    onConfigChange(pageId, STATIC_FILTERS_KEY, getFiltersQuery(filterState));
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Switch
            checked={config[HIDE_MENU_ITEM_KEY] ?? false}
            onChange={(_, checked) =>
              onConfigChange(pageId, HIDE_MENU_ITEM_KEY, checked)
            }
            size="small"
          />
        }
        label={<div className="text-xs">Hide menu item</div>}
      />
      <Input
        label="Service record text (singular form!)"
        placeholder="Leave empty to use default"
        value={config[SERVICE_RECORD_TEXT_KEY] || ""}
        onChange={({ target: { value } }) =>
          onConfigChange(pageId, SERVICE_RECORD_TEXT_KEY, value || null)
        }
        fullWidth={false}
        className="w-80"
      />
      <div className="mt-4">
        <DropdownSelect
          testId="static-serviceRecords-filters-dropdown"
          label={getFilterLabel(
            "Static Service Record Filters",
            filterState.filters
          )}
          open={dropdownOpen}
          onOpen={(open) => {
            setDropdownOpen(open);
          }}
          hasAdvancedFilters={false}
          content={
            <FilterSelector
              schema={schema}
              filterSortState={filterState}
              disableAdvancedFilter
              pendingFiltersKey={getPendingFiltersKey(PAGE_KEY)}
              onApplyFilters={handleOnChange}
              testId="static-serviceRecord-filters-selector"
            />
          }
        />
      </div>
    </div>
  );
};

export default ServiceRecordsConfigPage;
