import { useFlags } from "launchdarkly-react-client-sdk";
import { MdClose as CloseIcon } from "react-icons/md";
import { generatePath, Link } from "react-router-dom";

import { useConfigContext } from "shared/contexts/ConfigContext";

import Button from "features/ui/Button";

import { routes } from "services/routes";

interface InformationWrapperProps {
  children: React.ReactNode;
  externalID: string;
  claimID?: string;
  onClose?: () => void;
}

const InformationWrapper = ({
  externalID,
  claimID,
  children,
  onClose,
}: InformationWrapperProps) => {
  const { claimAnalytics } = useFlags();
  const { pages } = useConfigContext();

  const linkToClaimDetailPage =
    claimID && pages.claimAnalytics && claimAnalytics;

  return (
    <div className="mb-3">
      <div className="flex mb-3 items-center justify-between">
        {linkToClaimDetailPage ? (
          <Link
            to={generatePath(routes.claimAnalyticsDetails, {
              id: claimID,
            })}
            className="text-metabase-blue hover:underline"
          >
            {externalID}
          </Link>
        ) : (
          externalID
        )}
        {onClose && (
          <Button endIcon={<CloseIcon />} size="large" onClick={onClose} />
        )}
      </div>
      <div className="flex flex-col shadow bg-white px-7 py-2 text-sm">
        {children}
      </div>
    </div>
  );
};

export default InformationWrapper;
