import { jwtDecode } from "jwt-decode";
import { useOktaAuth } from "@okta/okta-react";

import { getAPIUrl } from "shared/api/getAPIURL";
import { useHealthcheck } from "shared/api/hooks";
import { JWT } from "shared/types";
import { getTenantFromHostname } from "shared/utils";

import Row from "./Row";

const GeneralSettings = () => {
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken() || "";
  const { tenant, sub: email }: JWT = jwtDecode(accessToken);
  const tenantFromHostname = getTenantFromHostname();

  const { data } = useHealthcheck();

  return (
    <div className="flex items-start space-x-3">
      <table>
        <tbody>
          <Row title="User" value={email.toString()} />
          <Row title="Tenant from hostname" value={tenantFromHostname} />
          <Row title="Tenant from JWT" value={tenant as string} />
          <Row
            title="App Version"
            value={process.env.REACT_APP_VERSION as string}
          />
        </tbody>
      </table>
      <table>
        <tbody>
          <Row title="API Instance" value={data?.instance as string} />
          <Row title="API URL" value={getAPIUrl()} />
          <Row
            title="API Multi-tenant"
            value={data?.multiTenant.toString() as string}
          />
          <Row title="API Version" value={data?.version as string} />
        </tbody>
      </table>
    </div>
  );
};

export default GeneralSettings;
