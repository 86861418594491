import { useCallback, useMemo } from "react";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";

import { CalculatedAttributeEvaluation } from "shared/api/calculatedAttributes/api";
import {
  useEvaluateCalculatedAttribute,
  useEvaluateCalculatedAttributeCount,
} from "shared/api/calculatedAttributes/hooks";
import { getSortFilter } from "shared/api/utils";
import {
  GENERIC_FILTER_WITHOUT_SELECT,
  VEHICLES_GENERIC_FILTER,
} from "shared/filterDefinitions";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";
import { SortBy } from "shared/types";

import { PAGE_KEY } from "pages/ServiceRecommendations/constants";
import VehicleFilters from "pages/Vehicles/VehicleFilters";

import APIError from "features/ui/APIError";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import { DataType } from "features/ui/Table/TableBodyCell/types";
import TableCount from "features/ui/Table/TableCount";

import { routes } from "services/routes";

import { ATTRIBUTES_PER_PAGE } from "./constants";

interface Props {
  ID: string;
  name: string;
}

const DEFAULT_SORT: SortBy = { VIN: "asc" };

const VehiclePreview = ({ ID, name }: Props) => {
  const vehiclesFiltersFilterSortState = useFilterSortState({
    pageKey: `calculated_attribute_preview_${ID}`,
    pendingFiltersLocalStorageKey: `calculated_attribute_preview_${ID}_pending`,
  });

  const { getDisplayLabel } = useVehiclesSchema();

  const {
    filters,
    sort,
    manageFilterChange,
    manageOnSortChange,
    resetFilterSortState,
    resetFilters,
    initialized: filtersInitialized,
  } = useFilterSortState({
    pageKey: `calculated_attribute_preview_table_${ID}`,
    defaultSort: DEFAULT_SORT,
  });

  const requestParams = {
    id: ID,
    filter: getFiltersQuery(filters),
    sort: getSortFilter(sort),
    vehiclesFilter: getFiltersQuery(vehiclesFiltersFilterSortState.filters),
  };

  const { data, error, isLoading, requestKey, ...paginationData } =
    useEvaluateCalculatedAttribute(requestParams);

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
  } = useEvaluateCalculatedAttributeCount(requestParams);

  const schema: SchemaEntry[] = [
    {
      label: getDisplayLabel("VIN", "VIN"),
      accessor: "VIN",
      dataType: DataType.STRING,
      sortable: true,
      filter: VEHICLES_GENERIC_FILTER({
        label: getDisplayLabel("VIN", "VIN"),
        fieldName: "VIN",
      }),
    },
    {
      label: name,
      accessor: "value",
      dataType: DataType.NUMBER,
      sortable: true,
      filter: GENERIC_FILTER_WITHOUT_SELECT({
        label: name,
        fieldName: "value",
        filterType: DataType.NUMBER,
      }),
    },
  ];

  const formatRow = useCallback((evaluation: CalculatedAttributeEvaluation) => {
    const { VIN } = evaluation;
    const pathname = generatePath(routes.vinView, {
      vin: encodeURIComponent(VIN),
    });

    return {
      ...evaluation,
      VIN: (
        <Link to={pathname} className="text-metabase-blue hover:underline">
          {VIN}
        </Link>
      ),
    };
  }, []);

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const formattedData = useMemo(() => data?.map(formatRow), [data, formatRow]);

  return (
    <div>
      <p className="my-2">
        See the value of the calculated attribute across vehicles
      </p>
      <VehicleFilters
        vehiclesFilterSortState={vehiclesFiltersFilterSortState}
        hideCollectionFilter
      />
      <div className="flex my-2 items-center">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={resetFilters}
        />
        <TableCount
          extraClasses="ml-auto self-end"
          count={countData?.count as number}
          entityName="evaluation"
          isLoading={countIsLoading}
          error={!!countError}
        />
      </div>
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={ATTRIBUTES_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageFilterChange}
          filters={filters}
          pageKey={PAGE_KEY}
          dense
        />
      )}
    </div>
  );
};

export default VehiclePreview;
