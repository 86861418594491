import { useGetIssueCumulativeSignalEventsByVehicleAge } from "shared/api/issues/hooks";
import { DEFAULT_EXPOSURE } from "shared/constants";
import { useCustomLocalStorageState, useTenantMileageUnit } from "shared/hooks";
import { IssueTypes } from "shared/types";

import { useSEByVehicleAgeExposureOptions } from "pages/hooks";
import ChartWithComparisonPopulation from "pages/Issues/Charts/ChartWithComparisonPopulation";
import PopulationChart from "pages/Issues/Charts/PopulationChart";
import { SignalEventsCharts } from "pages/Issues/constants";
import {
  getAxisKeyLabelFromActions,
  getChartOptionsKey,
} from "pages/Issues/utils";

import Card from "features/ui/Card";
import {
  cumulativeDPTV,
  cumulativeDPTVDV,
  xAxis,
  yAxis,
} from "features/ui/charts/actions";
import ChartActions from "features/ui/charts/Actions/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/Actions/ChartActionsWrap";
import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import { getDefaultActions } from "features/ui/charts/utils";

const CHART_NAME = SignalEventsCharts.OCCURRENCES_BY_VEHICLE_AGE;

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const OccurrencesByVehicleAgeChart = ({
  issue,
  withComparisonPopulation,
}: Props) => {
  const exposures = useSEByVehicleAgeExposureOptions();
  const chartOptionsKey = getChartOptionsKey(CHART_NAME, issue.ID);

  const actions: ChartAction[] = [
    {
      id: "x",
      title: xAxis,
      type: "dropdownSelect",
      options: exposures,
      defaultOptionId: DEFAULT_EXPOSURE,
    },
    {
      id: "y",
      title: yAxis,
      type: "dropdownSelect",
      options: [cumulativeDPTVDV, cumulativeDPTV],
    },
  ];

  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(chartOptionsKey, { defaultValue: getDefaultActions(actions) });

  const { axisKey: xAxisKey, axisValue: xAxisValue } =
    getAxisKeyLabelFromActions(selectedOptions, actions, "x");
  const { axisKey: yAxisKey, axisValue: yAxisValue } =
    getAxisKeyLabelFromActions(selectedOptions, actions, "y");

  const PopulationsChart = withComparisonPopulation
    ? ChartWithComparisonPopulation
    : PopulationChart;

  const chartTitle = `Signal Event Occurrences by ${xAxisValue}`;

  const dataLoadHookParams = {
    byVehicleAgeExposure: xAxisKey,
    mileageUnit: useTenantMileageUnit(),
  };

  return (
    <Card>
      <ChartActionsWrap id={CHART_NAME} chartTitle={chartTitle}>
        <ChartActions
          actions={actions}
          selectedOptions={selectedOptions}
          onOptionChange={setSelectedOptions}
        />
      </ChartActionsWrap>
      <PopulationsChart
        issue={issue}
        xAxisLabel={xAxisValue}
        yAxisKey={yAxisKey}
        yAxisLabel={yAxisValue}
        chartType="VehicleAge"
        chart={CHART_NAME}
        dataLoadHook={useGetIssueCumulativeSignalEventsByVehicleAge}
        dataLoadHookParams={{ ...dataLoadHookParams }}
        exposure={xAxisKey}
      />
    </Card>
  );
};

export default OccurrencesByVehicleAgeChart;
