import { useAttributesContext } from "shared/contexts/GenericAttributesContextWrapper";
import { ContextProviderName } from "shared/contexts/types";
import { REPAIRS_GENERIC_FILTER } from "shared/filterDefinitions";

import { SchemaEntry } from "features/ui/Table";

import { VEHICLE_ACCESSOR } from "./constants";
import { useGroupBySelectOptions } from "./hooks_groupBy";
import { SchemaEntryOverride, SchemaRelations, UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
  getSchemaFromAttributes,
} from "./utils";
import useVehiclesSchema from "./vehiclesSchema";
import useWorkOrdersSchema from "./workOrdersSchema";

export const useRepairsSchema = (accessorsToHide: string[] = []): UseSchema => {
  const { attributes } = useAttributesContext(ContextProviderName.Repair);

  const workOrdersSchema = useWorkOrdersSchema();

  const attributeMap = getAttributeMap(attributes);

  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const getFilter = getFilterFunction(REPAIRS_GENERIC_FILTER, attributeMap);

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const overrides: SchemaEntryOverride = {
    // Do not allow filtering on "Vehicle" via UI. We use a separate query param for that.
    [VEHICLE_ACCESSOR]: {
      schemaOverride: {
        filter: undefined,
      },
    },
    ID: {
      schemaOverride: {
        label: "",
        filter: undefined,
        sortable: false,
      },
    },
  };

  const relations: SchemaRelations = {
    "/v1/workOrders": workOrdersSchema,
  };

  const schema: SchemaEntry[] = getSchemaFromAttributes(
    attributes,
    getSchemaEntry,
    overrides,
    relations,
    accessorsToHide
  );

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  const groupByRelations: SchemaRelations = {
    "/v1/vehicles": useVehiclesSchema(),
  };

  const attributesForGroupBy = attributes?.filter(
    ({ ID }) => !accessorsToHide.includes(ID)
  );

  const groupBySelectOptions = useGroupBySelectOptions(
    attributesForGroupBy,
    groupByRelations
  );

  return {
    schema,
    attributes,
    groupBySelectOptions,
    getDisplayLabel,
    attributeMap,
    whitelist: Object.keys(attributeMap || {}),
    getSchemaEntry,
    getSchemaForAccessor,
  };
};
