import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";

import {
  AdminOrderedValuesConfig,
  getAdminOrderedValuesConfig,
  updateAdminOrderedValuesConfig,
} from "shared/api/admin/api";
import { useOrderedValuesConfigList } from "shared/api/admin/hooks";
import { APIError } from "shared/api/api";
import { capitalizeFirstLetter } from "shared/utils";

import Button from "features/ui/Button";
import DropdownWithSearch from "features/ui/DropdownWithSearch/DropdownWithSearch";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import { SelectOption } from "features/ui/Select";
import TextArea from "features/ui/TextArea";
import Title from "features/ui/Title";

import { showErrorToast } from "./utils";

const TITLE = "Ordered Values Configuration";
const CONFIG_ROWS = 25;

const OrderedValuesConfiguration = () => {
  const [selectedOrderedValuesType, setSelectedOrderedValuesType] =
    useState<SelectOption | null>(null);

  const [isConfigLoading, setIsConfigLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [configuration, setConfiguration] = useState("");

  const { data: orderedValuesTypes } = useOrderedValuesConfigList();

  const orderedValuesTypeOptions: SelectOption[] =
    orderedValuesTypes?.map((type) => ({
      id: type,
      value: capitalizeFirstLetter(type),
    })) || [];

  const handleResourceChange = (option: SelectOption | null) => {
    if (option) {
      setSelectedOrderedValuesType(option);
      setIsConfigLoading(true);
      setIsError(false);

      getAdminOrderedValuesConfig(option.id as string)
        .then(({ data }) => {
          setConfiguration(JSON.stringify(data, null, 2));
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => setIsConfigLoading(false));
    } else {
      setSelectedOrderedValuesType(null);
      setConfiguration("");
      setIsError(false);
    }
  };

  const textAreaLabel = `${selectedOrderedValuesType?.value} ordered values configuration`;

  const handleSave = () => {
    if (!showConfig && !isValidJSON) return;

    setIsSubmitting(true);

    const parsedConfig = JSON.parse(configuration);
    const configArray = Object.values(parsedConfig) as AdminOrderedValuesConfig;

    updateAdminOrderedValuesConfig({
      type: selectedOrderedValuesType!.id as string,
      values: configArray,
    })
      .then(() => {
        toast.success(
          `${selectedOrderedValuesType!.value} configuration saved successfully!`
        );
      })
      .catch((error: APIError) => {
        showErrorToast(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const isValidJSON = Boolean(
    configuration &&
      configuration.trim().length > 0 &&
      (() => {
        try {
          JSON.parse(configuration);

          return true;
        } catch (error) {
          return false;
        }
      })()
  );

  const showConfig = Boolean(!isConfigLoading && selectedOrderedValuesType);

  return (
    <div>
      <PageHeaderWrapper>
        <Title text={TITLE} />
      </PageHeaderWrapper>
      <div className="mb-4">
        <DropdownWithSearch
          label="Select ordered values type"
          options={orderedValuesTypeOptions}
          selectedOption={selectedOrderedValuesType}
          onSelectedOptionChange={handleResourceChange}
          testId="resource-select"
        />
      </div>
      {isError && (
        <div className="mb-4">
          <p className="text-red-500">Failed to load configuration.</p>
        </div>
      )}
      {isConfigLoading && <Skeleton height={CONFIG_ROWS * 20} />}
      {showConfig && (
        <div className="max-w-4xl">
          <TextArea
            label={textAreaLabel}
            value={configuration}
            onChange={(e) => setConfiguration(e.target.value)}
            testId="ordered-values-config-text-area"
            rows={CONFIG_ROWS}
            error={!isValidJSON}
          />
          <div className="flex justify-end mt-4">
            <Button
              label="Save"
              color="primary"
              variant="contained"
              testId="save-ordered-values-config"
              onClick={handleSave}
              disabled={!isValidJSON}
              isLoading={isSubmitting}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderedValuesConfiguration;
