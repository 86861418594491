import { useState } from "react";
import { BreadcrumbMatch } from "use-react-router-breadcrumbs";

import { APISuccessResponse } from "shared/api/api";
import { Claim, getClaim } from "shared/api/claims/api";
import { getInspection, Inspection } from "shared/api/inspections/api";
import { getRepair, Repair } from "shared/api/repairs/api";
import { useTenantMileageUnit } from "shared/hooks";
import { EventTypeEnum } from "shared/types";
import { getEntityWithExternalIDTitle } from "shared/utils";

interface Props {
  match: BreadcrumbMatch;
  entity?: EventTypeEnum;
}

type AllowedEventTypes = Extract<
  EventTypeEnum,
  EventTypeEnum.INSPECTION | EventTypeEnum.REPAIR | EventTypeEnum.CLAIM
>;

interface EntityMap
  extends Record<AllowedEventTypes, Inspection | Repair | Claim> {
  [EventTypeEnum.INSPECTION]: Inspection;
  [EventTypeEnum.REPAIR]: Repair;
  [EventTypeEnum.CLAIM]: Claim;
}

const ENTITY_MAPPINGS: Record<
  AllowedEventTypes,
  {
    get: (
      params: any
    ) => Promise<APISuccessResponse<EntityMap[AllowedEventTypes]>>;
  }
> = {
  [EventTypeEnum.INSPECTION]: {
    get: getInspection,
  },
  [EventTypeEnum.REPAIR]: {
    get: getRepair,
  },
  [EventTypeEnum.CLAIM]: {
    get: getClaim,
  },
};

const BreadcrumbWithIDParam = ({ match, entity }: Props) => {
  if (!entity || !(entity in ENTITY_MAPPINGS)) {
    throw new Error(`Invalid entity: ${entity}`);
  }

  const entityCallback = ENTITY_MAPPINGS[entity as AllowedEventTypes].get;

  const [data, setData] = useState<EntityMap[AllowedEventTypes]>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const mileageUnit = useTenantMileageUnit();

  if (isLoading) {
    return "...";
  }

  if (data?.externalID)
    return getEntityWithExternalIDTitle(
      data.externalID,
      entity as EventTypeEnum
    );

  if (error && match.params.id) {
    return match.params.id;
  }

  // mileage for CLAIM
  const entityCallbackOtherProps =
    entity === EventTypeEnum.CLAIM ? { mileageUnit } : {};

  entityCallback({
    ID: match.params.id as string,
    ...entityCallbackOtherProps,
  } as any)
    .then(({ data }) => {
      setData(data);
    })
    .catch(() => setError(true))
    .finally(() => setIsLoading(false));

  return <></>;
};

export default BreadcrumbWithIDParam;
