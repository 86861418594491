import { useFlags } from "launchdarkly-react-client-sdk";

import { EventTypeLabel } from "shared/types";

import {
  useByVehicleAgeBirthdayOptions,
  useClaimByVehicleAgeExposureOptions,
} from "pages/hooks";
import { getByVehicleAgeChartOptions } from "pages/utils";

import {
  cumulativeCPV,
  cumulativeEvents,
  cumulativeIPTV,
  totalVehicles,
} from "features/ui/charts/actions";
import { ChartAction } from "features/ui/charts/Actions/types";
import { Option, SelectOption } from "features/ui/Select";

/**
 * This hook is used by the ByVehicleAge chart to build the available chart actions.
 * It is also used by Duck to provide context to the agent.
 */
export const useByVehicleAgeChartActions = (
  eventType: EventTypeLabel
): ChartAction<Option>[] => {
  const { warrantyClaimsCost: warrantyClaimsCostFF } = useFlags();

  const birthdayOptions = useByVehicleAgeBirthdayOptions();
  const exposureOptions = useClaimByVehicleAgeExposureOptions();

  if (eventType === EventTypeLabel.CLAIM) {
    return getByVehicleAgeChartOptions(
      getClaimsByVehicleAgeYAxisOptions(warrantyClaimsCostFF),
      birthdayOptions,
      exposureOptions
    );
  }

  const yAxisOptions: SelectOption[] = [
    cumulativeIPTV,
    warrantyClaimsCostFF && cumulativeCPV,
    totalVehicles,
    cumulativeEvents(eventType),
  ].filter(Boolean);

  return getByVehicleAgeChartOptions(
    yAxisOptions,
    birthdayOptions,
    exposureOptions
  );
};

export const getClaimsByVehicleAgeYAxisOptions = (
  warrantyClaimsCostFF: boolean
): SelectOption[] =>
  [
    {
      id: "cumulativeIPTV",
      value: "IPTV",
    },
    {
      id: "distinctIPTV",
      value: "IPTV-Distinct VINs",
    },
    warrantyClaimsCostFF && {
      id: "costRate",
      value: "CPV",
    },
    {
      id: "numEvents",
      value: "Incident Count",
    },
    {
      id: "numVehicles",
      value: "Affected Vehicles",
    },
    warrantyClaimsCostFF && {
      id: "cumulativeCPV",
      value: "Cost",
    },
    {
      id: "vehiclesAtExposure",
      value: "Vehicles at Exposure",
    },
    {
      id: "totalVehicles",
      value: "Vehicles Manufactured",
    },
    {
      id: "percentVehiclesAtExposure",
      value: "Percent of Vehicles at Exposure",
    },
  ].filter(Boolean) as SelectOption[];
