import ClaimAnalyticsLink from "pages/shared/analytics/ClaimAnalyticsLink";

import Modal from "features/ui/Modal";
import ValuesSearchList from "features/ui/ValuesSearchList";

const MAX_VISIBLE_STRINGS_DEFAULT = 2;

export interface Props {
  title: string;
  values?: string[];
  maxVisible?: number;
  accessor?: string;
  linkToClaimAnalytics?: boolean;
}

const StringList = ({
  title,
  values,
  maxVisible = MAX_VISIBLE_STRINGS_DEFAULT,
  accessor,
  linkToClaimAnalytics,
}: Props) => {
  if (!values || values.length === 0) {
    return null;
  }

  const valuesPerString = values.slice(0, maxVisible);
  const valuesForMore = values.slice(maxVisible);
  const moreLabel = `+ ${valuesForMore.length} more`;

  const moreButton = (
    <span
      className="ml-1 text-gray-500 text-xs hover:text-viaduct-black"
      role="button"
    >
      {moreLabel}
    </span>
  );

  return (
    <div>
      {valuesPerString.map((value, idx) => (
        <span key={`val-${idx}`}>
          {idx !== 0 ? ", " : ""}
          <span>
            {(!linkToClaimAnalytics || !accessor) && value}
            {linkToClaimAnalytics && (
              <ClaimAnalyticsLink attribute={accessor || ""} value={value} />
            )}
          </span>
        </span>
      ))}
      {valuesForMore.length > 0 && (
        <Modal
          triggerElement={moreButton}
          children={[
            <ValuesSearchList title={title} values={values as string[]} />,
          ]}
        />
      )}
    </div>
  );
};

export default StringList;
