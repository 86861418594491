import { createContext, useContext } from "react";

import { GeneralConfigModel } from "shared/api/admin/api";
import { useAdminPagesConfig, useGeneralConfig } from "shared/api/admin/hooks";

import { DEFAULT_GENERAL_CONFIG } from "pages/Admin/constants";
import { PagesConfigModel } from "pages/Admin/PagesConfig/types";

import { ContextWrapComponentProps } from "./types";

export interface ConfigContextInterface {
  general: GeneralConfigModel;
  pages: PagesConfigModel;
  loaded: boolean;
}

const defaultGeneralConfig: GeneralConfigModel = DEFAULT_GENERAL_CONFIG;
const defaultPagesConfig: PagesConfigModel = {};

export const ConfigContext = createContext<ConfigContextInterface>({
  general: defaultGeneralConfig,
  pages: defaultPagesConfig,
  loaded: false,
});

const ConfigContextWrapper = ({ children }: ContextWrapComponentProps) => {
  const { data: generalConfig } = useGeneralConfig({});
  const { data: pagesConfigArray } = useAdminPagesConfig({});

  const pagesConfig: PagesConfigModel | undefined = pagesConfigArray?.reduce(
    (acc, { ID, config }) => {
      acc[ID] = config;

      return acc;
    },
    {} as PagesConfigModel
  );

  return (
    <ConfigContext.Provider
      value={{
        general: generalConfig || defaultGeneralConfig,
        pages: pagesConfig || defaultPagesConfig,
        loaded: Boolean(generalConfig) && Boolean(pagesConfig),
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfigContext = (): ConfigContextInterface => {
  const context = useContext(ConfigContext);

  if (!context) {
    throw new Error("useConfigContext must be used within a ConfigContext");
  }

  return context;
};

export default ConfigContextWrapper;
