import { useEffect, useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { BiPencil as EditIcon } from "react-icons/bi";
import { CgSpinnerTwo as LoadingIcon } from "react-icons/cg";
import {
  generatePath,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { FormControlLabel, IconButton, Radio, RadioGroup } from "@mui/material";
import Alert from "@mui/material/Alert";

import {
  AlertDefinition as AlertDefinitionModel,
  AlertDefinitionPostRequestData,
  AlertEventType,
  deleteAlertDefinition,
  getAlertDefinition,
  newAlertDefinition,
  updateAlertDefinition,
} from "shared/api/alertDefinitions/api";
import { DeleteRequest } from "shared/api/api";
import { loadGroupsListFunc } from "shared/api/filter.utils";
import { useEmailFromJWT } from "shared/hooks";
import useAlertDefinitionSchema from "shared/schemas/alertDefinitionsSchema";

import {
  ALERT_DEFINITION_DESCRIPTION_LABEL,
  ALERT_DEFINITION_NAME_LABEL,
  ALERT_EVENT_TYPE_OPTIONS,
  ALERT_PLAN_TITLE,
  AlertType,
  EMPTY_ALERT_DEFINITION_STATE,
  FrequencyOptions,
  ICON_SIZE,
  LIST_ALERT_LABEL,
  OBJECT_ALERT_LABEL,
  OBJECT_EVENT_TYPES,
  ON_SUCCESS_CREATE_TEXT,
  ON_UPDATE_CREATE_TEXT,
} from "pages/AlertDefinitions/constants";
import { AlertDefinitionState } from "pages/AlertDefinitions/types";
import { validateForm } from "pages/AlertDefinitions/utils";
import {
  CANCEL_CTA_TEXT,
  SUBMIT_CTA_EDIT,
  SUBMIT_CTA_NEW,
} from "pages/constants";
import FormLoader from "pages/ServicePlans/Form/FormLoader";

import Button from "features/ui/Button";
import DeleteAction from "features/ui/DeleteAction";
import {
  DEFAULT_FILTER_BUILDER_STATE,
  DEFAULT_FILTER_BUILDER_STATE_ANY,
} from "features/ui/Filters/FilterBuilder/constants";
import {
  filterBuilderQueryToFilterBuilderState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import SelectFilter from "features/ui/Filters/FilterTypes/SelectFilter";
import EditDetails from "features/ui/Form/EditDetails";
import FormSection from "features/ui/FormSection";
import Input from "features/ui/Input";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import PermissionsSettingsAction from "features/ui/PermissionsDialog/PermissionsSettingsAction";
import { isValidEmail } from "features/ui/PermissionsDialog/utils";
import Select from "features/ui/Select";
import TagsInput from "features/ui/TagsInput";
import Title from "features/ui/Title";

import { routes } from "services/routes";

import AlertDefinitionEventList from "./AlertDefinitionEventList";
import AlertDefinitionObjectEvent from "./AlertDefinitionObjectEvent";
import AlertTypeAndFrequencyRadioForm from "./AlertTypeAndFrequencyRadioForm";
import {
  ALERT_EVENT_TYPE_TEXT,
  ALERT_EVENT_TYPE_TITLE,
  ALERT_OBJECT_TYPE_TEXT,
  ALERT_OBJECT_TYPE_TITLE,
  ALERT_TYPE_TEXT,
  ALERT_TYPE_TITLE,
} from "./constants";

type AlertDefinitionParams = {
  id: string;
};

const AlertDefinition = () => {
  const { id: alertDefinitionId } = useParams<AlertDefinitionParams>();
  const isCreatePage = !alertDefinitionId;
  const [editMode, setEditMode] = useState(isCreatePage);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.LIST);
  const [error, setError] = useState<any>(undefined);

  const [validationErrors, setValidationErrors] = useState("");
  const [showStaticValidationErrors, setShowStaticValidationErrors] =
    useState(!isCreatePage);

  const navigate = useNavigate();

  const email = useEmailFromJWT();
  const initialState = EMPTY_ALERT_DEFINITION_STATE;
  initialState.emails = [email];

  const [currentAlertDefinition, setCurrentAlertDefinition] =
    useState<AlertDefinitionState>(initialState);

  const { rbac } = useFlags();
  const canEditAlertDefinition: boolean =
    !rbac || (rbac && currentAlertDefinition.canEdit);

  const { getDisplayLabel } = useAlertDefinitionSchema();
  const [initialAlertDefinition, setInitialAlertDefinition] =
    useState<AlertDefinitionState>();

  const SUBMIT_CTA_TEXT = isCreatePage ? SUBMIT_CTA_NEW : SUBMIT_CTA_EDIT;

  const isFormDisabled = isSubmitting || !editMode;

  const [alertEventTypeOptions, setAlertEventTypeOptions] = useState(
    ALERT_EVENT_TYPE_OPTIONS.filter((option) => option.type === alertType)
  );

  const onAlertTypeChanged = (type: AlertType) => {
    const options = ALERT_EVENT_TYPE_OPTIONS.filter(
      (option) => option.type === type
    );
    setAlertEventTypeOptions(options);
    setAlertType(type);

    currentAlertDefinition.eventType = options[0].id as AlertEventType;
  };

  useEffect(() => {
    if (isCreatePage) {
      return;
    }

    setIsLoading(true);
    getAlertDefinition({ ID: alertDefinitionId as string })
      .then(({ data }) => {
        setAndRememberInitialAlertDefinition(data);
        const type = OBJECT_EVENT_TYPES.includes(data.eventType)
          ? AlertType.OBJECT
          : AlertType.LIST;
        setAlertEventTypeOptions(ALERT_EVENT_TYPE_OPTIONS);
        setAlertType(type);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatePage, alertDefinitionId]);

  const { valid, message: staticErrorMessage } = validateForm(
    currentAlertDefinition
  );

  const showError =
    validationErrors || (showStaticValidationErrors && staticErrorMessage);

  const onDataUpdated = (update: Partial<AlertDefinitionState>) => {
    setCurrentAlertDefinition({ ...currentAlertDefinition, ...update });
  };

  const resetToInitialAlertDefinition = () => {
    if (!initialAlertDefinition) return;

    setCurrentAlertDefinition(initialAlertDefinition);
  };

  const setAndRememberInitialAlertDefinition = (data: AlertDefinitionModel) => {
    const alertDefinitionState: AlertDefinitionState = {
      ID: data.ID,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      createdBy: data.createdBy,
      updatedBy: data.createdBy,
      name: data.name,
      description: data.description,
      vehiclesFilter:
        filterBuilderQueryToFilterBuilderState(data.vehiclesFilter) ||
        DEFAULT_FILTER_BUILDER_STATE,
      eventType: data.eventType,
      eventFilter:
        filterBuilderQueryToFilterBuilderState(data.eventFilter) ||
        DEFAULT_FILTER_BUILDER_STATE_ANY,
      frequency: data.frequency,
      emails: data.emails,
      groups: data.groups.map((item) => item.ID),
      access: data.access,
      initialGroups: data.groups.map(({ name, ID }) => ({
        id: ID,
        value: name,
      })),
      canEdit: data.canEdit,
      inAppAlerts: data.inAppAlerts,
      emailAlerts: data.emailAlerts,
      notifications: data.notifications,
      watchedObjectEventTypes: data.watchedObjectEventTypes,
      watchedObjectID: data.watchedObjectID,
      watchedObject: data.watchedObject,
    };

    setInitialAlertDefinition(alertDefinitionState);
    setCurrentAlertDefinition(alertDefinitionState);
  };

  const getAlertDefinitionRequestData = () => {
    const vehiclesFiltersString = getFiltersQuery(
      currentAlertDefinition.vehiclesFilter
    );
    const eventFilterString = getFiltersQuery(
      currentAlertDefinition.eventFilter
    );

    const alertDefinitionPostRequestData: AlertDefinitionPostRequestData = {
      name: currentAlertDefinition.name,
      description: currentAlertDefinition.description,
      vehiclesFilter:
        vehiclesFiltersString === "" ? null : vehiclesFiltersString,
      eventType: currentAlertDefinition.eventType,
      eventFilter: eventFilterString === "" ? null : eventFilterString,
      frequency: currentAlertDefinition.frequency,
      emails: currentAlertDefinition.emails,
      groupIds: currentAlertDefinition.groups,
      inAppAlerts: currentAlertDefinition.inAppAlerts,
      emailAlerts: currentAlertDefinition.emailAlerts,
      watchedObjectEventTypes: currentAlertDefinition.watchedObjectEventTypes,
      watchedObjectID: currentAlertDefinition.watchedObjectID,
    };

    return alertDefinitionPostRequestData;
  };

  const handleAlertDefinitionCreate = () => {
    const data = getAlertDefinitionRequestData();

    newAlertDefinition(data)
      .then(({ data: { ID } }) => {
        toast.success(ON_SUCCESS_CREATE_TEXT);
        setEditMode(false);
        navigate(generatePath(routes.alertDefinition, { id: ID }));
      })
      .catch((error) => {
        setValidationErrors(error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleAlertDefinitionUpdate = () => {
    updateAlertDefinition({
      ...getAlertDefinitionRequestData(),
      ID: currentAlertDefinition.ID,
    })
      .then(({ data: { ID } }) => {
        toast.success(ON_UPDATE_CREATE_TEXT);
        setEditMode(false);
        navigate(generatePath(routes.alertDefinition, { id: ID }));
      })
      .catch((error) => {
        setValidationErrors(error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const submitCallback = isCreatePage
    ? handleAlertDefinitionCreate
    : handleAlertDefinitionUpdate;

  const handleOnCancel = () => {
    if (isCreatePage) {
      navigate(routes.alertDefinitions);
    } else {
      resetToInitialAlertDefinition();
      setEditMode(false);
    }
  };

  const handleOnSubmit = () => {
    if (!valid) {
      setShowStaticValidationErrors(true);

      return;
    }

    setValidationErrors("");
    setIsSubmitting(true);

    submitCallback();
  };

  const handleOnDelete = (args: DeleteRequest) =>
    deleteAlertDefinition(args).then((response) => {
      navigate(routes.alertDefinitions);

      return response;
    });

  if (error) {
    return <Navigate to={routes.alertDefinitions} replace />;
  }

  const ctaIcon =
    (isSubmitting && <LoadingIcon className="animate-spin" />) || undefined;

  const handleFrequencyOrTypeChange = (
    frequency: string,
    inAppAlerts: boolean,
    emailAlerts: boolean
  ) => {
    currentAlertDefinition.frequency = frequency;
    currentAlertDefinition.emailAlerts = emailAlerts;
    currentAlertDefinition.inAppAlerts = inAppAlerts;
    setCurrentAlertDefinition({ ...currentAlertDefinition });
    onDataUpdated({ ...currentAlertDefinition });
  };

  return (
    <div>
      {isLoading && <FormLoader />}
      {!isLoading && (
        <div className="max-w-5xl">
          {showError && (
            <Alert
              severity="error"
              data-testid="alert-definition-form-validation-errors"
              className="mb-5"
            >
              <div className="font-bold mb-1">Validation errors</div>
              {validationErrors || staticErrorMessage}
            </Alert>
          )}
          <PageHeaderWrapper>
            <Title text={ALERT_PLAN_TITLE} />
          </PageHeaderWrapper>
          <div className="mb-2 flex">
            <div className="flex w-full">
              <div className="mr-4 leading-9 text-right w-[82px]">
                {getDisplayLabel("name", ALERT_DEFINITION_NAME_LABEL)}
              </div>
              <Input
                value={currentAlertDefinition.name}
                onChange={({ target: { value } }) =>
                  onDataUpdated({ name: value })
                }
                testId="alert-definition-name"
                disabled={isFormDisabled}
                fullWidth={false}
                className="w-80"
                tabIndex={0}
              />
              <div className="flex items-center ml-auto">
                {!isCreatePage && (
                  <EditDetails props={currentAlertDefinition} />
                )}
                {!editMode && !isCreatePage && canEditAlertDefinition && (
                  <div className="ml-auto">
                    <PermissionsSettingsAction
                      entity="alertDefinition"
                      entityId={alertDefinitionId!}
                      entityName={currentAlertDefinition.name}
                      permissions={currentAlertDefinition.access}
                      canEdit={currentAlertDefinition.canEdit}
                      iconSize={ICON_SIZE}
                      entityRequestKeys={[]}
                    />
                    <DeleteAction
                      data={{
                        ID: currentAlertDefinition.ID!,
                        name: currentAlertDefinition?.name,
                      }}
                      entityName="alert definition"
                      deleteCallback={handleOnDelete}
                      entityRequestKeys={[]}
                      iconOnly={true}
                    />
                    <IconButton
                      onClick={() => setEditMode(true)}
                      size="small"
                      data-testid="alert-definition-form-edit-mode-cta"
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                )}
                {editMode && (
                  <>
                    <Button
                      color="secondary"
                      variant="outlined"
                      label={CANCEL_CTA_TEXT}
                      onClick={handleOnCancel}
                      isLoading={isSubmitting}
                      endIcon={ctaIcon}
                      disabled={isSubmitting}
                      testId="alert-form-cancel-cta"
                      size="medium"
                      className="!mr-4"
                      tabIndex={-1}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      label={SUBMIT_CTA_TEXT}
                      onClick={handleOnSubmit}
                      isLoading={isSubmitting}
                      endIcon={ctaIcon}
                      disabled={isSubmitting}
                      testId="alert-definition-form-submit-cta"
                      size="medium"
                      tabIndex={-1}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="mb-8 flex">
            <div className="mr-4  leading-9 text-right shrink-0 w-[82px]">
              {getDisplayLabel(
                "description",
                ALERT_DEFINITION_DESCRIPTION_LABEL
              )}
            </div>
            <div className="w-full">
              <Input
                value={currentAlertDefinition.description || ""}
                onChange={({ target: { value } }) =>
                  onDataUpdated({ description: value })
                }
                testId="alert-definition-description"
                disabled={isFormDisabled}
                tabIndex={1}
              />
            </div>
          </div>
          <FormSection title={ALERT_TYPE_TITLE} text={ALERT_TYPE_TEXT}>
            <RadioGroup
              row
              aria-labelledby="alert-type-radio-group-label"
              name="alert-type-radio-group"
              className="gap-8"
              value={alertType || AlertType.LIST}
              onChange={(event) => {
                onAlertTypeChanged(event.target.value as AlertType);
              }}
            >
              <FormControlLabel
                disabled={isFormDisabled}
                value={AlertType.LIST}
                control={<Radio />}
                label={LIST_ALERT_LABEL}
              />
              <FormControlLabel
                disabled={isFormDisabled}
                value={AlertType.OBJECT}
                control={<Radio />}
                label={OBJECT_ALERT_LABEL}
              />
            </RadioGroup>
          </FormSection>

          <FormSection
            title={
              alertType === AlertType.LIST
                ? ALERT_EVENT_TYPE_TITLE
                : ALERT_OBJECT_TYPE_TITLE
            }
            text={
              alertType === AlertType.LIST
                ? ALERT_EVENT_TYPE_TEXT
                : ALERT_OBJECT_TYPE_TEXT
            }
          >
            <div className="max-w-96">
              <Select
                testId="alert-event-type"
                disabled={isFormDisabled}
                options={alertEventTypeOptions}
                selected={ALERT_EVENT_TYPE_OPTIONS.find(
                  (option) => option.id === currentAlertDefinition.eventType
                )}
                onSelect={(selectedOption) =>
                  onDataUpdated({
                    eventType: selectedOption.id as AlertEventType,
                    eventFilter: DEFAULT_FILTER_BUILDER_STATE_ANY,
                  })
                }
              />
            </div>
          </FormSection>
          {OBJECT_EVENT_TYPES.includes(currentAlertDefinition.eventType) ? (
            <AlertDefinitionObjectEvent
              currentAlertDefinition={currentAlertDefinition}
              onDataUpdated={onDataUpdated}
              isFormDisabled={isFormDisabled}
            />
          ) : (
            <AlertDefinitionEventList
              currentAlertDefinition={currentAlertDefinition}
              onDataUpdated={onDataUpdated}
              editMode={editMode}
              isFormDisabled={isFormDisabled}
            />
          )}
          <FormSection title="Notification Recipients">
            <div className="flex">
              <div className="flex flex-row w-full">
                <div className="flex flex-col">
                  <div className="h-full">
                    <div className="mr-4 leading-9 text-right shrink-0">
                      Individuals
                    </div>
                  </div>
                  <div className="h-full pt-2">
                    <div className="mr-4 leading-9 text-right shrink-0">
                      Groups
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <div>
                    <TagsInput
                      label="Recipients"
                      key="recipients"
                      fieldName="Individuals"
                      testId="alert-definition-recipients"
                      onChange={(options) => {
                        onDataUpdated({ emails: options });
                      }}
                      disabled={isFormDisabled}
                      validationFunction={(value: string) =>
                        isValidEmail(value)
                      }
                      validationErrorMessage="Not a valid email."
                      values={currentAlertDefinition.emails}
                      caseInsensitive
                    />
                  </div>
                  <div className="pt-2">
                    <SelectFilter
                      label="Groups"
                      fieldName="name"
                      loadOptionsFunc={loadGroupsListFunc}
                      onChange={(options) => {
                        onDataUpdated({ groups: options });
                      }}
                      disabled={isFormDisabled}
                      initialSelected={currentAlertDefinition.initialGroups}
                      search={true}
                      loadDataOnOpen={true}
                      disableArbitraryText={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </FormSection>
          <FormSection title="Alert Type And Frequency" className="mt-6">
            <AlertTypeAndFrequencyRadioForm
              frequency={currentAlertDefinition.frequency as FrequencyOptions}
              inAppAlerts={currentAlertDefinition.inAppAlerts}
              emailAlerts={currentAlertDefinition.emailAlerts}
              onChange={handleFrequencyOrTypeChange}
              disabled={isFormDisabled}
            ></AlertTypeAndFrequencyRadioForm>
          </FormSection>
        </div>
      )}
    </div>
  );
};

export default AlertDefinition;
