import { add, format } from "date-fns";
import { toDate } from "date-fns-tz";
import { XAxisProps } from "recharts";

import {
  IssueMetricsRequest,
  IssueRepairEfficacyVINTimeline,
  IssuesOverview,
  SuggestedIssuesOverview,
} from "shared/api/issues/api";
import { SuggestedIssue } from "shared/api/suggestedIssues/api";
import { ISSUES_ROUTE, SUGGESTED_ISSUE_RUNS_ROUTE } from "shared/constants";
import { useConfigContext } from "shared/contexts/ConfigContext";
import {
  BucketByIssuesEnum,
  BucketBySuggestedIssuesEnum,
  ChartActionID,
  GroupByIssuesEnum,
  GroupBySuggestedIssuesEnum,
  IssueChart,
  IssueChartType,
  IssueTypes,
  IssueVehiclePopulation,
  ValueType,
} from "shared/types";
import {
  formatNumber,
  formatPercentValue,
  isNumeric,
  randomID,
} from "shared/utils";

import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import { DASHBOARD_GROUP_BY_KEY } from "features/ui/charts/constants";
import {
  ChartDataElement,
  DataElement,
  LegendConfigLabel,
  YAxisLine,
} from "features/ui/charts/types";
import {
  generateXAxisTickProps,
  getAxisValue,
  getColor,
  getMISTicksFromDIS,
  getSelectedOptionId,
} from "features/ui/charts/utils";
import {
  FilterGroupState,
  FilterRowState,
} from "features/ui/Filters/FilterBuilder/types";
import { filterBuilderQueryToFilterBuilderState } from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator } from "features/ui/Filters/types";
import { SelectOption } from "features/ui/Select";
import { SchemaEntry } from "features/ui/Table";

import {
  DEFAULT_ISSUES_FILTER,
  EMPTY_CHART_LABEL,
  EMPTY_GROUP_CHART_LABEL,
  ISSUE_HISTORIC_COMPARISONS,
  MEASURE_PERCENTAGE_LABELS,
} from "./constants";
import { ageOptions, ColumnTuple } from "./types";

const CHART_OPTIONS_KEY_PREFIX = "chartOptions";
const CHART_OPTIONS_KEY_SEPARATOR = ".";

export const getPopulationKeyValuePairs = () => [
  { id: "At-Risk", value: "At-Risk" },
  { id: "Comparison", value: "Comparison" },
];

export const getChartOptionsKey = (
  name: string,
  ID: string,
  versionSuffix?: string
) =>
  [CHART_OPTIONS_KEY_PREFIX, name, ID, versionSuffix]
    .filter(Boolean)
    .join(CHART_OPTIONS_KEY_SEPARATOR);

export const getRelatedSignalEventsTimePeriods = (): SelectOption<number>[] => [
  { id: 1, value: 1, testId: "period-1" },
  { id: 3, value: 3, testId: "period-3" },
  { id: 7, value: 7, testId: "period-7" },
  { id: 14, value: 14, testId: "period-14" },
  { id: 30, value: 30, testId: "period-30" },
];

export const getSignalEventReoccurrencesTimePeriods =
  (): SelectOption<number>[] => [
    { id: 0, value: 0, testId: "reoccurrence-period-0" },
    { id: 1, value: 1, testId: "reoccurrence-period-1" },
    { id: 3, value: 3, testId: "reoccurrence-period-3" },
    { id: 7, value: 7, testId: "reoccurrence-period-7" },
    { id: 14, value: 14, testId: "reoccurrence-period-14" },
    { id: 30, value: 30, testId: "reoccurrence-period-30" },
    { id: 45, value: 45, testId: "reoccurrence-period-45" },
    { id: 60, value: 60, testId: "reoccurrence-period-60" },
    { id: 75, value: 75, testId: "reoccurrence-period-75" },
    { id: 90, value: 90, testId: "reoccurrence-period-90" },
  ];

export const getDefaultRelatedSignalEventsTimePeriod = () =>
  getRelatedSignalEventsTimePeriods().slice(-1)[0];

export const getSelectedPeriod = (period: number) =>
  getRelatedSignalEventsTimePeriods().find((x) => x.id === period) ||
  getDefaultRelatedSignalEventsTimePeriod();

export const getMappedValues = (
  values: ColumnTuple[],
  nameIDMapping: { [key: string]: string } | undefined
): string[] => values.map(({ value }) => nameIDMapping?.[value] ?? value);

const getPromotedToValueChild = (promotedIssue: boolean): FilterRowState => ({
  type: "row",
  id: `row-${randomID()}`,
  attribute: "promotedFromID",
  operator: promotedIssue
    ? FilterOperator.IS_NOT_EMPTY
    : FilterOperator.IS_EMPTY,
  values: ["null"],
});

const createFilterGroup = (
  filterAttribute: string,
  values: string[],
  promotedIssue?: boolean
): FilterGroupState | FilterRowState => {
  const valuesChild: FilterRowState = {
    type: "row",
    id: `row-${randomID()}`,
    attribute: filterAttribute,
    operator: FilterOperator.IN,
    values,
  };

  if (promotedIssue === undefined) {
    return valuesChild;
  }

  return {
    type: "group",
    id: `group-${randomID()}`,
    anyAll: "all",
    children: [valuesChild, getPromotedToValueChild(promotedIssue)],
  };
};

const fillFilterGroup = (
  filterGroup: FilterGroupState,
  valueChild: FilterRowState,
  promotedIssue?: boolean
) => {
  if (promotedIssue === undefined) {
    // If we already have a child with required attribute and operator, do not create
    // another child but just append value to current child
    // Currently we support only stacking of values of IN operator
    if (valueChild.operator === FilterOperator.IN) {
      const existingChild = filterGroup.children.filter(
        (x) =>
          x.type === "row" &&
          x.attribute === valueChild.attribute &&
          x.operator === valueChild.operator
      );
      if (existingChild.length > 0) {
        const indexOfChild = filterGroup.children.indexOf(existingChild[0]);
        const updatedChild: FilterRowState = JSON.parse(
          JSON.stringify(existingChild[0])
        );
        updatedChild.values = Array.from(
          new Set([
            ...(updatedChild?.values || []),
            ...(valueChild.values || []),
          ])
        );
        filterGroup.children[indexOfChild] = updatedChild;
      } else {
        filterGroup.children.push(valueChild);
      }
    } else {
      filterGroup.children.push(valueChild);
    }
  } else {
    const allFilterGroup: FilterGroupState = {
      type: "group",
      id: `group-${randomID()}`,
      anyAll: "all",
      children: [valueChild, getPromotedToValueChild(promotedIssue)],
    };
    filterGroup.children.push(allFilterGroup);
  }
};

// TODO: Might find a better way to distinguish between issue and suggested issue
export const isSuggestedIssue = (issue: IssueTypes) => !("canEdit" in issue);

type IssuesRoutes = typeof ISSUES_ROUTE | typeof SUGGESTED_ISSUE_RUNS_ROUTE;

export const getBaseAPIRoute = (issue: IssueTypes): IssuesRoutes =>
  isSuggestedIssue(issue) ? SUGGESTED_ISSUE_RUNS_ROUTE : ISSUES_ROUTE;

export const getIssueCombinedID = (issue: IssueTypes): string[] =>
  isSuggestedIssue(issue)
    ? [issue.ID, (issue as SuggestedIssue).updated]
    : [issue.ID];

export const getPopulationString = (population: IssueVehiclePopulation) =>
  population === "atRisk" ? "At-Risk Population" : "Comparison Population";

const getValueFormatter = (
  actions: ChartAction[],
  actionId: ChartActionID,
  optionId: string | undefined
) =>
  actions
    .find((x) => x.id === actionId)
    ?.options?.find((x) => x.id === optionId)?.valueFormatter;

const getTooltipValueFormatter = (
  actions: ChartAction[],
  actionId: ChartActionID,
  optionId: string | undefined
) =>
  actions
    .find((x) => x.id === actionId)
    ?.options?.find((x) => x.id === optionId)?.tooltipValueFormatter;

const dateToTs = (date: string) => toDate(date).getTime();

export const prepareScatteredChartLineDefinitions = (
  data: IssueRepairEfficacyVINTimeline[]
): LegendConfigLabel[] => {
  if (!data || data.length === 0) {
    return [];
  }

  const uniqueValues = getChartValues("codeType", data, [], false);
  const uniqueCodeTypes = [...new Set([...uniqueValues, ""])];

  return uniqueCodeTypes.map(
    (value, idx) =>
      ({
        key: value,
        color: getColor(idx),
        label: value || "Claim",
        shape: value ? "circle" : "triangle",
      }) as LegendConfigLabel
  );
};

export const prepareLineDefinitions = (values: string[]) =>
  values.map(
    (value, idx) =>
      ({
        key: value === null ? "null" : value,
        color: getColor(idx),
        label: value === null ? "None" : value,
      }) as YAxisLine
  );

export const getChartValues = (
  field: string,
  data?: Record<string, any>[],
  comparisonData?: Record<string, any>[],
  numericSort: boolean = true
) => {
  if (
    (!data || data.length === 0) &&
    (!comparisonData || comparisonData.length === 0)
  ) {
    return [];
  }

  const dataValues = data?.map((entry) => entry[field]) || [];
  const compDataValues = comparisonData?.map((entry) => entry[field]) || [];

  const allValues = [...new Set([...dataValues, ...compDataValues])];

  return numericSort ? allValues.sort((a, b) => a - b) : allValues.sort();
};

export const appendTsFromDate = (data?: Record<string, any>[]) =>
  data?.map((row) => ({
    ts: dateToTs(row.date),
    ...row,
  }));

export const getKeysAndLabels = (
  type: IssueChartType,
  chart: IssueChart,
  xAxisLabel?: string,
  exposure?: string
) => {
  const defaultVehicleAgeKeysAndLabels = {
    xAxisKey: "exposure",
    xAxisLabel: xAxisLabel ?? "",
    tooltipLabel: xAxisLabel ?? "",
  };
  if (type === "VehicleAge" && exposure === "monthsInService") {
    defaultVehicleAgeKeysAndLabels.tooltipLabel = "Days In Service";
  }

  const defaultDaysSinceClaimKeysAndLabels = {
    xAxisKey: "daysSinceClaim",
    xAxisLabel: xAxisLabel ?? "Days Since Claim",
    tooltipLabel: "Days Since Claim",
  };

  const defaultCalendarDaysKeysAndLabels = {
    xAxisKey: "ts",
    xAxisLabel: xAxisLabel ?? "Date",
    tooltipLabel: "Date",
  };

  const defaultBarKeysAndLabels = {
    xAxisKey: "name",
    xAxisLabel: xAxisLabel ?? "",
    tooltipLabel: "",
  };

  const barCharts = ["RepairEfficacy_ReoccurrenceBar"];

  const repairDateXLabelCharts = [
    "Claims_OccurrencesByCalendarTime",
    "Claims_TopXByCalendarTime",
    "Relationships_ClaimOccurrencesByCalendarTime",
  ];

  const occurrenceDateXLabelCharts = [
    "SignalEvents_OccurrencesByCalendarTime",
    "SignalEvents_RateByCalendarTime",
    "Relationships_SignalEventOccurrencesByCalendarTime",
  ];

  if (barCharts.includes(chart)) {
    return defaultBarKeysAndLabels;
  }

  if (repairDateXLabelCharts.includes(chart)) {
    return { ...defaultCalendarDaysKeysAndLabels, xAxisLabel: "Repair Date" };
  }

  if (occurrenceDateXLabelCharts.includes(chart)) {
    return {
      ...defaultCalendarDaysKeysAndLabels,
      xAxisLabel: "Occurrence Date",
    };
  }

  switch (type) {
    case "VehicleAge":
      return defaultVehicleAgeKeysAndLabels;
    case "CalendarTime":
      return defaultCalendarDaysKeysAndLabels;
    case "DaysSinceClaim":
      return defaultDaysSinceClaimKeysAndLabels;
    default:
      return defaultVehicleAgeKeysAndLabels;
  }
};

export const getXAxisProps = (
  type: IssueChartType,
  data: DataElement[],
  exposure?: string
): XAxisProps => {
  if (type === "VehicleAge") {
    return {
      type: "number",
      domain: ["auto", "auto"],
      tickFormatter: (value: number) =>
        exposure === "monthsInService"
          ? // API returns days in service so convert it to months in service for ticks
            `${formatNumber(value / 30, 0)}`
          : value.toString(),
      // since we use tickFormatter above, X axis tick values can be duplicated, so we have to make them unique
      ticks:
        exposure === "monthsInService" ? getMISTicksFromDIS(data) : undefined,
    };
  }

  if (type === "DaysSinceClaim") {
    return {
      type: "number",
      domain: ["auto", "auto"],
    };
  }

  return generateXAxisTickProps(data);
};

export const getTooltipProps = (type: IssueChartType, tooltipLabel: string) => {
  if (["VehicleAge", "DaysSinceClaim"].includes(type)) {
    return {
      labelFormatter: (value: number) => `${tooltipLabel}: ${value}`,
    };
  }

  return {
    labelFormatter: (unixTime: number) =>
      `${tooltipLabel}: ${format(unixTime, "dd MMMM yy")}`,
  };
};

export const getAxisKeyLabelFromActions = (
  selectedOptions: SelectedChartOptions[],
  actions: ChartAction[],
  actionID: ChartActionID
) => {
  const axisKey = getSelectedOptionId(selectedOptions, actionID);
  const axisValue = getAxisValue(actions, actionID, axisKey);
  const valueFormatter = getValueFormatter(actions, actionID, axisKey);
  const tooltipValueFormatter = getTooltipValueFormatter(
    actions,
    actionID,
    axisKey
  );

  return { axisKey, axisValue, valueFormatter, tooltipValueFormatter };
};

export interface transformedData {
  chartData: ChartDataElement[];
  yAxisBars: { [key: string]: string };
  splitChartData: ChartDataElement[];
  nameIDMapping: { [key: string]: string };
}

const yAxisBarsAge: Record<string, ageOptions> = {
  "0": "<1 Week",
  "1": "1 Week",
  "2": "2 Weeks",
  "3": "3 Weeks",
  "4": "4+ Weeks",
};

export interface dateLimits {
  limitOld: number;
  limitNew: number;
}

export const weekLimitMap: Record<ageOptions, dateLimits> = {
  "<1 Week": { limitOld: -1, limitNew: 0 },
  "1 Week": { limitOld: -2, limitNew: -1 },
  "2 Weeks": { limitOld: -3, limitNew: -2 },
  "3 Weeks": { limitOld: -4, limitNew: -3 },
  "4+ Weeks": { limitOld: -5214, limitNew: -4 }, // 100 years ago - 4 weeks ago
};

const formatXAxis = (
  chartData: ChartDataElement[],
  groupBy: GroupByIssuesEnum | GroupBySuggestedIssuesEnum
) => {
  // remove empty chart data (such that only have xAxisKey and no data elements)
  const onlyHasXAxisKeys = chartData.every(
    (group) =>
      Object.keys(group).length === 1 &&
      group.hasOwnProperty(DASHBOARD_GROUP_BY_KEY)
  );

  if (onlyHasXAxisKeys) {
    return [];
  }

  // format empty values
  chartData.forEach((group) => {
    if (group[DASHBOARD_GROUP_BY_KEY] === "") {
      if (groupBy === "assignedGroupID") {
        group[DASHBOARD_GROUP_BY_KEY] = EMPTY_GROUP_CHART_LABEL;
      } else {
        group[DASHBOARD_GROUP_BY_KEY] = EMPTY_CHART_LABEL;
      }
    }
  });

  // handle age label formatting
  if (groupBy === "statusUpdatedAt") {
    chartData = chartData.map((item) => ({
      ...item,
      xAxisKey: yAxisBarsAge[item.xAxisKey] || item.xAxisKey,
    }));
  }

  return chartData;
};

const formatYAxis = (
  yAxisBars: { [key: string]: string },
  bucketBy: BucketByIssuesEnum | BucketBySuggestedIssuesEnum,
  measureLabel: string
): { [key: string]: string } => {
  if (bucketBy === "statusUpdatedAt") {
    return Object.fromEntries(
      Object.entries(yAxisBars).map(([key]) => [key, yAxisBarsAge[key]])
    );
  } else if (bucketBy === "none") {
    // we need to handle the null bucketByAttributeValue
    return { ...yAxisBars, null: measureLabel };
  }

  return yAxisBars;
};

const findOrCreateGroup = (
  targetChartData: ChartDataElement[],
  groupByAttributeValue: string
) => {
  let group = targetChartData.find(
    (el) => el[DASHBOARD_GROUP_BY_KEY] === groupByAttributeValue
  );
  if (!group) {
    group = {
      xAxisKey: groupByAttributeValue,
    };
    targetChartData.push(group);
  }

  return group;
};

export const getMeasureLabel = (
  actions: ChartAction[],
  valueType: ValueType | undefined,
  measureKey: string,
  lookbackWindow: string | undefined,
  defaultMeasureLabel: string
) => {
  const percentageLabels = Object.keys(MEASURE_PERCENTAGE_LABELS);
  let modifiedMeasureLabel = defaultMeasureLabel;
  if (valueType === "percentage" && percentageLabels.includes(measureKey)) {
    modifiedMeasureLabel = MEASURE_PERCENTAGE_LABELS[measureKey];
  }

  if (lookbackWindow !== undefined && parseInt(lookbackWindow) !== 0) {
    const lookbackAction = actions.find(({ id }) => id === "lookbackWindow");
    if (lookbackAction) {
      const currentLookbackOption = lookbackAction.options?.find(
        ({ id }) => id === lookbackWindow
      );
      if (currentLookbackOption) {
        const currentLookbackLabel = (currentLookbackOption.label as string)
          .toLocaleLowerCase()
          .replace("previous", "last")
          .replace(" 1 ", " ");
        const previousLookbackLabel = (currentLookbackOption.label as string)
          .toLocaleLowerCase()
          .replace(" 1 ", " ");
        modifiedMeasureLabel = `${modifiedMeasureLabel} in ${currentLookbackLabel}`;
        if (valueType) {
          modifiedMeasureLabel = `${modifiedMeasureLabel}, compared to ${previousLookbackLabel}`;
        }
      }
    }
  }

  return modifiedMeasureLabel;
};

export const transformIssuesOverviewData = (
  data: IssuesOverview[] | undefined,
  bucketBy: BucketByIssuesEnum,
  groupBy: GroupByIssuesEnum,
  measureKey: string,
  measureLabel: string,
  splitByIssueSource: boolean,
  valueType: ValueType
) => {
  const result: transformedData = {
    chartData: [],
    yAxisBars: {},
    splitChartData: [],
    nameIDMapping: {},
  };

  data?.forEach((item) => {
    let {
      groupByAttributeValue,
      groupByAttributeID,
      bucketByAttributeValue,
      bucketByAttributeID,
      count,
      origin,
    } = item;

    if (measureKey !== "count" && valueType === "percentage") {
      count = formatPercentValue(count);
    }

    // we eliminate the possibility of having null as a key
    bucketByAttributeValue = String(bucketByAttributeValue);

    // values greater than 4 should default to 4 in case of age
    if (bucketBy === "statusUpdatedAt") {
      bucketByAttributeValue = normalizeAgeValue(bucketByAttributeValue);
    } else if (groupBy === "statusUpdatedAt") {
      groupByAttributeValue = normalizeAgeValue(groupByAttributeValue);
    }

    result.nameIDMapping[groupByAttributeValue] = groupByAttributeID;
    result.nameIDMapping[bucketByAttributeValue] = bucketByAttributeID;

    let targetChartData = result.chartData;
    if (splitByIssueSource) {
      targetChartData =
        origin === "ManuallyCreated" ? result.chartData : result.splitChartData;
    }

    let group = findOrCreateGroup(targetChartData, groupByAttributeValue);

    if (splitByIssueSource) {
      // we need to make sure to have all xAxisKeys in both chartData and splitChartData
      let otherChartData;
      if (targetChartData === result.chartData) {
        otherChartData = result.splitChartData;
      } else {
        otherChartData = result.chartData;
      }

      findOrCreateGroup(otherChartData, groupByAttributeValue);
    }

    group[bucketByAttributeValue] = count;

    if (bucketByAttributeValue === "") {
      if (bucketBy === "assignedGroupID") {
        result.yAxisBars[bucketByAttributeValue] = EMPTY_GROUP_CHART_LABEL;
      } else {
        result.yAxisBars[bucketByAttributeValue] = EMPTY_CHART_LABEL;
      }
    } else {
      result.yAxisBars[bucketByAttributeValue] = bucketByAttributeValue;
    }
  });

  // format axis labels
  result.chartData = formatXAxis(result.chartData, groupBy);
  if (splitByIssueSource) {
    result.splitChartData = formatXAxis(result.splitChartData, groupBy);
  }

  result.yAxisBars = formatYAxis(result.yAxisBars, bucketBy, measureLabel);

  return result;
};

export const transformSuggestedIssuesOverviewData = (
  data: SuggestedIssuesOverview[] | undefined,
  bucketBy: BucketByIssuesEnum | BucketBySuggestedIssuesEnum,
  groupBy: GroupByIssuesEnum | GroupBySuggestedIssuesEnum,
  measureKey: string,
  measureLabel: string,
  valueType: ValueType
) => {
  const result: transformedData = {
    chartData: [],
    yAxisBars: {},
    splitChartData: [],
    nameIDMapping: {},
  };

  data?.forEach((item) => {
    let {
      groupByAttributeValue,
      groupByAttributeID,
      bucketByAttributeValue,
      bucketByAttributeID,
      count,
    } = item;

    if (measureKey !== "count" && valueType === "percentage") {
      count = formatPercentValue(count);
    }

    // we eliminate the possibility of having null as a key
    bucketByAttributeValue = String(bucketByAttributeValue);

    // values greater than 4 should default to 4 in case of age
    if (bucketBy === "statusUpdatedAt") {
      bucketByAttributeValue = normalizeAgeValue(bucketByAttributeValue);
    } else if (groupBy === "statusUpdatedAt") {
      groupByAttributeValue = normalizeAgeValue(groupByAttributeValue);
    }

    result.nameIDMapping[groupByAttributeValue] = groupByAttributeID;
    result.nameIDMapping[bucketByAttributeValue] = bucketByAttributeID;

    let group = findOrCreateGroup(result.chartData, groupByAttributeValue);

    group[bucketByAttributeValue] = count;

    if (bucketByAttributeValue === "") {
      if (bucketBy === "assignedGroupID") {
        result.yAxisBars[bucketByAttributeValue] = EMPTY_GROUP_CHART_LABEL;
      } else {
        result.yAxisBars[bucketByAttributeValue] = EMPTY_CHART_LABEL;
      }
    } else {
      result.yAxisBars[bucketByAttributeValue] = bucketByAttributeValue;
    }
  });

  // format axis labels
  result.chartData = formatXAxis(result.chartData, groupBy);
  result.yAxisBars = formatYAxis(result.yAxisBars, bucketBy, measureLabel);

  return result;
};

const normalizeAgeValue = (value: string): string => {
  // maximum value for age is 4+ Weeks
  if (isNumeric(value)) {
    return Number(value) > 4 ? "4" : value;
  }

  return value;
};

export const useDefaultIssueFilter = (): FilterGroupState => {
  const {
    pages: { issues },
  } = useConfigContext();

  return (
    filterBuilderQueryToFilterBuilderState(issues?.defaultFilters) ||
    DEFAULT_ISSUES_FILTER
  );
};

export const getMaxValue = (data: ChartDataElement[]): number => {
  let maxValue = 0;

  data.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (key === DASHBOARD_GROUP_BY_KEY || !isNumeric(item[key])) {
        return;
      }

      if (Number(item[key]) > maxValue) {
        maxValue = Number(item[key]);
      }
    });
  });

  return maxValue;
};

export const getAttributeFilters = (
  filterAttribute: string,
  columnTuples: ColumnTuple[],
  nameIDMapping: { [key: string]: string } | undefined
): (FilterRowState | FilterGroupState)[] => {
  const filters: (FilterRowState | FilterGroupState)[] = [];

  const nullablePromotedIssues = columnTuples.filter(
    ({ promotedIssue }) => promotedIssue === undefined
  );
  const nonPromotedIssues = columnTuples.filter(
    ({ promotedIssue }) => promotedIssue !== undefined && !promotedIssue
  );
  const promotedIssues = columnTuples.filter(
    ({ promotedIssue }) => promotedIssue
  );

  if (nullablePromotedIssues.length > 0) {
    const values = getMappedValues(nullablePromotedIssues, nameIDMapping);
    filters.push(createFilterGroup(filterAttribute, values, undefined));
  }

  if (nonPromotedIssues.length > 0) {
    const values = getMappedValues(nonPromotedIssues, nameIDMapping);
    filters.push(createFilterGroup(filterAttribute, values, false));
  }

  if (promotedIssues.length > 0) {
    const values = getMappedValues(promotedIssues, nameIDMapping);
    filters.push(createFilterGroup(filterAttribute, values, true));
  }

  return filters;
};

export const getAssignedGroupFilter = (
  filterAttribute: string,
  filterColumnTuples: ColumnTuple[],
  nameIDMapping: { [key: string]: string } | undefined
): FilterGroupState | FilterRowState => {
  const newFilterGroup: FilterGroupState = {
    type: "group",
    id: `group-${randomID()}`,
    anyAll: "any",
    children: [],
  };

  const emptyGroupTuples = filterColumnTuples.filter(
    ({ value }) => value === EMPTY_GROUP_CHART_LABEL
  );
  const nonEmptyGroupTuples = filterColumnTuples.filter(
    ({ value }) => value !== EMPTY_GROUP_CHART_LABEL
  );

  emptyGroupTuples.forEach(({ promotedIssue }) => {
    const valueChild: FilterRowState = {
      type: "row",
      id: `row-${randomID()}`,
      attribute: filterAttribute,
      operator: FilterOperator.IS_EMPTY,
      values: ["null"],
    };

    fillFilterGroup(newFilterGroup, valueChild, promotedIssue);
  });

  const attributeFilters = getAttributeFilters(
    filterAttribute,
    nonEmptyGroupTuples,
    nameIDMapping
  );
  newFilterGroup.children.push(...attributeFilters);

  if (newFilterGroup.children.length === 1) {
    return newFilterGroup.children[0];
  }

  return newFilterGroup;
};

export const getStatusUpdatedAtFilter = (
  filterAttribute: string,
  filterColumnTuples: ColumnTuple[]
): FilterGroupState | FilterRowState => {
  const newFilterGroup: FilterGroupState = {
    type: "group",
    id: `group-${randomID()}`,
    anyAll: "any",
    children: [],
  };

  filterColumnTuples.forEach(({ value, promotedIssue }) => {
    const { limitOld, limitNew } = weekLimitMap[value as ageOptions];
    const startDate = add(new Date(), { weeks: limitOld }).toISOString();
    const endDate = add(new Date(), { weeks: limitNew }).toISOString();

    const valueChild: FilterRowState = {
      type: "row",
      id: `row-${randomID()}`,
      attribute: filterAttribute,
      operator: FilterOperator.BETWEEN,
      values: [startDate, endDate],
    };

    fillFilterGroup(newFilterGroup, valueChild, promotedIssue);
  });

  if (newFilterGroup.children.length === 1) {
    return newFilterGroup.children[0];
  }

  return newFilterGroup;
};

export const getDefaultFilter = (
  filterAttribute: string,
  filterColumnTuples: ColumnTuple[],
  nameIDMapping: { [key: string]: string }
): FilterGroupState | FilterRowState => {
  const newFilterGroup: FilterGroupState = {
    type: "group",
    id: `group-${randomID()}`,
    anyAll: "any",
    children: [],
  };

  const attributeFilters = getAttributeFilters(
    filterAttribute,
    filterColumnTuples,
    nameIDMapping
  );
  newFilterGroup.children.push(...attributeFilters);

  if (newFilterGroup.children.length === 1) {
    return newFilterGroup.children[0];
  }

  return newFilterGroup;
};

export const getFilterFromAttribute = (
  attribute: string,
  valueTuples: ColumnTuple[],
  nameIDMapping: { [key: string]: string }
): FilterGroupState | FilterRowState | undefined => {
  if (attribute === "assignedGroupID") {
    return getAssignedGroupFilter(attribute, valueTuples, nameIDMapping);
  } else if (attribute === "statusUpdatedAt") {
    return getStatusUpdatedAtFilter(attribute, valueTuples);
  } else if (ISSUE_HISTORIC_COMPARISONS.includes(attribute as any)) {
    return getHistoricFilter(valueTuples);
  }

  return getDefaultFilter(attribute, valueTuples, nameIDMapping);
};

const getHistoricFilter = (
  valueTuples: ColumnTuple[]
): FilterGroupState | FilterRowState | undefined => {
  const newFilterGroup: FilterGroupState = {
    type: "group",
    id: `group-${randomID()}`,
    anyAll: "any",
    children: [],
  };

  valueTuples.forEach(({ promotedIssue }) => {
    if (promotedIssue !== undefined) {
      newFilterGroup.children.push(getPromotedToValueChild(promotedIssue));
    }
  });

  if (newFilterGroup.children.length === 1) {
    return newFilterGroup.children[0];
  } else if (newFilterGroup.children.length === 0) {
    return;
  }

  return newFilterGroup;
};

export const getFilterFromColumns = (
  selectedColumns: { [key: string]: ColumnTuple[] },
  filterGroupAttribute: string,
  filterBucketAttribute: string,
  splitByIssueSource: boolean | undefined,
  nameIDMapping: { [key: string]: string }
): FilterGroupState | undefined => {
  if (Object.keys(selectedColumns).length <= 0) {
    return undefined;
  }

  let filterGroup: FilterGroupState = {
    id: `group-0`,
    type: "group",
    anyAll: "any",
    children: [],
  };

  // only filterGroupAttribute is defined and maybe data are split by issue source
  if (filterBucketAttribute === "none") {
    const distinctGroupValues = [...new Set(Object.keys(selectedColumns))]
      .sort()
      .map((value) => (value === EMPTY_CHART_LABEL ? "" : value));

    if (splitByIssueSource === undefined) {
      const filter = getFilterFromAttribute(
        filterGroupAttribute,
        distinctGroupValues.map((value) => ({ value })),
        nameIDMapping
      );
      filter && filterGroup.children.push(filter);
    } else {
      Object.keys(selectedColumns).forEach((attribute) => {
        const filter = getFilterFromAttribute(
          filterGroupAttribute,
          selectedColumns[attribute].map(({ value, promotedIssue }) => ({
            value: attribute === EMPTY_CHART_LABEL ? "" : attribute,
            promotedIssue,
          })),
          nameIDMapping
        );
        filter && filterGroup.children.push(filter);
      });
    }

    return filterGroup;
  }

  Object.entries(selectedColumns).forEach(
    ([filterGroupValue, filterBucketValues]) => {
      if (filterBucketValues.length <= 0) {
        return;
      }

      const newFilterGroup: FilterGroupState = {
        type: "group",
        id: `group-${randomID()}`,
        anyAll: "all",
        children: [],
      };

      // re-map empty group value
      const mappedFilterGroupValue =
        filterGroupValue === EMPTY_CHART_LABEL ? "" : filterGroupValue;

      let filter = getFilterFromAttribute(
        filterGroupAttribute,
        [{ value: mappedFilterGroupValue }],
        nameIDMapping
      );
      filter && newFilterGroup.children.push(filter);

      // re-map empty bucket values
      const mappedFilterBucketValues = filterBucketValues.map(
        ({ value, promotedIssue }) =>
          value === EMPTY_CHART_LABEL
            ? { value: "", promotedIssue }
            : { value, promotedIssue }
      );

      filter = getFilterFromAttribute(
        filterBucketAttribute,
        mappedFilterBucketValues,
        nameIDMapping
      );
      filter && newFilterGroup.children.push(filter);

      filterGroup.children.push(newFilterGroup);
    }
  );

  return filterGroup;
};

export const getMetricsRequestParams = (
  selectedOptions: SelectedChartOptions[],
  actions: ChartAction[]
): IssueMetricsRequest => {
  const { axisKey: measureKey } = getAxisKeyLabelFromActions(
    selectedOptions,
    actions,
    "measure"
  );

  const { axisKey: lookbackWindowKey } = getAxisKeyLabelFromActions(
    selectedOptions,
    actions,
    "lookbackWindow"
  );

  const { axisKey: valueTypeKey } = getAxisKeyLabelFromActions(
    selectedOptions,
    actions,
    "valueType"
  );

  const queryMetrics = measureKey !== "count";
  const metricType = queryMetrics ? measureKey : undefined;
  const lookbackWindow =
    queryMetrics && lookbackWindowKey ? parseInt(lookbackWindowKey) : undefined;
  const valueType = queryMetrics ? valueTypeKey : undefined;

  return {
    metricType,
    lookbackWindow,
    valueType,
  };
};

export const getFieldLabel = (schema: SchemaEntry[], field: string) => {
  const fieldSchema = schema.find((s) => s.accessor === field);

  return fieldSchema?.label || field;
};

export const handleSelectedColumnsUpdate = (
  currentSelectedColumns: { [key: string]: ColumnTuple[] },
  clickedBar: string,
  clickedColumnTuple: ColumnTuple
): { [key: string]: ColumnTuple[] } => {
  const updatedColumns: { [key: string]: ColumnTuple[] } = JSON.parse(
    JSON.stringify(currentSelectedColumns)
  );
  if (!(clickedBar in updatedColumns)) {
    updatedColumns[clickedBar] = [clickedColumnTuple];

    return updatedColumns;
  }

  const selectedIndex = updatedColumns[clickedBar].findIndex(
    (columnTuple) =>
      columnTuple.value === clickedColumnTuple.value &&
      columnTuple.promotedIssue === clickedColumnTuple.promotedIssue
  );

  if (selectedIndex !== -1) {
    updatedColumns[clickedBar].splice(selectedIndex, 1);
    // remove the key if there are no values left
    if (updatedColumns[clickedBar].length === 0) {
      delete updatedColumns[clickedBar];
    }
  } else {
    updatedColumns[clickedBar].push(clickedColumnTuple);
  }

  return updatedColumns;
};

interface TabsAvailability {
  claimsTabDisabled: boolean;
  signalEventsTabDisabled: boolean;
  relationshipsTabDisabled: boolean;
  repairEfficacyTabDisabled: boolean;
}

export const getIssueTabsAvailability = (
  issue: IssueTypes
): TabsAvailability => {
  const { claimFilter, signalEventOccurrencesFilter } = issue;

  const claimsTabDisabled = !Boolean(claimFilter);
  const signalEventsTabDisabled = !Boolean(signalEventOccurrencesFilter);
  const relationshipsTabDisabled = claimsTabDisabled || signalEventsTabDisabled;
  const repairEfficacyTabDisabled = relationshipsTabDisabled;

  return {
    claimsTabDisabled,
    signalEventsTabDisabled,
    relationshipsTabDisabled,
    repairEfficacyTabDisabled,
  };
};
