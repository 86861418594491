import { getCollection, getTransportCategory } from "shared/api/api";
import { getFailureMode } from "shared/api/failureModes/api";
import { getIssue } from "shared/api/issues/api";
import { getGroup } from "shared/api/rbac/api";

import { SchemaEntry } from "features/ui/Table";

import { MAX_FILTER_ID, MIN_FILTER_ID } from "./constants";
import { FilterGroupState } from "./FilterBuilder/types";
import { getTopLevelRowFromFilterGroupState } from "./FilterBuilder/utils";
import { FilterOperator } from "./types";
import { getMinMaxSelectOptions } from "./utils";

const collectionFieldNameMapping = ({ id }: { id: string }) =>
  getCollection({ id }).then(({ data }) => data.name);

const issueFieldNameMapping = ({ id }: { id: string }) =>
  getIssue({ id }).then(({ data }) => data.name);

const failureModeFieldNameMapping = ({ id }: { id: string }) =>
  getFailureMode({ id }).then(({ data }) => data.name);

const transportCategoryFieldNameMapping = ({ id }: { id: string }) =>
  getTransportCategory({ id }).then(({ data }) => data.name);

const groupIDNameMapping = ({ id }: { id: string }) =>
  getGroup({ id }).then(({ data }) => data.name);

export const fieldNameMapFunction = {
  collection: collectionFieldNameMapping,
  "collection.ID": collectionFieldNameMapping,
  collectionID: collectionFieldNameMapping,
  failureModeID: failureModeFieldNameMapping,
  transportCategoryID: transportCategoryFieldNameMapping,
  assignedGroupID: groupIDNameMapping,
  promotedToIDs: issueFieldNameMapping,
};

export const transformValues = (
  filters: FilterGroupState,
  fieldName: string,
  tableSchema?: SchemaEntry[]
): Promise<string[]> => {
  const childRow = getTopLevelRowFromFilterGroupState(fieldName, filters);

  if (!childRow) return Promise.resolve([]);

  const { values, operator } = childRow;

  // Should we do transformation using API
  if (
    Object.keys(fieldNameMapFunction).includes(fieldName) &&
    ![FilterOperator.CONTAINS, FilterOperator.NOT_CONTAINS].includes(operator)
  ) {
    const getDataFunc =
      fieldNameMapFunction[fieldName as keyof typeof fieldNameMapFunction];

    return Promise.all(
      values.map((value) => {
        if (value && value !== "null") {
          return getDataFunc({ id: value })
            .then((name: string) => name)
            .catch(() => "null");
        }

        return new Promise<string>((resolve) => "null");
      })
    );
  }

  // Should we do transformation using local mappings
  const currentFilterSchema = tableSchema?.find(
    ({ accessor }) => accessor === fieldName
  )?.filter;
  const enableMinMaxFilters = currentFilterSchema?.enableMinMaxFilters;

  if (
    enableMinMaxFilters &&
    [FilterOperator.IN, FilterOperator.NOT_IN].includes(operator) &&
    (values.includes(MAX_FILTER_ID) || values.includes(MIN_FILTER_ID))
  ) {
    const filterType = currentFilterSchema?.filterType;
    const minMaxOptions = getMinMaxSelectOptions(filterType);

    const transformedValues = values.map((value) => {
      const transformedValue = minMaxOptions
        ?.find(({ id }) => id === value)
        ?.value?.toString();

      return transformedValue || value;
    });

    return Promise.resolve(transformedValues);
  }

  return Promise.resolve(values);
};
