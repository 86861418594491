import {
  ASSOCIATED_SIGNAL_EVENTS_TAB_KEY,
  ASSOCIATED_SIGNAL_EVENTS_TAB_TITLE,
  ASSOCIATED_VEHICLES_TAB_KEY,
  ASSOCIATED_VEHICLES_TAB_TITLE,
  BY_VEHICLE_AGE_TAB_TITLE,
  BY_VEHICLES_AGE_TAB_KEY,
  TOP_CONTRIBUTORS_TAB_KEY,
  TOP_CONTRIBUTORS_TAB_TITLE,
} from "pages/constants";

import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";

export const SIGNAL_EVENTS_TAB_KEY = "signal-events";
export const SIGNAL_EVENTS_TAB_TITLE = "Signal Events";
export const ASSOCIATED_CLAIMS_TAB_KEY = "associated-claims";
export const ASSOCIATED_CLAIMS_TAB_TITLE = "Associated Claims";

export const PAGE_TITLE = "Signal Event Analytics";
export const SIGNAL_EVENTS_PAGE_KEY = "sigEvAn";
export const VEHICLES_PAGE_KEY = "sigEvAnVehicles";

export const VEHICLES_FILTER_LABEL = "Vehicle Filters";
export const SIGNAL_EVENTS_FILTER_LABEL = "Signal Event Filters";

export const SIGNAL_EVENTS_TABLE_PAGE_KEY = "sigEvAn-se-table";

export const SIGNAL_EVENT_ANALYTICS_TABS = {
  [SIGNAL_EVENTS_TAB_KEY]: SIGNAL_EVENTS_TAB_TITLE,
  [BY_VEHICLES_AGE_TAB_KEY]: BY_VEHICLE_AGE_TAB_TITLE,
  [TOP_CONTRIBUTORS_TAB_KEY]: TOP_CONTRIBUTORS_TAB_TITLE,
  [ASSOCIATED_VEHICLES_TAB_KEY]: ASSOCIATED_VEHICLES_TAB_TITLE,
  [ASSOCIATED_CLAIMS_TAB_KEY]: ASSOCIATED_CLAIMS_TAB_TITLE,
  [ASSOCIATED_SIGNAL_EVENTS_TAB_KEY]: ASSOCIATED_SIGNAL_EVENTS_TAB_TITLE,
};

export const PENDING_FILTERS_SE_LS_KEY = getPendingFiltersKey(
  SIGNAL_EVENTS_PAGE_KEY
);

export const PENDING_FILTERS_VEHICLES_LS_KEY =
  getPendingFiltersKey(VEHICLES_PAGE_KEY);
