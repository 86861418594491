import { useState } from "react";

import { useSignalEventOccurrencesFiltersSchema } from "shared/hooks";

import DropdownSelect from "features/ui/DropdownSelect";
import { DropdownSelectOption } from "features/ui/DropdownSelect/DropdownSelect";
import FilterBuilder from "features/ui/Filters/FilterBuilder";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { filterBuilderQueryToFilterBuilderState } from "features/ui/Filters/FilterBuilder/utils";
import FiltersSummary, {
  ViewFiltersButton,
} from "features/ui/Filters/FiltersSummary";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import {
  OccursFilterWindowDirectionType,
  OccursWindowOptions,
} from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";

import { DEFAULT_BASE_ENTITY_TEXT } from "./constants";
import OccursTimeWindowForm from "./OccursTimeWindowForm";
import {
  decodeOccursFilterAndOptions,
  encodeOccursFilterAndOptions,
} from "./utils";

interface Props {
  label: string;
  fieldName: string;
  onChange: (values: string[]) => void;
  disabled?: boolean;
  currentFilterValues: string[];
  isAdvancedFilterEditor?: boolean;
  pageKey?: string;
  filterTitle?: string;
  baseEntityText?: string;
  selectorTestId?: string;
  inFilterSelector?: boolean;
  windowDirectionOptions?: DropdownSelectOption<OccursFilterWindowDirectionType>[];
}

// This component is very similar to Exists Filter, we can combine it at some point.
const OccursFilter = ({
  filterTitle,
  baseEntityText = DEFAULT_BASE_ENTITY_TEXT,
  label,
  fieldName,
  onChange,
  isAdvancedFilterEditor,
  currentFilterValues = [],
  disabled,
  pageKey = "",
  selectorTestId,
  inFilterSelector = true,
}: Props) => {
  const occursPageKey = `${pageKey}-occurs-${fieldName}`;
  const pendingFiltersKey = getPendingFiltersKey(
    `${pageKey}-occurs-${fieldName}`
  );

  const schema = useSignalEventOccurrencesFiltersSchema([
    "relatedSignalEventOccurrences",
  ]);
  const [filterSummaryOpen, setFilterSummaryOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { filters, windowSize, windowType, windowDirection } =
    decodeOccursFilterAndOptions(currentFilterValues[0]);

  const existsFilterSortState = useFilterSortState({
    pageKey: occursPageKey,
    defaultFilterValues: filterBuilderQueryToFilterBuilderState(filters),
    disableUsingQuery: true,
    disableUsingLocalStorage: true,
    pendingFiltersLocalStorageKey: pendingFiltersKey,
  });

  const [occursOptionsState, setOccursOptionsState] =
    useState<OccursWindowOptions>({
      windowSize,
      windowType,
      windowDirection,
    });

  const onApplyFilters = (newFilterState: FilterGroupState) => {
    existsFilterSortState.updateFilters(newFilterState);
    propagateChanges(newFilterState, occursOptionsState);
    setDropdownOpen(false);
  };

  const propagateChanges = (
    filters: FilterGroupState,
    occursOptionsState: OccursWindowOptions
  ): void => {
    onChange([encodeOccursFilterAndOptions(filters, occursOptionsState)]);
  };

  const onWindowOptionsChange = (newOptions: OccursWindowOptions) => {
    setOccursOptionsState(newOptions);
    propagateChanges(existsFilterSortState.filters, newOptions);
  };

  const filterLabel = `${label} Filters`;
  const filterLabelWithCount = getFilterLabel(
    `${label} Filters`,
    existsFilterSortState.filters
  );

  if (isAdvancedFilterEditor) {
    return (
      <div className="w-full">
        <FilterBuilder
          filterBuilderState={existsFilterSortState.filters}
          filtersSchema={schema}
          onChange={onApplyFilters}
          inline
          embedded
          disabled={disabled}
        />
        <OccursTimeWindowForm
          occursOptionsState={occursOptionsState}
          onUpdate={onWindowOptionsChange}
          baseEntityText={baseEntityText}
          inFilterSelector={inFilterSelector}
        />
      </div>
    );
  }

  const testID = selectorTestId || `filter-exists-${fieldName}`;

  return (
    <>
      <div className="flex gap-2">
        {filterTitle && <span>{filterTitle}</span>}
        <DropdownSelect
          testId={`filter-exists-${fieldName}-dropdown`}
          label={filterLabelWithCount}
          buttonClass="mt-6 h-[38px] mr-4"
          open={dropdownOpen}
          disabled={disabled}
          onOpen={(open) => {
            setDropdownOpen(open);
          }}
          content={
            <FilterSelector
              schema={schema}
              filterSortState={existsFilterSortState}
              title={filterLabel}
              testId={testID}
              onApplyFilters={onApplyFilters}
              pendingFiltersKey={pendingFiltersKey}
              disableAdvancedFilter
            />
          }
        />
        {inFilterSelector && (
          <ViewFiltersButton
            open={filterSummaryOpen}
            onClick={() => setFilterSummaryOpen(true)}
            onClose={() => setFilterSummaryOpen(false)}
          />
        )}
      </div>
      {inFilterSelector && (
        <FiltersSummary
          open={filterSummaryOpen}
          filterStates={[
            {
              name: "",
              filters: existsFilterSortState.filters,
              schema,
            },
          ]}
          additionalClasses="mb-3"
        />
      )}
      <OccursTimeWindowForm
        occursOptionsState={occursOptionsState}
        onUpdate={onWindowOptionsChange}
        baseEntityText={baseEntityText}
        inFilterSelector={inFilterSelector}
      />
    </>
  );
};

export default OccursFilter;
