import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  CountRequest,
  ExportRequest,
  getFetcher,
  ListAttributesRequest,
} from "shared/api/api";
import client from "shared/api/axios";
import { RiskModelPrediction } from "shared/api/failureModes/api";
import { createURL } from "shared/api/utils";
import { MileageUnit } from "shared/types";

export const VEHICLES_BASE_URL = "vehicles";

export interface Vehicle {
  VIN: string;
  createdAt: string;
  updatedAt: string;
  fleet: string | null;
  externalID: string | null;
  saleDealerName: string | null;
  saleIntendedService: string | null;
  vehicleMake: string;
  vehicleModel: string;
  vehicleModelYear: number | null;
  vehicleGvwr: string | null;
  vehicleManufacturedAt: string | null;
  engineModel: string | null;
  engineModelYear: number | null;
  engineHorsepower: number | null;
  engineOnTimeTotal: number | null;
  idleRate: number | null;
  fuelConsumption: number | null;
  fuelConsumptionUnit: FuelConsumptionUnit | null;
  engineManufacturedAt: string | null;
  mileage: number | null;
  mileageUnit: MileageUnit | null;
  vehicleStartedDrivingAt: string | null;
  serviceDurationInMonths: number | null;
  vehicleCategoryID: string | null;
  transportCategoryID: string | null;
  trimLevel: string | null;
  tags: string[];
  platform: string | null;
  body: string | null;
  country: string | null;
  purpose: string | null;
  engineSupplierCode: string | null;
  engineNumber: string | null;
  productCode: string | null;
  infotainmentModel: string | null;
  transmissionModel: string | null;
  transmissionSupplierCode: string | null;
  transmissionNumber: string | null;
  lastKnownDealerCountry: string | null;
  lastKnownDealerState: string | null;
  lastKnownDealerCity: string | null;
  brakeModel: string | null;
  chassisDetail: string | null;
  hvacModel: string | null;
  radio: string | null;
  collisionSensorModel: string | null;
  riskModelPredictions: RiskModelPrediction[];
}

type FuelConsumptionUnit = "mpg" | "lkm";

export interface GetVehicleRequest {
  vin: string;
  mileageUnit: MileageUnit;
}

export interface ListVehiclesRequest extends APIPaginatedRequest {
  mileageUnit: MileageUnit;
}

export interface CountRequestWithVehiclesFilter extends CountRequest {
  vehiclesFilter?: string;
  analytics?: boolean;
}

// --------
// List Vehicles
// --------
export const listVehiclesRequestURI = (params: ListVehiclesRequest): string =>
  client.getUri({
    method: "get",
    url: `/${VEHICLES_BASE_URL}`,
    params,
  });

export const listVehicles = (args: APIPaginatedRequest) => {
  const url = listVehiclesRequestURI({ ...args, mileageUnit: "km" });

  return getFetcher<Vehicle[]>(url);
};

// --------
// Get Single Vehicle
// --------
export const getVehicleRequestURI = ({
  vin,
  mileageUnit,
}: GetVehicleRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([VEHICLES_BASE_URL, vin]),
    params: { mileageUnit },
  });

// --------
// Vehicle Count
// --------
export const listVehiclesCountRequestURI = (params: CountRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([VEHICLES_BASE_URL, "count"]),
    params,
  });

// --------
// Vehicle Export
// --------
const getVehiclesExportRequestURI = ({
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([VEHICLES_BASE_URL, "export", type]),
    params,
  });

export const getVehiclesExport = (args: ExportRequest) =>
  getFetcher<Blob>(getVehiclesExportRequestURI(args), {
    responseType: "blob",
  });

// ---------
// Vehicles values
// ---------
export const listVehiclesValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([VEHICLES_BASE_URL, "values", fieldName]),
    params,
  });

export const listVehiclesValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listVehiclesValuesRequestURI(args));

// --------
// Vehicle Attributes
// --------
export const listVehicleAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([VEHICLES_BASE_URL, "attributes"]),
    params,
  });
