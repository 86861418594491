import { Dispatch, useEffect } from "react";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";

import { useListCustomSignalEventsTimeline } from "shared/api/customSignalEvents/hooks";
import { getSortFilter } from "shared/api/utils";
import { useTenantMileageUnit } from "shared/hooks";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";
import { SortBy } from "shared/types";

import { MAX_VEHICLES_PREVIEW } from "pages/CustomSignalEvents/constants";
import SignalEventPreviewIPTVWrapper from "pages/CustomSignalEvents/Preview/SignalEventPreviewIPTVWrapper";
import {
  getCustomSignalEventRequestParams,
  SignalEventStudioState,
} from "pages/CustomSignalEvents/utils";

import APIError from "features/ui/APIError";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  getFiltersQuery,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import FormSection from "features/ui/FormSection";
import Table, { OnSortParams, RowData, SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell/types";
import TableCount from "features/ui/Table/TableCount";

interface Props {
  signalEventStudioState: SignalEventStudioState;
  dateFilters: FilterGroupState;
  VINs: string[];
  dateType: DataType;
  setSelectedRow: Dispatch<React.SetStateAction<RowData | undefined>>;
  className?: string;
}

const DEFAULT_SORT: SortBy = { occurrences: "desc" };

const SignalEventPreviewVehicleSummary = ({
  signalEventStudioState,
  dateFilters,
  VINs,
  setSelectedRow,
  dateType,
  className,
}: Props) => {
  const { getDisplayLabel } = useVehiclesSchema();

  const schema: SchemaEntry[] = [
    {
      label: getDisplayLabel("VIN", "VIN"),
      accessor: "VIN",
      dataType: DataType.STRING,
      sortable: true,
      filter: {
        fieldName: "VIN",
        label: getDisplayLabel("VIN", "VIN"),
      },
    },
    {
      label: "Num. of Occurrences",
      accessor: "occurrences",
      dataType: DataType.NUMBER,
      sortable: true,
      filter: {
        fieldName: "occurrences",
        label: "Num. of Occurrences",
        filterType: DataType.NUMBER,
      },
    },
    {
      label: "Most Recent Occurrence",
      accessor: "date",
      dataType: dateType,
      sortable: true,
      filter: {
        fieldName: "date",
        label: "Most Recent Occurrence",
        filterType: "date",
        filterDataType: dateType,
      },
    },
  ];

  const {
    filterBuilderState,
    selectedEventTypeOption,
    customAttributeFilterBuilderState,
    customAttributes,
    selectedDownsamplingOption,
  } = signalEventStudioState;

  const updatedFilterBuilderState = mergeFilterGroupStates(
    filterBuilderState,
    dateFilters
  );

  const requestBody = getCustomSignalEventRequestParams(
    VINs,
    selectedEventTypeOption,
    updatedFilterBuilderState,
    customAttributeFilterBuilderState,
    customAttributes,
    selectedDownsamplingOption,
    useTenantMileageUnit()
  );

  const pageKey = `custom_signal_event_preview_${requestBody.inputEventType}`;
  const {
    manageFilterChange,
    resetFilters,
    filters: tableFilters,
    initialized: filtersInitialized,
    sort,
    manageOnSortChange,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey,
    defaultSort: DEFAULT_SORT,
    disableUsingQuery: true,
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const { data, isLoading, error, requestKey } =
    useListCustomSignalEventsTimeline(
      {
        grouping: "all",
        sort: getSortFilter(sort),
        filter: getFiltersQuery(tableFilters),
        limit: MAX_VEHICLES_PREVIEW,
      },
      requestBody
    );

  // when data changes, update selectedRow
  useEffect(() => {
    if (!data || error) {
      setSelectedRow(undefined);
    } else {
      setSelectedRow(data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  if (isLoading) {
    return <Skeleton height={200} />;
  }

  if (error) {
    return <APIError error={error} onBadRequest={resetFilterSortState} />;
  }

  const VINsWithOccurrences = data?.map(({ VIN }) => VIN) || [];
  const numAllOccurrences =
    data
      ?.map(({ occurrences }) => occurrences)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) ||
    0;

  return (
    <div
      className={classNames(
        "inline-flex flex-1 space-x-2 !w-[65rem] items-center",
        className
      )}
    >
      <FormSection
        data-testid="vehicle-preview-summary"
        title="Summary of Preview Vehicles"
        text="Select a vehicle to see its events in the list below"
      >
        <div className="flex flex-col space-y-2">
          <FiltersOverview
            filters={tableFilters}
            tableSchema={schema}
            onFiltersReset={resetFilters}
          />
          <Table
            key={requestKey}
            data={data}
            schema={schema}
            isLoading={isLoading}
            onRowSelect={setSelectedRow}
            sortBy={sort}
            onSort={handleSorting}
            filtersInitialized={filtersInitialized}
            onFiltersReset={resetFilters}
            onFilterChange={manageFilterChange}
            filters={tableFilters}
            scrollHeight={300}
            dense
          />
          <TableCount
            extraClasses="ml-auto"
            count={data?.length as number}
            entityName="vehicle"
            isLoading={isLoading}
            error={!!error}
          />
        </div>
      </FormSection>
      {VINsWithOccurrences.length > 0 && (
        <SignalEventPreviewIPTVWrapper
          VINs={VINsWithOccurrences}
          dateFilter={dateFilters}
          numAllOccurrences={numAllOccurrences}
        />
      )}
    </div>
  );
};

export default SignalEventPreviewVehicleSummary;
