const content: Record<string, string> = {
  "22.1.2": `**New functionality**    
* Show/hide and download multiple Failure Modes
  * Vehicles table allows users to show and hide Failure Modes to easily compare vehicles across Failure Mode attributes, including Risk Group, Risk Status, and Risk Multiple.
  * Column visibility is persistent so that users don’t have to reselect columns when they log in or return to a page if they are using the same browser on the same device.

**App Improvements**
* Embedded filters on Vehicles and other tables 
  * Cleaner UI when user shows or hides multiple Failure Modes per VIN.
  * Persistent filtering and sorting so that users don’t have to reselect filters when when they log in or return to a page if they are using the same browser on the same device.
* Updated odometer logic across the application
* In-App Release Notes
  * Shows latest application updates and related user benefits.
    `,
  "22.2.1": `**App Improvements**
  * Campaign Scoping design changes
    * Failure Mode Risk and Failure Mode Statuses are now divided into two columns for easier filtering
    * Failure Mode Risk and Failure Mode Status are also two separate filters
`,
  "22.2.2": `**New functionality**
* Dashboards
  * On the left sidebar, users now have Dashboards to quickly access new insights.
  * New dashboard, Customer Survival Curve Dashboard is live under Dashboards.

**App improvements**
* Improve SSO authentication issues with Data Explorer
  * Data Explorer is now decoupled from the application and opens in another tab.`,

  "22.2.3": `**App Improvements**
* Security fixes / improvements
    * On the left sidebar, users now have an option to sign out of the app via Logout button
* Table header design adjustments`,
  "22.2.4": `**New functionality**    
* **Campaigns**
  * Users may now browse through all campaigns under “Campaigns,” located on the sidebar navigation. 
  * Clicking on a campaign code shows the campaign details, including number of vehicles in the campaign, when it started, applicable failure modes, as well as cost distribution and repair frequency.
* **Failure Modes**
  * Users may now see all failure modes under “Failure Modes” in the sidebar navigation.
  * Clicking on a failure mode shows additional information, including details about which vehicles are eligible to experience the failure mode, the list of vehicles with their associated risk category, related campaigns, as well as a survival curve indicating the probability of failure by mileage for the eligible vehicle population.

**App Improvements**
* **Diagnostic History** for vehicles is now a standalone dashboard on the sidebar navigation under the Dashboards section. Previously, the dashboard was located in the VIN View's “History” tab. This helps users more easily locate the data for a vehicle.
* **Track** dashboard has been deprecated in favor of the new Campaigns page. 
    `,
  "22.3.1": `**New functionality**    
  * Vehicles is now the home page so users can immediately begin viewing high risk VINs.
  * Users can customize columns on the vehicles table in Fleet view so they can view all failures modes which affect their fleets.
  
  **App Improvements**
  * Filters were updated to make it easier to select multiple items.
      `,
  "22.3.2": `**App Improvements**    
  - **Failure Modes** 
    - After clicking on a specific failure mode, the Definition tab and Campaigned tab now have a Collection filter that allows the user to filter by any existing collection, or create one following the existing flow. 
  - **Vehicles**
    - A “Choose Failure Mode Columns” button has been added to the Vehicles table, containing the same functionality as the previously used columns settings to make it more noticeable for new users. 
      `,
  "22.3.3": `**App Improvements**    
  - Step-by-step documentation on the Vehicles page serves as both a useful introduction for new users and a convenient reference for existing users.
  - Two-level design for failure mode columns makes it easier to fit more vehicle risk information on-screen.
  - Tooltips added to failure mode columns on the Vehicles page so users can quickly reference column definitions.
  - Users can now export to excel directly from the vehicles tab for a particular failure mode, making it easier to download risk information just for vehicles affected by that failure mode.
  - Failure modes now presented in order of recency.`,
  "22.3.4": `**New functionality**
  - Failure Modes now have projections to help users can assess the magnitude and severity of a failure mode going forwards.
  
  **App Improvements**    
  - Survival Curves now go out to 1 million miles.
  - Vehicles not eligible for a failure mode now show risk status “Ineligible” for that failure mode on the Vehicles page.`,

  "22.3.5": `**New functionality**
  - **Signal Events**
     - Signal Events page added under the quality section of the left sidebar. `,

  "22.4.1": `**New functionality**
  - **Vehicle and Failure Analysis** tab added to anomaly page.`,

  "22.4.2": `
  **App Improvements**    
  - Add VIN search to the left sidebar and remove it from VIN View.
  - Remove VIN View link from sidebar.`,

  "22.4.3": `
  **New functionality**    
  - Added Timeline tab to the VINView page.`,

  "23.1.1": `
  **New functionality**    
  - **Dynamic collections** are now available!
    - Dynamic collections are defined by a set of filters on vehicle attributes. Consequently, dynamic collections can change over time when the vehicles are updated or added to the app.
    - Static collection behaviour did not change — it always contains a fixed set of vehicles.`,

  "23.1.2": `  
**App Improvements**
* Wider tables now have a sticky first column. This enables cross-referencing when scrolling tables horizontally. 
* General table style improvements.
    `,

  "23.1.3": `
  **New functionality**
  - Table filters now have various operations you can use based on column data type. These operations are "Includes", "Does not include", "Is empty", "Is not empty", "Between (inclusive)", "Greater than", "Greater than or equal", "Less than", "Less than or equal".`,

  "23.2.1": `**New functionality**
* Users can now share their own collections with other users.

**Other changes**
* Service Events have been renamed to Service Records`,

  "23.2.2": `**New functionality**
* **Claim Analytics** page provides insight into which claim types account for the largest share of cost and count of claims across all vehicles.`,

  "23.2.3": `**Other changes**
* **Campaign Scoping** page removed from the app. Vehicles page includes all the information that was present there. `,

  "23.2.4": `**App Improvements**    
  - Fix sorting by similarity on Similar VINs.

**Other changes**
* Rename **Claim Analytics** page to **Parts Performance**.`,

  "23.2.5": `**App Improvements**    
  - Enable filtering by mileage on the **Vehicles** page.`,

  "23.2.6": `
  **New functionality**
  - **Suggested Issues** page shows the candidates for systematic issues that were found by Viaduct's early issue identification engine.
  - **Signal Event Analytics** page enables a field quality investigator to look for patterns and trends in signal events that are indicative of potential field quality issues.  
  - **Claims Detail** page shows all the information about a certain claim.
  
  **App Improvements**    
  - Add Vehicle Tags and support for using tags to filter Vehicles.
  - Add Pop-Up modal for searching Issues or Suggested Issues based on Signal Event IDs.
  - Add Cumulative & Rolling occurrences metrics to Signal Event Analytics.
  - Add By Vehicle age tab to Signal Event Analytics. 
  - Issues can now be created from the Claim Analytics page. 
    
  **Bug Fixes**
  - Users with read-only permissions to issues should still see issue notes.`,

  "23.3.1": `
  **New functionality**
  - Show only Suggested Issues from the latest run.
  - Add CSV download to Claims on Claim Analytics.
  
  **App Improvements**    
  - Show Vehicle Tags on VIN View.
  - Set IPTV descending as default sort in Top Contributors table.
  - Round float numbers to two decimals in Top Contributors tooltips.
  - Add association strength column to associated tables for Claims and Signal Event Analytics pages.
  
  **Other changes**
  - Remove the Share Action button.`,

  "23.3.2": `
  **App Improvements**    
  - Enable search on Mentioned Key-phrases.
  - Show all Mentioned Signal Events & Key-phrases in a modal.
  - Make IPTV metrics default instead of CPV.
  
  **Other changes**
  - Add new Product Guide.`,

  "23.3.3": `
  **New functionality**
  - Add Repair Efficacy Timeline Chart to Issues.
  - Support filtering Claims on Related Signal Events occurrences. 

  **App Improvements**    
  - Add Mileage to Signal Events table.
  - Add reset to default button to Claim & Signal Events Analytics filters.
  - Make sort and filter icons more visible.
  - Add DPTV-DV metric to issue details charts.
  - Add descriptions for Mentioned Signal Events.
  - Add Last Known Dealer information to Vehicle.`,

  "23.3.4": `
  **App Improvements**
  - Allow filtering by float or negative numbers on DTPV in Suggested Issues.
  - Add Days In Service as a Claim field.

  **Bug Fixes**
  - Fix order of lines drawn for By Vehicle Age chart.`,

  "23.4.1": `
  **New functionality**
  - Implement group permissions. Users can now create groups and assign permissions to the whole group at once.
  - Expose ECUs in VIN View.

  **App Improvements**
  - Add association strength trend to Suggested Issues.

  **Other changes**
  - Delete Claim Collections.
  - Remove Maximum Risk from Vehicle and Fleets pages.`,

  "23.4.2": `**App Improvements**
  - Add trigger column to Signal Event Analytics.
  - Add 'is empty' filters for dates.
  - Add Date and Time to VIN timeline service records.
  - Add 1 & 3 months to Top Contributors.
  - Show true/false instead of Empty for booleans on Top Contributors.

  **Bug Fixes**
  - Fix date timezone issues for Claim and Signal Events.

  **Other changes**
  - Drop Subtenancy.
  - Remove Vehicle and Failure Analysis tab from Anomaly details page.`,

  "23.4.3": `
  **New functionality**
  - Use filters to define related signal event occurrences for Issues.

  **App Improvements**
  - Add zoom to Scatter chart.
  - Make Vehicles tab default on Single Fleet page.

  **Bug Fixes**
  - Fix chart zooming highlight colors.
  - Fix VIN Search so it allows repeating the same VIN multiple times.`,

  "24.1.1": `
  **New Functionality**
  - Timeline on Vehicle details page now includes sensors if selected.
  - Similar Claims on Claim Analytics page.
  - Export of Suggested Issues.

  **App Improvements**
  - Added distinct vehicle counts to Issue pages.
  - Improve Custom Record filtering.

  **Bug Fixes**
  - Fix large exports.`,

  "24.1.2": `
  **New Functionality**
  - Notes and status added to Suggested Issues.

  **App Improvements**
  - Improved UX for filtering: multiple filters can now be changed before applying once with the Apply button.

  **Bug Fixes**
  - Fix links from Signal Event Analytics to Claim Analytics when Related Signal Event filtering is applied.
  - Remove commas from the Vehicle & Engine Model Year attributes in Vehicles table.`,

  "24.1.3": `
  **New Functionality**
  - Added assigned group to suggested issues.
  - Added support for "min/max" filtering. Only enabled for specific attributes at this point.
  - Added "repair status" as a failure mode attribute where applicable.

  **App Improvements**
  - Removed Anomalies pages.
  - Change suggested issue status to "Promoted to Issue" when an issue is created from a suggested issue.`,

  "24.2.1": `
  **New Functionality**
  - Added "does not contain" filter operator.
  - All Vehicles pages that had table filters now have top-level filters which allow users to filter across all vehicle attributes - and not just attributes visible in the table. This makes it easier to find specific vehicles across all attributes.

  **App Improvements**
  - Renamed filter operators to be more intuitive.
  - Updated the look and feel of multiple UI components to make them more user-friendly and consistent. Ie. textarea, inputs, dropdowns ..
  - Many other small UI and UX improvements.
  - Comma-separated list of values can now be pasted into the "contains" and "starts-with" filters too.
  - Make "is not filtered" a default filter operator. 

  **Bug Fixes**
  - Fix missing events on VIN timeline.
  - Fix suggested issue download.`,
  "24.2.2": `
  **New Functionality**
  - Added more options for exposure parameter on Analytics and Issues charts.
  - Users can now download Issues.
  - Users can now download Top Contributors and By Vehicle Age data.

  **App Improvements**
  - Show units for sensor readings on the VIN timeline.
  - Improved Issue UI presentation.
  - Many other small UI and UX improvements.

  **Bug Fixes**
  - Fixed ticks on the x-axis some of the timeline charts.`,
  "24.2.3": `
  **New Functionality**
  - Advanced filter builder with support for AND and OR operators.

  **App Improvements**
  - Improve text wrapping on single Claim page.

  **Bug Fixes**
  - Fix horizontal scroll on the Vehicles page.
  - Fix Related Signal Event base object name on Claim Analytics.
  
  **Removed Functionality**
  - The "eq" and "neq" comparison operators are now deprecated in the API.`,

  "24.3.1": `
  **New Functionality**
  - Signal Event Studio: a tool to define custom signal events
  - Service Plans: a tool to help service managers make informed decisions on how to service their vehicles
  - Show last known dealer contact in VIN View
  - Add Suggested Issue Promotion tracking

  **App Improvements**
  - Simplify relates filter to only allow one row in Standard filter
  - Add last dealer information to associated vehicles table
  - Sensor chart & Events timeline improvements
  - Add top level filters for Suggested Issues
  - Add table filters to Issues & Suggested Issues table
  
  **Removed Functionality**
  - Projections were removed from the Failure Mode pages`,

  "24.3.2": `
  **New Functionality**
  - Management dashboards for Issues & Suggested Issues
  - Service Alerts: a tool to set email notifications on Service Recommendations

  **App Improvements**
  - Do not show filter overview on downloads
  - Add sale dealer information to view view
  - Add dealer information to single claim page
  - Support filtering on datetime fields with timezone
  - Set 'Everyone can read' ACL as default for Service Plans

  **Bug Fixes**
  - Make top level filters work in combination with table filters
  - Fix date filtering on UTC dates
  - Round floats on top contributors chart - Claim Analytics
  - Consolidate breadcrumbs
  
  **Removed Functionality**
  - Signal Event Alerts were removed from the app
  - Remove functionality to edit suggested issue statuses
  - Remove In-Scope Vehicles by Engine Model and Model Year from the Failure Mode pages`,

  "24.3.3": `
  **New Functionality**
  - Add Repairs and Inspections analytics
  
  **App Improvements**
  - Support filtering and sorting for timeline
  - Add metrics to issues and suggested issues
  - Add limits to sensors, readings and timeline
  - Add summary table and vehicle timeline to preview tab
  - Add Cancel button to custom signal events page
  
  **Bug Fixes**
  - Fix grouping by hour
  - Service alert creation retains previous frequency`,

  "24.4.1": `
  **New Functionality**
  - Details pages added for Custom Records and Signal Event Occurrences
  
  **App Improvements**
  - Additional chart settings added to dashboards of Issues and Suggested Issues
  - Repairs and Inspections can now be used as base when defining a Custom Signal Event
  
  **Bug Fixes**
  - Fixed disabled "Apply" button in advanced filters when certain filters are added
  - Fixed issue with page-level filters changing when interacting with top contributors chart`,

  "24.4.2": `
  **New Functionality**
  - Issue Activity Log
  - Added Risk Model Results tab to failure mode definition
  - New Failure Modes page
  
  **App Improvements**
  - Additional event types added to Signal Event Studio
  - Additional fields added to Repairs and Inspections
  
  **Bug Fixes**
  - Fixed 'in last' date filtering
  - Fixed 'count window' custom attribute function
  - Fixed downsampling on custom signal events`,

  "24.4.3": `
  **New Functionality**
  - Landing Page
  - Help Articles
  
  **App Improvements**
  - Enabled show/hide in v1 APIs
  - Multiple updates to Parts and Repairs APIs
  
  **Bug Fixes**
  - Fixed deleting row in advanced filtering
  - Fixed applying Related Signal Events filter in Claim Analytics`,

  "25.1.1": `
  **New Functionality**
  - General Configuration
  - Pages Configuration
  - Attributes Configuration
  - Various new Duck capabilities
  - Names and Descriptions added to suggested issues
  
  **App Improvements**
  - Improved data on By Vehicle Age pages
  - M2M Filtering improvements
  - Added "Compare to X weeks" options on issues dashboard
  - Removed Product Fruits
  - Relates Filter updates
  
  **Bug Fixes**
  - Fixed left navigation positioning
  - Fixed survival curve plotting`,

  "25.1.2": `
  **New Functionality**
  
  **App Improvements**
  - "By Vehicle Age" pages renamed to "By Production Date"
  
  **Bug Fixes**`,
};

export default content;
