import {
  useClaimsFiltersSchema,
  useServiceRecommendationsFiltersSchema,
  useSignalEventOccurrencesFiltersSchema,
  useVehiclesFiltersSchema,
} from "shared/hooks";
import useAlertDefinitionSchema from "shared/schemas/alertDefinitionsSchema";

import { ALERT_EVENT_TYPE_OPTIONS } from "pages/AlertDefinitions/constants";
import { AlertDefinitionState } from "pages/AlertDefinitions/types";
import ClaimCount from "pages/Issues/CreateIssue/ClaimCount";
import SignalEventOccurrencesCount from "pages/Issues/CreateIssue/SignalEventOccurrencesCount";
import RecommendationCount from "pages/ServiceRecommendations/RecommendationCount";
import EligibleVehicleCount from "pages/shared/EligibleVehicleCount";

import FilterBuilder from "features/ui/Filters/FilterBuilder";
import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";
import FormSection from "features/ui/FormSection";
import { SchemaEntry } from "features/ui/Table";

interface Props {
  currentAlertDefinition: AlertDefinitionState;
  isFormDisabled: boolean;
  editMode: boolean;
  onDataUpdated: (data: Partial<AlertDefinitionState>) => void;
}

export const AlertDefinitionEventList = ({
  currentAlertDefinition,
  editMode,
  isFormDisabled,
  onDataUpdated,
}: Props) => {
  const signalEventOccurrencesSchema = useSignalEventOccurrencesFiltersSchema();
  const claimsSchema = useClaimsFiltersSchema();
  const vehiclesFiltersSchema = useVehiclesFiltersSchema({
    disableIsNotFilteredFilters: true,
  });
  const serviceRecommendationsSchema = useServiceRecommendationsFiltersSchema({
    disableIsNotFilteredFilters: true,
  });
  const { getDisplayLabel } = useAlertDefinitionSchema();

  const getSchemaForEventType = (eventType: string): SchemaEntry[] => {
    switch (eventType) {
      case "signalEventOccurrence":
        return signalEventOccurrencesSchema;
      case "claim":
        return claimsSchema;
      case "serviceRecommendation":
        return serviceRecommendationsSchema;
      default:
        return [];
    }
  };

  const getLabelForEventFilter = () => {
    const base = "Eligible";
    const option = ALERT_EVENT_TYPE_OPTIONS.find(
      (option) => option.id === currentAlertDefinition.eventType
    )?.value.toString();

    return `${base} ${option}`;
  };
  const getLabelForEventFilterDescription = () => {
    const base = (type: string) =>
      `Specify the ${type} for which alerts should be sent`;
    const option = ALERT_EVENT_TYPE_OPTIONS.find(
      (option) => option.id === currentAlertDefinition.eventType
    )?.value.toString();
    if (!option) return "";

    return base(option);
  };

  return (
    <>
      <FormSection
        title={getLabelForEventFilter()}
        extraTitleComponent={
          !editMode && (
            <>
              {currentAlertDefinition.eventType === "serviceRecommendation" && (
                <RecommendationCount
                  filterState={currentAlertDefinition.eventFilter}
                  vehiclesFilterState={currentAlertDefinition.vehiclesFilter}
                />
              )}
              {currentAlertDefinition.eventType === "claim" && (
                <ClaimCount
                  filters={currentAlertDefinition.eventFilter}
                  vehicleFilters={currentAlertDefinition.vehiclesFilter}
                  clickable={true}
                />
              )}
              {currentAlertDefinition.eventType === "signalEventOccurrence" && (
                <SignalEventOccurrencesCount
                  filters={currentAlertDefinition.eventFilter}
                  vehicleFilters={currentAlertDefinition.vehiclesFilter}
                  clickable={true}
                />
              )}
            </>
          )
        }
        text={getLabelForEventFilterDescription()}
        middleMargin={false}
      >
        {isFormDisabled ? (
          <FilterQueryPresentation
            filterState={currentAlertDefinition.eventFilter}
            tableSchema={getSchemaForEventType(
              currentAlertDefinition.eventType
            )}
            dense={false}
          />
        ) : (
          <FilterBuilder
            filterBuilderState={currentAlertDefinition.eventFilter}
            filtersSchema={getSchemaForEventType(
              currentAlertDefinition.eventType
            )}
            onChange={(filterState) =>
              onDataUpdated({ eventFilter: filterState })
            }
            inline
            testId="alert-event-filter-builder"
          />
        )}
      </FormSection>
      <FormSection
        title={getDisplayLabel("vehicleFilter", "Eligible Vehicles")}
        extraTitleComponent={
          !editMode && (
            <EligibleVehicleCount
              vehicleFilter={currentAlertDefinition.vehiclesFilter}
            />
          )
        }
        text={
          isFormDisabled
            ? undefined
            : "Optional filters to refine the set of affected vehicles covered by this alert"
        }
        middleMargin={false}
      >
        {isFormDisabled ? (
          <FilterQueryPresentation
            filterState={currentAlertDefinition.vehiclesFilter}
            tableSchema={vehiclesFiltersSchema}
            dense={false}
          />
        ) : (
          <FilterBuilder
            filterBuilderState={currentAlertDefinition.vehiclesFilter}
            filtersSchema={vehiclesFiltersSchema}
            onChange={(filterState) =>
              onDataUpdated({ vehiclesFilter: filterState })
            }
            inline
            testId="eligible-vehicles-filter-builder"
          />
        )}
      </FormSection>{" "}
    </>
  );
};

export default AlertDefinitionEventList;
