import { EventTypeLabel } from "shared/types";

import {
  useByVehicleAgeBirthdayOptions,
  useSEByVehicleAgeExposureOptions,
} from "pages/hooks";
import { getByVehicleAgeChartOptions } from "pages/utils";

import {
  cumulativeDPTV,
  cumulativeDPTVDV,
  cumulativeEvents,
  totalVehicles,
} from "features/ui/charts/actions";
import { ChartAction } from "features/ui/charts/Actions/types";
import { Option, SelectOption } from "features/ui/Select";

export const useGetByVehicleAgeChartActions = (
  eventType: EventTypeLabel
): ChartAction<Option>[] => {
  const birthdayOptions = useByVehicleAgeBirthdayOptions();
  const exposureOptions = useSEByVehicleAgeExposureOptions();

  if (eventType === EventTypeLabel.SIGNAL_EVENT) {
    return getByVehicleAgeChartOptions(
      getSignalEventByVehicleAgeYAxisOptions(),
      birthdayOptions,
      exposureOptions
    );
  }

  return getByVehicleAgeChartOptions(
    [
      cumulativeDPTV,
      cumulativeDPTVDV,
      totalVehicles,
      cumulativeEvents(eventType),
    ],
    birthdayOptions,
    exposureOptions
  );
};

export const getSignalEventByVehicleAgeYAxisOptions = (): SelectOption[] =>
  [
    {
      id: "cumulativeDPTV",
      value: "DPTV-Distinct VINs",
    },
    {
      id: "cumulativeIPTV",
      value: "DPTV",
    },
    {
      id: "numEvents",
      value: "Event Count",
    },
    {
      id: "numVehicles",
      value: "Affected Vehicles",
    },
    {
      id: "vehiclesAtExposure",
      value: "Vehicles at Exposure",
    },
    {
      id: "totalVehicles",
      value: "Vehicles Manufactured",
    },
    {
      id: "percentVehiclesAtExposure",
      value: "Percent of Vehicles at Exposure",
    },
  ].filter(Boolean) as SelectOption[];
