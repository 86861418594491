import { toDate } from "date-fns-tz";
import qs from "qs";

import { EntityAttribute, VehicleAgeTimeline } from "shared/api/api";
import { ageGranularityOptions, Granularity } from "shared/api/constants";
import {
  DEFAULT_BY_VEHICLE_AGE,
  DEFAULT_EXPOSURE,
  MONTH_YEAR,
  MONTH_YEAR_DAY,
  NONE_EXPOSURE,
} from "shared/constants";
import { TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR } from "shared/schemas/constants";
import { sortByValue } from "shared/schemas/utils";
import { EventTypeEnum } from "shared/types";
import {
  formatDate,
  pluralize,
  PopulationLine,
  toTitleCase,
} from "shared/utils";

import { yAxis } from "features/ui/charts/actions";
import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import { GRAPH_STROKE_WIDTH } from "features/ui/charts/constants";
import { DataElement } from "features/ui/charts/types";
import {
  getAxisLabel,
  getAxisValue,
  getColor,
  getSelectedOptionId,
} from "features/ui/charts/utils";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterBuilderQueryToFilterBuilderState,
  getFiltersQuery,
  updateOrAddRowFilterGroupState,
} from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator, OccursFilterState } from "features/ui/Filters/types";
import { getFiltersKey } from "features/ui/Filters/utils";
import { SelectOption } from "features/ui/Select";
import { RowData, SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell/types";

import { routes } from "services/routes";

import {
  MONTHS_IN_SERVICE_EXPOSURE,
  MONTHS_IN_SERVICE_EXPOSURE_BUCKET,
} from "./constants";
import {
  SIGNAL_EVENTS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
} from "./SignalEventsAnalytics/constants";
import { ByVehicleAgeData } from "./types";

/**
 * Analytics pages: Claims utils
 */
export const getClaimsChartOptions = (
  yAxisOptions: SelectOption[]
): ChartAction[] => [
  {
    id: "y",
    title: yAxis,
    type: "dropdownSelect",
    options: yAxisOptions,
  },
];

export const claimsActionIds = ["y"] as const;

export type ClaimsActionIdType = (typeof claimsActionIds)[number];

export const vinViewTimelineActionIds = ["legend"] as const;

export type VinViewTimelineActionIdType =
  (typeof vinViewTimelineActionIds)[number];

/**
 * Analytics pages: By Production Date utils
 */
export const formatDateBasedOnGranularity = (
  date: string,
  granularity: string
) =>
  granularity === "week"
    ? formatDate(date, MONTH_YEAR_DAY, true)
    : formatDate(date, MONTH_YEAR, true);

export const getByVehicleAgeChartOptions = (
  yAxisOptions: SelectOption[],
  xAxisOptions: SelectOption[],
  exposures: SelectOption[]
): ChartAction[] => [
  {
    id: "y",
    title: yAxis,
    type: "dropdownSelect",
    options: yAxisOptions,
  },
  {
    id: "x",
    title: "X-axis",
    type: "dropdownSelect",
    options: xAxisOptions,
    defaultOptionId: DEFAULT_BY_VEHICLE_AGE,
  },
  {
    id: "granularity",
    title: "Granularity",
    type: "dropdownSelect",
    options: ageGranularityOptions,
  },
  {
    id: "exposure",
    title: "Exposure",
    type: "dropdownSelect",
    options: exposures,
    defaultOptionId: DEFAULT_EXPOSURE,
  },
];

export const areThereAnyByAgeAttributes = (
  vehicleAttributes?: EntityAttribute[],
  resourceAttributes?: EntityAttribute[]
) =>
  vehicleAttributes &&
  vehicleAttributes.length > 0 &&
  vehicleAttributes.find(({ byVehicleAgeBirthday }) => byVehicleAgeBirthday) &&
  resourceAttributes &&
  resourceAttributes.length > 0 &&
  resourceAttributes.find(({ byVehicleAgeExposure }) => byVehicleAgeExposure);

export const isSelectedByAgeAttributeInvalid = (
  byVehicleAgeData: ByVehicleAgeData,
  vehicleAttributes?: EntityAttribute[],
  resourceAttributes?: EntityAttribute[]
) =>
  (byVehicleAgeData.byVehicleAgeBirthday &&
    vehicleAttributes &&
    vehicleAttributes.length > 0 &&
    !vehicleAttributes.find(
      ({ ID, byVehicleAgeBirthday }) =>
        ID === byVehicleAgeData.byVehicleAgeBirthday && byVehicleAgeBirthday
    )) ||
  (byVehicleAgeData.byVehicleAgeExposure &&
    resourceAttributes &&
    resourceAttributes.length > 0 &&
    !resourceAttributes.find(
      ({ ID, byVehicleAgeExposure }) =>
        ID === byVehicleAgeData.byVehicleAgeExposure && byVehicleAgeExposure
    ));

export const byVehicleAgeActionIds = [
  "y",
  "x",
  "granularity",
  "exposure",
] as const;

export type ByVehicleAgeActionIdType = (typeof byVehicleAgeActionIds)[number];

export const generateByVehicleAgeYAxisLines = (
  data: VehicleAgeTimeline[],
  byVehicleAgeData: ByVehicleAgeData
): PopulationLine[] => {
  const { yAxisKey, byVehicleAgeBirthday } = byVehicleAgeData;
  const uniqueIDs = Array.from(
    new Set(data.map(({ exposureBucket }) => exposureBucket))
  ).sort((a, b) => a - b);

  return uniqueIDs.map((exposureBucket: number, index: number) => ({
    color: getColor(index),
    label: exposureBucket.toString(),
    dashed: false,
    key: `${yAxisKey}-${byVehicleAgeBirthday}-${exposureBucket}`,
    width: GRAPH_STROKE_WIDTH,
    dot: true,
  }));
};

export const prepareByVehicleAgeDataByTimestamp = (
  data: VehicleAgeTimeline[],
  byVehicleAgeData: ByVehicleAgeData
): DataElement[] => {
  const { yAxisKey, byVehicleAgeBirthday } = byVehicleAgeData;

  return (
    data
      .map((data) => {
        const { birthdayBucket, exposureBucket, ...otherData } = data;
        const keyYAxis = `${yAxisKey}-${byVehicleAgeBirthday}-${exposureBucket}`;

        const ts = toDate(birthdayBucket).getTime();

        return {
          [keyYAxis]: data[yAxisKey as keyof VehicleAgeTimeline],
          ts,
          exposureBucket,
          [exposureBucket]: otherData,
          byVehicleAgeData,
        };
      })
      // we would like to have only one entry per timestamp, so recharts does not go mad
      .reduce((acc: DataElement[], value: DataElement) => {
        const i = acc.findIndex(({ ts }) => ts === value.ts);
        if (i > -1) {
          acc[i] = { ...acc[i], ...value };
        } else {
          acc.push(value);
        }

        return acc;
      }, [])
  );
};

const prepareClaimsVehiclesByAgeTableSchemaAndData = (
  data: VehicleAgeTimeline[],
  showCosts: boolean,
  granularity: Granularity,
  buildDateLabel: string
) => {
  const newData: RowData[] = [];
  const exposureList: number[] = [];

  data.forEach(
    ({
      birthdayBucket,
      cumulativeIPTV,
      cumulativeCPV,
      numVehicles,
      numEvents,
      exposureBucket,
      distinctIPTV,
      vehiclesAtExposure,
      percentVehiclesAtExposure,
      totalVehicles,
      costRate,
    }) => {
      exposureList.push(exposureBucket);

      const currentRow = newData.find(
        (row) => row.birthdayBucket === birthdayBucket
      );

      if (currentRow) {
        currentRow[`IPTV_${exposureBucket}`] = cumulativeIPTV;
        currentRow[`DIPTV_${exposureBucket}`] = distinctIPTV;
        currentRow[`costRate_${exposureBucket}`] = costRate;
        currentRow[`numEvents_${exposureBucket}`] = numEvents;
        currentRow[`numVehicles_${exposureBucket}`] = numVehicles;
        currentRow[`CPV_${exposureBucket}`] = cumulativeCPV;
        currentRow[`vehiclesAtExposure_${exposureBucket}`] = vehiclesAtExposure;
        currentRow[`totalVehicles_${exposureBucket}`] = totalVehicles;
        currentRow[`percentVehiclesAtExposure_${exposureBucket}`] =
          percentVehiclesAtExposure;
      } else {
        newData.push({
          birthdayBucket,
          [`IPTV_${exposureBucket}`]: cumulativeIPTV,
          [`DIPTV_${exposureBucket}`]: distinctIPTV,
          [`costRate_${exposureBucket}`]: costRate,
          [`numEvents_${exposureBucket}`]: numEvents,
          [`numVehicles_${exposureBucket}`]: numVehicles,
          [`CPV_${exposureBucket}`]: cumulativeCPV,
          [`vehiclesAtExposure_${exposureBucket}`]: vehiclesAtExposure,
          [`totalVehicles_${exposureBucket}`]: totalVehicles,
          [`percentVehiclesAtExposure_${exposureBucket}`]:
            percentVehiclesAtExposure,
        });
      }
    }
  );

  const uniqueExposureValues = new Set(exposureList);
  const exposureValuesArray = Array.from(uniqueExposureValues).sort(
    (a, b) => a - b
  );

  const exposureSchemaEntries = exposureValuesArray
    .map((exposureBucket) =>
      [
        {
          accessor: `IPTV_${exposureBucket}`,
          label: "IPTV",
          description:
            "Cumulative incidence rate per one thousand vehicles at exposure level. Not shown for periods where there are fewer than 15 vehicles manufactured",
          dataType: DataType.NUMBER,
          decimals: 2,
          minDecimals: 2,
        },
        {
          accessor: `DIPTV_${exposureBucket}`,
          label: "IPTV-Distinct VINs",
          description:
            "Cumulative distinct affected vehicle rate per on thousand vehicles at exposure level. Not shown for periods where there are fewer than 15 vehicles manufactured",
          dataType: DataType.NUMBER,
          decimals: 2,
          minDecimals: 2,
        },
        showCosts && {
          accessor: `costRate_${exposureBucket}`,
          label: "CPV",
          description:
            "Cumulative cost per vehicle at exposure level. Not shown for periods where there are fewer than 15 vehicles manufactured",
          dataType: DataType.NUMBER,
          decimals: 2,
          minDecimals: 2,
        },
        {
          accessor: `numEvents_${exposureBucket}`,
          label: "Incident Count",
          description: "Cumulative incidents up to exposure level",
          dataType: DataType.NUMBER,
        },
        {
          accessor: `numVehicles_${exposureBucket}`,
          label: `Affected Vehicles`,
          description: "Cumulative affected vehicles up to exposure level",
          dataType: DataType.NUMBER,
        },
        showCosts && {
          accessor: `CPV_${exposureBucket}`,
          label: "Cost",
          description: "Cumulative cost up to exposure level",
          dataType: DataType.NUMBER,
          decimals: 2,
          minDecimals: 2,
        },
        {
          accessor: `vehiclesAtExposure_${exposureBucket}`,
          label: `Vehicles at Exposure`,
          description: "Total vehicles that have reached exposure threshold",
          dataType: DataType.NUMBER,
        },
        {
          accessor: `totalVehicles_${exposureBucket}`,
          label: `Vehicles Manufactured`,
          description: "Total vehicles manufactured in period",
          dataType: DataType.NUMBER,
        },
        {
          accessor: `percentVehiclesAtExposure_${exposureBucket}`,
          label: `Percent of Vehicles at Exposure`,
          description:
            "Percent of total vehicles manufactured that have reached exposure level",
          dataType: DataType.NUMBER,
        },
      ].filter(Boolean)
    )
    .flat() as SchemaEntry[];

  const schema: SchemaEntry[] = [
    {
      accessor: "birthdayBucket",
      label: buildDateLabel,
      dataType:
        granularity === Granularity.MONTH
          ? DataType.DATE_YEAR_MONTH
          : DataType.DATE_YEAR_MONTH_DAY,
    },
    ...exposureSchemaEntries,
  ];

  return {
    data: newData,
    schema,
    uniqueExposureValues,
  };
};

const prepareSignalEventVehiclesByAgeTableSchemaAndData = (
  data: VehicleAgeTimeline[],
  granularity: Granularity,
  buildDateLabel: string
) => {
  const newData: RowData[] = [];
  const exposureList: number[] = [];

  data.forEach(
    ({
      birthdayBucket,
      cumulativeIPTV,
      cumulativeDPTV,
      numVehicles,
      numEvents,
      exposureBucket,
      vehiclesAtExposure,
      percentVehiclesAtExposure,
      totalVehicles,
    }) => {
      exposureList.push(exposureBucket);

      const currentRow = newData.find(
        (row) => row.birthdayBucket === birthdayBucket
      );

      if (currentRow) {
        currentRow[`cumulativeDPTV_${exposureBucket}`] = cumulativeDPTV;
        currentRow[`cumulativeIPTV_${exposureBucket}`] = cumulativeIPTV;
        currentRow[`numEvents_${exposureBucket}`] = numEvents;
        currentRow[`numVehicles_${exposureBucket}`] = numVehicles;
        currentRow[`vehiclesAtExposure_${exposureBucket}`] = vehiclesAtExposure;
        currentRow[`totalVehicles_${exposureBucket}`] = totalVehicles;
        currentRow[`percentVehiclesAtExposure_${exposureBucket}`] =
          percentVehiclesAtExposure;
      } else {
        newData.push({
          birthdayBucket,
          [`cumulativeDPTV_${exposureBucket}`]: cumulativeIPTV,
          [`cumulativeIPTV_${exposureBucket}`]: cumulativeIPTV,
          [`numEvents_${exposureBucket}`]: numEvents,
          [`numVehicles_${exposureBucket}`]: numVehicles,
          [`vehiclesAtExposure_${exposureBucket}`]: vehiclesAtExposure,
          [`totalVehicles_${exposureBucket}`]: totalVehicles,
          [`percentVehiclesAtExposure_${exposureBucket}`]:
            percentVehiclesAtExposure,
        });
      }
    }
  );

  const uniqueExposureValues = new Set(exposureList);
  const exposureValuesArray = Array.from(uniqueExposureValues).sort(
    (a, b) => a - b
  );

  const exposureSchemaEntries = exposureValuesArray
    .map((exposureBucket) =>
      [
        {
          accessor: `cumulativeDPTV_${exposureBucket}`,
          label: "DPTV-Distinct VINs",
          description:
            "Cumulative distinct affected vehicle rate per on thousand vehicles at exposure level. Not shown for periods where there are fewer than 15 vehicles manufactured",
          dataType: DataType.NUMBER,
          decimals: 2,
          minDecimals: 2,
        },
        {
          accessor: `cumulativeIPTV_${exposureBucket}`,
          label: "DPTV",
          description:
            "Cumulative event rate per one thousand vehicles at exposure level. Not shown for periods where there are fewer than 15 vehicles manufactured",
          dataType: DataType.NUMBER,
          decimals: 2,
          minDecimals: 2,
        },
        {
          accessor: `numEvents_${exposureBucket}`,
          label: "Event Count",
          description: "Cumulative events up to exposure level",
          dataType: DataType.NUMBER,
        },
        {
          accessor: `numVehicles_${exposureBucket}`,
          label: `Affected Vehicles`,
          description: "Cumulative affected vehicles up to exposure level",
          dataType: DataType.NUMBER,
        },
        {
          accessor: `vehiclesAtExposure_${exposureBucket}`,
          label: `Vehicles at Exposure`,
          description: "Total vehicles that have reached exposure threshold",
          dataType: DataType.NUMBER,
        },
        {
          accessor: `totalVehicles_${exposureBucket}`,
          label: `Vehicles Manufactured`,
          description: "Total vehicles manufactured in period",
          dataType: DataType.NUMBER,
        },
        {
          accessor: `percentVehiclesAtExposure_${exposureBucket}`,
          label: `Percent of Vehicles at Exposure`,
          description:
            "Percent of total vehicles manufactured that have reached exposure level",
          dataType: DataType.NUMBER,
        },
      ].filter(Boolean)
    )
    .flat() as SchemaEntry[];

  const schema: SchemaEntry[] = [
    {
      accessor: "birthdayBucket",
      label: buildDateLabel,
      dataType:
        granularity === Granularity.MONTH
          ? DataType.DATE_YEAR_MONTH
          : DataType.DATE_YEAR_MONTH_DAY,
    },
    ...exposureSchemaEntries,
  ];

  return {
    data: newData,
    schema,
    uniqueExposureValues,
  };
};

export const prepareVehiclesByAgeTableSchemaAndData = (
  data: VehicleAgeTimeline[],
  showCosts: boolean,
  granularity: Granularity,
  buildDateLabel: string,
  entity: EventTypeEnum
) => {
  if (entity === EventTypeEnum.CLAIM) {
    return prepareClaimsVehiclesByAgeTableSchemaAndData(
      data,
      showCosts,
      granularity,
      buildDateLabel
    );
  }

  if (entity === EventTypeEnum.SIGNAL_EVENT) {
    return prepareSignalEventVehiclesByAgeTableSchemaAndData(
      data,
      granularity,
      buildDateLabel
    );
  }

  const newData: RowData[] = [];
  const exposureList: number[] = [];

  // rewrite the below using reduce to make it nicer..
  data.forEach(
    ({
      birthdayBucket,
      cumulativeIPTV,
      cumulativeCPV,
      numVehicles,
      numEvents,
      exposureBucket,
    }) => {
      exposureList.push(exposureBucket);

      const currentRow = newData.find(
        (row) => row.birthdayBucket === birthdayBucket
      );

      if (currentRow) {
        currentRow[`IPTV_${exposureBucket}`] = cumulativeIPTV;
        currentRow[`CPV_${exposureBucket}`] = cumulativeCPV;
        currentRow[`numVehicles_${exposureBucket}`] = numVehicles;
        currentRow[`numEvents_${exposureBucket}`] = numEvents;
      } else {
        newData.push({
          birthdayBucket,
          [`IPTV_${exposureBucket}`]: cumulativeIPTV,
          [`CPV_${exposureBucket}`]: cumulativeCPV,
          [`numVehicles_${exposureBucket}`]: numVehicles,
          [`numEvents_${exposureBucket}`]: numEvents,
        });
      }
    }
  );

  const uniqueExposureValues = new Set(exposureList);
  const exposureValuesArray = Array.from(uniqueExposureValues).sort(
    (a, b) => a - b
  );

  const exposureSchemaEntries = exposureValuesArray
    .map((exposureBucket) =>
      [
        {
          accessor: `IPTV_${exposureBucket}`,
          label: `IPTV`,
          dataType: DataType.NUMBER,
          decimals: 2,
          minDecimals: 2,
        },
        showCosts && {
          accessor: `CPV_${exposureBucket}`,
          label: `CPV`,
          dataType: DataType.NUMBER,
          decimals: 2,
          minDecimals: 2,
        },
        {
          accessor: `numVehicles_${exposureBucket}`,
          label: `Total Vehicles`,
          dataType: DataType.NUMBER,
        },
        {
          accessor: `numEvents_${exposureBucket}`,
          label: toTitleCase(pluralize(entity)),
          dataType: DataType.NUMBER,
        },
      ].filter(Boolean)
    )
    .flat() as SchemaEntry[];

  const schema: SchemaEntry[] = [
    {
      accessor: "birthdayBucket",
      label: buildDateLabel,
      dataType:
        granularity === Granularity.MONTH
          ? DataType.DATE_YEAR_MONTH
          : DataType.DATE_YEAR_MONTH_DAY,
    },
    ...exposureSchemaEntries,
  ];

  return {
    data: newData,
    schema,
    uniqueExposureValues,
  };
};

/**
 * Analytics pages: Top Contributors utils
 */
export const topContributorActionIds = [
  "y",
  "exposure",
  "exposureBucket",
] as const;

export type TopContributorActionIdType =
  (typeof topContributorActionIds)[number];

const getFirstBucketId = (currentExposureBuckets: SelectOption[]) =>
  currentExposureBuckets.length === 0 ? "" : currentExposureBuckets[0].id;

export const buildNewTopContributorsSelectedOptions = (
  selectedOptions: SelectedChartOptions[],
  currentExposureBuckets: SelectOption[]
) => {
  if (selectedOptions.find(({ id }) => id === "exposureBucket")) {
    return selectedOptions.map((option) =>
      option.id === "exposureBucket"
        ? { ...option, optionId: getFirstBucketId(currentExposureBuckets) }
        : option
    );
  }

  return [
    ...selectedOptions,
    {
      id: "exposureBucket",
      optionId: getFirstBucketId(currentExposureBuckets),
    },
  ];
};

export const prepareTopContributorsData = <
  T extends { groupByAttributeValue: string | number | boolean | null },
>(
  data: T[],
  attribute: keyof T
) =>
  data
    .map((tc) => ({
      dimension: tc[attribute],
      // we use .toString() so that boolean values are presented correctly in the BarChart
      [TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR]:
        tc[TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR] === null
          ? null
          : tc[TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR].toString(),
    }))
    .sort(({ dimension: dimensionA }, { dimension: dimensionB }) => {
      // nulls sort after anything else
      if (dimensionA === null) {
        return 1;
      }

      if (dimensionB === null) {
        return -1;
      }

      if (typeof dimensionA === "string" && typeof dimensionB === "string") {
        return dimensionA.localeCompare(dimensionB);
      }

      return (dimensionB as number) - (dimensionA as number);
    });

export const getTopContributorsChartActions = (
  yAxisOptions: SelectOption[],
  exposures: SelectOption[],
  exposureBuckets: SelectOption[],
  selectedExposure: string
): ChartAction[] =>
  [
    {
      id: "y",
      title: "Y Axis",
      type: "dropdownSelect",
      options: yAxisOptions,
    },
    {
      id: "exposure",
      title: "Exposure",
      type: "dropdownSelect",
      options: exposures,
      defaultOptionId: selectedExposure,
    },
    selectedExposure !== NONE_EXPOSURE && {
      id: "exposureBucket",
      title: "Less than or equal to",
      type: "dropdownSelect",
      options: exposureBuckets,
    },
  ].filter(Boolean) as ChartAction[];

export const getDefaultTopContributorsExposure = (
  pageKey: string,
  availableExposures: SelectOption[]
) => {
  const predefinedChartActions = localStorage.getItem(pageKey);
  if (!predefinedChartActions) {
    return NONE_EXPOSURE;
  }

  const availableExposureIds = availableExposures.map(
    ({ id }) => id
  ) as string[];

  try {
    const parsedChartActions = JSON.parse(
      predefinedChartActions
    ) as SelectedChartOptions[];
    if (parsedChartActions.length === 0) {
      return NONE_EXPOSURE;
    }

    const selectedExposure = parsedChartActions.find(
      ({ id }) => id === "exposure"
    );
    if (selectedExposure) {
      const exposureId = selectedExposure.optionId as string;

      return availableExposureIds.includes(exposureId)
        ? exposureId
        : NONE_EXPOSURE;
    }
  } catch (error) {
    console.error(
      `Could not parse local storage key '${pageKey}', value: ${predefinedChartActions}`
    );

    return NONE_EXPOSURE;
  }

  return NONE_EXPOSURE;
};

export const getDefaultTopContributorChartActions = (
  attributes: EntityAttribute[] | undefined,
  pageKey: string,
  yAxisOptions: SelectOption[],
  exposures: SelectOption[]
): ChartAction[] => {
  const defaultExposure = getDefaultTopContributorsExposure(pageKey, exposures);
  const defaultBuckets = mapByVehicleAgeExposureBuckets(
    attributes,
    defaultExposure
  );

  return getTopContributorsChartActions(
    yAxisOptions,
    exposures,
    defaultBuckets,
    defaultExposure
  );
};

export const getTopContributorsChartTitle = (
  actions: ChartAction[],
  selectedOptions: SelectedChartOptions[],
  exposure?: string,
  exposureBucket?: number
) => {
  const yAxisKey = getSelectedOptionId(selectedOptions, "y");
  const yAxisLabel = getAxisLabel(actions, "y", yAxisKey);
  const byVehicleAgeExposureValue = exposure
    ? getAxisValue(actions, "exposure", exposure)
    : "";
  const titleSuffix = exposure
    ? `by ${exposureBucket} ${byVehicleAgeExposureValue}`
    : "";

  return [yAxisLabel, titleSuffix].filter(Boolean).join(" ");
};

export const getTablePageKey = (pageKey: string) => `${pageKey}_table`;

export const getNavigatePropsForSignalEventAnalytics = (
  signalEventFilter: OccursFilterState,
  selectedSignalEvents: Set<string>,
  vehiclesFilters?: FilterGroupState
) => {
  const vehicleFilterKey = getFiltersKey(VEHICLES_PAGE_KEY);
  const signalEventsFilterKey = getFiltersKey(SIGNAL_EVENTS_PAGE_KEY);

  let seFilter = filterBuilderQueryToFilterBuilderState(
    signalEventFilter.filters
  );

  seFilter = updateOrAddRowFilterGroupState(seFilter, {
    attribute: "signalEventID",
    id: "signalEventID",
    type: "row",
    operator: FilterOperator.IN,
    values: Array.from(selectedSignalEvents),
  });

  return {
    pathname: routes.signalEventAnalytics,
    search: qs.stringify({
      [vehicleFilterKey]: getFiltersQuery(vehiclesFilters),
      [signalEventsFilterKey]: getFiltersQuery(seFilter),
    }),
  };
};

export const replaceURLWithHTMLLinks = (text: string, className?: string) => {
  const exp =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

  return text.replace(
    exp,
    className ? `<a class=${className} href='$1'>$1</a>` : `<a href='$1'>$1</a>`
  );
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);

    return true;
  } catch (err) {
    return false;
  }
};

export const mapByVehicleAgeExposureAttributes = (
  attributes: EntityAttribute[] | undefined
): SelectOption[] => {
  if (!attributes || attributes.length === 0) {
    return [MONTHS_IN_SERVICE_EXPOSURE];
  }

  return attributes
    ?.filter((attr) => attr.byVehicleAgeExposure)
    .map(
      ({ ID, displayName }) =>
        ({
          id: ID,
          value: displayName,
          testId: `top-contrib-exposure-${ID}`,
        }) as SelectOption
    )
    .sort(sortByValue);
};

// TODO: we shouldn't hardcode MONTHS_IN_SERVICE_EXPOSURE_BUCKET when no
// attributes are present as monthsInService attribute is not guaranteed to exist.
export const mapByVehicleAgeExposureBuckets = (
  attributes: EntityAttribute[] | undefined,
  byVehicleAgeExposure: string
): SelectOption[] => {
  if (!attributes || attributes.length === 0) {
    return [MONTHS_IN_SERVICE_EXPOSURE_BUCKET];
  }

  const ageExposureBuckets =
    attributes.find((attr) => attr.ID === byVehicleAgeExposure)
      ?.byVehicleAgeExposureBuckets || [];

  return ageExposureBuckets.map(
    (bucket) =>
      ({
        id: bucket,
        value: bucket,
        testId: `top-contrib-exposureBucket-${bucket}`,
      }) as SelectOption
  );
};
