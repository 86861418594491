import axios from "axios";
import {
  FILTER_PROMPT_CLAUSE,
  PAGE_AGENT_WORKFLOW_CLAUSE,
  SORT_PROMPT_CLAUSE,
} from "duck/graph/nodes/constants";
import { PromptTag, promptTags, tagMapping } from "duck/graph/nodes/types";
import { PageHandler } from "duck/graph/PageHandler";
import { PageHandlerRoute } from "duck/graph/PageHandler/types";
import { GraphStateType } from "duck/graph/state";
import {
  DuckGraphParams,
  PageState,
  StringSetter,
  UIHandlers,
} from "duck/graph/types";
import { getLLM, NodeNames, NodeNamesType } from "duck/graph/utils";
import { DuckAccess } from "duck/ui/types";
import { BindToolsInput } from "@langchain/core/language_models/chat_models";
import {
  AIMessage,
  BaseMessage,
  ToolMessage,
  ToolMessageFieldsWithToolCallId,
} from "@langchain/core/messages";
import { ChatPromptTemplate } from "@langchain/core/prompts";
import { Runnable, RunnableConfig } from "@langchain/core/runnables";
import { StructuredTool } from "@langchain/core/tools";
import { ToolNode } from "@langchain/langgraph/prebuilt";
import { ChatOpenAI, ChatOpenAICallOptions } from "@langchain/openai";

import client from "shared/api/axios";

import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import {
  MAX_WINDOW_SIZE,
  MIN_WINDOW_SIZE,
} from "features/ui/Filters/FilterTypes/OccursFilter/constants";
import {
  FilterOperator,
  OccursFilterState,
  OccursFilterWindowDirection,
  OccursFilterWindowDirectionType,
  signalEventsFilterOperators,
  SignalEventsFilterOperatorsType,
} from "features/ui/Filters/types";

import * as config from "config/config";

export type NodeOutputType = Partial<GraphStateType>;

export interface NodeType {
  (state: GraphStateType, config?: RunnableConfig): Promise<NodeOutputType>;
}

/**
 * @param messages The messages array. Accepting an array makes it easier for
 * the caller because they don't have to worry about whether the array is empty.
 * @return If the last message in the parameter array is an AIMessage with
 * non-empty tool_calls, then return a
 * ToolMessage based on the first tool call. Otherwise, return an empty array.
 * Returning an array like this makes it easy to destructure it to obtain the
 * ToolMessage if it exists without any conditional logic.
 */
export const createToolMessageFromAIMessageToolCall = (
  messages: BaseMessage[],
  content: string = "Success",
  status: ToolMessageFieldsWithToolCallId["status"] = "success"
): ToolMessage[] => {
  // Check if the last message is an AIMessage with non-empty tool calls
  const toolMessage: ToolMessage[] = [];

  if (messages.length === 0) {
    return toolMessage;
  }

  const message = messages[messages.length - 1];
  if (
    message instanceof AIMessage &&
    message.tool_calls &&
    message.tool_calls.length > 0
  ) {
    // Add a tool message to the messages array
    const toolCall = message.tool_calls[0];
    toolMessage.push(
      new ToolMessage({
        name: toolCall.name,
        content,
        tool_call_id: String(toolCall.id),
        status,
      })
    );
  }

  return toolMessage;
};

const prunePageState = (
  pageState: PageState,
  nodeName: keyof PageState
): Partial<PageState> => {
  const pageStateKeys: (keyof PageState)[] = Object.keys(
    pageState
  ) as (keyof PageState)[];
  const prunedState: Record<string, any> = pageStateKeys.reduce(
    (acc: Record<string, any>, key) => {
      if (typeof pageState[key] !== "object" || pageState[key] === null) {
        acc[key] = pageState[key];
      }

      return acc;
    },
    {}
  );

  if (pageState[nodeName]) {
    prunedState[nodeName] = pageState[nodeName];
  }

  return prunedState;
};

const prettifyAgentName = (rawName: NodeNamesType): string => {
  if (rawName === "vinView") {
    return "VIN View";
  }

  const words = rawName.split(/(?=[A-Z])/).map((word) => word.toLowerCase());

  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

interface InvokeAgentNodeParams {
  agent: Runnable;
  name: NodeNamesType;
  route?: PageHandlerRoute;
  pageHandler?: PageHandler;
  setAgentResponse?: UIHandlers["setAgentResponse"];
  setEphemeralMessage: StringSetter;
  duckAccess: DuckAccess;
  data?: any;
}

/**
 * Invokes an agent node with the provided state and configuration.
 *
 * @param agent - The agent to be invoked, which implements the Runnable interface.
 * @param name - Name to be assigned to the response message.
 * @param setEphemeralMessage - Function to set the ephemeral message in the UI.
 * @param setAgentResponse - Function to respond to the user in the UI.
 * @param pageHandler - Optional PageHandler instance to handle page navigation.
 * @param route - Optional route to navigate to when the agent is invoked.
 * @param data - Optional additional data to be passed to the agent.
 * @returns A function that takes the current graph state and configuration, and returns a promise resolving to the node output.
 *
 * @param state - The current state of the graph.
 * @param config - Optional configuration object for the agent invocation.
 * @returns A promise that resolves to the node output containing the updated messages array.
 */
export const invokeAgentNode =
  ({
    agent,
    data,
    name,
    setEphemeralMessage,
    setAgentResponse,
    duckAccess,
    pageHandler,
    route,
  }: InvokeAgentNodeParams): NodeType =>
  async (
    { messages, pageState }: GraphStateType,
    config: RunnableConfig = {}
  ): Promise<NodeOutputType> => {
    setEphemeralMessage(getEphemeralMessageForNode(name));

    console.debug(`[${new Date().toISOString()}] invokeAgentNode: ${name}`);

    if (route && pageHandler && pageState.pathname !== route) {
      // This lets the machinery in the useQueryStringNavigation hook know that we need to
      // navigate to the agent's page with a soft reload.
      pageHandler.navigateToPage();
      if (setAgentResponse) {
        const prettyName = prettifyAgentName(name);
        setAgentResponse(`- Navigate to the ${prettyName} page`, {
          triggerAgentPreludeMessage: true,
        });
      }
    }

    // `name` actually might not be a keyof PageState but if it is not,
    // it will be gracefully ignored. No problem.
    const currentPageState = prunePageState(pageState, name as keyof PageState);

    const agentMessage = await agent.invoke(
      {
        messages,
        current_state: JSON.stringify(currentPageState),
        page_agent_names: getPageAgentNames(duckAccess),
        filter_prompt_clause: FILTER_PROMPT_CLAUSE,
        sort_prompt_clause: SORT_PROMPT_CLAUSE,
        page_agent_workflow_clause: PAGE_AGENT_WORKFLOW_CLAUSE,
        ...data,
      },
      config
    );
    agentMessage.name = name;

    return {
      messages: agentMessage,
    };
  };

export interface GetAgentNodesParams {
  params: DuckGraphParams;
  tools: StructuredTool[];
  prompt: ChatPromptTemplate;
  name: NodeNamesType;
  route?: PageHandlerRoute;
}

export interface AgentNodes {
  node: NodeType;
  toolNode: ToolNode<GraphStateType>;
}

export const getAgentNodes = async ({
  params,
  tools,
  prompt,
  name,
  route,
}: GetAgentNodesParams): Promise<AgentNodes> => {
  const pageHandler = new PageHandler(route);

  const llm = getLLM();
  const agent = createStrictToolCallingAgent(llm, tools, prompt);

  return {
    node: invokeAgentNode({
      agent,
      pageHandler,
      route,
      name,
      setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
      setAgentResponse: params.uiHandlers.setAgentResponse,
      duckAccess: params.uiHandlers.duckAccess,
    }),
    toolNode: new ToolNode<GraphStateType>(tools, {
      handleToolErrors: true,
    }),
  };
};

/**
 * @summary Create and return the agent responsible for processing the utterance.
 * @param llm The LLM agent that processes the utterance
 * @param tools The tools available to the LLM
 * @param prompt The prompt to send to the LLM
 * @param toolsArgs The arguments to pass to the tools
 * @returns The agent responsible for processing the utterance.
 */
export const createAgent = (
  llm: ChatOpenAI<ChatOpenAICallOptions>,
  tools: BindToolsInput[],
  prompt: ChatPromptTemplate,
  toolsArgs?: Record<string, any>
): Runnable => prompt.pipe(llm.bindTools(tools, toolsArgs));

/**
 * @summary Create and return the agent that strictly calls tools. The Agent calls the end tool when finished.
 * @param llm The LLM agent that processes the utterance
 * @param tools The tools available to the LLM
 * @param prompt The prompt to send to the LLM
 * @returns The runnable agent responsible for rejecting or clarifying the user's utterance.
 */
export const createStrictToolCallingAgent = (
  llm: ChatOpenAI<ChatOpenAICallOptions>,
  tools: BindToolsInput[],
  prompt: ChatPromptTemplate,
  parallelToolCalls: boolean = true
): Runnable =>
  createAgent(llm, tools, prompt, {
    strict: true,
    tool_choice: "required",
    parallel_tool_calls: parallelToolCalls,
  });

// Define a generic type for the filter request
type FilterRequest = Record<string, any>;

/**
 * Validate parameters that will be sent to the API by making an actual
 * API request with them.
 * If they are invalid, the API will respond with a 400 status code,
 * which we consider to be an error.
 * We use the parameterType parameter to provide more descriptive error
 * messages to the agent, which should help the agent respond more effectively.
 */
export const validateApiRequest = async <T extends FilterRequest>(
  params: T,
  getRequestURI: (params: T) => string,
  parameterType: string = "filter"
): Promise<void> => {
  const url = getRequestURI(params);
  try {
    const validationResponse = await client.get(url);
    if (validationResponse.status < 200 || validationResponse.status >= 300) {
      console.error(
        `Invalid ${parameterType} parameter validation status code`,
        validationResponse
      );
      throw new Error(
        `The ${parameterType} parameter of "${JSON.stringify(params)}" is invalid.
The API server responded with a status of "${validationResponse.status}".
The message from the API server is: "${validationResponse.data}".
Please try a different ${parameterType}.`
      );
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.error("Invalid filter parameters", error.response);
      throw new Error(
        `The ${parameterType} parameter of "${JSON.stringify(params)}" is invalid.
The API server responded with a status of "${error.response.status}".
The error message from the API server is: "${JSON.stringify(error.response.data)}".
Please try a different ${parameterType}.`
      );
    } else {
      // This error did not originate from Axios. It is a mystery, so just rethrow it.
      throw error;
    }
  }
};

/**
 * @param windowSize The number of days in the signal event window.
 * @throws If the window size is invalid, a descriptive error will be thrown.
 */
export const validateSignalEventOccurrencesWindowSize = (
  windowSize: number
): void => {
  if (windowSize < MIN_WINDOW_SIZE || windowSize > MAX_WINDOW_SIZE) {
    throw new Error(
      `The window size for signal event occurrences must be between ${MIN_WINDOW_SIZE} and ${MAX_WINDOW_SIZE} days. The value of ${windowSize} is not valid.`
    );
  }
};

/**
 * Validate the signal event filter operator and values.
 *
 * @param operator The operator to filter the signal event IDs.
 * @param values The list of values to filter the signal event IDs.
 * @throws If the operator or values are invalid, an error will be thrown.
 * @returns The operator and values, if they are valid.
 */
export const validateSignalEventFilterOperatorValues = (
  operator: SignalEventsFilterOperatorsType,
  values: string[]
) => {
  if (!signalEventsFilterOperators.includes(operator)) {
    throw new Error(
      `The signal event filter operator must be one of ${signalEventsFilterOperators.join(
        ", "
      )}. The value of ${operator} is not valid.`
    );
  }

  if (
    operator === FilterOperator.NOT_FILTERED ||
    operator === FilterOperator.IS_NOT_EMPTY
  ) {
    // we can automatically ignore values
    return { operator, values: ["null"] };
  }

  // Ensure all values are non-empty strings
  const sanitizedValues = values.map((value) => {
    if (value.trim() === "") {
      throw new Error(
        `The signal event filter values must be non-empty strings. The value of ${JSON.stringify(values)} is not valid.`
      );
    }

    return value.trim();
  });

  return { operator, values: sanitizedValues };
};

/**
 * @param operator
 * @param values
 * @param windowSize
 * @param windowDirection
 * @returns An object that can be used to filter signal events.
 */
export const createRelatedSignalEventFilter = (
  operator: FilterOperator,
  values: string[],
  windowSize: number | undefined,
  windowDirection?: OccursFilterWindowDirectionType
): OccursFilterState => ({
  windowSize: windowSize ?? 30,
  windowDirection: windowDirection ?? OccursFilterWindowDirection.BEFORE,
  windowType: "days",
  filters: getFiltersQuery({
    id: "group-0",
    type: "group",
    anyAll: "all",
    children: [
      {
        id: "row-0",
        type: "row",
        attribute: "signalEventID",
        operator,
        values,
      },
    ],
  }),
});

/**
 * @returns A random tool call id that complies with the langgraph standards.
 * This is generally intended to be unique but does not need to be universally unique.
 */
export const generateToolCallId = (): string => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

  return `call_${Array.from(
    { length: 24 },
    () => characters[Math.floor(Math.random() * characters.length)]
  ).join("")}`;
};

const ephemeralMessages: Partial<Record<NodeNamesType, string>> = {
  [NodeNames.ROUTER]: "routing",
  [NodeNames.RAG]: "analyzing documents",
  [NodeNames.GREETING_REJECT_CLARIFY]: "formulating response",
  [NodeNames.CLAIM_ANALYTICS]: "queuing claim analytics actions",
  [NodeNames.SIGNAL_EVENT_ANALYTICS]: "queuing signal event analytics actions",
  [NodeNames.VIN_VIEW]: "queuing vin view actions",
  [NodeNames.VEHICLES]: "queuing vehicles actions",
  [NodeNames.ISSUES]: "queuing issues actions",
  [NodeNames.ISSUE_DETAILS]: "queuing issue details actions",
  [NodeNames.KNIGHT_SWIFT_VIN_VIEW]: "queuing vin view actions",
  [NodeNames.RESPOND_TO_USER]: "sending response",
  [NodeNames.ANALYZE_SCREENSHOT]: "analyzing screenshot",
  [NodeNames.SUBMIT_FEEDBACK]: "submitting feedback",
  [NodeNames.RETRIEVE_INFO]: "retrieving information",
  [NodeNames.SEARCH_CODES_BY_DESCRIPTION]: "searching codes by description",
};

export const getEphemeralMessageForNode = (nodeName: NodeNamesType): string =>
  ephemeralMessages[nodeName] ?? nodeName;

/**
 * @returns The list of page agent names that are active for this tenant.
 */
export const getPageAgentNames = (duckAccess: DuckAccess): string[] =>
  [
    duckAccess.claimAnalytics.enabled && "**Claim Analytics Expert** Agent",
    duckAccess.signalEventAnalytics.enabled &&
      "**Signal Event Analytics Expert** Agent",
    duckAccess.vehicles.enabled && "**Vehicles Expert** Agent",
    duckAccess.vinView.enabled && "**VIN View Expert** Agent",
    duckAccess.issues.enabled && "**Issues Expert** Agent",
    duckAccess.issueDetails.enabled && "**Issue Details Expert** Agent",
  ]
    .filter(Boolean)
    .map((name) => String(name));

const getDefaultTagName = (): string => {
  if (process.env.NODE_ENV === "production") {
    if (config.get().instance === "dev") {
      // Special treatment for the "dev" instance.
      // We want to be able to test the `development` prompts in the "dev" instance.
      return promptTags.DEV;
    }

    return tagMapping.production ?? promptTags.PROD;
  }

  return tagMapping[process.env.NODE_ENV] ?? promptTags.DEV;
};
/**
 * @param tag The tag to use
 * @returns The tag suffix to append to the prompt name.
 */
export const getTagSuffix = (tag?: PromptTag): string => {
  if (tag === promptTags.LATEST) {
    return "";
  }

  const tagToUse = tag ?? getDefaultTagName();

  return `:${tagToUse}`;
};

export const validateSortOrder = (sortOrder: string): void => {
  if (!["asc", "desc"].includes(sortOrder)) {
    throw new Error(
      `The sort order must be one of "asc" or "desc". The value of ${sortOrder} is not valid.`
    );
  }
};

export const parseSortKeySuffix = (sortKeySuffix: string): string => {
  // Remove enclosing brackets if they exist
  const fieldName = sortKeySuffix.replace(/^\[|\]$/g, "");

  if (fieldName === sortKeySuffix) {
    throw new Error(
      `The name of the attribute to use for sorting must be enclosed in brackets ("[" and "]").
The value of ${sortKeySuffix} is not valid. Please add enclosing brackets.`
    );
  }

  if (!fieldName) {
    throw new Error(
      `The name of the attribute to use for sorting must not be empty.
The value of ${sortKeySuffix} is not valid.`
    );
  }

  return fieldName;
};

/**
 * Get the args for the routing tool call corresponding to the current agent.
 * @param messages
 * @returns The args
 */
export const getToolArgs = (messages: BaseMessage[]): Record<string, any> => {
  if (messages.length === 0) {
    throw new Error("Invalid state: there are no messages.");
  }

  const lastMessage = messages[messages.length - 1];
  if (
    !(lastMessage instanceof AIMessage) ||
    !lastMessage.tool_calls ||
    lastMessage.tool_calls.length === 0
  ) {
    // this should never happen but just in case
    throw new Error(
      `Invalid state: we arrived in the agent without calling the corresponding routing tool. The last message was ${JSON.stringify(lastMessage)}`
    );
  }

  const toolCall = lastMessage.tool_calls[0];

  return toolCall.args;
};

/**
 * This function is used to filter `false` values from tools arrays that are
 * built with feature flag and config gates and could contain `false` values.
 * This is functionally equivalent to the Boolean() function, but it lets the
 * Typescript engine correctly infer that the type of the filtered array is
 * StructuredTool[].
 * @param potentialTool A potential tool, that might also be `false`.
 * @returns True if the potential tool is a StructuredTool, which is also an
 * indicator that the type of the parameter is StructuredTool.
 */
export const isStructuredTool = (
  potentialTool: StructuredTool | false
): potentialTool is StructuredTool => potentialTool !== false;
