import { AlertEventType } from "shared/api/alertDefinitions/api";
import { useConfigContext } from "shared/contexts/ConfigContext";

import BackToIssueBreadcrumb from "pages/Issues/BackToIssueBreadcrumb";
import {
  ISSUE_ID_QUERY_KEY,
  ISSUE_IS_SUGGESTED_QUERY_KEY,
  ISSUE_NAME_QUERY_KEY,
  ISSUE_RUN_DATE_QUERY_KEY,
} from "pages/Issues/constants";
import WatchlistCreateAlertAction from "pages/LandingPage/Watchlist/WatchlistCreateAlertAction";

import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { filterBuilderQueryToFilterBuilderState } from "features/ui/Filters/FilterBuilder/utils";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { getChartSettingsKey, getFiltersKey } from "features/ui/Filters/utils";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Title from "features/ui/Title";

import BackToSingleClaimBreadCrumb from "./BackToSingleClaimBreadCrumb";
import ClaimAnalyticsFilters from "./ClaimAnalyticsFilters";
import ClaimAnalyticsTabs from "./ClaimAnalyticsTabs";
import ConfirmationModalIssueUpdate from "./ConfirmationModalIssueUpdate";
import {
  CLAIMS_PAGE_KEY,
  CLAIMS_TAB_KEY,
  PAGE_TITLE,
  VEHICLES_PAGE_KEY,
} from "./constants";

const QUERY_PARAMS_TO_PERSIST_THROUGH_TABS = [
  CLAIMS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
].reduce(
  (soFar, key) => [...soFar, getFiltersKey(key), getChartSettingsKey(key)],
  [
    ISSUE_ID_QUERY_KEY,
    ISSUE_NAME_QUERY_KEY,
    ISSUE_IS_SUGGESTED_QUERY_KEY,
    ISSUE_RUN_DATE_QUERY_KEY,
  ]
);

const ClaimAnalytics = () => {
  const { pages } = useConfigContext();

  const defaultClaimFilters =
    filterBuilderQueryToFilterBuilderState(
      pages.claimAnalytics?.defaultFilters
    ) || DEFAULT_FILTER_BUILDER_STATE;

  const claimsFiltersFilterSortState = useFilterSortState({
    pageKey: CLAIMS_PAGE_KEY,
    defaultFilterValues: defaultClaimFilters,
    pendingFiltersLocalStorageKey: getPendingFiltersKey(CLAIMS_PAGE_KEY),
    defaultTab: CLAIMS_TAB_KEY,
  });

  const defaultVehicleFilters =
    filterBuilderQueryToFilterBuilderState(
      pages.claimAnalytics?.defaultVehicleFilters
    ) || DEFAULT_FILTER_BUILDER_STATE;

  const vehiclePopulationFiltersFilterSortState = useFilterSortState({
    pageKey: VEHICLES_PAGE_KEY,
    defaultFilterValues: defaultVehicleFilters,
    pendingFiltersLocalStorageKey: getPendingFiltersKey(VEHICLES_PAGE_KEY),
    defaultTab: CLAIMS_TAB_KEY,
  });

  const resetState = () => {
    claimsFiltersFilterSortState?.resetFilterSortState();
    vehiclePopulationFiltersFilterSortState?.resetFilterSortState();
  };

  return (
    <>
      <ConfirmationModalIssueUpdate
        page="claimAnalytics"
        vehicleFilters={vehiclePopulationFiltersFilterSortState.filters}
        claimFilters={claimsFiltersFilterSortState.filters}
      />
      <PageHeaderWrapper>
        <div className="flex flex-col">
          <BackToIssueBreadcrumb />
          <BackToSingleClaimBreadCrumb />
          <Title text={PAGE_TITLE} />
        </div>
        <PageHeaderActionsWrapper>
          <WatchlistCreateAlertAction
            eventType={AlertEventType.CLAIM}
            eventFilter={claimsFiltersFilterSortState.filters}
          />
        </PageHeaderActionsWrapper>
      </PageHeaderWrapper>
      <ClaimAnalyticsFilters
        claimsFilterSortState={claimsFiltersFilterSortState}
        vehiclesFilterSortState={vehiclePopulationFiltersFilterSortState}
        defaultClaimFilters={defaultClaimFilters}
        defaultVehicleFilters={defaultVehicleFilters}
      />
      <ClaimAnalyticsTabs
        claimsFiltersFilterSortState={claimsFiltersFilterSortState}
        vehiclesFiltersFilterSortState={vehiclePopulationFiltersFilterSortState}
        queryParamsToPersist={QUERY_PARAMS_TO_PERSIST_THROUGH_TABS}
        onBadRequest={resetState}
      />
    </>
  );
};

export default ClaimAnalytics;
