import { MileageUnit } from "./types";

export const MAX_ROWS_DOWNLOAD_LIMIT = 1000000;
export const MAX_TOP_CONTRIBUTORS_ROWS_DOWNLOAD_LIMIT = 2000;
export const MAX_SIGNAL_EVENT_OCCURRENCES_DOWNLOAD_LIMIT = 5000;
export const ISSUES_ROUTE = "issues";
export const SUGGESTED_ISSUES_ROUTE = "suggestedIssues";
export const SUGGESTED_ISSUE_RUNS_ROUTE = "suggestedIssueRuns";
export const ORDERED_VALUES_ROUTE = "orderedValues";
export const DEFAULT_MILEAGE_UNIT: MileageUnit = "mi";
export const NO_DATA = <h3 className="text-gray-400 text-sm">No data.</h3>;
export const NO_BY_PRODUCTION_DATE_ATTRIBUTES_CONFIGURED = (
  <div className="py-4 text-gray-400 text-sm">
    No vehicle attributes configured for Production Date.
  </div>
);
export const NONE_EXPOSURE = "None";
export const DEFAULT_GRANULARITY = "month";
export const DEFAULT_EXPOSURE = "monthsInService";
export const DEFAULT_BY_VEHICLE_AGE = "vehicleManufacturedAt";
export const DEFAULT_EXPOSURE_VALUE = "Months In Service";
export const DEFAULT_EXPOSURE_BUCKET = 36;
export const BOOKMARKS_ROUTE = "bookmarks";
export const RECENT_ITEMS_ROUTE = "recentItems";
export const NOTIFICATIONS_ROUTE = "notifications";

export const TABLE_STATE_LOCAL_STORAGE_PREFIX = "filterSortState";
// This bumps ALL our localStorage & URL keys, so we can invalidate all of them at once.
// USE SPARINGLY!
export const GLOBAL_PAGE_KEY_PREFIX = "v1";
export const DEFAULT_SERVICE_RECORD_TEXT = "Service Record";
export const OPENING_BRACKETS = ["(", "{", "["];
export const CLOSING_BRACKETS = [")", "}", "]"];
export const LONG_DATE_FORMAT = "LLLL d, yyyy";
export const SHORT_DATE_FORMAT = "MM/dd/yyyy";
export const SHORTER_DATE_FORMAT = "M/d/yyyy";
export const LOCALIZED_DATETIME_FORMAT = "Pp";
export const API_DATE_FORMAT = "yyyy-MM-dd";
export const DATE_WITH_TIME_FORMAT = "MM/dd/yyyy H:mm:ss a";
export const API_DATE_FORMAT_W_TIME = "yyyy-MM-dd'T'HH:mm:ss";
export const ISO_FORMAT = "ISO_FORMAT";
export const RFC3339_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXX";
export const MONTH_YEAR_SHORT = "MMM yy";
export const MONTH_YEAR = "MMM yyyy";
export const MONTH_YEAR_FULL = "MMMM yyyy";
export const MONTH_YEAR_DAY = "MMM, d yyyy";
export const TIME_AGO_THRESHOLD_HOURS = 12;
export const SENSORS_LIMIT = 1000;

export const TAB_QUERY_PARAM = "tab";
