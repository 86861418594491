import { isStructuredTool } from "duck/graph/nodes/utils";
import getGoToTabTool from "duck/graph/nodes/VinViewAgent/tools/getGoToTabTool";
import getGoToVinViewPageTool from "duck/graph/nodes/VinViewAgent/tools/getGoToVinViewPageTool";
import { PageHandler } from "duck/graph/PageHandler";
import { DuckGraphParams } from "duck/graph/types";
import { StructuredTool } from "@langchain/core/tools";

import {
  VIN_VIEW_ECUS_TAB_KEY,
  VIN_VIEW_ECUS_TAB_TITLE,
  VIN_VIEW_OPTIONS_TAB_KEY,
  VIN_VIEW_OPTIONS_TAB_TITLE,
  VIN_VIEW_RISK_MODEL_RESULTS_TAB_KEY,
  VIN_VIEW_RISK_MODEL_RESULTS_TAB_TITLE,
  VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_KEY,
  VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_TITLE,
  VIN_VIEW_SERVICE_RECORDS_TAB_KEY,
  VIN_VIEW_SERVICE_SCHEDULE_TAB_KEY,
  VIN_VIEW_SERVICE_SCHEDULE_TAB_TITLE,
} from "pages/VINView/constants";

import getGoToTimelineTabTool from "./goToTimelineTab/getGoToTimelineTabTool";

/**
 * @summary Get the tools for the vin view agent.
 * The tools are specific to vin view, and are bound to the UI handlers
 * for vin view.
 * @param params The data from the UI layer that is necessary for the tools to function.
 * @returns The full set of tools for the signal event analytics agent.
 */
export const getRetrievalTools = (params: DuckGraphParams) => []; // No retrieval tools currently

export const getActionTools = (
  params: DuckGraphParams,
  pageHandler: PageHandler
): StructuredTool[] => {
  const duckAccess = params.uiHandlers.duckAccess;
  const vinViewTabs = params.availableData.vinView?.vinViewTabs ?? {};

  return [
    getGoToVinViewPageTool(pageHandler, params.uiHandlers.setAgentResponse),
    duckAccess.vinView.healthTabRiskModel &&
      getGoToTabTool(
        pageHandler,
        params.uiHandlers.setEphemeralMessage,
        params.uiHandlers.setAgentResponse,
        VIN_VIEW_RISK_MODEL_RESULTS_TAB_KEY,
        VIN_VIEW_RISK_MODEL_RESULTS_TAB_TITLE,
        "Navigate to the Health tab to view failure mode profiles for the selected vehicle"
      ),
    duckAccess.vinView.serviceRecordsTab &&
      getGoToTabTool(
        pageHandler,
        params.uiHandlers.setEphemeralMessage,
        params.uiHandlers.setAgentResponse,
        VIN_VIEW_SERVICE_RECORDS_TAB_KEY,
        vinViewTabs[VIN_VIEW_SERVICE_RECORDS_TAB_KEY],
        "Navigate to the Service Records tab to view service history or maintenance records for the selected vehicle."
      ),
    duckAccess.vinView.serviceScheduleTab &&
      getGoToTabTool(
        pageHandler,
        params.uiHandlers.setEphemeralMessage,
        params.uiHandlers.setAgentResponse,
        VIN_VIEW_SERVICE_SCHEDULE_TAB_KEY,
        VIN_VIEW_SERVICE_SCHEDULE_TAB_TITLE,
        "Navigate to the Service Schedule tab to view service schedule details for the selected vehicle."
      ),
    duckAccess.vinView.serviceRecommendationsTab &&
      getGoToTabTool(
        pageHandler,
        params.uiHandlers.setEphemeralMessage,
        params.uiHandlers.setAgentResponse,
        VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_KEY,
        VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_TITLE,
        "Navigate to the Service Recommendations tab to view recommended maintenance or service actions for the selected vehicle."
      ),
    duckAccess.vinView.timelineTab &&
      getGoToTimelineTabTool(
        pageHandler,
        params.uiHandlers.setAgentResponse,
        params.availableData.vinView?.sensorOptions,
        params.availableData.vinView?.timelineChartOptions,
        params.currentState.vinView?.selectedTimelineChartOptions,
        params.currentState.vinView?.selectedSensorsAndTriggers,
        params.currentState.vinView?.selectedDateRange,
        params.currentState.vinView?.selectedSignalEventFilters
      ),
    duckAccess.vinView.ecusTab &&
      getGoToTabTool(
        pageHandler,
        params.uiHandlers.setEphemeralMessage,
        params.uiHandlers.setAgentResponse,
        VIN_VIEW_ECUS_TAB_KEY,
        VIN_VIEW_ECUS_TAB_TITLE,
        "Navigate to the ECUs tab to view information about the electronic control units for the selected vehicle, including their serial numbers, hardware, software, and calibration."
      ),
    duckAccess.vinView.optionsTab &&
      getGoToTabTool(
        pageHandler,
        params.uiHandlers.setEphemeralMessage,
        params.uiHandlers.setAgentResponse,
        VIN_VIEW_OPTIONS_TAB_KEY,
        VIN_VIEW_OPTIONS_TAB_TITLE,
        "Navigate to the options tab to view the options available for the selected vehicle."
      ),
  ].filter(isStructuredTool);
};
