import { useState } from "react";
import { CgSpinnerTwo as LoadingIcon } from "react-icons/cg";
import { generatePath, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { Alert } from "@mui/material";

import {
  CustomSignalEventDefinition,
  deleteCustomSignalEventDefinition,
  newCustomSignalEventDefinition,
  updateCustomSignalEventDefinition,
} from "shared/api/customSignalEvents/api";
import { useTenantMileageUnit } from "shared/hooks";

import Button from "features/ui/Button";
import DeleteAction from "features/ui/DeleteAction";
import Input from "features/ui/Input";

import { routes } from "services/routes";

import CopyCustomSignalEventAction from "./Actions/CopyCustomSignalEventAction";
import PublishSignalEventAction from "./Actions/PublishCustomSignalEventAction";
import { CANCEL_CSE, ERROR_STATUSES, SAVE_CSE, UPDATE_CSE } from "./constants";
import StatusSection from "./StatusSection";
import {
  getCreateCustomSignalEventRequestBody,
  getCustomSignalEventState,
  getIdFromName,
  isInitialCustomSEDefinitionState,
  setInitialCustomSEDefinitionState,
  SignalEventStudioState,
} from "./utils";

interface Props {
  signalEventStudioState: SignalEventStudioState;
  customSignalEventDefinition?: CustomSignalEventDefinition;
  requestKey?: string;
}

const CustomSignalEventData = ({
  signalEventStudioState,
  customSignalEventDefinition,
  requestKey,
}: Props) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    id,
    setId,
    isIdSet,
    setIsIdSet,
    name,
    setName,
    description,
    setDescription,
    selectedVIN,
    selectedEventTypeOption,
    filterBuilderState,
    customAttributeFilterBuilderState,
    customAttributes,
    selectedDownsamplingOption,
    editMode,
    isValidForm,
  } = signalEventStudioState;
  const ctaIcon =
    (isSubmitting && <LoadingIcon className="animate-spin" />) || undefined;
  // if the user sets its own id, we do not change it automatically unless they clear it
  const handleIdSet = (value: string) => {
    setIsIdSet(value !== "");
    setId(value);
  };

  const handleNameSet = (value: string) => {
    if (value !== "" && !isIdSet) {
      setId(getIdFromName(value));
    }

    // unset ID only if custom signal event definition is not saved yet
    if (value === "" && !customSignalEventDefinition) {
      setId("");
      setIsIdSet(false);
    }

    setName(value);
  };

  const {
    actionsState: {
      canEditID,
      canEditDefinition,
      canPublish,
      canDelete,
      canCancel,
    },
  } = getCustomSignalEventState(customSignalEventDefinition?.status, editMode);

  const definitionEdited = !isInitialCustomSEDefinitionState(
    signalEventStudioState,
    customSignalEventDefinition
  );

  const disableCancel =
    isSubmitting ||
    !canCancel ||
    (customSignalEventDefinition && !definitionEdited);

  const idAndNameSet = id && name;

  const disableSubmit =
    !idAndNameSet ||
    isSubmitting ||
    !isValidForm ||
    !canEditDefinition ||
    !definitionEdited;

  const disablePublish =
    !idAndNameSet || isSubmitting || !isValidForm || !canPublish;

  const handleOnCancel = () => {
    if (customSignalEventDefinition) {
      // edit page
      setIsSubmitting(true);
      setInitialCustomSEDefinitionState(
        signalEventStudioState,
        customSignalEventDefinition
      );
      setIsSubmitting(false);
    } else {
      // create page
      navigate(routes.customSignalEvents);
    }
  };

  const mileageUnit = useTenantMileageUnit();

  const handleOnSubmit = () => {
    if (disableSubmit) {
      return;
    }

    setIsSubmitting(true);

    const callback = customSignalEventDefinition
      ? updateCustomSignalEventDefinition
      : newCustomSignalEventDefinition;

    const requestBody = getCreateCustomSignalEventRequestBody(
      id,
      name,
      description,
      selectedVIN!,
      selectedEventTypeOption,
      filterBuilderState,
      customAttributeFilterBuilderState,
      customAttributes,
      selectedDownsamplingOption,
      mileageUnit
    );

    // unset error status if set during update
    const body = customSignalEventDefinition
      ? {
          ...requestBody,
          status: ERROR_STATUSES.includes(customSignalEventDefinition.status)
            ? "ready_for_validation"
            : customSignalEventDefinition.status,
        }
      : requestBody;

    callback(body)
      .then(({ data: { ID } }) => {
        toast.success(
          `Custom Signal Event ${customSignalEventDefinition ? "updated" : "created"}`
        );
        requestKey && mutate(requestKey);
        navigate(generatePath(routes.customSignalEvent, { id: ID }));
      })
      .catch(() => {
        toast.error(
          `${customSignalEventDefinition ? "Updating" : "Creating"} Custom Signal Event failed`
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="w-[65rem] mt-5">
      <div className="mb-5 flex">
        <div className="flex w-full">
          <div className="mr-4 leading-9 text-right w-[82px]">Name</div>
          <Input
            testId="cse-name-input"
            value={name}
            onChange={({ target: { value } }) => handleNameSet(value)}
            disabled={!canEditDefinition}
            fullWidth={false}
            className="w-80"
            tabIndex={0}
            characterLimit={50}
          />
          <div className="flex items-center ml-auto">
            <div className="flex mr-4 ml-auto">
              {customSignalEventDefinition && (
                <CopyCustomSignalEventAction
                  customSignalEventDefinition={customSignalEventDefinition}
                />
              )}
              <DeleteAction
                data={{
                  ID: customSignalEventDefinition
                    ? customSignalEventDefinition.ID
                    : "",
                  name,
                }}
                entityName="custom signal event"
                deleteCallback={deleteCustomSignalEventDefinition}
                iconOnly={true}
                onSuccessNavigateToRoute={routes.customSignalEvents}
                onSuccessDeleteText="Succesfully scheduled for deletion"
                disabled={!canDelete}
              />
            </div>
            <Button
              color="secondary"
              variant="outlined"
              label={CANCEL_CSE}
              onClick={handleOnCancel}
              isLoading={isSubmitting}
              endIcon={ctaIcon}
              disabled={disableCancel}
              size="medium"
              className="!mr-4"
              tabIndex={-1}
            />
            <Button
              color="primary"
              variant="contained"
              label={customSignalEventDefinition ? UPDATE_CSE : SAVE_CSE}
              onClick={handleOnSubmit}
              isLoading={isSubmitting}
              endIcon={ctaIcon}
              disabled={disableSubmit}
              size="medium"
              className="!mr-4"
              tabIndex={-1}
            />
            <PublishSignalEventAction
              data={customSignalEventDefinition}
              entityRequestKey={requestKey}
              disablePublish={disablePublish}
              setIsSubmitting={setIsSubmitting}
            />
          </div>
        </div>
      </div>
      <div className="mb-5 flex">
        <div className="mr-4 leading-9 text-right shrink-0 w-[82px]">ID</div>
        <div className="w-full">
          <Input
            testId="cse-id-input"
            value={id}
            onChange={({ target: { value } }) => handleIdSet(value)}
            disabled={!canEditID}
            fullWidth={false}
            className="w-80"
            tabIndex={0}
            characterLimit={30}
          />
        </div>
      </div>
      <div className="mb-5 flex">
        <div className="mr-4 leading-9 text-right shrink-0 w-[82px]">
          Description
        </div>
        <div className="w-full">
          <Input
            value={description || ""}
            onChange={({ target: { value } }) => setDescription(value)}
            disabled={!canEditDefinition}
            tabIndex={1}
            characterLimit={300}
          />
        </div>
      </div>
      {customSignalEventDefinition && (
        <div className="mb-5 flex">
          <div className="mr-4 leading-9 text-right shrink-0 w-[82px]">
            Status
          </div>
          <div className="w-full">
            <StatusSection
              customSignalEventDefinition={customSignalEventDefinition}
            />
          </div>
        </div>
      )}
      {customSignalEventDefinition &&
        ERROR_STATUSES.includes(customSignalEventDefinition.status) && (
          <div className="mb-5 w-full">
            <Alert
              color="error"
              severity="error"
              data-testid="error-message"
              className=""
            >
              <div className="font-bold mb-1">
                {customSignalEventDefinition.status === "invalid" &&
                  "An error occurred while validating definition"}
                {customSignalEventDefinition.status ===
                  "error_during_backfill" &&
                  "An error occurred during backfill process"}
              </div>
            </Alert>
          </div>
        )}
      <div className="text-gray-600 text-sm mb-4 max-w-4xl">
        Your custom signal event can be based on any event type. It is defined
        by the combination of filters on base event type attributes and filters
        on derived attributes you create. You can see how your custom signal
        event performs by viewing it in a sample of vehicles.
      </div>
    </div>
  );
};

export default CustomSignalEventData;
