import { APIPaginatedRequest, patchFetcher, postFetcher } from "shared/api/api";
import client from "shared/api/axios";
import { createURL } from "shared/api/utils";
import { BOOKMARKS_ROUTE } from "shared/constants";

export interface Bookmark {
  ID: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  description?: string;
  path: string;
}

export interface ListBookmarksRequest extends APIPaginatedRequest {
  refreshKey: boolean;
}
export interface DeleteBookmarkRequest {
  id: string;
}
export interface UpdateBookmarkRequest {
  ID: string;
  title: string;
  description?: string;
  path: string;
}

export interface CreateBookmarkRequest {
  title: string;
  description?: string;
  path: string;
}

export const listBookmarksRequestURI = (params: ListBookmarksRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([BOOKMARKS_ROUTE]),
    params,
  });

const createBookmarkRequestURI = (params: CreateBookmarkRequest): string =>
  client.getUri({
    method: "POST",
    url: createURL([BOOKMARKS_ROUTE]),
    params,
  });

export const createBookmark = (args: CreateBookmarkRequest) =>
  postFetcher<Bookmark>(createBookmarkRequestURI(args), args);

const updateBookmarkRequestURI = ({
  ID,
  ...params
}: UpdateBookmarkRequest): string =>
  client.getUri({
    method: "PATCH",
    url: createURL([BOOKMARKS_ROUTE, ID]),
    params,
  });

export const updateBookmark = (args: UpdateBookmarkRequest) =>
  patchFetcher<Bookmark>(updateBookmarkRequestURI(args), args);

const deleteBookmarkRequestURI = ({ id }: DeleteBookmarkRequest): string =>
  client.getUri({
    method: "DELETE",
    url: createURL([BOOKMARKS_ROUTE, id]),
  });

export const deleteBookmark = (args: DeleteBookmarkRequest) =>
  client.delete(deleteBookmarkRequestURI(args));
