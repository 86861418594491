import { useState } from "react";

import {
  useInspectionsFiltersSchema,
  useVehiclesFiltersSchema,
} from "shared/hooks";

import { PageConfigProps } from "pages/Admin/PagesConfig/types";
import type { InspectionAnalyticsConfig } from "pages/Admin/PagesConfig/types";

import DropdownSelect from "features/ui/DropdownSelect";
import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterBuilderQueryToFilterBuilderState,
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { getFilterLabel } from "features/ui/Filters/utils";

const INSPECTION_PAGE_KEY = "admin_insAn_default_inspection_filters";
const VEHICLES_PAGE_KEY = "admin_insAn_default_vehicle_filters";

export const DEFAULT_FILTERS_KEY = "defaultFilters";
export const DEFAULT_VEHICLE_FILTERS_KEY = "defaultVehicleFilters";

const InspectionAnalyticsConfigPage = ({
  pageId,
  config,
  onConfigChange,
}: PageConfigProps<InspectionAnalyticsConfig>) => {
  const inspectionsFiltersSchema = useInspectionsFiltersSchema();
  const vehiclesFiltersSchema = useVehiclesFiltersSchema();

  const initialInspectionFilters = filterBuilderQueryToFilterBuilderState(
    config[DEFAULT_FILTERS_KEY]
  );

  const initialVehicleFilters = filterBuilderQueryToFilterBuilderState(
    config[DEFAULT_VEHICLE_FILTERS_KEY]
  );

  const defaultInspectionFilters = filterStateToFilterGroupState(
    initialInspectionFilters
  );

  const defaultVehicleFilters = filterStateToFilterGroupState(
    initialVehicleFilters
  );

  const [inspectionsDropdownOpen, setInspectionsDropdownOpen] = useState(false);
  const [vehiclesDropdownOpen, setVehiclesDropdownOpen] = useState(false);

  const inspectionsFilterState = useFilterSortState({
    pageKey: INSPECTION_PAGE_KEY,
    defaultFilterValues:
      defaultInspectionFilters ?? DEFAULT_FILTER_BUILDER_STATE,
    disableUsingLocalStorage: true,
    disableUsingQuery: true,
  });

  const vehiclesFilterState = useFilterSortState({
    pageKey: VEHICLES_PAGE_KEY,
    defaultFilterValues: defaultVehicleFilters ?? DEFAULT_FILTER_BUILDER_STATE,
    disableUsingLocalStorage: true,
    disableUsingQuery: true,
  });

  const handleInspectionsChange = (filterState: FilterGroupState) => {
    onConfigChange(pageId, DEFAULT_FILTERS_KEY, getFiltersQuery(filterState));
  };

  const handleVehiclesChange = (filterState: FilterGroupState) => {
    onConfigChange(
      pageId,
      DEFAULT_VEHICLE_FILTERS_KEY,
      getFiltersQuery(filterState)
    );
  };

  return (
    <div className="flex space-x-4">
      <DropdownSelect
        testId="default-inspection-filters-dropdown"
        label={getFilterLabel(
          "Default Inspection Filters",
          inspectionsFilterState.filters
        )}
        open={inspectionsDropdownOpen}
        onOpen={(open) => {
          setInspectionsDropdownOpen(open);
        }}
        hasAdvancedFilters={false}
        content={
          <FilterSelector
            schema={inspectionsFiltersSchema}
            filterSortState={inspectionsFilterState}
            disableAdvancedFilter
            pendingFiltersKey={getPendingFiltersKey(INSPECTION_PAGE_KEY)}
            onApplyFilters={handleInspectionsChange}
            testId="inspection-filters-selector"
          />
        }
      />
      <DropdownSelect
        testId="default-vehicle-filters-dropdown"
        label={getFilterLabel(
          "Default Vehicle Filters",
          vehiclesFilterState.filters
        )}
        open={vehiclesDropdownOpen}
        onOpen={(open) => {
          setVehiclesDropdownOpen(open);
        }}
        hasAdvancedFilters={false}
        content={
          <FilterSelector
            schema={vehiclesFiltersSchema}
            filterSortState={vehiclesFilterState}
            disableAdvancedFilter
            pendingFiltersKey={getPendingFiltersKey(VEHICLES_PAGE_KEY)}
            onApplyFilters={handleVehiclesChange}
            testId="vehicle-filters-selector"
          />
        }
      />
    </div>
  );
};

export default InspectionAnalyticsConfigPage;
