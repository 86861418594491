import { useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import {
  useServiceRecommendationsFiltersSchema,
  useVehiclesFiltersSchema,
} from "shared/hooks";

import { VEHICLES_FILTER_LABEL } from "pages/SignalEventsAnalytics/constants";

import Button from "features/ui/Button";
import DropdownSelect from "features/ui/DropdownSelect";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import FiltersSummary, {
  ViewFiltersButton,
} from "features/ui/Filters/FiltersSummary";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { UseFilterSortState } from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";

import {
  SP_RECOMMENDATIONS_FILTER_LABEL,
  SP_RECOMMENDATIONS_FILTERS_LS_KEY,
  SP_RECOMMENDATIONS_VEHICLE_FILTERS_LS_KEY,
} from "./constants";

interface Props {
  serviceRecommendationFilterSortState: UseFilterSortState;
  vehiclesFilterSortState: UseFilterSortState;
  defaultServiceRecommendationFilters: FilterGroupState;
  defaultVehicleFilters: FilterGroupState;
  isInsideTab?: boolean;
}

const ServiceRecommendationsFilters = ({
  serviceRecommendationFilterSortState,
  vehiclesFilterSortState,
  defaultServiceRecommendationFilters,
  defaultVehicleFilters,
  isInsideTab = false,
}: Props) => {
  const [filterSummaryOpen, setFilterSummaryOpen] = useState(true);

  const { createWorkOrderCta: createWorkOrderCtaFF } = useFlags();

  const serviceRecommendationsFiltersSchema =
    useServiceRecommendationsFiltersSchema();
  const vehiclesFiltersSchema = useVehiclesFiltersSchema();

  const isAdvancedServicePlansEditor =
    serviceRecommendationFilterSortState.isAdvancedFilterEditor;
  const isAdvancedVehiclesEditor =
    vehiclesFilterSortState.isAdvancedFilterEditor;

  const servicePlanFilterLabel = getFilterLabel(
    SP_RECOMMENDATIONS_FILTER_LABEL,
    serviceRecommendationFilterSortState.filters
  );
  const vehicleFilterLabel = getFilterLabel(
    VEHICLES_FILTER_LABEL,
    vehiclesFilterSortState.filters
  );

  const [
    serviceRecommendationFilterDropdownOpen,
    setServiceRecommendationFilterDropdownOpen,
  ] = useState(false);
  const [vehicleFilterDropdownOpen, setVehicleFilterDropdownOpen] =
    useState(false);

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex space-x-3">
          <DropdownSelect
            testId="vehicles-filters-dropdown"
            label={vehicleFilterLabel}
            open={vehicleFilterDropdownOpen}
            onOpen={(open) => {
              setVehicleFilterDropdownOpen(open);
              setServiceRecommendationFilterDropdownOpen(false);
            }}
            hasAdvancedFilters={isAdvancedVehiclesEditor}
            content={
              <FilterSelector
                schema={vehiclesFiltersSchema}
                filterSortState={vehiclesFilterSortState}
                defaultFilters={defaultVehicleFilters}
                title={VEHICLES_FILTER_LABEL}
                testId="vehicles-filters"
                pendingFiltersKey={getPendingFiltersKey(
                  SP_RECOMMENDATIONS_VEHICLE_FILTERS_LS_KEY
                )}
                baseEntityText="Vehicles"
                onCloseFilters={() => {
                  setVehicleFilterDropdownOpen(false);
                }}
                initialIsAdvancedFilter={isAdvancedVehiclesEditor}
              />
            }
          />
          <DropdownSelect
            testId="service-recommendations-filters-dropdown"
            label={servicePlanFilterLabel}
            open={serviceRecommendationFilterDropdownOpen}
            onOpen={(open) => {
              setServiceRecommendationFilterDropdownOpen(open);
              setVehicleFilterDropdownOpen(false);
            }}
            hasAdvancedFilters={isAdvancedServicePlansEditor}
            content={
              <FilterSelector
                schema={serviceRecommendationsFiltersSchema}
                filterSortState={serviceRecommendationFilterSortState}
                defaultFilters={defaultServiceRecommendationFilters}
                title={SP_RECOMMENDATIONS_FILTER_LABEL}
                testId="service-recommendations-filters"
                pendingFiltersKey={getPendingFiltersKey(
                  SP_RECOMMENDATIONS_FILTERS_LS_KEY
                )}
                baseEntityText="Service Recommendation"
                onCloseFilters={() => {
                  setServiceRecommendationFilterDropdownOpen(false);
                }}
                initialIsAdvancedFilter={
                  serviceRecommendationFilterSortState.isAdvancedFilterEditor
                }
              />
            }
          />
          <ViewFiltersButton
            open={filterSummaryOpen}
            onClick={() => setFilterSummaryOpen(true)}
            onClose={() => setFilterSummaryOpen(false)}
          />
        </div>
        {Boolean(createWorkOrderCtaFF && isInsideTab) && (
          // Only for demo purposes - ask Gui when we can remove
          <Button
            className="ml-auto"
            variant="contained"
            color="secondary"
            size="small"
            disabled
          >
            Create Work Order
          </Button>
        )}
      </div>
      <FiltersSummary
        open={filterSummaryOpen}
        filterStates={[
          {
            name: VEHICLES_FILTER_LABEL,
            filters: vehiclesFilterSortState.filters,
            schema: vehiclesFiltersSchema,
          },
          {
            name: SP_RECOMMENDATIONS_FILTER_LABEL,
            filters: serviceRecommendationFilterSortState.filters,
            schema: serviceRecommendationsFiltersSchema,
            baseEntityText: "Service Recommendations",
          },
        ]}
      />
    </div>
  );
};

export default ServiceRecommendationsFilters;
