import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  DeleteRequest,
  getFetcher,
  ListAttributesRequest,
  patchFetcher,
  PermissionEntry,
  postFetcher,
  putFetcher,
  UpdatePermissionRequest,
} from "shared/api/api";
import client from "shared/api/axios";
import { Issue } from "shared/api/issues/api";
import { Group } from "shared/api/rbac/api";
import { SuggestedIssue } from "shared/api/suggestedIssues/api";
import { createURL } from "shared/api/utils";
import { Vehicle } from "shared/api/vehicles/api";

export enum AlertEventType {
  SIGNAL_EVENT_OCCURRENCE = "signalEventOccurrence",
  CLAIM = "claim",
  SERVICE_RECOMMENDATION = "serviceRecommendation",
  VEHICLE = "vehicle",
  ISSUE = "issue",
  SUGGESTED_ISSUE = "suggestedIssue",
  METADATA = "metadata",
}

export interface AlertDefinition {
  ID: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  name: string;
  description: string;
  vehiclesFilter: string;
  eventType: AlertEventType;
  eventFilter: string;
  frequency: string;
  nextEvaluation: string | null;
  lastEvaluation: string | null;
  alertAtMinute: number | null;
  alertAtHour: number | null;
  alertAtWeekday: number | null;
  emails: string[];
  canEdit: boolean;
  groups: Group[];
  access: PermissionEntry[];
  notifications: AlertNotification[];
  emailAlerts: boolean;
  inAppAlerts: boolean;
  watchedObjectEventTypes?: AlertEventType[];
  watchedObjectID?: string;
  watchedObject?: Issue | SuggestedIssue | Vehicle;
}

export interface AlertNotification {
  ID: string;
  alertID: string;
  alert: AlertDefinition;
  eventType: AlertEventType;
  userID: string;
  count: number;
  seenAt: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
}

export interface AlertDefinitionPostRequestData {
  name: string;
  description: string | null;
  vehiclesFilter: string | null;
  eventType: string;
  eventFilter: string | null;
  frequency: string;
  emails: string[] | null;
  groupIds: string[] | null;
  inAppAlerts: boolean;
  emailAlerts: boolean;
  watchedObjectEventTypes?: AlertEventType[];
  watchedObjectID?: string;
}

export interface AlertDefinitionPutRequestData
  extends AlertDefinitionPostRequestData {
  ID: string;
}

export interface AlertDefinitionGetRequestData {
  ID: string;
}

interface UpdateAlertDefinitionAccessRequest
  extends Partial<UpdatePermissionRequest> {
  ID: string;
}

// interface UpdateNotificationRequest {
//   ID: string;
//   seenAt: string;
// }

const ALERT_DEFINITIONS_BASE_ROUTE = "alertDefinitions";
// const ALERT_NOTIFICATIONS_BASE_ROUTE = "notifications";

export const listAlertDefinitionRequestURI = (
  params: APIPaginatedRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([ALERT_DEFINITIONS_BASE_ROUTE]),
    params,
  });

export const listAlertDefinitionsCountRequestURI = (
  params: APIPaginatedRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([ALERT_DEFINITIONS_BASE_ROUTE, "count"]),
    params,
  });

const newAlertDefinitionRequestURI = (): string =>
  client.getUri({
    method: "POST",
    url: ALERT_DEFINITIONS_BASE_ROUTE,
  });

export const getAlertDefinitionURI = ({
  ID,
}: AlertDefinitionGetRequestData): string =>
  client.getUri({
    method: "get",
    url: createURL([ALERT_DEFINITIONS_BASE_ROUTE, ID]),
  });

export const deleteAlertDefinitionURI = ({ id }: DeleteRequest): string =>
  client.getUri({
    method: "delete",
    url: createURL([ALERT_DEFINITIONS_BASE_ROUTE, id]),
  });

export const updateAlertDefinitionRequestURI = ({
  ID,
}: AlertDefinitionPutRequestData): string =>
  client.getUri({
    method: "put",
    url: createURL([ALERT_DEFINITIONS_BASE_ROUTE, ID]),
  });

export const updateAlertDefinitionAccessRequestURI = ({
  ID,
  ...params
}: UpdateAlertDefinitionAccessRequest): string =>
  client.getUri({
    method: "PATCH",
    url: createURL([ALERT_DEFINITIONS_BASE_ROUTE, ID, "access"]),
    params,
  });

const listAlertDefinitionsValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([ALERT_DEFINITIONS_BASE_ROUTE, "values", fieldName]),
    params,
  });

export const listAlertDefinitionsAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([ALERT_DEFINITIONS_BASE_ROUTE, "attributes"]),
    params,
  });

export const getAlertDefinition = (args: AlertDefinitionGetRequestData) =>
  getFetcher<AlertDefinition>(getAlertDefinitionURI(args));

export const newAlertDefinition = (data: AlertDefinitionPostRequestData) =>
  postFetcher<AlertDefinition>(newAlertDefinitionRequestURI(), data);

export const deleteAlertDefinition = (args: DeleteRequest) =>
  client.delete(deleteAlertDefinitionURI(args));

export const updateAlertDefinition = (data: AlertDefinitionPutRequestData) =>
  putFetcher<AlertDefinition>(updateAlertDefinitionRequestURI(data), data);

export const updateAlertDefinitionAccess = ({
  ID,
  ...args
}: UpdateAlertDefinitionAccessRequest) =>
  patchFetcher<PermissionEntry[]>(
    updateAlertDefinitionAccessRequestURI({ ID }),
    args
  );

export const listAlertDefinitionValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listAlertDefinitionsValuesRequestURI(args));
