import { DuckMessage as DuckMessageType } from "duck/context/DuckContextWrapper";
import { DuckMessageAuthor, DuckMessageFormat } from "duck/context/types";
import DuckChartOptionMessage from "duck/ui/DuckChartOptionMessage";
import DuckFilterMessage from "duck/ui/DuckFilterMessage";
import DuckHumanMessage from "duck/ui/DuckHumanMessage";
import DuckMarkdownMessage from "duck/ui/DuckMarkdownMessage";
import DuckSortMessage from "duck/ui/DuckSortMessage";

type MessageComponent = (props: DuckMessageProps) => JSX.Element;

const agentFormatMap: Record<DuckMessageFormat, MessageComponent> = {
  [DuckMessageFormat.TEXT]: DuckMarkdownMessage,
  [DuckMessageFormat.FILTER]: DuckFilterMessage,
  [DuckMessageFormat.SORT]: DuckSortMessage,
  [DuckMessageFormat.CHART_OPTIONS]: DuckChartOptionMessage,
};

const getMessageComponent = (
  format: DuckMessageFormat | undefined,
  author: DuckMessageAuthor
): MessageComponent => {
  if (author === DuckMessageAuthor.HUMAN) {
    return DuckHumanMessage;
  }

  return (
    agentFormatMap[format ?? DuckMessageFormat.TEXT] ?? DuckMarkdownMessage
  );
};

export interface DuckMessageProps {
  message: DuckMessageType;
}

/**
 * The DuckMessage component is responsible for rendering one message.
 * It does that by delegating to the correct component based on the message format
 * and author.
 * @param props Contains the message to render
 */
const DuckMessage = (props: DuckMessageProps) =>
  getMessageComponent(props.message.format, props.message.author)(props);

export default DuckMessage;
