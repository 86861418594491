import NewCTA from "shared/components/NewCTA";

import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import StatefulTabs from "features/ui/StatefulTabs";
import Title from "features/ui/Title";

import { routes } from "services/routes";

import AlertDefinitionsEvents from "./AlertDefinitionsEvents";
import AlertDefinitionsObjects from "./AlertDefinitionsObjects";
import {
  ALERT_DEFINITIONS_TITLE,
  CTA_TEXT,
  LIST_ALERT_DEFINITIONS_KEY,
  LIST_ALERT_DEFINITIONS_TITLE,
  OBJECT_ALERT_DEFINITIONS_KEY,
  OBJECT_ALERT_DEFINITIONS_TITLE,
} from "./constants";

const AlertDefinitions = () => {
  const tabs = [
    {
      key: LIST_ALERT_DEFINITIONS_KEY,
      title: LIST_ALERT_DEFINITIONS_TITLE,
      content: <AlertDefinitionsEvents />,
      testId: "list_alerts_tab",
    },
    {
      key: OBJECT_ALERT_DEFINITIONS_KEY,
      title: OBJECT_ALERT_DEFINITIONS_TITLE,
      content: <AlertDefinitionsObjects />,
      testId: "object_alerts_tab",
    },
  ];

  return (
    <>
      <PageHeaderWrapper>
        <Title text={ALERT_DEFINITIONS_TITLE} />
        <PageHeaderActionsWrapper>
          <NewCTA
            to={routes.newAlertDefinition}
            label={CTA_TEXT}
            testId="new-alert-definition-cta"
          />
        </PageHeaderActionsWrapper>
      </PageHeaderWrapper>

      <StatefulTabs tabs={tabs} defaultTab={0} />
    </>
  );
};
export default AlertDefinitions;
