import { format } from "date-fns";

import { Granularity } from "shared/api/constants";
import { API_DATE_FORMAT } from "shared/constants";
import { randomID } from "shared/utils";

import {
  ReferenceAreaElement,
  ReferenceElement,
} from "features/ui/charts/types";
import {
  FilterGroupState,
  FilterRowStateNotNull,
} from "features/ui/Filters/FilterBuilder/types";
import {
  getFiltersForAttributeFromFilterState,
  updateOrAddRowFilterGroupState,
} from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator } from "features/ui/Filters/types";
import { SelectOption } from "features/ui/Select";

import { FieldDateRange, SelectedDateRange } from "./types";

export const getSelectionReferenceLines = (
  selectedTimestamps: SelectedDateRange
) => {
  const references: ReferenceElement[] = [];

  if (selectedTimestamps.max) {
    references.push({
      label: new Date(selectedTimestamps.max).toLocaleDateString(),
      className: "cursor-pointer",
      xAxisValue: selectedTimestamps.max,
      color: "rgb(30,167,253)",
      legendType: "none",
    });
  }

  if (selectedTimestamps.min) {
    references.push({
      label: new Date(selectedTimestamps.min).toLocaleDateString(),
      className: "cursor-pointer",
      xAxisValue: selectedTimestamps.min,
      color: "rgb(30,167,253)",
      legendType: "none",
    });
  }

  return references;
};

export const getSelectionReferenceAreas = (
  selectedTimestamps: SelectedDateRange
) => {
  const referenceAreas: ReferenceAreaElement[] = [];
  if (selectedTimestamps.max && selectedTimestamps.min) {
    referenceAreas.push({
      x1: selectedTimestamps.min,
      x2: selectedTimestamps.max,
      fill: "rgb(30,167,253)",
      fillOpacity: 0.1,
    });
  } else if (selectedTimestamps.min) {
    referenceAreas.push({
      x1: selectedTimestamps.min,
      fill: "rgb(30,167,253)",
      fillOpacity: 0.1,
    });
  }

  return referenceAreas;
};

export const addGranularityToDate = (date: Date, granularity: Granularity) => {
  if (granularity === "week") {
    date.setDate(date.getDate() + 7);

    return date;
  }

  if (granularity === "month") {
    date.setMonth(date.getMonth() + 1);

    return date;
  }

  return date;
};

export const getDateRangeFilter = (
  fieldName: string,
  fromTimestamp: string,
  toTimestamp: string
): FilterRowStateNotNull => ({
  type: "row",
  id: `row-${fieldName}-${randomID()}`,
  attribute: fieldName,
  operator: FilterOperator.BETWEEN,
  values: [fromTimestamp, toTimestamp],
});

export const getAfterDateFilter = (
  fieldName: string,
  fromTimestamp: string
): FilterRowStateNotNull => ({
  type: "row",
  id: `row-${fieldName}-${randomID()}`,
  attribute: fieldName,
  operator: FilterOperator.GREATER_OR_EQUAL,
  values: [fromTimestamp],
});

export const addDateRangeToFilterState = (
  vehiclesFilters: FilterGroupState,
  { fieldName, fromDate, toDate }: FieldDateRange
) => {
  if (!toDate) {
    const upper = getCurrentUpperDateBound(
      vehiclesFilters,
      fieldName,
      fromDate
    );

    if (upper) {
      return updateOrAddRowFilterGroupState(
        vehiclesFilters,
        getDateRangeFilter(fieldName, fromDate, upper)
      );
    }

    return updateOrAddRowFilterGroupState(
      vehiclesFilters,
      getAfterDateFilter(fieldName, fromDate)
    );
  }

  const filter = getDateRangeFilter(fieldName, fromDate, toDate);

  return updateOrAddRowFilterGroupState(vehiclesFilters, filter);
};

// calculates the smallest upper bound in current filter state for the given date attribute
export const getCurrentUpperDateBound = (
  filterState: FilterGroupState,
  fieldName: string,
  fromDate: string
) => {
  const filters = getFiltersForAttributeFromFilterState(fieldName, filterState);
  const from = new Date(fromDate);
  let currentMax: Date | undefined;
  for (const filter of filters) {
    if (!filter || filter.type !== "row") continue;

    if (filter.operator === FilterOperator.BETWEEN && filter.values) {
      const upperDate = new Date(filter.values[1]);

      if (!currentMax && upperDate.getTime() > from.getTime()) {
        currentMax = upperDate;
      }

      if (
        currentMax &&
        upperDate.getTime() < currentMax.getTime() &&
        upperDate.getTime() > from.getTime()
      ) {
        currentMax = upperDate;
      }
    }

    if (
      (filter.operator === FilterOperator.LESS_THAN ||
        filter.operator === FilterOperator.LESS_OR_EQUAL) &&
      filter.values
    ) {
      const upperDate = new Date(filter.values[0]);

      if (
        !currentMax &&
        upperDate.getTime() > from.getTime() &&
        upperDate.getTime() > from.getTime()
      ) {
        currentMax = upperDate;
      }

      if (
        currentMax &&
        upperDate.getTime() < currentMax.getTime() &&
        upperDate.getTime() > from.getTime()
      ) {
        currentMax = upperDate;
      }
    }
  }

  return currentMax ? format(currentMax.getTime(), API_DATE_FORMAT) : undefined;
};

export const sortGroupByOptions = (options: SelectOption[]): SelectOption[] =>
  [...options].sort((a, b) => {
    // sort options to put 'vehicle' first and sort the rest alphabetically
    if (a.id === "vehicle") return -1;

    if (b.id === "vehicle") return 1;

    const valueA = String(a.value);
    const valueB = String(b.value);

    return valueA.localeCompare(valueB);
  });
