import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { mutate } from "swr";

import {
  Bookmark,
  GeneralConfigModel,
  MetabaseDashboard,
  updateGeneralConfig,
} from "shared/api/admin/api";
import { useGeneralConfig } from "shared/api/admin/hooks";
import { APIError } from "shared/api/api";
import { formatAPIDate } from "shared/api/utils";
import { MileageUnit } from "shared/types";

import Button from "features/ui/Button";
import DatePicker from "features/ui/DatePicker";
import Input from "features/ui/Input";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import { isValidEmail } from "features/ui/PermissionsDialog/utils";
import Select from "features/ui/Select";
import { DataType } from "features/ui/Table/TableBodyCell/types";
import TextArea from "features/ui/TextArea";
import Title from "features/ui/Title";

import { DEFAULT_GENERAL_CONFIG } from "./constants";
import { showErrorToast } from "./utils";

const TITLE = "General Config";

const mileageUnitOptions = [
  { id: "km" as MileageUnit, value: "Kilometers" },
  { id: "mi" as MileageUnit, value: "Miles" },
];

const bookmarkExample: Bookmark[] = [
  {
    title: "bookmark",
    path: 'vehicles?filters_v1.vehicles=mileage%3Dgt%3A"2"&sort_v1.vehicles%5Bmileage%5D=desc&chartSettings_v1.vehicles=&relatedSignalEventsFilter_v1.vehicles=&filters_v1.vehicles_table=&sort_v1.vehicles_table%5Bmileage%5D=desc&chartSettings_v1.vehicles_table=&relatedSignalEventsFilter_v1.vehicles_table=',
    description: "bookmark description",
  },
];
const metabaseDashboardExample: MetabaseDashboard[] = [
  { name: "dashboard", id: "1" },
];

const GeneralConfig = () => {
  const [generalConfigState, setGeneralConfigState] =
    useState<GeneralConfigModel>(DEFAULT_GENERAL_CONFIG);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    data,
    isLoading,
    error,
    requestKey: adminGeneralConfigRequestKey,
  } = useGeneralConfig({});

  const onDataUpdated = (update: Partial<GeneralConfigModel>) => {
    setGeneralConfigState({ ...generalConfigState, ...update });
  };

  const [metabaseDashboardString, setMetabaseDashboardString] =
    useState<string>("");
  const [dashboardsValid, setDashboardsValid] = useState(true);
  const [bookmarksString, setBookmarksString] = useState<string>("");
  const [bookmarksValid, setBookmarksValid] = useState(true);

  const setBookmarks = (value: string) => {
    try {
      const bookmarks = JSON.parse(value);
      onDataUpdated({ bookmarks });
      setBookmarksValid(true);
    } catch (e) {
      setBookmarksValid(false);
    }

    setBookmarksString(value);
  };

  const setMetabaseDashboards = (value: string) => {
    try {
      const metabaseDashboards = JSON.parse(value);
      onDataUpdated({ metabaseDashboards });
      setDashboardsValid(true);
    } catch (e) {
      setDashboardsValid(false);
    }

    setMetabaseDashboardString(value);
  };

  useEffect(() => {
    if (data && !isLoading && !error) {
      setGeneralConfigState(data);
      setMetabaseDashboardString(
        JSON.stringify(data.metabaseDashboards || [], null, 2)
      );
      setBookmarksString(JSON.stringify(data.bookmarks || [], null, 2));
    }
  }, [data, isLoading, error]);

  const handleSave = async () => {
    setIsSubmitting(true);

    updateGeneralConfig({
      config: {
        ...generalConfigState,
        rootPage: generalConfigState.rootPage || null,
        helpContactEmail: generalConfigState.helpContactEmail || null,
      },
    })
      .then(() => {
        toast.success("General configuration saved successfully");
        mutate(adminGeneralConfigRequestKey);
      })
      .catch((error: APIError) => {
        showErrorToast(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const [showJSON, setShowJSON] = useState(false);

  const handleShowJSON = () => {
    setShowJSON(!showJSON);
  };

  const ctaEnabled = dashboardsValid && bookmarksValid && !isSubmitting;

  return (
    <>
      <PageHeaderWrapper>
        <Title text={TITLE} />
      </PageHeaderWrapper>
      {error && <p className="text-red-500">Failed to load configuration.</p>}
      {isLoading && <Skeleton height={100} />}
      {!isLoading && !error && (
        <div className="mt-4 max-w-4xl">
          <div className="pb-3 max-w-48">
            <Select
              label="Mileage Unit"
              options={mileageUnitOptions}
              selected={
                mileageUnitOptions.find(
                  ({ id }) => id === generalConfigState.mileageUnit
                ) || undefined
              }
              onSelect={(selectedOption) =>
                onDataUpdated({ mileageUnit: selectedOption.id as MileageUnit })
              }
            />
          </div>
          <DatePicker
            label="Max Date"
            initialDate={
              generalConfigState.maxDate
                ? new Date(generalConfigState.maxDate)
                : null
            }
            key={(generalConfigState.maxDate !== "").toString()}
            onChange={(date: Date | null) => {
              onDataUpdated({
                maxDate: date
                  ? formatAPIDate(date.toString(), DataType.DATE)
                  : null,
              });
            }}
          />
          <div className="mt-6">
            <Input
              label="Help Contact Email"
              value={generalConfigState.helpContactEmail || ""}
              onChange={({ target: { value } }) =>
                onDataUpdated({ helpContactEmail: value })
              }
            />
          </div>
          <div className="mt-3">
            <div className="text-gray-400 text-xs mb-2">
              For tenants that do not have Landing page enabled (feature flag),
              we redirect them to root page. It is usually set to /vehicles.
            </div>
            <Input
              label="Root Page"
              value={generalConfigState.rootPage || ""}
              onChange={({ target: { value } }) =>
                onDataUpdated({ rootPage: value })
              }
            />
          </div>
          <div className="mt-4 md:flex space-x-2 justify-between">
            <TextArea
              label="Bookmarks"
              value={bookmarksString}
              onChange={(e) => setBookmarks(e.target.value)}
              rows={12}
              error={!bookmarksValid}
            />
            <div className="md:w-1/2 text-xs text-gray-400 mb-2">
              JSON bookmark example:
              <pre className="text-xs bg-gray-100 mt-2 p-2 text-viaduct-black whitespace-pre-wrap overflow-y-auto break-all">
                {JSON.stringify(bookmarkExample, null, 2)}
              </pre>
            </div>
          </div>
          <div className="mt-4 flex space-x-2 justify-between">
            <TextArea
              label="Metabase Dashboards"
              value={metabaseDashboardString}
              onChange={(e) => setMetabaseDashboards(e.target.value)}
              rows={12}
              error={!dashboardsValid}
            />
            <div className="w-1/2 text-xs text-gray-400 mb-2">
              JSON metabase dashboard example:
              <pre className=" text-xs bg-gray-100 mt-2 p-2 text-viaduct-black whitespace-pre-wrap overflow-y-auto break-all">
                {JSON.stringify(metabaseDashboardExample, null, 2)}
              </pre>
            </div>
          </div>
          <div className="flex justify-between items-center mt-4">
            <div
              className="text-gray-400 cursor-pointer hover:underline"
              onClick={handleShowJSON}
            >
              {showJSON ? "Hide" : "Show"} JSON
            </div>
            <div className="flex justify-end mt-4">
              <Button
                label="Save"
                color="primary"
                variant="contained"
                testId="save-pages-config"
                onClick={handleSave}
                disabled={
                  !ctaEnabled ||
                  (generalConfigState.helpContactEmail !== null &&
                    generalConfigState.helpContactEmail.length > 0 &&
                    !isValidEmail(generalConfigState.helpContactEmail || ""))
                }
                isLoading={isSubmitting}
              />
            </div>
          </div>
          {showJSON && (
            <div className="mt-4 flex justify-between text-xs text-gray-600">
              <div>
                Current local:
                <pre className="bg-gray-50">
                  {JSON.stringify(generalConfigState, null, 2)}
                </pre>
              </div>
              <div>
                Current API:
                <pre className="bg-gray-100">
                  {JSON.stringify(data, null, 2)}
                </pre>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default GeneralConfig;
