// Icons
import { LDFlagSet } from "launchdarkly-js-client-sdk";
import {
  AiOutlineFileProtect as ClaimsIcon,
  AiOutlineGroup as CollectionsIcon,
  AiOutlineDashboard as DashboardIcon,
  AiOutlineFileSearch as DataExplorerIcon,
  AiFillTool as FailureModesIcon,
  AiOutlineInfoCircle as InfoIcon,
  AiOutlineExclamationCircle as IssuesIcon,
  AiOutlineAppstoreAdd as PagesConfigIcon,
  AiOutlineProfile as ServiceRecordsIcon,
  AiOutlineCar as VehiclesIcon,
} from "react-icons/ai";
import {
  BsBell as AlertDefinitionIcon,
  BsFillCalculatorFill as CalculatorIcon,
  BsPeopleFill as GroupsIcon,
  BsCardChecklist as ServicePlansIcon,
} from "react-icons/bs";
import { FaListCheck as AttributesConfigIcon } from "react-icons/fa6";
import {
  GiTruck as FleetsIcon,
  GiSettingsKnobs as GeneralConfigIcon,
  GiAutoRepair as RepairIcon,
} from "react-icons/gi";
import { GoListOrdered as OrderedValuesIcon } from "react-icons/go";
import { generatePath, Location } from "react-router-dom";

import { useConfigContext } from "shared/contexts/ConfigContext";
import { useTenantServiceRecordName } from "shared/utils";

import {
  ALERT_DEFINITIONS_PAGE_CONFIG_KEY,
  CALCULATED_ATTRIBUTES_PAGE_CONFIG_KEY,
  CLAIM_ANALYTICS_PAGE_CONFIG_KEY,
  COLLECTIONS_PAGE_CONFIG_KEY,
  CUSTOM_SIGNAL_EVENTS_PAGE_CONFIG_KEY,
  FAILURE_MODES_PAGE_CONFIG_KEY,
  FLEETS_PAGE_CONFIG_KEY,
  INSPECTION_ANALYTICS_PAGE_CONFIG_KEY,
  ISSUES_PAGE_CONFIG_KEY,
  REPAIR_ANALYTICS_PAGE_CONFIG_KEY,
  SERVICE_PLANS_PAGE_CONFIG_KEY,
  SERVICE_RECORDS_PAGE_CONFIG_KEY,
  SIGNAL_EVENTS_ANALYTICS_PAGE_CONFIG_KEY,
  VEHICLES_PAGE_CONFIG_KEY,
} from "pages/Admin/PagesConfig/types";
import { PAGE_TITLE as claimsAnalyticsTitle } from "pages/ClaimAnalytics/constants";
import { PAGE_TITLE as customSignalEventsTitle } from "pages/CustomSignalEvents/constants";
import { PAGE_TITLE as signalEventsAnalyticsTitle } from "pages/SignalEventsAnalytics/constants";

import { routes } from "services/routes";
import * as config from "config/config";

interface Subroute {
  to: string;
  text: string;
}

interface NavItem {
  icon: JSX.Element;
  text: JSX.Element | string;
  to: string;
  exact?: boolean;
  isExternal?: boolean;
  isActiveFunc?: (match: boolean, location: Location) => boolean;
  matchRoute?: string;
  // set to true if there are subroutes, but no matching item in nav menu
  hasUnlistedSubroutes?: boolean;
  subroutes?: Subroute[];
}

type NavItemOrNothing = NavItem | boolean | undefined;

export interface NavGroup {
  title: string;
  links: NavItem[];
}

interface Props {
  flags: LDFlagSet;
}

export const NAV_ITEM_ICON_SIZE = 17;

export const useSideNavGroups = ({ flags }: Props): NavGroup[] => {
  const { pages, general } = useConfigContext();

  const { dataExplorer } = config.get();
  const rbacGroupsEnables = flags.rbac && flags.rbacGroups;
  const issuesEnabled = pages[ISSUES_PAGE_CONFIG_KEY] && flags.issues;
  const suggestedIssuesEnabled =
    pages.issues?.suggestedIssues && flags.suggestedIssues;

  const qualityItems: NavItemOrNothing[] = [
    pages[SIGNAL_EVENTS_ANALYTICS_PAGE_CONFIG_KEY] &&
      flags.signalEventsAnalytics && {
        icon: <ClaimsIcon size={NAV_ITEM_ICON_SIZE} />,
        text: signalEventsAnalyticsTitle,
        to: routes.signalEventAnalytics,
      },
    pages[CLAIM_ANALYTICS_PAGE_CONFIG_KEY] &&
      flags.claimAnalytics && {
        icon: <ClaimsIcon size={NAV_ITEM_ICON_SIZE} />,
        text: claimsAnalyticsTitle,
        to: routes.claimAnalytics,
      },
    (issuesEnabled || suggestedIssuesEnabled) && {
      icon: <IssuesIcon size={NAV_ITEM_ICON_SIZE} />,
      text: "Issues",
      to: issuesEnabled ? routes.issues : routes.suggestedIssues,
      hasUnlistedSubroutes: true,
    },
  ];

  const quality = qualityItems.filter(Boolean) as NavItem[];

  const tenantServiceRecordName = useTenantServiceRecordName();

  const serviceItems: NavItemOrNothing[] = [
    pages[FLEETS_PAGE_CONFIG_KEY] && {
      icon: <FleetsIcon size={NAV_ITEM_ICON_SIZE} />,
      text: "Fleets",
      to: routes.fleets,
      hasUnlistedSubroutes: true,
    },
    pages[VEHICLES_PAGE_CONFIG_KEY] && {
      icon: <VehiclesIcon className="inline-block" size={NAV_ITEM_ICON_SIZE} />,
      text: "Vehicles",
      to: routes.vehicles,
      exact: true,
      hasUnlistedSubroutes: true,
    },
    pages[SERVICE_RECORDS_PAGE_CONFIG_KEY] &&
      !pages[SERVICE_RECORDS_PAGE_CONFIG_KEY].hideMenuItem && {
        icon: <ServiceRecordsIcon size={NAV_ITEM_ICON_SIZE} />,
        text: tenantServiceRecordName,
        to: routes.serviceRecords,
      },
    pages[INSPECTION_ANALYTICS_PAGE_CONFIG_KEY] &&
      flags.inspectionAnalytics && {
        icon: <ClaimsIcon size={NAV_ITEM_ICON_SIZE} />,
        text: "Inspection Analytics",
        to: routes.inspectionAnalytics,
        exact: true,
        hasUnlistedSubroutes: true,
      },
    pages[REPAIR_ANALYTICS_PAGE_CONFIG_KEY] &&
      flags.repairAnalytics && {
        icon: <RepairIcon size={NAV_ITEM_ICON_SIZE} />,
        text: "Repair Analytics",
        to: routes.repairAnalytics,
        exact: true,
        hasUnlistedSubroutes: true,
      },
    pages[SERVICE_PLANS_PAGE_CONFIG_KEY] &&
      flags.servicePlans && {
        icon: <ServicePlansIcon size={NAV_ITEM_ICON_SIZE} />,
        text: "Service Plans",
        to: routes.servicePlans,
        exact: true,
        hasUnlistedSubroutes: true,
      },
    pages[SERVICE_PLANS_PAGE_CONFIG_KEY] &&
      flags.servicePlans && {
        icon: <ServicePlansIcon size={NAV_ITEM_ICON_SIZE} />,
        text: "Service Recommendations",
        to: routes.serviceRecommendations,
        exact: true,
      },
    pages[FAILURE_MODES_PAGE_CONFIG_KEY] && {
      icon: <FailureModesIcon size={NAV_ITEM_ICON_SIZE} />,
      text: "Failure Modes",
      to: routes.failureModes,
      hasUnlistedSubroutes: true,
    },
    pages[ALERT_DEFINITIONS_PAGE_CONFIG_KEY] && {
      icon: <AlertDefinitionIcon size={NAV_ITEM_ICON_SIZE} />,
      text: "Alert Definitions",
      to: routes.alertDefinitions,
      exact: true,
      hasUnlistedSubroutes: true,
    },
  ];

  const service = serviceItems.filter(Boolean) as NavItem[];

  const dataHubItems: NavItemOrNothing[] = [
    pages[CUSTOM_SIGNAL_EVENTS_PAGE_CONFIG_KEY] &&
      flags.customSignalEvents && {
        icon: <ClaimsIcon size={NAV_ITEM_ICON_SIZE} />,
        text: customSignalEventsTitle,
        to: routes.customSignalEvents,
      },
    pages[CALCULATED_ATTRIBUTES_PAGE_CONFIG_KEY] &&
      flags.calculatedAttributes && {
        icon: <CalculatorIcon size={NAV_ITEM_ICON_SIZE} />,
        text: "Calculated Attributes",
        to: routes.calculatedAttributes,
        exact: true,
        hasUnlistedSubroutes: true,
      },
    pages.dataExplorer && {
      icon: <DataExplorerIcon size={NAV_ITEM_ICON_SIZE} />,
      text: "Data Explorer",
      isExternal: true,
      to: dataExplorer?.url,
    },
    rbacGroupsEnables && {
      icon: <GroupsIcon size={NAV_ITEM_ICON_SIZE} />,
      text: "Groups",
      to: routes.groups,
    },
    pages[COLLECTIONS_PAGE_CONFIG_KEY] && {
      icon: <CollectionsIcon size={NAV_ITEM_ICON_SIZE} />,
      text: "Collections",
      to: routes.collections,
    },
  ];

  const dataHub = dataHubItems.filter(Boolean) as NavItem[];
  const dashboardsItems: NavItemOrNothing[] = general?.metabaseDashboards
    ? general.metabaseDashboards.map(({ id, name }) => ({
        icon: <DashboardIcon size={NAV_ITEM_ICON_SIZE} />,
        text: <span>{name}</span>,
        to: generatePath(routes.dashboard, {
          id,
        }),
      }))
    : [];

  const DASHBOARDS = dashboardsItems.filter(Boolean) as NavItem[];

  const adminPages: NavItem[] = [
    {
      icon: <AttributesConfigIcon />,
      text: "Attributes",
      to: routes.attributesConfig,
    },
    {
      icon: <PagesConfigIcon />,
      text: "Pages",
      to: routes.pagesConfig,
    },
    {
      icon: <GeneralConfigIcon />,
      text: "General",
      to: routes.generalConfig,
    },
    {
      icon: <OrderedValuesIcon />,
      text: "Ordered Values",
      to: routes.orderedValues,
    },
    {
      icon: <InfoIcon />,
      text: "Info",
      to: routes.adminInfo,
    },
  ];

  const filterSideBarSections = ({ links }: NavGroup) => links.length > 0;

  const groupItems: NavGroup[] = [
    {
      title: "Quality",
      links: quality,
    },
    {
      title: "Service",
      links: service,
    },
    {
      title: "Dashboards",
      links: DASHBOARDS,
    },
    {
      title: "Data Hub",
      links: dataHub,
    },
    flags.adminPages && {
      title: "Admin",
      links: adminPages,
    },
  ].filter(Boolean) as NavGroup[];

  return groupItems.filter(Boolean).filter(filterSideBarSections) as NavGroup[];
};
