import classNames from "classnames";

import Button from "features/ui/Button";
import {
  FILTER_INDENT_GROUP_MARGIN_PX,
  FILTER_INDENT_MARGIN_PX,
  FILTER_ROW_OPACITY_PERCENTAGE_INCREASE,
} from "features/ui/Filters/FilterBuilder/constants";
import AnyAllSelect, {
  AnyAll,
} from "features/ui/Filters/FilterBuilder/FilterGroup/AnyAllSelect";
import FilterRow from "features/ui/Filters/FilterBuilder/FilterRow";
import RowActions from "features/ui/Filters/FilterBuilder/FilterRow/RowActions";
import { OnRowDataChangeHandle } from "features/ui/Filters/FilterBuilder/FilterRow/types";
import {
  FilterGroupState,
  FilterRowState,
} from "features/ui/Filters/FilterBuilder/types";
import { SchemaEntry } from "features/ui/Table";

interface Props {
  schema: SchemaEntry<string>[];
  id: string;
  depth: number;
  children: (FilterRowState | FilterGroupState)[];
  onNewRow: (id: string) => void;
  onNewGroup: (id: string) => void;
  onDelete?: (id: string) => void;
  anyAll: AnyAll;
  onGroupOperatorChange: (id: string, anyAll: AnyAll) => void;
  onRowDataChange: OnRowDataChangeHandle;
  onResetToDefault?: () => void;
  onClear?: () => void;
  disabled?: boolean;
  disableDelete?: boolean;
  attributePlaceholder?: string;
  embedded?: boolean;
}

const FilterGroup = ({
  schema,
  id,
  depth,
  children,
  onNewRow,
  onNewGroup,
  onDelete,
  disableDelete,
  anyAll,
  onGroupOperatorChange,
  onRowDataChange,
  onResetToDefault,
  onClear,
  disabled,
  attributePlaceholder,
  embedded,
}: Props) => {
  const shouldDiplayResetToDefaultButton =
    depth === 0 && onResetToDefault && !embedded;

  return (
    <div
      style={
        depth === 0
          ? undefined
          : {
              marginLeft: `${FILTER_INDENT_GROUP_MARGIN_PX}px`,
              paddingLeft: `${
                FILTER_INDENT_MARGIN_PX - FILTER_INDENT_GROUP_MARGIN_PX
              }px`,
              background: `rgba(0,0,0, ${depth * FILTER_ROW_OPACITY_PERCENTAGE_INCREASE}%)`,
            }
      }
      className={classNames("mt-1 pt-1 rounded-sm pb-1", {
        "mb-2 pb-2": !embedded,
      })}
      data-testid="filter-group"
    >
      <div
        className="flex items-center justify-between group/group"
        data-testid="group-actions"
      >
        {!embedded && (
          <AnyAllSelect
            selected={anyAll}
            onChange={(selected) => onGroupOperatorChange(id, selected.id)}
            disabled={disabled}
          />
        )}
        {shouldDiplayResetToDefaultButton && (
          <div className="flex flex-row items-center gap-1">
            <Button
              variant="text"
              size="small"
              color="primary"
              onClick={onClear}
              className="text-nowrap"
              data-testid="clear-all"
              disabled={disabled}
            >
              Clear
            </Button>
            <Button
              variant="text"
              size="small"
              color="primary"
              onClick={onResetToDefault}
              className="text-nowrap"
              data-testid="reset-all-to-default"
              disabled={disabled}
            >
              Reset all to default
            </Button>
          </div>
        )}
        {depth > 0 && (
          <RowActions
            id={id}
            onNewRow={onNewRow}
            onNewGroup={onNewGroup}
            onDelete={disableDelete ? undefined : onDelete}
            disabled={disabled}
            hideAddGroupCta={embedded}
          />
        )}
      </div>
      {/* Each group can contain any combination of rows or groups */}
      {children.map((child) => {
        if (child.type === "row") {
          return (
            <FilterRow
              key={child.id}
              onNewRow={onNewRow}
              onNewGroup={onNewGroup}
              onDelete={disableDelete ? undefined : onDelete}
              onRowDataChange={onRowDataChange}
              schema={schema}
              disabled={disabled}
              attributePlaceholder={attributePlaceholder}
              embedded={embedded}
              depth={depth}
              {...child}
            />
          );
        } else {
          return (
            <FilterGroup
              schema={schema}
              key={child.id}
              id={child.id}
              depth={depth + 1}
              children={child.children}
              onNewRow={onNewRow}
              onNewGroup={onNewGroup}
              onDelete={onDelete}
              disableDelete={disableDelete}
              anyAll={child.anyAll}
              onClear={onClear}
              onGroupOperatorChange={onGroupOperatorChange}
              onRowDataChange={onRowDataChange}
              disabled={disabled}
              attributePlaceholder={attributePlaceholder}
            />
          );
        }
      })}
    </div>
  );
};

export default FilterGroup;
