import { Dispatch, SetStateAction } from "react";

import { APIListValuesRequest } from "shared/api/api";
import { SortBy } from "shared/types";

import { SelectedChartOptions } from "features/ui/charts/Actions/types";
import { SelectOption } from "features/ui/Select";
import { DataType } from "features/ui/Table/TableBodyCell/types";

import { FilterGroupState } from "./FilterBuilder/types";

/**
 * @interface FilterSchemaItem
 * @member {string} label label to display on top of filter
 * @member {boolean} fieldName API field identifier
 * @member {boolean?} search whether filter should hit API whenever search query is changed
 * @member {boolean?} loadDataOnOpen whether filter should load data when dropdown is opened but search query is empty
 * @member {boolean?} multiple whether to allow user to select multiple values
 * @member {number?} maxValues maximum number of values that can be selected. If not set, there is no limit
 * @member {function?} loadOptionsFunc function that loads initial values for dropdown
 * @member {function?} transformInitialSelectedFunc function that converts raw data (like IDs) to human readable format (like label/description)
 * @member {function?} formatLabelFunc function that formats value in appropriate format and adds a label
 * @member {boolean} description description that appears in the tooltip next to label
 * @member {JSX.Element | string | undefined} filterType filter type in case we want to override the SearchSelect type
 * @member {string?} fieldNameForAPI if field on values endpoints do not match the one in the table, we can override it with setting custom field name
 * @member {boolean?} disableFiltering if we do not want to apply filters from current table for getting values for dropdown
 * @member {boolean?} disableSelectFilters if we do not want to allow "in" and "not in" operators for this filter and thus SearchSelect filter
 * @member {boolean?} disableContainsFilters if we do not want to allow "Contains" operators for this filter
 * @member {boolean?} disableIsEmptyFilters if we do not want to allow "Is empty" and "Is not empty" operators for this filter
 * @member {boolean?} disableStartsWithFilters if we do not want to allow "Starts with" and "Does not start with" operators for this filter
 * @member {boolean?} disableArbitraryText if we do not want to allow custom text to be typed as filter value in SelectFilter (freeSolo)
 * @member {boolean?} enableMinMaxFilters if we want to allow min/max filter for this filter
 */
export interface FilterSchemaItem {
  label: string;
  fieldName: string;
  search?: boolean;
  loadDataOnOpen?: boolean;
  multiple?: boolean;
  maxValues?: number;
  loadOptionsFunc?: (args: APIListValuesRequest) => Promise<SelectOption[]>;
  transformInitialSelectedFunc?: (
    initialSelected: SelectOption[]
  ) => Promise<SelectOption[]>;
  formatLabelFunc?: (selected: SelectOption[]) => SelectOption[];
  loadOptionsArgs?: Record<string, any>;
  description?: JSX.Element | string;
  filterType?: FilterType;
  filterDataType?: DataType;
  fieldNameForAPI?: string;
  disableFiltering?: boolean;
  disableSelectFilters?: boolean;
  disableContainsFilters?: boolean;
  disableIsEmptyFilters?: boolean;
  disableStartsWithFilters?: boolean;
  disableIsNotFilteredFilters?: boolean;
  enableMinMaxFilters?: boolean;
  disableArbitraryText?: boolean;
  whitelistedFilterOperators?: FilterOperator[];
  onlyAllowPositiveIntegers?: boolean;
  customFilter?: FilterGroupState;
  baseEntityText?: string;
  disableSelectingWindowDirection?: boolean;
  relationEndpoint?: string;
}

export const enum FilterOperator {
  NOT_FILTERED = "not_filtered",
  IN = "in",
  NOT_IN = "not_in",
  IS_EMPTY = "is_empty",
  IS_NOT_EMPTY = "is_not_empty",
  GREATER_THAN = "greater_than",
  GREATER_OR_EQUAL = "greater_or_equal",
  LESS_THAN = "less_than",
  LESS_OR_EQUAL = "less_or_equal",
  BETWEEN = "between",
  CONTAINS = "contains",
  NOT_CONTAINS = "not_contains",
  IN_LAST = "in_last",
  STARTS_WITH = "starts_with",
  NOT_STARTS_WITH = "not_starts_with",
  IS_TRUE = "is_true",
  IS_FALSE = "is_false",
  EQUALS = "eq",
  NOT_EQUALS = "neq",
  OCCURS = "occurs",
  NOT_OCCURS = "noccurs",
  EXISTS = "exists",
  NOT_EXISTS = "nexists",
}

export const signalEventsFilterOperators = [
  FilterOperator.STARTS_WITH,
  FilterOperator.IN,
  FilterOperator.NOT_FILTERED,
  FilterOperator.IS_NOT_EMPTY,
] as const;
export type SignalEventsFilterOperatorsType =
  (typeof signalEventsFilterOperators)[number];

export type FilterState = {
  [key: string]: SingleFilterState;
};

export type OccursFilterOperator = "occurs" | "noccurs";

export type OccursFilterWindowDirectionType = OccursFilterWindowDirection;

export type OccursFilterOptionKeys =
  | "windowSize"
  | "windowType"
  | "windowDirection";

export enum OccursFilterWindowDirection {
  BEFORE = "before",
  AFTER = "after",
  BOTH = "both",
}

export type OccursFilterState = {
  filters: string;
  windowSize: number;
  windowType: string;
  windowDirection: OccursFilterWindowDirection;
};

export type OccursWindowOptions = {
  windowSize: string | number;
  windowType: string;
  windowDirection: OccursFilterWindowDirection;
};

type OccursFilterWindowDirectionAssertion = (
  value: string | number
) => asserts value is OccursFilterWindowDirection;

export const assertOccursFilterWindowDirection: OccursFilterWindowDirectionAssertion =
  (value) => {
    if (
      !Object.values(OccursFilterWindowDirection).includes(
        value as OccursFilterWindowDirection
      )
    ) {
      throw new Error(
        `Invalid OccursFilterWindowDirectionAssertion: ${value} is not in ${Object.values(OccursFilterWindowDirection).join(", ")}`
      );
    }
  };

export type SingleFilterState = {
  values: string[];
  operator: FilterOperator;
  extra?: Record<string, string>;
};

export type FilterType =
  | "string"
  | "number"
  | "date"
  | "boolean"
  | "duration"
  | "exists"
  | "occurs";

export type FilterChangeProps = {
  key: string;
  op_id: FilterOperator;
  values?: string[];
  extra?: SingleFilterState["extra"];
  dataType?: DataType;
};

export type FilterChangeCallback = (props: FilterChangeProps) => void;
export type onInputChangeCallback = (inputValue: string) => void;

export interface ChartSettingsChangeHandler {
  (chartSettings: ChartSettingsState, chartKey: string): void;
}

export type FilterOverviewFormat =
  | "badge"
  | "label"
  | "label-inline"
  | "label-block";
export type FilterValue = string | JSX.Element;

export type ActiveBgColor = `bg-${string}` | "";

export interface FilterEntry {
  fieldName: string;
  operator: FilterOperator;
  fieldValue: FilterValue;
}

type DateUnit = "d" | "m" | "w" | "y";

export interface DateUnitOption extends SelectOption<DateUnit> {}

export type InitialDateValues = (string | null)[];

export interface UseFilterSortStateProps {
  pageKey: string;
  defaultSort?: SortBy;
  defaultFailureModeColumns?: string[];
  disableUsingQuery?: boolean;
  disableUsingLocalStorage?: boolean;
  defaultFilterValues?: FilterGroupState;
  pendingFiltersLocalStorageKey?: string;
  defaultTab?: string;
}

export interface FilterSortState {
  filters?: FilterGroupState;
  sort?: SortBy;
  columns?: string[];
  chartSettings?: PageChartSettingsState;
  relatedSignalEventsFilter?: OccursFilterState;
}

/**
 * The settings for a single chart.
 * There can be any number of settings for a single chart, things like yAxis, xAxis, granularity, etc.
 * Each single setting has two properties:
 * - id: The id of the setting, which is typically a ChartAction.
 *   An example would be the id "y" from this action from the ClaimsChart:
 *   {
 *     id: "y",
 *     title: yAxis,
 *     type: "dropdownSelect",
 *     options: yAxisOptions,
 *   }
 * - optionId: The id of the option that is selected for the corresponding setting.
 *   An example would be the id "rollingIPTV" from this option from the ClaimsChart:
 *   {
 *     id: "rollingIPTV",
 *     value: "Rolling 7-day IPTV",
 *     label: "Rolling 7-day IPTV",
 *   }
 * An example of the complete type would be:
 * [
 *   {
 *     id: "y",
 *     optionId: "rollingIPTV",
 *   },
 * ],
 *
 * This is the level of data that is sent to the manageChartSettingsChange() function.
 */
export type ChartSettingsState = SelectedChartOptions[];

/**
 * The settings for the charts on a tab.
 * There can be any number of settings for a chart, and any number of charts on a tab.
 * The string corresponds to the chart options key, such as "claimAnalyticsClaimsChartOptions".
 * An example would be:
 * {
 *   "claimAnalyticsClaimsChartOptions": [
 *     {
 *       id: "y",
 *       optionId: "rollingIPTV",
 *     },
 *   ],
 * }
 */
export type TabChartSettingsState = Record<string, ChartSettingsState>;

/**
 * The settings for the charts on a single page.
 * The page consists of a collection of tabs, each of which has a collection of charts.
 * The string corresponds to the tab key, such as "claims" or "by-vehicle-age".
 * An example would be:
 * {
 *   "claims": {
 *     "claimAnalyticsClaimsChartOptions": [
 *       {
 *         id: "y",
 *         optionId: "rollingIPTV",
 *       },
 *     ],
 *   ],
 * }
 *
 * This is the level of data that is provided by the useFilterSortState hook.
 */
export type PageChartSettingsState = Record<string, TabChartSettingsState>;

/**
 * Asserts that the given object is a PageChartSettingsState.
 * We use this to ensure that the data we get from the query string is valid.
 * This matters because people could type anything into the query string.
 * @param pageChartSettings The type of the parameter has not yet been validated.
 * @throws Error if the data is not valid.
 */
export const assertIsPageChartSettingsState: (
  pageChartSettings: any
) => asserts pageChartSettings is PageChartSettingsState = (
  pageChartSettings
) => {
  if (typeof pageChartSettings !== "object" || pageChartSettings === null) {
    throw new Error("PageChartSettingsState must be an object");
  }

  for (const [tabKey, tabSettings] of Object.entries(pageChartSettings)) {
    if (typeof tabSettings !== "object" || tabSettings === null) {
      throw new Error(`Tab settings for "${tabKey}" must be an object`);
    }

    for (const [chartKey, chartSettings] of Object.entries(tabSettings)) {
      if (!Array.isArray(chartSettings)) {
        throw new Error(
          `Chart settings for "${tabKey}.${chartKey}" must be an array`
        );
      }

      for (const setting of chartSettings) {
        if (typeof setting !== "object" || setting === null) {
          throw new Error(
            `Setting in "${tabKey}.${chartKey}" must be an object`
          );
        }

        if (typeof setting.id !== "string") {
          throw new Error(
            `Setting id in "${tabKey}.${chartKey}" must be a string`
          );
        }

        if (
          typeof setting.optionId !== "string" &&
          typeof setting.optionId !== "number"
        ) {
          throw new Error(
            `Setting optionId in "${tabKey}.${chartKey}" must be a string or number`
          );
        }
      }
    }
  }
};

export interface UseFilterSortState {
  initialized: boolean;
  sort: SortBy | undefined;
  manageOnSortChange: (next: SortBy) => void;
  filters: FilterGroupState;
  updateFilters: (filters: FilterGroupState) => void;
  manageFilterChange: FilterChangeCallback;
  chartSettings?: PageChartSettingsState;
  manageChartSettingsChange?: ChartSettingsChangeHandler;
  relatedSignalEventsFilter?: OccursFilterState;
  manageRelatedSignalEventsFilterChange?: (next: OccursFilterState) => void;
  resetFilters: (fieldNames?: string[]) => void;
  resetSort: () => void;
  resetFilterSortState: () => void;
  failureModeColumns: string[];
  manageOnVisibleFMColumnChange: (columns: string[]) => void;
  isAdvancedFilterEditor: boolean;
  setIsAdvancedFilterEditor: (isAdvanced: boolean) => void;
}

export interface QueryKeys {
  filtersKey: string;
  sortKey: string;
  columnsKey: string;
  chartSettingsKey: string;
  relatedSignalEventsFilterKey: string;
}

export interface UseInitialStateValuesAndKeysReturn {
  initialValues: FilterSortState;
  queryKeys: QueryKeys;
}

export type InternalFilterStateParams = {
  pageKeyWithVersion: string;
  defaultSort?: SortBy;
  defaultFailureModeColumns?: string[];
  disableUsingQuery?: boolean;
  disableUsingLocalStorage?: boolean;
  defaultFilterValues?: FilterGroupState;
};

export type InternalFilterState = {
  queryKeys: QueryKeys;
  initialized: boolean;
  setInitialized: Dispatch<SetStateAction<boolean>>;
  isAdvancedFilterEditor: boolean;
  setIsAdvancedFilterEditor: Dispatch<SetStateAction<boolean>>;
  filters: FilterGroupState;
  setFilters: Dispatch<SetStateAction<FilterGroupState>>;
  sort: SortBy | undefined;
  setSort: Dispatch<SetStateAction<SortBy | undefined>>;
  columns: string[];
  setColumns: Dispatch<SetStateAction<string[]>>;
  chartSettings: PageChartSettingsState | undefined;
  setChartSettings: Dispatch<
    SetStateAction<PageChartSettingsState | undefined>
  >;
  relatedSignalEventsFilter: OccursFilterState | undefined;
  setRelatedSignalEventsFilter: Dispatch<
    SetStateAction<OccursFilterState | undefined>
  >;
};
