import { AlertEventType } from "shared/api/alertDefinitions/api";

import { AlertDefinitionState } from "pages/AlertDefinitions/types";

import { DropdownSelectOption } from "features/ui/DropdownSelect/DropdownSelect";
import {
  DEFAULT_FILTER_BUILDER_STATE,
  DEFAULT_FILTER_BUILDER_STATE_ANY,
} from "features/ui/Filters/FilterBuilder/constants";
import { SelectOption } from "features/ui/Select";

export const ALERT_PLAN_TITLE = "Alert Definition";
export const ICON_SIZE = 15;
export const ON_SUCCESS_CREATE_TEXT =
  "Successfully created a new alert definition.";
export const ON_UPDATE_CREATE_TEXT =
  "Your alert definition has been updated successfully.";
export const ALERT_DEFINITION_DESCRIPTION_LABEL = "Description";
export const ALERT_DEFINITION_NAME_LABEL = "Name";
export const ALERT_DEFINITIONS_TITLE = "Alert Definitions";
export const ALERT_DEFINITIONS_PER_PAGE = 15;
export const CTA_TEXT = "New Alert Definition";

export const LIST_ALERT_LABEL = "Event alert";
export const OBJECT_ALERT_LABEL = "Object alert";

export const EMPTY_ALERT_DEFINITION_STATE: AlertDefinitionState = {
  ID: "",
  createdAt: "",
  updatedAt: "",
  createdBy: "",
  updatedBy: "",
  name: "",
  description: null,
  vehiclesFilter: DEFAULT_FILTER_BUILDER_STATE,
  eventType: AlertEventType.SIGNAL_EVENT_OCCURRENCE,
  eventFilter: DEFAULT_FILTER_BUILDER_STATE_ANY,
  frequency: "asap",
  emails: [],
  groups: [],
  initialGroups: [],
  access: [],
  canEdit: false,
  inAppAlerts: true,
  emailAlerts: false,
};

export enum FrequencyOptions {
  ASAP = "asap",
  HOURLY = "hourly",
  DAILY = "daily",
  WEEKLY = "weekly",
}

export const FREQUENCY_OPTIONS_SELECT_TIME_SCHEDULED_OPTIONS: DropdownSelectOption<string>[] =
  [
    {
      id: FrequencyOptions.HOURLY.toString(),
      value: "Hour",
    },
    {
      id: FrequencyOptions.DAILY.toString(),
      value: "Day",
    },
    {
      id: FrequencyOptions.WEEKLY.toString(),
      value: "Week",
    },
  ];

export enum AlertType {
  LIST = "list",
  OBJECT = "object",
}

export const ALERT_EVENT_TYPE_OPTIONS: (SelectOption & { type?: string })[] = [
  {
    id: AlertEventType.SIGNAL_EVENT_OCCURRENCE,
    value: "Signal Events",
    type: AlertType.LIST,
  },
  {
    id: AlertEventType.CLAIM,
    value: "Claims",
    type: AlertType.LIST,
  },
  {
    id: AlertEventType.SERVICE_RECOMMENDATION,
    value: "Service Recommendations",
    type: AlertType.LIST,
  },
  {
    id: AlertEventType.VEHICLE,
    value: "Vehicle",
    type: AlertType.OBJECT,
  },
  {
    id: AlertEventType.ISSUE,
    value: "Issue",
    type: AlertType.OBJECT,
  },
  {
    id: AlertEventType.SUGGESTED_ISSUE,
    value: "Suggested Issue",
    type: AlertType.OBJECT,
  },
];

export const ALERT_EVENT_TYPE_LABELS: Record<string, string> = {
  [AlertEventType.SIGNAL_EVENT_OCCURRENCE]: "Signal Event",
  [AlertEventType.CLAIM]: "Claim",
  [AlertEventType.SERVICE_RECOMMENDATION]: "Service Recommendation",
  [AlertEventType.VEHICLE]: "Vehicle",
  [AlertEventType.ISSUE]: "Issue",
  [AlertEventType.SUGGESTED_ISSUE]: "Suggested Issue",
};

export const getEventTypeLabel = (eventType: AlertEventType) =>
  ALERT_EVENT_TYPE_LABELS[eventType];

export type ObjectEventType =
  | AlertEventType.VEHICLE
  | AlertEventType.ISSUE
  | AlertEventType.SUGGESTED_ISSUE;

export const OBJECT_TO_WATCHED_EVENTS_TYPE_MAP: Record<
  ObjectEventType,
  WatchedObjectEventType[]
> = {
  [AlertEventType.VEHICLE]: [
    AlertEventType.SIGNAL_EVENT_OCCURRENCE,
    AlertEventType.CLAIM,
  ],
  [AlertEventType.ISSUE]: [
    AlertEventType.SIGNAL_EVENT_OCCURRENCE,
    AlertEventType.CLAIM,
    AlertEventType.METADATA,
  ],
  [AlertEventType.SUGGESTED_ISSUE]: [
    AlertEventType.SIGNAL_EVENT_OCCURRENCE,
    AlertEventType.CLAIM,
    AlertEventType.METADATA,
  ],
};

export const OBJECT_EVENT_TYPES = [
  AlertEventType.VEHICLE,
  AlertEventType.ISSUE,
  AlertEventType.SUGGESTED_ISSUE,
];

export type WatchedObjectEventType =
  | AlertEventType.SIGNAL_EVENT_OCCURRENCE
  | AlertEventType.CLAIM
  | AlertEventType.VEHICLE
  | AlertEventType.ISSUE
  | AlertEventType.SUGGESTED_ISSUE
  | AlertEventType.METADATA;

export const WATCHED_OBJECT_EVENT_TYPES = [
  AlertEventType.SIGNAL_EVENT_OCCURRENCE,
  AlertEventType.CLAIM,
  AlertEventType.VEHICLE,
  AlertEventType.ISSUE,
  AlertEventType.SUGGESTED_ISSUE,
  AlertEventType.METADATA,
];

export const OBJECT_EVENT_TYPE_URLS = {
  [AlertEventType.VEHICLE]: "/vehicles",
  [AlertEventType.ISSUE]: "/issues",
  [AlertEventType.SUGGESTED_ISSUE]: "/suggested-issues",
};

export const ALERT_FREQUENCY_TEXT = "Frequency:";
export const ALERT_TYPE_TEXT = "Type:";
export const ALERT_FREQUENCY_LABELS: Record<FrequencyOptions, string> = {
  [FrequencyOptions.ASAP]: "As soon as possible",
  [FrequencyOptions.HOURLY]: "Hourly",
  [FrequencyOptions.DAILY]: "Daily",
  [FrequencyOptions.WEEKLY]: "Weekly",
};

export const NOTIFICATION_TYPE_LABELS: Record<string, string> = {
  app: "In application",
  email: "Email",
  all: "Both",
};

export const LIST_ALERT_DEFINITIONS_KEY = "list_alert_definitions";
export const LIST_ALERT_DEFINITIONS_TITLE = "Event alerts";

export const OBJECT_ALERT_DEFINITIONS_KEY = "object_alert_definitions";
export const OBJECT_ALERT_DEFINITIONS_TITLE = "Object alerts";
