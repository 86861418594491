import { getFetcher, patchFetcher, putFetcher } from "shared/api/api";
import client from "shared/api/axios";
import { createURL } from "shared/api/utils";
import { MileageUnit } from "shared/types";

import { PagesConfig } from "pages/Admin/PagesConfig/types";

const ADMIN_BASE_ROUTE = "admin";

/**
 * Attributes config
 */
const ATTRIBUTES_CONFIG_BASE = "attributesConfig";

export interface AdminAttributesConfigRequest {
  resource: string;
}

export interface AdminAttributesConfigUpdateRequest
  extends AdminAttributesConfigRequest {
  config: object;
}

export interface AdminAttributesConfigResponse {}

export interface AdminAttributeConfig {
  resource: string;
  endpoint: string;
}

export const listAdminAttributesConfigRequestURI = (): string =>
  client.getUri({
    method: "GET",
    url: createURL([ADMIN_BASE_ROUTE, ATTRIBUTES_CONFIG_BASE]),
  });

export const getAdminAttributesConfigRequestURI = ({
  resource,
  ...params
}: AdminAttributesConfigRequest): string =>
  client.getUri({
    method: "GET",
    url: createURL([ADMIN_BASE_ROUTE, ATTRIBUTES_CONFIG_BASE, resource]),
    params,
  });

export const getAdminAttributesConfig = (args: AdminAttributesConfigRequest) =>
  getFetcher<AdminAttributesConfigResponse>(
    getAdminAttributesConfigRequestURI(args)
  );

export const updateAdminAttributesConfigRequestURI = ({
  resource,
}: AdminAttributesConfigRequest): string =>
  client.getUri({
    method: "put",
    url: createURL([ADMIN_BASE_ROUTE, ATTRIBUTES_CONFIG_BASE, resource]),
  });

export const updateAdminAttributesConfig = ({
  resource,
  ...args
}: AdminAttributesConfigUpdateRequest) =>
  putFetcher<AdminAttributesConfigResponse>(
    updateAdminAttributesConfigRequestURI({ resource }),
    { data: args.config }
  );

/**
 * Pages config
 */
const PAGES_CONFIG_BASE = "pagesConfig";

export interface AdminPagesConfigRequest {}

export interface AdminPagesConfigUpdateRequest {
  config: PagesConfig;
}

export const getAdminPagesConfigRequestURI = (
  params: AdminPagesConfigRequest
): string =>
  client.getUri({
    method: "GET",
    url: createURL([ADMIN_BASE_ROUTE, PAGES_CONFIG_BASE]),
    params,
  });

export const getAdminPagesConfig = (args: AdminPagesConfigRequest) =>
  getFetcher<PagesConfig>(getAdminPagesConfigRequestURI(args));

export const updateAdminPagesConfigRequestURI = (): string =>
  client.getUri({
    method: "PUT",
    url: createURL([ADMIN_BASE_ROUTE, PAGES_CONFIG_BASE]),
  });

export const updateAdminPagesConfig = (args: AdminPagesConfigUpdateRequest) =>
  putFetcher<PagesConfig>(updateAdminPagesConfigRequestURI(), {
    data: args.config,
  });

/**
 * General config
 */
const GENERAL_CONFIG_BASE = "generalConfig";

export interface MetabaseDashboard {
  id: string;
  name: string;
}

export interface Bookmark {
  title: string;
  path: string;
  description?: string;
}

export interface GeneralConfigModel {
  mileageUnit: MileageUnit;
  maxDate: string | null;
  helpContactEmail: string | null;
  rootPage: string | null;
  bookmarks: Bookmark[];
  metabaseDashboards: MetabaseDashboard[];
}

export interface GeneralConfigRequest {}

export interface GeneralConfigUpdateRequest {
  config: GeneralConfigModel;
}

export const getGeneralConfigRequestURI = (
  params: GeneralConfigRequest
): string =>
  client.getUri({
    method: "GET",
    url: createURL([ADMIN_BASE_ROUTE, GENERAL_CONFIG_BASE]),
    params,
  });

export const updateGeneralConfigRequestURI = (): string =>
  client.getUri({
    method: "PUT",
    url: createURL([ADMIN_BASE_ROUTE, GENERAL_CONFIG_BASE]),
  });

export const updateGeneralConfig = (args: GeneralConfigUpdateRequest) =>
  patchFetcher<GeneralConfigModel>(
    updateGeneralConfigRequestURI(),
    args.config
  );

/**
 * Ordered values config
 */
export type AdminOrderedValuesConfig = Record<string, any>[];

export const listAdminOrderedValuesConfigRequestURI = (): string =>
  client.getUri({
    method: "GET",
    url: createURL([ADMIN_BASE_ROUTE, "orderedValues"]),
  });

export const getAdminOrderedValuesConfigRequestURI = (type: string): string =>
  client.getUri({
    method: "GET",
    url: createURL([ADMIN_BASE_ROUTE, "orderedValues", type]),
  });

export const getAdminOrderedValuesConfig = (type: string) =>
  getFetcher<AdminOrderedValuesConfig>(
    getAdminOrderedValuesConfigRequestURI(type)
  );

export const updateAdminOrderedValuesConfigRequestURI = (
  type: string
): string =>
  client.getUri({
    method: "PATCH",
    url: createURL([ADMIN_BASE_ROUTE, "orderedValues", type]),
  });

interface UpdateAdminOrderedValuesConfigRequest {
  type: string;
  values: AdminOrderedValuesConfig;
}

export const updateAdminOrderedValuesConfig = ({
  type,
  values,
}: UpdateAdminOrderedValuesConfigRequest) =>
  patchFetcher<AdminOrderedValuesConfig>(
    updateAdminOrderedValuesConfigRequestURI(type),
    {
      data: values,
    }
  );
