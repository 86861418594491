import { useGetIssueSignalEventByVehicleAge } from "shared/api/issues/hooks";
import { DEFAULT_EXPOSURE } from "shared/constants";
import { useCustomLocalStorageState, useTenantMileageUnit } from "shared/hooks";
import {
  IssueChart,
  IssueChartGroupBy,
  IssueChartType,
  IssueTypes,
} from "shared/types";

import { useSEByVehicleAgeExposureOptions } from "pages/hooks";
import GroupByChart from "pages/Issues/Charts/GroupByChart";
import { SignalEventsCharts } from "pages/Issues/constants";
import {
  getAxisKeyLabelFromActions,
  getChartOptionsKey,
  getPopulationString,
} from "pages/Issues/utils";

import Card from "features/ui/Card";
import {
  cumulativeDPTV,
  cumulativeDPTVDV,
  xAxis,
  yAxis,
} from "features/ui/charts/actions";
import ChartActions from "features/ui/charts/Actions/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/Actions/ChartActionsWrap";
import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import { getDefaultActions } from "features/ui/charts/utils";

const CHART_NAME = SignalEventsCharts.RATE_BY_VEHICLE_AGE;

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const RateByVehicleAgeChart = ({ issue, withComparisonPopulation }: Props) => {
  const exposures = useSEByVehicleAgeExposureOptions();
  const chartOptionsKey = getChartOptionsKey(CHART_NAME, issue.ID);

  const actions: ChartAction[] = [
    {
      id: "x",
      title: xAxis,
      type: "dropdownSelect",
      options: exposures,
      defaultOptionId: DEFAULT_EXPOSURE,
    },
    {
      id: "y",
      title: yAxis,
      type: "dropdownSelect",
      options: [cumulativeDPTVDV, cumulativeDPTV],
    },
  ];

  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(chartOptionsKey, { defaultValue: getDefaultActions(actions) });

  const { axisKey: xAxisKey, axisValue: xAxisValue } =
    getAxisKeyLabelFromActions(selectedOptions, actions, "x");

  const title = `Rate of Signal Events by ${xAxisValue}`;
  const atRiskChartTitle = `${title} : ${getPopulationString("atRisk")}`;
  const comparisonChartTitle = `${title} : ${getPopulationString(
    "comparison"
  )}`;

  const commonChartProps = {
    issue,
    chartType: "VehicleAge" as IssueChartType,
    chart: CHART_NAME as IssueChart,
    dataLoadHook: useGetIssueSignalEventByVehicleAge,
    selectedOptions,
    groupByField: "signalEventID" as IssueChartGroupBy,
    actions,
    syncId: CHART_NAME,
    xAxisLabel: xAxisValue,
    exposure: xAxisKey,
    dataLoadHookParams: {
      byVehicleAgeExposure: xAxisKey,
      mileageUnit: useTenantMileageUnit(),
    },
  };

  return (
    <Card>
      <div className="space-y-5">
        <div>
          <ChartActionsWrap id={CHART_NAME} chartTitle={atRiskChartTitle}>
            <ChartActions
              actions={actions}
              selectedOptions={selectedOptions}
              onOptionChange={setSelectedOptions}
            />
          </ChartActionsWrap>
          <GroupByChart {...commonChartProps} population="atRisk" />
        </div>
        {withComparisonPopulation && (
          <div>
            <ChartActionsWrap
              id={CHART_NAME}
              chartTitle={comparisonChartTitle}
              showActions={false}
            />
            <GroupByChart {...commonChartProps} population="comparison" />
          </div>
        )}
      </div>
    </Card>
  );
};

export default RateByVehicleAgeChart;
