import React, { ReactNode } from "react";
import { Element } from "hast";

// https://github.com/rexxars/react-markdown/issues/404#issuecomment-604019030

const flatten = (
  text: string,
  child: JSX.ElementChildrenAttribute
): JSX.ElementChildrenAttribute =>
  // @ts-ignore
  typeof child === "string"
    ? text + child // @ts-ignore
    : // @ts-ignore
      React.Children.toArray(child.props.children).reduce(flatten, text);

/**
 * HeadingRenderer is a custom renderer
 * It parses the heading and attaches an id to it to be used as an anchor
 */
interface Props {
  children?: ReactNode;
  node?: Element;
}

const getTextSizeClassName = (tagName?: string): string => {
  switch (tagName) {
    case "h1":
      return "text-3xl";
    case "h2":
      return "text-2xl";
    case "h3":
      return "text-xl";
    case "h4":
      return "text-lg";
    case "h5":
      return "text-base";
    case "h6":
      return "text-sm";
    default:
      return "";
  }
};

const HeadingRenderer = (props: Props) => {
  const children = React.Children.toArray(props.children);
  // @ts-ignore
  const text = children.reduce(flatten, "");
  const slug = generateMarkdownSlugFromText(text as string);

  const element = props.node?.tagName || "span";
  const textSizeClassName = getTextSizeClassName(props.node?.tagName);

  return React.createElement(
    element,
    {
      id: slug,
      // we want to be able to show an icon(image) next to the heading, ie.
      // ### Collections![Collections Icon](./images/product-guide/collections-icon.svg)
      className: `flex items-center space-x-2 mt-5 font-semibold ${textSizeClassName}`,
    },
    props.children
  );
};
export default HeadingRenderer;

// get a slug by removing all non-word characters and replacing spaces with -, but only one "-"
const generateMarkdownSlugFromText = (text: string): string =>
  text
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 ]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");
