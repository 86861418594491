import { Switch } from "@mui/material";

import {
  ALERT_DEFINITIONS_PAGE_CONFIG_KEY,
  CALCULATED_ATTRIBUTES_PAGE_CONFIG_KEY,
  CLAIM_ANALYTICS_PAGE_CONFIG_KEY,
  COLLECTIONS_PAGE_CONFIG_KEY,
  CUSTOM_SIGNAL_EVENTS_PAGE_CONFIG_KEY,
  DATA_EXPLORER_PAGE_CONFIG_KEY,
  ENABLED_KEY,
  FAILURE_MODES_PAGE_CONFIG_KEY,
  FEEBACK_FORM_PAGE_KEY,
  FLEETS_PAGE_CONFIG_KEY,
  HELP_ARTICLES_PAGE_CONFIG_KEY,
  INSPECTION_ANALYTICS_PAGE_CONFIG_KEY,
  ISSUES_PAGE_CONFIG_KEY,
  KnownPageConfigKeys,
  LANDING_PAGE_PAGE_CONFIG_KEY,
  PageConfigProps,
  RELEASE_NOTES_PAGE_KEY,
  REPAIR_ANALYTICS_PAGE_CONFIG_KEY,
  SERVICE_PLANS_PAGE_CONFIG_KEY,
  SERVICE_RECORDS_PAGE_CONFIG_KEY,
  SIGNAL_EVENTS_ANALYTICS_PAGE_CONFIG_KEY,
  VEHICLES_PAGE_CONFIG_KEY,
} from "pages/Admin/PagesConfig/types";

import Card from "features/ui/Card";

import FeatureFlagExternalLink from "./FeatureFlagExternalLink";
import ClaimAnalyticsConfigPage from "./perPageConfigs/ClaimAnalyticsConfigPage";
import CustomSignalEventsConfigPage from "./perPageConfigs/CustomSignalEventsConfigPage";
import FailureModesConfigPage from "./perPageConfigs/FailureModesConfigPage";
import HelpArticlesConfigPage from "./perPageConfigs/HelpArticlesConfigPage";
import InspectionAnalyticsConfigPage from "./perPageConfigs/InspectionAnalyticsConfigPage";
import IssuesConfigPage from "./perPageConfigs/IssuesConfigPage";
import RepairAnalyticsConfigPage from "./perPageConfigs/RepairAnalyticsConfigPage";
import ServiceRecordsConfigPage from "./perPageConfigs/ServiceRecordsConfigPage";
import SignalEventAnalyticsConfigPage from "./perPageConfigs/SignalEventAnalyticsConfigPage";
import VehiclesConfigPage from "./perPageConfigs/VehiclesConfigPage";

// Define a mapping of page keys to their config components
export const PAGE_CONFIG_COMPONENTS: Record<
  KnownPageConfigKeys,
  React.ComponentType<PageConfigProps<any>>
> = {
  [VEHICLES_PAGE_CONFIG_KEY]: VehiclesConfigPage,
  [SERVICE_RECORDS_PAGE_CONFIG_KEY]: ServiceRecordsConfigPage,
  [FAILURE_MODES_PAGE_CONFIG_KEY]: FailureModesConfigPage,
  [ISSUES_PAGE_CONFIG_KEY]: IssuesConfigPage,
  [CLAIM_ANALYTICS_PAGE_CONFIG_KEY]: ClaimAnalyticsConfigPage,
  [SIGNAL_EVENTS_ANALYTICS_PAGE_CONFIG_KEY]: SignalEventAnalyticsConfigPage,
  [CUSTOM_SIGNAL_EVENTS_PAGE_CONFIG_KEY]: CustomSignalEventsConfigPage,
  [HELP_ARTICLES_PAGE_CONFIG_KEY]: HelpArticlesConfigPage,
  [FLEETS_PAGE_CONFIG_KEY]: () => null,
  [SERVICE_PLANS_PAGE_CONFIG_KEY]: () => null,
  [ALERT_DEFINITIONS_PAGE_CONFIG_KEY]: () => null,
  [CALCULATED_ATTRIBUTES_PAGE_CONFIG_KEY]: () => null,
  [REPAIR_ANALYTICS_PAGE_CONFIG_KEY]: RepairAnalyticsConfigPage,
  [INSPECTION_ANALYTICS_PAGE_CONFIG_KEY]: InspectionAnalyticsConfigPage,
  [COLLECTIONS_PAGE_CONFIG_KEY]: () => null,
  [DATA_EXPLORER_PAGE_CONFIG_KEY]: () => null,
  [LANDING_PAGE_PAGE_CONFIG_KEY]: () => null,
  [RELEASE_NOTES_PAGE_KEY]: () => null,
  [FEEBACK_FORM_PAGE_KEY]: () => null,
} as const;

export const PAGE_CONFIG_FEATURE_FLAG_BY_KEY: Partial<
  Record<KnownPageConfigKeys, string | undefined>
> = {
  [CLAIM_ANALYTICS_PAGE_CONFIG_KEY]: "claim-analytics",
  [SIGNAL_EVENTS_ANALYTICS_PAGE_CONFIG_KEY]: "signal-events-analytics",
  [REPAIR_ANALYTICS_PAGE_CONFIG_KEY]: "repair-analytics",
  [INSPECTION_ANALYTICS_PAGE_CONFIG_KEY]: "inspection-analytics",
  [SERVICE_PLANS_PAGE_CONFIG_KEY]: "service-plans",
  [ISSUES_PAGE_CONFIG_KEY]: "issues",
  [CALCULATED_ATTRIBUTES_PAGE_CONFIG_KEY]: "calculated-attributes",
};

const SinglePageConfigItem = ({
  pageId,
  config,
  onConfigChange,
}: PageConfigProps) => {
  const isEnabled = !!config;
  const ConfigComponent = PAGE_CONFIG_COMPONENTS[pageId as KnownPageConfigKeys];
  const featureFlagID = PAGE_CONFIG_FEATURE_FLAG_BY_KEY[pageId];

  return (
    <Card classNames="mb-3">
      <div className="flex justify-between">
        <div className="flex items-center space-x-2">
          <span className="capitalize font-semibold">{pageId}</span>
          {featureFlagID && <FeatureFlagExternalLink flagID={featureFlagID} />}
        </div>

        <Switch
          checked={isEnabled}
          onChange={(_, checked) =>
            onConfigChange(pageId, ENABLED_KEY, checked)
          }
          color="primary"
          size="small"
        />
      </div>
      {isEnabled && (
        <div className="mt-2 text-xs">
          {ConfigComponent && (
            <ConfigComponent
              pageId={pageId}
              onConfigChange={onConfigChange}
              config={config}
            />
          )}
        </div>
      )}
    </Card>
  );
};

export default SinglePageConfigItem;
