import {
  ClipboardEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Autocomplete,
  Chip,
  FilterOptionsState,
  TextField,
} from "@mui/material";

import { useDebounce } from "shared/hooks";

import { FILTER_PASTE_DELIMITER } from "features/ui/Filters/constants";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator } from "features/ui/Filters/types";
import InfoIcon from "features/ui/Icons/Info";
import { SelectOption } from "features/ui/Select";

import {
  DEBOUNCE_DELAY,
  DEFAULT_APPEND_ARBITRARY_OPTION,
  DEFAULT_DISABLE_ARBITRARY_TEXT,
  DEFAULT_LOAD_NUM_ITEMS,
  DEFAULT_PLACEHOLDER,
  DEFAULT_WIDTH_PX,
  SELECTED_TAGS_TO_SHOW,
} from "./constants";
import { FilterSkeleton } from "./FilterSkeleton";
import { SelectFilterProps } from "./types";
import {
  getNewItemOption,
  getNoResultsMessage,
  getOptions,
  modifySelectedValue,
  transformOptions,
} from "./utils";

const DEFAULT_FORMAT_FUNCTION = (options: SelectOption[]) => options;
const DEFAULT_LOAD_OPTIONS_ARGS = {};

const SelectFilter = ({
  fullWidth = true,
  label,
  fieldName,
  initialSelected = [],
  multiple = true,
  maxValues,
  search,
  loadDataOnOpen = false,
  loadOptionsFunc = () => Promise.resolve([]),
  loadOptionsArgs = DEFAULT_LOAD_OPTIONS_ARGS,
  onChange,
  onFilterChange,
  onInputChange,
  transformInitialSelectedFunc,
  formatLabelFunc = DEFAULT_FORMAT_FUNCTION,
  description,
  fieldNameForAPI,
  disableFiltering,
  enableMinMaxFilters,
  staticFilters = [],
  filterType,
  filterDataType,
  customFilter,
  placeholder = DEFAULT_PLACEHOLDER,
  defaultLoadLimit = DEFAULT_LOAD_NUM_ITEMS,
  disableArbitraryText = DEFAULT_DISABLE_ARBITRARY_TEXT,
  appendArbitraryOption = DEFAULT_APPEND_ARBITRARY_OPTION,
  disabled,
  testId,
}: SelectFilterProps) => {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [loading, setLoading] = useState(false);

  const initialSelectedWithoutNull =
    initialSelected.length > 0 && initialSelected[0].id === "null"
      ? []
      : initialSelected;

  const [initialSelectedOption, setInitialSelectedOption] = useState(
    initialSelectedWithoutNull
  );
  const [initialized, setInitialized] = useState(
    transformInitialSelectedFunc === undefined || !initialSelected.length
  );

  const [input, setInput] = useState("");
  const debouncedInput = useDebounce(input, DEBOUNCE_DELAY);

  const updateInput = useCallback(
    (inputValue: string) => {
      setInput(inputValue);
      onInputChange && onInputChange(inputValue);
    },
    [onInputChange]
  );

  const filtersQuery = !disableFiltering
    ? getFiltersQuery(customFilter, staticFilters)
    : getFiltersQuery(customFilter);

  useEffect(() => {
    if (initialSelected.length > 0 && transformInitialSelectedFunc) {
      transformInitialSelectedFunc(initialSelected)
        .then((options: SelectOption[]) => {
          setInitialSelectedOption(formatLabelFunc(options));
        })
        .catch((e) => {
          setInitialSelectedOption([]);
          onFilterChange &&
            onFilterChange({
              key: fieldName,
              op_id: FilterOperator.IN,
              values: [],
              dataType: filterDataType,
            });
        })
        .finally(() => {
          setInitialized(true);
        });
    }
    // handle scenario when values are removed
    else if (initialSelected?.length === 0) {
      setInitialSelectedOption([]);
    }

    setInitialSelectedOption(formatLabelFunc(initialSelected));
    // we do not want to fetch information every render of component so we
    // limit to only when elements change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName, initialSelected.length]);

  useEffect(() => {
    // don't search on an empty query
    if (!debouncedInput) {
      return;
    }

    setLoading(true);

    loadOptionsFunc({
      fieldName: fieldNameForAPI || fieldName,
      like: debouncedInput,
      filter: filtersQuery,
      ...loadOptionsArgs,
    })
      .then((options: SelectOption[]) => {
        setOptions(
          getOptions(formatLabelFunc(options), filterType, enableMinMaxFilters)
        );
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    debouncedInput,
    fieldName,
    fieldNameForAPI,
    loadOptionsFunc,
    formatLabelFunc,
    loadOptionsArgs,
    filtersQuery,
    filterType,
    enableMinMaxFilters,
  ]);

  const loadOptions = () => {
    // only load the options once
    if (options.length !== 0 || (search && !loadDataOnOpen)) {
      return;
    }

    setLoading(true);

    loadOptionsFunc({
      fieldName: fieldNameForAPI || fieldName,
      limit: loadDataOnOpen ? defaultLoadLimit : undefined,
      filter: filtersQuery,
      ...loadOptionsArgs,
    })
      .then((options: SelectOption[]) => {
        setOptions(
          getOptions(formatLabelFunc(options), filterType, enableMinMaxFilters)
        );
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOnChange = (values: SelectOption[]) => {
    // See https://blog.logrocket.com/using-strict-mode-react-18-guide-new-behaviors/:
    // - quote: React’s v18 strict mode does some interesting things regarding popular inbuilt hooks like useState, useMemo, and useReducer. Specifically, it invokes these functions twice in development and once (as expected) in production mode.
    const uniqueValues = Array.from(
      new Set(values.map(({ id }) => id.toString()))
    );

    onChange && onChange(uniqueValues);
    onFilterChange &&
      onFilterChange({
        key: fieldName,
        op_id: FilterOperator.IN,
        values: uniqueValues,
        dataType: filterDataType,
      });
  };

  const noResultsMessage = getNoResultsMessage({
    loading,
    options,
    search,
    input,
  });

  const selectedValues = useMemo(() => {
    if (!initialSelectedOption) return null;

    if (multiple) {
      return transformOptions(
        initialSelectedOption,
        filterType,
        enableMinMaxFilters
      );
    } else if (initialSelectedOption.length > 0) {
      // this helps us set the initial value for single select (multiple = false)
      updateInput(initialSelectedOption[0].value.toString());

      return initialSelectedOption[0];
    }

    return null;
  }, [
    multiple,
    initialSelectedOption,
    enableMinMaxFilters,
    filterType,
    updateInput,
  ]);

  if (!initialized) {
    return <FilterSkeleton />;
  }

  const onChangeHandler = (
    _: SyntheticEvent<Element, Event> | null,
    value: string | SelectOption | (string | SelectOption)[] | null
  ) => {
    if (!value) {
      handleOnChange([]);
      setInitialSelectedOption([]);
    } else {
      let values: SelectOption[] = [];
      if (typeof value === "string") {
        values.push({ id: value, value, label: value });
      } else if (Array.isArray(value)) {
        values = value.map((val) =>
          typeof val === "string"
            ? {
                id: val,
                value: val,
                label: val,
              }
            : modifySelectedValue(val)
        );
      } else {
        values.push(modifySelectedValue(value));
      }

      if (!maxValues || values.length <= maxValues) {
        handleOnChange(values);
        setInitialSelectedOption(values);
      }
    }

    updateInput("");
  };

  const handleFilterOptions = (
    options: SelectOption[],
    state: FilterOptionsState<SelectOption>
  ) => {
    // if multiple=false && input equals currently selected value, don't filter anything, just show all options currently present
    if (
      !multiple &&
      selectedValues &&
      !Array.isArray(selectedValues) &&
      input === selectedValues.value
    ) {
      return options;
    }

    const filteredOptions = options.filter((option) => {
      if (option.value === null) {
        return false;
      }

      return option.value
        .toString()
        .toLowerCase()
        .includes(input.toLowerCase());
    });

    const { inputValue } = state;
    // Suggest the creation of a new value due to freeSolo prop
    const isExisting = options.some((option) => inputValue === option.value);
    if (
      appendArbitraryOption &&
      !disableArbitraryText &&
      inputValue !== "" &&
      !isExisting
    ) {
      filteredOptions.push(getNewItemOption(inputValue));
    }

    return filteredOptions;
  };

  const onPaste = ({ target, clipboardData }: ClipboardEvent) => {
    const text = clipboardData.getData("Text");
    const splitValues = text.split(FILTER_PASTE_DELIMITER);
    const newValues = initialSelectedOption.concat(
      splitValues.filter(Boolean).map((value) => ({
        id: value.trim(),
        value: value.trim(),
      }))
    );

    // also unfocus the input so that message that asks the user to type something hides after paste
    (target as HTMLInputElement).blur();

    onChangeHandler(null, newValues);
  };

  const isMaxValueLimitReached = Boolean(
    maxValues &&
      selectedValues &&
      Array.isArray(selectedValues) &&
      selectedValues.length >= maxValues
  );

  return (
    <Autocomplete
      multiple={multiple}
      disableCloseOnSelect={multiple}
      disabled={disabled}
      size="small"
      id={`${fieldName}-autocomplete`}
      data-testid={testId || `${fieldName}-autocomplete`}
      options={options}
      limitTags={SELECTED_TAGS_TO_SHOW}
      value={selectedValues}
      onPaste={onPaste}
      freeSolo={!disableArbitraryText}
      isOptionEqualToValue={(current, selected) => {
        if (!current || !selected) return false;

        // selected can actually be an array of options or a single option depending on the multiple prop
        if (Array.isArray(selected)) {
          return selected.some(({ id: sId }) => sId === current.id);
        }

        return current.id === selected.id;
      }}
      filterOptions={search ? handleFilterOptions : undefined}
      filterSelectedOptions={true}
      onOpen={loadOptions}
      loading={loading}
      getOptionLabel={(value) =>
        typeof value === "string"
          ? value
          : (value as SelectOption).value.toString()
      }
      noOptionsText={noResultsMessage}
      sx={fullWidth ? undefined : { width: DEFAULT_WIDTH_PX }}
      fullWidth={fullWidth}
      clearOnBlur={false}
      onChange={onChangeHandler}
      renderTags={
        multiple
          ? (value: readonly SelectOption[], getTagProps) =>
              value.map((option: SelectOption, index: number) => (
                <Chip
                  variant="outlined"
                  label={option.label || option.value}
                  size="small"
                  id={`${fieldName}-autocomplete-selected-${index}`}
                  data-testid={`${fieldName}-autocomplete-selected-${index}`}
                  {...getTagProps({ index })}
                  className="autocomplete-selected-item"
                  title={option.value.toString()}
                />
              ))
          : undefined
      }
      inputValue={search ? input : undefined}
      onInputChange={
        search
          ? (event, value, reason) => {
              // if search = true, this means we dont load data on open. We only load data when user types something! When clearing input to "" we should just keep the previous results shown until user types something
              // if search = false, this means we load data on open and it's always available after that - when clearing to "" ALL options should be available
              if (search && reason === "reset") {
                return;
              }

              updateInput(value);
            }
          : undefined
      }
      renderOption={(props, option) => (
        <span
          {...props}
          title={option.value.toString()}
          data-testid={`option-${option.id}`}
          aria-disabled={isMaxValueLimitReached}
        >
          {option.label || option.value}
        </span>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          label={label}
          size="small"
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: description ? (
                <>
                  <InfoIcon text={description} />
                  {params.InputProps.endAdornment}
                </>
              ) : (
                params.InputProps.endAdornment
              ),
            },
          }}
        />
      )}
    />
  );
};

export default SelectFilter;
