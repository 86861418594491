import { useContext } from "react";

import { EntityAttribute, Option } from "shared/api/api";
import { VehicleOptionsAttributeContext } from "shared/contexts/VehicleOptionsAttributesContextWrapper";
import { VEHICLES_OPTIONS_GENERIC_FILTER } from "shared/filterDefinitions";

import { filterStateToFilterGroupState } from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator } from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell/types";

import { UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
} from "./utils";

const NON_SCHEMA_ACCESSOR = ["VIN", "optionID"];

const useVehicleOptionsCombinedSchema = (): UseSchema => {
  const { attributes, options } = useContext(VehicleOptionsAttributeContext);

  const attributeMap = getAttributeMap(attributes);

  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const combinedAttributes: EntityAttribute[] = generateCombinedAttributes(
    options,
    attributes
  );

  const schema = generateCombinedSchema(options, attributes);

  const getFilter = getFilterFunction(
    VEHICLES_OPTIONS_GENERIC_FILTER,
    attributeMap
  );

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  return {
    schema,
    attributes: combinedAttributes,
    getDisplayLabel,
    getSchemaEntry,
    getSchemaForAccessor,
  };
};

const generateCombinedAttributes = (
  options?: Option[],
  attributes?: EntityAttribute[]
): EntityAttribute[] => {
  if (!options || !attributes) {
    return [];
  }

  const combinedAttributes: EntityAttribute[] = [];

  for (const option of options) {
    combinedAttributes.push({
      ID: `options.${option.ID}.present`,
      displayName: `Option: ${option.ID} present`,
      columnName: null,
      dataExplorerColumnName: null,
      type: "string",
      description: null,
      filtering: false,
      sorting: false,
      nullable: false,
      grouping: true,
      attributeGrouping: false,
      values: false,
      filteringConfig: {
        negativeNumbers: false,
        empty: false,
        startsWith: false,
        contains: false,
        lowCardinality: false,
        decimalNumbers: false,
        minMax: false,
      },
      inJSONBody: true,
      displayWideColumn: false,
      hideInTable: false,
      hideFilter: false,
      byVehicleAgeBirthday: false,
      byVehicleAgeExposure: false,
      byVehicleAgeExposureBuckets: [],
    });
    for (const { ID, displayName, ...otherAttributeFields } of attributes) {
      if (!NON_SCHEMA_ACCESSOR.includes(ID)) {
        combinedAttributes.push({
          ID: `options.${option.ID}.present`,
          displayName: `Option: ${option.ID} ${displayName}`,
          ...otherAttributeFields,
        });
      }
    }
  }

  return combinedAttributes;
};

const generateCombinedSchema = (
  options?: Option[],
  attributes?: EntityAttribute[]
): SchemaEntry[] => {
  if (!options || !attributes) {
    return [];
  }

  const schema: SchemaEntry[] = [];

  for (const option of options) {
    schema.push({
      accessor: `options.${option.ID}.present`,
      label: `Option: ${option.ID} present`,
      dataType: DataType.STRING,
      filter: VEHICLES_OPTIONS_GENERIC_FILTER({
        label: `Option: ${option.ID} present`,
        fieldName: `options.${option.ID}.present`,
        filterType: "boolean",
        disableIsEmptyFilters: true,
      }),
    });
    for (const attribute of attributes) {
      if (!NON_SCHEMA_ACCESSOR.includes(attribute.ID)) {
        schema.push({
          accessor: `options.${option.ID}.${attribute.ID}`,
          label: `Option: ${option.ID} ${attribute.displayName}`,
          dataType: DataType.STRING,
          hideFilter: attribute.hideFilter,
          filter: VEHICLES_OPTIONS_GENERIC_FILTER({
            label: `Option: ${option.ID} ${attribute.displayName}`,
            fieldName: `options.${option.ID}.${attribute.ID}`,
            fieldNameForAPI: attribute.ID,
            filterType: "string",
            customFilter: filterStateToFilterGroupState({
              OptionID: {
                values: [option.ID],
                operator: FilterOperator.IN,
              },
            }),
          }),
        });
      }
    }
  }

  return schema;
};

export default useVehicleOptionsCombinedSchema;
