import { useAttributesContext } from "shared/contexts/GenericAttributesContextWrapper";
import { ContextProviderName } from "shared/contexts/types";
import { PARTS_GENERIC_FILTER } from "shared/filterDefinitions";

import { SchemaEntry } from "features/ui/Table";

import { UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
  getSchemaFromAttributes,
} from "./utils";

export const usePartSchema = (accessorsToHide: string[] = []): UseSchema => {
  const { attributes } = useAttributesContext(ContextProviderName.Part);

  const attributeMap = getAttributeMap(attributes);

  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const getFilter = getFilterFunction(PARTS_GENERIC_FILTER, attributeMap);

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const schema: SchemaEntry[] = getSchemaFromAttributes(
    attributes,
    getSchemaEntry,
    undefined,
    undefined,
    accessorsToHide
  );

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  return {
    schema,
    attributes,
    getDisplayLabel,
    attributeMap,
    whitelist: Object.keys(attributeMap || {}),
    getSchemaEntry,
    getSchemaForAccessor,
  };
};

export default usePartSchema;
