import { DuckAccess } from "duck/ui/types";

import { CLAIM_ANALYTICS_TABS } from "pages/ClaimAnalytics/constants";
import { ASSOCIATED_SIGNAL_EVENTS_TAB_TITLE } from "pages/constants";
import {
  ISSUE_DETAILS_TAB_TITLES,
  ISSUES_TAB_TITLE,
  SUGGESTED_ISSUES_TAB_TITLE,
} from "pages/Issues/constants";
import { SIGNAL_EVENT_ANALYTICS_TABS } from "pages/SignalEventsAnalytics/constants";
import {
  VIN_VIEW_ECUS_TAB_KEY,
  VIN_VIEW_EVENTS_TIMELINE_TAB_KEY,
  VIN_VIEW_RISK_MODEL_RESULTS_TAB_KEY,
  VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_KEY,
  VIN_VIEW_SERVICE_RECORDS_TAB_KEY,
  VIN_VIEW_SERVICE_SCHEDULE_TAB_KEY,
  VIN_VIEW_TABS,
} from "pages/VINView/constants";

const toPromptString = (tabTitles: string[]): string =>
  tabTitles.map((tabTitle) => `   - **${tabTitle}**`).join("\n");

const truthyToPromptString = (tabTitles: (string | false)[]): string =>
  toPromptString(tabTitles.filter(Boolean) as string[]);

export const getClaimAnalyticsTabTitleList = (): string =>
  toPromptString(Object.values(CLAIM_ANALYTICS_TABS));

/**
 * Only include the associated signal events tab if it is enabled.
 * Always include the others.
 * @param duckAccess
 * @returns A list of the active signal event analytics tab titles.
 */
export const getSignalEventAnalyticsTabTitleList = (
  duckAccess: DuckAccess
): string =>
  toPromptString(
    Object.values(SIGNAL_EVENT_ANALYTICS_TABS).filter(
      (tabTitle) =>
        tabTitle !== ASSOCIATED_SIGNAL_EVENTS_TAB_TITLE ||
        duckAccess.signalEventAnalytics.associatedSignalEventsTab
    )
  );

export const getVinViewTabTitleList = (duckAccess: DuckAccess): string =>
  truthyToPromptString([
    duckAccess.vinView.healthTabRiskModel &&
      VIN_VIEW_TABS[VIN_VIEW_RISK_MODEL_RESULTS_TAB_KEY],
    duckAccess.vinView.serviceRecordsTab &&
      VIN_VIEW_TABS[VIN_VIEW_SERVICE_RECORDS_TAB_KEY],
    duckAccess.vinView.serviceScheduleTab &&
      VIN_VIEW_TABS[VIN_VIEW_SERVICE_SCHEDULE_TAB_KEY],
    duckAccess.vinView.serviceRecommendationsTab &&
      VIN_VIEW_TABS[VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_KEY],
    duckAccess.vinView.timelineTab &&
      VIN_VIEW_TABS[VIN_VIEW_EVENTS_TIMELINE_TAB_KEY],
    duckAccess.vinView.ecusTab && VIN_VIEW_TABS[VIN_VIEW_ECUS_TAB_KEY],
  ]);

export const getIssuesTabTitleList = (duckAccess: DuckAccess): string =>
  truthyToPromptString([
    duckAccess.issues.issuesTab && ISSUES_TAB_TITLE,
    duckAccess.issues.suggestedIssuesTab && SUGGESTED_ISSUES_TAB_TITLE,
  ]);

export const getIssueDetailsTabTitleList = (): string =>
  Object.values(ISSUE_DETAILS_TAB_TITLES)
    .map((tabTitle) => `   - **${tabTitle}**`)
    .join("\n");
