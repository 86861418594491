import { useConfigContext } from "shared/contexts/ConfigContext";

import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { filterBuilderQueryToFilterBuilderState } from "features/ui/Filters/FilterBuilder/utils";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Title from "features/ui/Title";

import {
  PAGE_TITLE,
  REPAIRS_PAGE_KEY,
  REPAIRS_VEHICLES_PAGE_KEY,
} from "./constants";
import RepairAnalyticsFilters from "./RepairAnalyticsFilters";
import RepairAnalyticsTabs from "./RepairAnalyticsTabs";

const RepairAnalytics = () => {
  const { pages } = useConfigContext();

  const defaultRepairFilters =
    filterBuilderQueryToFilterBuilderState(
      pages.repairAnalytics?.defaultFilters
    ) || DEFAULT_FILTER_BUILDER_STATE;

  const repairsFiltersFilterSortState = useFilterSortState({
    pageKey: REPAIRS_PAGE_KEY,
    defaultFilterValues: defaultRepairFilters,
    pendingFiltersLocalStorageKey: getPendingFiltersKey(REPAIRS_PAGE_KEY),
  });

  const defaultVehicleFilters =
    filterBuilderQueryToFilterBuilderState(
      pages.repairAnalytics?.defaultVehicleFilters
    ) || DEFAULT_FILTER_BUILDER_STATE;

  const vehiclesFiltersFilterSortState = useFilterSortState({
    pageKey: REPAIRS_VEHICLES_PAGE_KEY,
    defaultFilterValues: defaultVehicleFilters,
    pendingFiltersLocalStorageKey: getPendingFiltersKey(
      REPAIRS_VEHICLES_PAGE_KEY
    ),
  });

  const resetState = () => {
    repairsFiltersFilterSortState?.resetFilterSortState();
    vehiclesFiltersFilterSortState?.resetFilterSortState();
  };

  return (
    <>
      <PageHeaderWrapper>
        <Title text={PAGE_TITLE} />
      </PageHeaderWrapper>
      <RepairAnalyticsFilters
        repairsFilterSortState={repairsFiltersFilterSortState}
        vehiclesFilterSortState={vehiclesFiltersFilterSortState}
        defaultRepairFilters={defaultRepairFilters}
        defaultVehicleFilters={defaultVehicleFilters}
      />
      <RepairAnalyticsTabs
        repairsFiltersFilterSortState={repairsFiltersFilterSortState}
        vehiclesFiltersFilterSortState={vehiclesFiltersFilterSortState}
        onBadRequest={resetState}
      />
    </>
  );
};

export default RepairAnalytics;
