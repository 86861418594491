import { FormControlLabel, Switch } from "@mui/material";

import { API_DATE_FORMAT } from "shared/constants";
import { formatDate } from "shared/utils";

import FeatureFlagExternalLink from "pages/Admin/PagesConfig/FeatureFlagExternalLink";
import { PageConfigProps, VehiclesConfig } from "pages/Admin/PagesConfig/types";

import DatePickerRange from "features/ui/DatePickerRange";

import DefaultSignalEventFilterSelector from "./DefaultSignalEventFilterSelector";
import SingleVehicleDateAttributeSelect from "./SingleVehicleDateAttributeSelect";

const EVENT_TIMELINE_KEY = "eventTimeline";
const EVENT_TIMELINE_FROM_DATE_KEY = "eventTimelineFromDate";
const EVENT_TIMELINE_TO_DATE_KEY = "eventTimelineToDate";
const EVENT_TIMELINE_FROM_DATE_VEHICLE_ATTRIBUTE_KEY =
  "eventTimelineFromDateVehicleAttribute";
const EVENT_TIMELINE_SIG_EV_FILTERS_KEY =
  "eventTimelineDefaultSignalEventFilters";
const MAINTENANCE_SCHEDULES_KEY = "maintenanceSchedules";

const VehiclesConfigPage = ({
  pageId,
  config,
  onConfigChange,
}: PageConfigProps<VehiclesConfig>) => (
  <div>
    <div className="flex space-x-3 items-center">
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={config[EVENT_TIMELINE_KEY] ?? false}
              onChange={(_, checked) =>
                onConfigChange(pageId, EVENT_TIMELINE_KEY, checked)
              }
              size="small"
            />
          }
          label={<div className="text-xs">Show event timeline on VINView</div>}
        />
        <FeatureFlagExternalLink flagID="event-timeline" />
      </div>
      <FormControlLabel
        control={
          <Switch
            checked={config[MAINTENANCE_SCHEDULES_KEY] ?? false}
            onChange={(_, checked) =>
              onConfigChange(pageId, MAINTENANCE_SCHEDULES_KEY, checked)
            }
            size="small"
          />
        }
        label={<div className="text-xs">Show maintenance schedules</div>}
      />
    </div>
    {config.eventTimeline && (
      <div className="flex flex-col space-y-3 text-xs mt-2 p-2 border-2">
        <div className="mb-1">Event timeline configs:</div>
        <SingleVehicleDateAttributeSelect
          selectedAttribute={
            config[EVENT_TIMELINE_FROM_DATE_VEHICLE_ATTRIBUTE_KEY]
          }
          onAttributeChange={(attr) =>
            onConfigChange(
              pageId,
              EVENT_TIMELINE_FROM_DATE_VEHICLE_ATTRIBUTE_KEY,
              attr
            )
          }
        />
        <DatePickerRange
          startDateLabel="From date"
          endDateLabel="To date"
          initialDateStart={
            config[EVENT_TIMELINE_FROM_DATE_KEY]
              ? new Date(config[EVENT_TIMELINE_FROM_DATE_KEY])
              : null
          }
          initialDateEnd={
            config[EVENT_TIMELINE_TO_DATE_KEY]
              ? new Date(config[EVENT_TIMELINE_TO_DATE_KEY])
              : null
          }
          onChange={(dateFrom, dateTo) => {
            onConfigChange(
              pageId,
              EVENT_TIMELINE_FROM_DATE_KEY,
              dateFrom
                ? formatDate(dateFrom.toISOString(), API_DATE_FORMAT)
                : null
            );
            onConfigChange(
              pageId,
              EVENT_TIMELINE_TO_DATE_KEY,
              dateTo ? formatDate(dateTo.toISOString(), API_DATE_FORMAT) : null
            );
          }}
        />
        <DefaultSignalEventFilterSelector
          initialFilterQuery={config[EVENT_TIMELINE_SIG_EV_FILTERS_KEY]}
          onChange={(filterQuery) =>
            onConfigChange(
              pageId,
              EVENT_TIMELINE_SIG_EV_FILTERS_KEY,
              filterQuery
            )
          }
        />
      </div>
    )}
  </div>
);

export default VehiclesConfigPage;
