import { useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";

import { useIssuesFiltersSchema } from "shared/hooks";
import useSuggestedIssuesSchema from "shared/schemas/useSuggestedIssuesSchema";

import FeatureFlagExternalLink from "pages/Admin/PagesConfig/FeatureFlagExternalLink";
import { IssuesConfig, PageConfigProps } from "pages/Admin/PagesConfig/types";

import DropdownSelect from "features/ui/DropdownSelect";
import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterBuilderQueryToFilterBuilderState,
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { getFilterLabel } from "features/ui/Filters/utils";

import { DEFAULT_FILTERS_KEY } from "./ClaimAnalyticsConfigPage";
import ClaimAttributeSingleSelect from "./ClaimAttributeSingleSelect";
import ClaimAttributesMultiSelect from "./ClaimAttributesMultiSelect";

const SUGGESTED_ISSUES_KEY = "suggestedIssues";
const ISSUES_PAGE_KEY = "admin_default_issues_filters";
const SUGGESTED_ISSUES_PAGE_KEY = "admin_default_suggested_issues_filters";
const ISSUES_FILTER_LABEL = "Default Issue Filters";
const SUGGESTED_ISSUES_FILTER_LABEL = "Default Suggested Issue Filters";
const TOP_X_CHARTS_OPTIONS_KEY = "topXChartsOptions";
const SUGGESTED_ISSUES_DEFAULT_FILTERS_KEY = "suggestedIssuesDefaultFilters";
const DEFAULT_BREAKDOWN_ATTRIBUTE_KEY = "defaultBreakdownAttribute";
export const ISSUE_ACTIVITY_LOG_KEY = "issueActivityLog";

const IssuesConfigPage = ({
  pageId,
  config,
  onConfigChange,
}: PageConfigProps<IssuesConfig>) => {
  // issues filters
  const issuesFiltersSchema = useIssuesFiltersSchema();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const initialFilters = filterBuilderQueryToFilterBuilderState(
    config[DEFAULT_FILTERS_KEY]
  );

  const defaultFilters = filterStateToFilterGroupState(initialFilters);

  const filterState = useFilterSortState({
    pageKey: ISSUES_PAGE_KEY,
    defaultFilterValues: defaultFilters ?? DEFAULT_FILTER_BUILDER_STATE,
    disableUsingLocalStorage: true,
    disableUsingQuery: true,
  });

  const handleFiltersChange = (filterState: FilterGroupState) => {
    onConfigChange(pageId, DEFAULT_FILTERS_KEY, getFiltersQuery(filterState));
  };

  // suggested issues filters
  const { schema: suggestedIssuesSchema } = useSuggestedIssuesSchema();
  const [suggestedIssuesDropdownOpen, setSuggestedIssuesDropdownOpen] =
    useState(false);

  const initialSuggestedIssuesFilters = filterBuilderQueryToFilterBuilderState(
    config[SUGGESTED_ISSUES_DEFAULT_FILTERS_KEY]
  );

  const defaultSuggestedIssuesFilters = filterStateToFilterGroupState(
    initialSuggestedIssuesFilters
  );

  const suggestedIssuesFilterState = useFilterSortState({
    pageKey: SUGGESTED_ISSUES_PAGE_KEY,
    defaultFilterValues:
      defaultSuggestedIssuesFilters ?? DEFAULT_FILTER_BUILDER_STATE,
    disableUsingLocalStorage: true,
    disableUsingQuery: true,
  });

  const handleSuggestedFiltersChange = (filterState: FilterGroupState) => {
    onConfigChange(
      pageId,
      SUGGESTED_ISSUES_DEFAULT_FILTERS_KEY,
      getFiltersQuery(filterState)
    );
  };

  // Ensure we always have a defined value for selectedBreakdownAttribute
  const selectedBreakdownAttribute =
    config[DEFAULT_BREAKDOWN_ATTRIBUTE_KEY] ?? null;

  return (
    <>
      <div className="flex space-x-3 mb-4">
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={config[SUGGESTED_ISSUES_KEY] ?? false}
                onChange={(_, checked) =>
                  onConfigChange(pageId, SUGGESTED_ISSUES_KEY, checked)
                }
                size="small"
              />
            }
            label={<div className="text-xs">Show Suggested Issues</div>}
          />
          <FeatureFlagExternalLink flagID="suggested-issues" block />
        </div>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={config[ISSUE_ACTIVITY_LOG_KEY] ?? false}
                onChange={(_, checked) =>
                  onConfigChange(pageId, ISSUE_ACTIVITY_LOG_KEY, checked)
                }
                size="small"
              />
            }
            label={<div className="text-xs">Show Issue Activity Log</div>}
          />
        </div>
        <DropdownSelect
          testId="default-issues-filters-dropdown"
          label={getFilterLabel(ISSUES_FILTER_LABEL, filterState.filters)}
          open={dropdownOpen}
          onOpen={(open) => {
            setDropdownOpen(open);
          }}
          hasAdvancedFilters={false}
          content={
            <FilterSelector
              schema={issuesFiltersSchema}
              filterSortState={filterState}
              disableAdvancedFilter
              pendingFiltersKey={getPendingFiltersKey(ISSUES_PAGE_KEY)}
              onApplyFilters={handleFiltersChange}
              testId="issues-filters-selector"
            />
          }
        />
        <DropdownSelect
          testId="default-suggested-issues-filters-dropdown"
          label={getFilterLabel(
            SUGGESTED_ISSUES_FILTER_LABEL,
            suggestedIssuesFilterState.filters
          )}
          open={suggestedIssuesDropdownOpen}
          onOpen={(open) => {
            setSuggestedIssuesDropdownOpen(open);
          }}
          hasAdvancedFilters={false}
          content={
            <FilterSelector
              schema={suggestedIssuesSchema}
              filterSortState={suggestedIssuesFilterState}
              disableAdvancedFilter
              pendingFiltersKey={getPendingFiltersKey(
                SUGGESTED_ISSUES_PAGE_KEY
              )}
              onApplyFilters={handleSuggestedFiltersChange}
              testId="suggested-issues-filters-selector"
            />
          }
        />
      </div>
      <div className="flex space-x-3">
        <ClaimAttributesMultiSelect
          selectedAttributes={config[TOP_X_CHARTS_OPTIONS_KEY]}
          onAttributesChange={(attributes) =>
            onConfigChange(pageId, TOP_X_CHARTS_OPTIONS_KEY, attributes)
          }
        />
        <ClaimAttributeSingleSelect
          key={`breakdown-${config[DEFAULT_BREAKDOWN_ATTRIBUTE_KEY]}`}
          selectedAttribute={selectedBreakdownAttribute}
          onAttributesChange={(attribute) =>
            onConfigChange(pageId, DEFAULT_BREAKDOWN_ATTRIBUTE_KEY, attribute)
          }
        />
      </div>
    </>
  );
};

export default IssuesConfigPage;
