import React from "react";

import { useListAlertDefinitionsAttributes } from "shared/api/alertDefinitions/hooks";
import { useClaimAttributes } from "shared/api/claims/hooks";
import { useCustomRecordAttributes } from "shared/api/customRecords/hooks";
import { useCustomSignalEventDefinitionsAttributes } from "shared/api/customSignalEvents/hooks";
import {
  useFailureModeAttributes,
  useFailureModeEventsAttributes,
  useRiskModelPredictionAttributes,
} from "shared/api/failureModes/hooks";
import {
  useInspectionAttributes,
  useInspectionItemAttributes,
} from "shared/api/inspections/hooks";
import { useIssuesAttributes } from "shared/api/issues/hooks";
import { useListPartsAttributes } from "shared/api/parts/hooks";
import { useListRepairPartsAttributes } from "shared/api/repairParts/hooks";
import { useRepairAttributes } from "shared/api/repairs/hooks";
import { useSensorReadingsAttributes } from "shared/api/sensors/hooks";
import { useServicePlanAttributes } from "shared/api/servicePlans/hooks";
import { useServiceRecommendationsAttributes } from "shared/api/serviceRecommendations/hooks";
import { useServiceRecordsAttributes } from "shared/api/serviceRecords/hooks";
import {
  useDealerAttributes,
  useSignalEventsOccurrencesAttributes,
} from "shared/api/signalEvents/hooks";
import { useSuggestedIssuesAttributes } from "shared/api/suggestedIssues/hooks";
import { useVehicleAttributes } from "shared/api/vehicles/hooks";
import { useListWorkOrdersAttributes } from "shared/api/workOrders/hooks";

import ConfigContextWrapper from "./ConfigContext";
import EventRegistryContextWrapper from "./EventRegistryContext";
import { createGenericContextWrapper } from "./GenericAttributesContextWrapper";
import {
  ContextName,
  ContextProviderConfig,
  ContextProviderName,
  ContextWrapComponentProps,
  ContextWrapper,
} from "./types";
import VehicleECUsAttributesContextWrapper from "./VehicleECUsAttributesContextWrapper";
import VehicleOptionsAttributeContextWrapper from "./VehicleOptionsAttributesContextWrapper";

const CONTEXT_PROVIDERS_CONFIG: ContextProviderConfig = {
  [ContextProviderName.AlertDefinition]: useListAlertDefinitionsAttributes,
  [ContextProviderName.Claim]: useClaimAttributes,
  [ContextProviderName.CustomRecord]: useCustomRecordAttributes,
  [ContextProviderName.CustomSignalEventDefinition]:
    useCustomSignalEventDefinitionsAttributes,
  [ContextProviderName.Dealer]: useDealerAttributes,
  [ContextProviderName.FailureMode]: useFailureModeAttributes,
  [ContextProviderName.FailureModeEvents]: useFailureModeEventsAttributes,
  [ContextProviderName.Inspection]: useInspectionAttributes,
  [ContextProviderName.InspectionItem]: useInspectionItemAttributes,
  [ContextProviderName.Issue]: useIssuesAttributes,
  [ContextProviderName.Repair]: useRepairAttributes,
  [ContextProviderName.RiskModelPrediction]: useRiskModelPredictionAttributes,
  [ContextProviderName.SensorReading]: useSensorReadingsAttributes,
  [ContextProviderName.ServicePlan]: useServicePlanAttributes,
  [ContextProviderName.ServiceRecommendation]:
    useServiceRecommendationsAttributes,
  [ContextProviderName.SignalEventOccurrence]:
    useSignalEventsOccurrencesAttributes,
  [ContextProviderName.SuggestedIssue]: useSuggestedIssuesAttributes,
  [ContextProviderName.Vehicle]: useVehicleAttributes,
  [ContextProviderName.WorkOrder]: useListWorkOrdersAttributes,
  [ContextProviderName.Part]: useListPartsAttributes,
  [ContextProviderName.RepairPart]: useListRepairPartsAttributes,
  [ContextProviderName.ServiceRecord]: useServiceRecordsAttributes,
};

// Other context that don't match our /attributes specific interfaces above
const ADDITIONAL_WRAPPERS: ContextWrapper[] = [
  VehicleECUsAttributesContextWrapper,
  EventRegistryContextWrapper,
  ConfigContextWrapper,
  VehicleOptionsAttributeContextWrapper,
];

const contextWrapperItems = Object.entries(CONTEXT_PROVIDERS_CONFIG).map(
  ([name, useAttributesHook]) => {
    const GenericContextWrapper = createGenericContextWrapper(
      useAttributesHook,
      name as ContextName
    );

    return { name, GenericContextWrapper };
  }
);

const ContextWrappers: React.FC<ContextWrapComponentProps> = ({ children }) => {
  // First, wrap with the generic context wrappers
  let wrappedChildren = contextWrapperItems.reduce(
    (acc, { GenericContextWrapper }) => (
      <GenericContextWrapper>{acc}</GenericContextWrapper>
    ),
    children
  );

  // Wrap with the additional context wrappers
  wrappedChildren = ADDITIONAL_WRAPPERS.reduce(
    (acc, Wrapper) => <Wrapper>{acc}</Wrapper>,
    wrappedChildren
  );

  return wrappedChildren;
};

export default ContextWrappers;
