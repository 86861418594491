import "shared/filterDefinitions";

import { useAttributesContext } from "shared/contexts/GenericAttributesContextWrapper";
import { ContextProviderName } from "shared/contexts/types";
import { FAILURE_MODE_FILTER } from "shared/filterDefinitions";

import { FilterSchemaItem } from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";

import { useFailureModeSchema } from "./failureModeSchema";
import { SchemaEntryOverride, SchemaRelations, UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
  getSchemaFromAttributes,
} from "./utils";
import useVehiclesSchema from "./vehiclesSchema";

export const useRiskModelPredictionsSchema = (
  filterGenerator: (item: FilterSchemaItem) => FilterSchemaItem,
  accessorsToHide: string[] = [],
  vehicleAccessorsToHide: string[] = [],
  failureModeAccessorsToHide: string[] = [],
  showVehiclesSchema: boolean = false,
  showFailureModeSchema: boolean = false
): UseSchema => {
  const { attributes } = useAttributesContext(
    ContextProviderName.RiskModelPrediction
  );

  const vehiclesSchema = useVehiclesSchema(vehicleAccessorsToHide);
  const failureModeSchema = useFailureModeSchema(failureModeAccessorsToHide);

  const attributeMap = getAttributeMap(attributes);

  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const getFilter = getFilterFunction(filterGenerator, attributeMap);

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const relations: SchemaRelations = {};
  if (showVehiclesSchema) {
    relations["/v1/vehicles"] = vehiclesSchema;
  }

  if (showFailureModeSchema) {
    relations["/v1/failureModes"] = failureModeSchema;
  }

  const overrides: SchemaEntryOverride = {
    failureModeID: {
      schemaOverride: {
        label: "Failure Mode",
        filter: FAILURE_MODE_FILTER,
        sortable: false,
      },
    },
    riskStatus: {
      filterOverride: {
        disableArbitraryText: true,
      },
    },
    riskLabel: {
      filterOverride: {
        disableArbitraryText: true,
      },
    },
  };

  const schema: SchemaEntry[] = getSchemaFromAttributes(
    attributes,
    getSchemaEntry,
    overrides,
    relations,
    accessorsToHide,
    true
  );

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  return {
    schema,
    attributes,
    getDisplayLabel,
    attributeMap,
    whitelist: Object.keys(attributeMap || {}),
    getSchemaEntry,
    getSchemaForAccessor,
  };
};
