import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import { useInspection } from "shared/api/inspections/hooks";
import { useTenantMileageUnit } from "shared/hooks";
import { EventTypeEnum } from "shared/types";
import { getEntityWithExternalIDTitle } from "shared/utils";

import CardResourceDetails from "pages/shared/CardResourceDetails";

import APIError from "features/ui/APIError/APIError";
import Board from "features/ui/Board/Board";
import CardWithJSONData from "features/ui/CardWithJSONData";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Title from "features/ui/Title";

import InspectionItems from "./InspectionItems";

type Params = {
  id: string;
};

const SingleInspection = () => {
  const { id } = useParams<Params>();

  const { data, isLoading, error } = useInspection({
    ID: id as string,
    mileageUnit: useTenantMileageUnit(),
  });

  if (isLoading) {
    return <Skeleton count={2} height={300} className="mb-3" />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (!data) {
    return <div>No data</div>;
  }

  const boardElements: JSX.Element[][] = [
    [
      <CardResourceDetails
        data={data}
        eventType={EventTypeEnum.INSPECTION}
        key="inspection-details"
      />,
    ],
    [
      <CardWithJSONData
        data={data}
        title="Inspection Data"
        key="inspection-other-data"
      />,
    ],
  ];

  const { externalID } = data;

  const title = getEntityWithExternalIDTitle(
    externalID,
    EventTypeEnum.INSPECTION
  );

  return (
    <>
      <PageHeaderWrapper>
        <Title text={title} />
      </PageHeaderWrapper>
      <Board elements={boardElements} />
      {data.items?.length > 0 && <InspectionItems items={data.items} />}
    </>
  );
};

export default SingleInspection;
