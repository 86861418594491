import {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import classNames from "classnames";
import { DuckContext } from "duck/context/DuckContextWrapper";
import { preloadPrompts } from "duck/graph/nodes/loadPrompt";
import { createCodeDescriptionsVectorstore } from "duck/graph/Vectorstore/codeDescriptionsVectorstore";
import { DUCK_DRAG_HANDLE_CLASS_NAME } from "duck/ui/constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import { MdExpandMore, MdFullscreen } from "react-icons/md";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
} from "@mui/material";

import DuckOpenContent from "./DuckOpenContent";
import DuckSummary from "./DuckSummary";
import { useDuckVisibility } from "./hooks";
import { getDuckHeight, hasAllEnvVarsForViaDuck } from "./utils";

interface DuckProps {
  isDraggable?: boolean;
  setIsDraggable?: (draggable: boolean) => void;
  forceOpen?: boolean;
}

const Duck = ({
  isDraggable = false,
  setIsDraggable,
  forceOpen,
}: DuckProps) => {
  const { viaDuck } = useFlags();
  const supportsDuck = hasAllEnvVarsForViaDuck();

  const { isDuckVisible, setIsDuckVisible } = useDuckVisibility(forceOpen);

  const [isDragging, setIsDragging] = useState(false);

  const { loading } = useContext(DuckContext);

  useEffect(() => {
    if (supportsDuck && viaDuck) {
      // Preloading prompts allows the agent to initialize faster.
      // This runs asynchronously and we do not wait for the result.
      // The preloading happens in memory. We don't need to actively do anything with
      // the result of the preload call.
      void preloadPrompts();
      void createCodeDescriptionsVectorstore();
    }
  }, [supportsDuck, viaDuck]);

  const handleMouseDown = () => {
    if (isDraggable) setIsDragging(true);
  };

  const handleMouseUp = () => {
    if (isDragging) setIsDragging(false);
  };

  const handleAccordionChange = useCallback(
    (event: MouseEvent<SVGElement>) => {
      event.stopPropagation();
      setIsDuckVisible(!isDuckVisible);
    },
    [isDuckVisible, setIsDuckVisible]
  );

  const handlePopoutClick = useCallback(() => {
    setIsDraggable && setIsDraggable(!isDraggable);
  }, [isDraggable, setIsDraggable]);

  if (!viaDuck || !supportsDuck) {
    return null;
  }

  // The accordion automatically rotates the icon vertically 180 degrees
  // to change from expand to contract.
  const expandIcon = isDraggable ? (
    <MdExpandMore
      onClick={loading ? undefined : handlePopoutClick}
      className={classNames("rotate-180", {
        "text-gray-600": !loading,
        "text-gray-400": loading,
      })}
      cursor={loading ? "default" : "pointer"}
    />
  ) : (
    <MdExpandMore
      onClick={handleAccordionChange}
      className="rotate-180"
      cursor="pointer"
    />
  );

  const popoutIcon = isDraggable ? undefined : (
    <MdFullscreen
      className={classNames({
        "text-gray-600": !loading,
        "text-gray-400": loading,
      })}
    />
  );

  const height = getDuckHeight(isDraggable, isDuckVisible);

  return (
    <Accordion
      expanded={isDuckVisible}
      sx={{
        backgroundColor: "#cbd5e1",
        "&.MuiAccordion-root": {
          borderTopRightRadius: "0.75rem",
        },
        height: { height },
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
      disableGutters
    >
      <AccordionSummary
        className={DUCK_DRAG_HANDLE_CLASS_NAME}
        expandIcon={expandIcon}
        style={{
          cursor: isDragging ? "grabbing" : isDraggable ? "grab" : "default",
          paddingLeft: "1.5rem",
          flexShrink: 0,
        }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          width="100%"
        >
          <DuckSummary />
          <IconButton
            disabled={loading}
            onClick={handlePopoutClick}
            size="small"
            sx={{
              borderRadius: "0.375rem",
            }}
            component="div"
            role="button"
          >
            {popoutIcon}
          </IconButton>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          // It was tricky to get the AccordionDetails to fill the remaining space in the Accordion.
          // Manually setting the top value is not awesome but that is not likely to change frequently.
          // And it works.
          padding: "0rem",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          top: "58px", // when we remove the BETA badge, this should be "48px",
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <DuckOpenContent />
      </AccordionDetails>
    </Accordion>
  );
};

export default Duck;
