import { useCallback, useMemo } from "react";
import { generatePath, Link } from "react-router-dom";

import {
  AlertDefinition,
  AlertEventType,
  deleteAlertDefinition,
} from "shared/api/alertDefinitions/api";
import {
  useListAlertDefinitions,
  useListAlertDefinitionsCount,
} from "shared/api/alertDefinitions/hooks";
import { getSortFilter } from "shared/api/utils";
import { ALERT_DEFINITIONS_GENERIC_FILTER } from "shared/filterDefinitions";
import useAlertDefinitionSchema from "shared/schemas/alertDefinitionsSchema";
import { SortBy } from "shared/types";

import APIError from "features/ui/APIError";
import {
  FilterGroupState,
  FilterRowState,
} from "features/ui/Filters/FilterBuilder/types";
import {
  getFiltersQuery,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { FilterOperator } from "features/ui/Filters/types";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import DeleteAndPermissionRowAction from "features/ui/Table/PermissionRowAction/DeleteAndPermissionRowAction";
import { DataType } from "features/ui/Table/TableBodyCell/types";
import TableCount from "features/ui/Table/TableCount";

import { routes } from "services/routes";

import {
  ALERT_DEFINITIONS_PER_PAGE,
  ALERT_EVENT_TYPE_OPTIONS,
  OBJECT_EVENT_TYPES,
} from "./constants";
import AlertDefinitionObjectAlertLink from "./Form/AlertDefinitionObjectAlertLink";

const PAGE_KEY = "alertDefinitions";
const DEFAULT_SORT: SortBy = { name: "asc" };

const AlertDefinitions = () => {
  const { schema } = useAlertDefinitionSchema();

  const modifiedSchema: SchemaEntry[] = [
    {
      label: "Name",
      accessor: "name",
      dataType: DataType.STRING,
      sortable: true,
      filter: ALERT_DEFINITIONS_GENERIC_FILTER({
        label: "Name",
        fieldName: "name",
      }),
    },
    {
      label: "Description",
      accessor: "description",
      dataType: DataType.STRING,
      filter: ALERT_DEFINITIONS_GENERIC_FILTER({
        label: "Description",
        fieldName: "description",
      }),
      sortable: true,
    },
    {
      label: "Object type",
      accessor: "eventType",
      dataType: DataType.STRING,
      sortable: true,
      filter: ALERT_DEFINITIONS_GENERIC_FILTER({
        label: "Object type",
        fieldName: "eventType",
      }),
    },
    {
      label: "Watched object name",
      accessor: "watchedObjectID",
      dataType: DataType.STRING,
      filter: ALERT_DEFINITIONS_GENERIC_FILTER({
        label: "Watched object name",
        fieldName: "watchedObjectID",
      }),
      sortable: false,
    },
    {
      label: "Events",
      accessor: "watchedObjectEventTypes",
      dataType: DataType.STRING,
      sortable: false,
    },
    {
      label: "Updated",
      accessor: "updatedAt",
      dataType: DataType.DATE_WITH_TIME,
      sortable: true,
      filter: ALERT_DEFINITIONS_GENERIC_FILTER({
        label: "Updated",
        fieldName: "updatedAt",
      }),
    },
    {
      label: "Updated by",
      accessor: "updatedBy",
      dataType: DataType.STRING,
      sortable: true,
      filter: ALERT_DEFINITIONS_GENERIC_FILTER({
        label: "Updated by",
        fieldName: "updatedBy",
      }),
    },
    {
      label: "Created",
      accessor: "createdAt",
      dataType: DataType.DATE_WITH_TIME,
      sortable: true,
      filter: ALERT_DEFINITIONS_GENERIC_FILTER({
        label: "Created",
        fieldName: "createdAt",
      }),
    },
    {
      label: "Created by",
      accessor: "createdBy",
      dataType: DataType.STRING,
      sortable: true,
      filter: ALERT_DEFINITIONS_GENERIC_FILTER({
        label: "Created by",
        fieldName: "createdBy",
      }),
    },
    {
      label: "Actions",
      accessor: "actions",
      dataType: DataType.JSX,
      sortable: false,
    },
  ];

  const DEFAULT_EVENT_TYPE_FILTER: FilterGroupState<FilterRowState> = {
    id: "group-0",
    type: "group",
    anyAll: "all",
    children: [
      {
        id: "row-0",
        type: "row",
        attribute: "eventType",
        operator: FilterOperator.IN,
        values: ALERT_EVENT_TYPE_OPTIONS.map(
          (option) => option.id as string
        ).filter((id) => OBJECT_EVENT_TYPES.includes(id as AlertEventType)),
      },
    ],
  };

  const {
    filters,
    sort,
    manageFilterChange,
    manageOnSortChange,
    resetFilterSortState,
    resetFilters,
    initialized: filtersInitialized,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
  });

  const requestParams = {
    filter: getFiltersQuery(
      mergeFilterGroupStates(filters, DEFAULT_EVENT_TYPE_FILTER)
    ),
    sort: getSortFilter(sort),
    refreshKey: true,
  };

  const { data, error, isLoading, requestKey, ...paginationData } =
    useListAlertDefinitions(requestParams);

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
    requestKey: countRequestKey,
  } = useListAlertDefinitionsCount(requestParams);

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const formatRows = useCallback(
    (alertDefinition: AlertDefinition) => {
      const pathname = generatePath(routes.alertDefinition, {
        id: alertDefinition.ID,
      });

      return {
        ...alertDefinition,
        name: (
          <Link to={pathname} className="text-metabase-blue hover:underline">
            {alertDefinition.name}
          </Link>
        ),
        watchedObjectEventTypes: (
          <span>
            {alertDefinition.watchedObjectEventTypes?.join(", ") || ""}
          </span>
        ),
        watchedObjectID: (
          <AlertDefinitionObjectAlertLink
            small
            currentAlertDefinition={alertDefinition}
          ></AlertDefinitionObjectAlertLink>
        ),
        actions: (
          <DeleteAndPermissionRowAction
            entity={alertDefinition}
            canEdit={alertDefinition.canEdit}
            deleteCallback={deleteAlertDefinition}
            entityType="alertDefinition"
            permissions={alertDefinition.access}
            requestKeys={[requestKey, countRequestKey]}
          />
        ),
      };
    },
    [requestKey, countRequestKey]
  );

  const formattedData = useMemo(
    () => data?.filter((row) => row.watchedObjectID !== null).map(formatRows),
    [data, formatRows]
  );

  return (
    <>
      <div className="flex my-2 items-center">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={resetFilters}
        />
        <TableCount
          extraClasses="ml-auto self-end"
          count={countData?.count as number}
          entityName="alert definition"
          isLoading={countIsLoading}
          error={!!countError}
        />
      </div>
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
      {!error && (
        <div className="flex flex-col gap-4">
          <PaginatedTable
            {...paginationData}
            data={formattedData}
            schema={modifiedSchema}
            isLoading={isLoading}
            loadingRows={ALERT_DEFINITIONS_PER_PAGE}
            sortBy={sort}
            onSort={handleSorting}
            filtersInitialized={filtersInitialized}
            onFiltersReset={resetFilters}
            onFilterChange={manageFilterChange}
            filters={filters}
            pageKey={PAGE_KEY}
            dense
          />
        </div>
      )}
    </>
  );
};
export default AlertDefinitions;
