import { useConfigContext } from "shared/contexts/ConfigContext";

import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { filterBuilderQueryToFilterBuilderState } from "features/ui/Filters/FilterBuilder/utils";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Title from "features/ui/Title";

import {
  INSPECTIONS_PAGE_KEY,
  INSPECTIONS_VEHICLES_PAGE_KEY,
  PAGE_TITLE,
} from "./constants";
import InspectionAnalyticsFilters from "./InspectionAnalyticsFilters";
import InspectionAnalyticsTabs from "./InspectionAnalyticsTabs";

const InspectionAnalytics = () => {
  const { pages } = useConfigContext();

  const defaultInspectionFilters =
    filterBuilderQueryToFilterBuilderState(
      pages.inspectionAnalytics?.defaultFilters
    ) || DEFAULT_FILTER_BUILDER_STATE;

  const inspectionsFiltersFilterSortState = useFilterSortState({
    pageKey: INSPECTIONS_PAGE_KEY,
    defaultFilterValues: defaultInspectionFilters,
    pendingFiltersLocalStorageKey: getPendingFiltersKey(INSPECTIONS_PAGE_KEY),
  });

  const defaultVehicleFilters =
    filterBuilderQueryToFilterBuilderState(
      pages.inspectionAnalytics?.defaultVehicleFilters
    ) || DEFAULT_FILTER_BUILDER_STATE;

  const vehiclesFiltersFilterSortState = useFilterSortState({
    pageKey: INSPECTIONS_VEHICLES_PAGE_KEY,
    defaultFilterValues: defaultVehicleFilters,
    pendingFiltersLocalStorageKey: getPendingFiltersKey(
      INSPECTIONS_VEHICLES_PAGE_KEY
    ),
  });

  const resetState = () => {
    inspectionsFiltersFilterSortState?.resetFilterSortState();
    vehiclesFiltersFilterSortState?.resetFilterSortState();
  };

  return (
    <>
      <PageHeaderWrapper>
        <Title text={PAGE_TITLE} />
      </PageHeaderWrapper>
      <InspectionAnalyticsFilters
        inspectionsFilterSortState={inspectionsFiltersFilterSortState}
        vehiclesFilterSortState={vehiclesFiltersFilterSortState}
        defaultInspectionFilters={defaultInspectionFilters}
        defaultVehicleFilters={defaultVehicleFilters}
      />
      <InspectionAnalyticsTabs
        inspectionsFiltersFilterSortState={inspectionsFiltersFilterSortState}
        vehiclesFiltersFilterSortState={vehiclesFiltersFilterSortState}
        onBadRequest={resetState}
      />
    </>
  );
};

export default InspectionAnalytics;
