import React, { useState } from "react";
import DP, { ReactDatePickerProps } from "react-datepicker";

import { toSnakeCase } from "shared/utils";

import "./DatePicker.css";

import { TextField } from "@mui/material";

import {
  LOCALIZED_DATETIME_FORMAT,
  SHORT_DATE_FORMAT,
  SHORTER_DATE_FORMAT,
} from "shared/constants";

export interface DatePickerProps extends ReactDatePickerProps {
  label?: string;
  initialDate?: Date | null;
  defaultIsOpen?: boolean;
}

const DatePicker = ({
  label,
  initialDate = null,
  placeholderText = "Select a date",
  onChange,
  defaultIsOpen,
  ...datePickerProps
}: DatePickerProps) => {
  const [selected, setSelected] = useState<Date | null>(initialDate);

  const handleDateChange = (
    date: Date | null,
    event: React.SyntheticEvent<any, Event> | undefined
  ) => {
    setSelected(date);
    if (onChange) {
      onChange(date, event);
    }
  };

  return (
    <div>
      <DP
        {...datePickerProps}
        selected={selected}
        fixedHeight
        onChange={handleDateChange}
        customInput={<TextField label={label} size="small" />}
        placeholderText={placeholderText}
        nextMonthButtonLabel=">"
        previousMonthButtonLabel="<"
        autoFocus={defaultIsOpen}
        id={label && toSnakeCase(label)}
        strictParsing
        popperPlacement="bottom-start"
        dateFormat={[
          datePickerProps.showTimeSelect
            ? LOCALIZED_DATETIME_FORMAT
            : SHORT_DATE_FORMAT,
          SHORTER_DATE_FORMAT,
        ]}
        popperClassName={datePickerProps.showTimeSelect ? "w-96" : ""}
        onCalendarOpen={() => {
          setTimeout(() => {
            const calendar = document.querySelector(".react-datepicker-popper");
            if (calendar) {
              calendar.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "nearest",
              });
            }
          }, 0);
        }}
      />
    </div>
  );
};

export default DatePicker;
