import LabelValuePair from "features/ui/LabelValuePairs/LabelValuePair";
import { RowData, SchemaEntry } from "features/ui/Table";
import { formatValue } from "features/ui/Table/TableBodyCell/TableBodyCell";
import { getValueByAccessor } from "features/ui/Table/utils";

interface Props<T> {
  schema: SchemaEntry<keyof T>[];
  data: T;
  fields: string[];
  hasNarratives?: boolean;
  alignment?: "left" | "center";
  translate?: boolean;
}

const DEFAULT_ALIGNMENT = "center";

const LabelValuePairs = <T,>({
  schema,
  data,
  fields = [],
  hasNarratives = false,
  alignment = DEFAULT_ALIGNMENT,
  translate = false,
}: Props<T>) => (
  <div>
    {fields.map((accessor) => {
      const schemaEntry = schema.find((entry) => entry.accessor === accessor);
      if (!schemaEntry) return null;

      const value = formatValue({
        value: getValueByAccessor(
          accessor as string,
          data as RowData
        ) as string,
        dataType: schemaEntry.dataType,
      });

      if (!value) return null;

      return (
        <LabelValuePair
          key={accessor}
          hasNarratives={hasNarratives}
          alignment={alignment}
          schemaEntryLabel={schemaEntry.label}
          initialValue={value}
          translate={translate}
        />
      );
    })}
  </div>
);

export default LabelValuePairs;
